import React, { useEffect, useState, KeyboardEvent } from "react";
import { Helmet } from "react-helmet-async";
import { Formik, Field, FormikHelpers } from "formik";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import {
  TextField,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  Switch,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { DoneAll as DoneAllIcon } from "@mui/icons-material";
import InputMask from "react-input-mask";
import Alert from "../../../components/Alert";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import {
  Client,
  statesOfBrazil,
  constructionExecutedTypes,
  hireTypes,
  employeeQuantities,
} from "../../../config/app-info";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const validationSchema = yup.object({
  name: yup
    .string()
    .min(3, "O nome deve ter no mínimo 3 letras")
    .required("Nome é obrigatório"),
  cnpj: yup.string().required("CNPJ é obrigatório"),
  email: yup.string().required("E-mail é obrigatório"),
});

type Props = {
  section: string;
};

const ClientsForm: React.FC<Props> = ({ section }) => {
  const [client, setClient] = useState<Client>({} as Client);
  const [alert, setAlert] = useState({
    title: "",
    message: "",
    isOpened: false,
  });
  let { id: clientId } = useParams();
  const { user } = useAuth();

  useEffect(() => {
    if (clientId) {
      const fetchUserData = async () => {
        const response = await api.get(`api/clients/${clientId}`);
        setClient(response.data);
      };
      fetchUserData();
    } else {
      setClient({
        id: 0,
        name: "",
        status: true,
        email: "",
        cnpj: "",
        phone: "",
        nameResponsible: "",
        address: "",
        number: "",
        cep: "",
        neighborhood: "",
        complement: "",
        comments: "",
        city: "",
        uf: "",
        userId: 0,
        constructionExecutedType: 0,
        hireType: 0,
        employeeQuantity: 0,
      } as Client);
    }
  }, [clientId]);

  const onSubmit = async (
    values: Client,
    { setSubmitting, resetForm }: FormikHelpers<Client>
  ) => {
    setSubmitting(true);

    if (String(values.employeeQuantity) === "") {
      values.employeeQuantity = -1;
    }
    if (String(values.constructionExecutedType) === "") {
      values.constructionExecutedType = -1;
    }
    if (String(values.hireType) === "") {
      values.hireType = -1;
    }

    if (clientId) {
      try {
        const response = await api.put(`api/clients/${clientId}/`, values);

        if (response.status === 200 || response.status === 204) {
          setAlert({
            title: "Alteração concluída!",
            message: "Cliente alterado com sucesso",
            isOpened: true,
          });
        }
      } catch (error) {
        setAlert({
          title: "Erro na alteração",
          message: "Algo deu errado. Tente novamente mais tarde.",
          isOpened: true,
        });
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        const verifyCNPJ = await api.post("api/clients/verify-cnpj/", {
          cnpj: values.cnpj,
        });

        if (!verifyCNPJ.data) {
          if (!values.status) values.status = false;
          values.userId = user.id;
          const response = await api.post("api/clients/", values);

          if (response.status === 201) {
            setAlert({
              title: "Cadastro concluído!",
              message: "Cliente cadastrado com sucesso",
              isOpened: true,
            });
            resetForm();
          }
        } else {
          setAlert({
            title: "Erro no cadastro",
            message: "CNPJ já existente no cadastro de clientes.",
            isOpened: true,
          });
        }
      } catch (error) {
        setAlert({
          title: "Erro no cadastro",
          message: "Algo deu errado. Tente novamente mais tarde.",
          isOpened: true,
        });
      } finally {
        setSubmitting(false);
      }
    }
  };

  const closeError = () => {
    setAlert({
      title: "",
      message: "",
      isOpened: false,
    });
  };

  const checkKeyDown = (e: KeyboardEvent) => {
    if (e.code === "Enter") e.preventDefault();
  };

  return (
    <>
      <Helmet>
        <title>{section}</title>
      </Helmet>
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            {section}
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Card mb={6}>
        <CardContent>
          {client && (
            <Formik
              initialValues={client}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                handleSubmit,
                values,
                handleChange,
                errors,
                isSubmitting,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  noValidate
                  onKeyDown={(e) => checkKeyDown(e)}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={2} mb={4}>
                      <Field name="cnpj">
                        {({ field }: any) => (
                          <InputMask
                            {...field}
                            mask="99.999.999/9999-99"
                            value={values.cnpj || ""}
                            onChange={handleChange}
                          >
                            {(inputProps: any) => (
                              <TextField
                                {...inputProps}
                                name="cnpj"
                                error={Boolean(errors.cnpj)}
                                helperText={errors.cnpj}
                                id="cnpj"
                                variant="outlined"
                                size="small"
                                fullWidth
                                label="CNPJ"
                                type="text"
                              />
                            )}
                          </InputMask>
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={4} mb={4}>
                      <Field
                        component={TextField}
                        label="Nome"
                        id="name"
                        name="name"
                        onChange={handleChange}
                        value={values.name || ""}
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                      />
                    </Grid>

                    <Grid item xs={4} mb={4}>
                      <Field
                        component={TextField}
                        label="E-mail"
                        id="email"
                        name="email"
                        value={values.email || ""}
                        onChange={handleChange}
                        error={Boolean(errors.email)}
                        helperText={errors.email}
                        type="email"
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                      />
                    </Grid>

                    <Grid item xs={2} mb={4}>
                      <Field
                        name="phone"
                        error={Boolean(errors.phone)}
                        helperText={errors.phone}
                      >
                        {({ field }: any) => (
                          <InputMask
                            {...field}
                            mask="(99) 99999-9999"
                            value={values.phone || ""}
                            onChange={handleChange}
                          >
                            {(inputProps: any) => (
                              <TextField
                                {...inputProps}
                                name="phone"
                                id="phone"
                                variant="outlined"
                                size="small"
                                fullWidth
                                label="Telefone"
                                type="text"
                              />
                            )}
                          </InputMask>
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={4} mb={4}>
                      <Field
                        component={TextField}
                        label="Nome do responsável"
                        id="nameResponsible"
                        name="nameResponsible"
                        onChange={handleChange}
                        value={values.nameResponsible || ""}
                        error={Boolean(errors.nameResponsible)}
                        helperText={errors.nameResponsible}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                      />
                    </Grid>

                    <Grid item xs={4} mb={4}>
                      <Field
                        component={TextField}
                        label="Logradouro"
                        id="address"
                        name="address"
                        onChange={handleChange}
                        value={values.address || ""}
                        error={Boolean(errors.address)}
                        helperText={errors.address}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                      />
                    </Grid>

                    <Grid item xs={2} mb={4}>
                      <Field
                        component={TextField}
                        label="Número"
                        id="number"
                        name="number"
                        onChange={handleChange}
                        value={values.number || ""}
                        error={Boolean(errors.number)}
                        helperText={errors.number}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                      />
                    </Grid>

                    <Grid item xs={2} mb={4}>
                      <Field
                        name="cep"
                        error={Boolean(errors.cep)}
                        helperText={errors.cep}
                      >
                        {({ field }: any) => (
                          <InputMask
                            {...field}
                            mask="99999-999"
                            value={values.cep || ""}
                            onChange={handleChange}
                          >
                            {(inputProps: any) => (
                              <TextField
                                {...inputProps}
                                name="cep"
                                id="cep"
                                variant="outlined"
                                size="small"
                                fullWidth
                                label="CEP"
                                type="text"
                              />
                            )}
                          </InputMask>
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={3} mb={4}>
                      <Field
                        component={TextField}
                        label="Bairro"
                        id="neighborhood"
                        name="neighborhood"
                        onChange={handleChange}
                        value={values.neighborhood || ""}
                        error={Boolean(errors.neighborhood)}
                        helperText={errors.neighborhood}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                      />
                    </Grid>

                    <Grid item xs={3} mb={4}>
                      <Field
                        component={TextField}
                        label="Complemento"
                        id="complement"
                        name="complement"
                        onChange={handleChange}
                        value={values.complement || ""}
                        error={Boolean(errors.complement)}
                        helperText={errors.complement}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <Field
                        component={TextField}
                        label="Cidade"
                        id="city"
                        name="city"
                        value={values.city || ""}
                        onChange={handleChange}
                        error={Boolean(errors.city)}
                        helperText={errors.city}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={1} mb={2}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel htmlFor="uf">UF</InputLabel>
                        <Field
                          component={Select}
                          native
                          value={values.uf || ""}
                          onChange={handleChange}
                          error={Boolean(errors.uf)}
                          label="UF"
                          inputProps={{
                            name: "uf",
                            id: "uf",
                          }}
                        >
                          <option aria-label="Escolha uma UF" value=""></option>
                          {statesOfBrazil.map((uf) => {
                            return (
                              <option key={uf} value={uf}>
                                {uf}
                              </option>
                            );
                          })}
                        </Field>
                        <FormHelperText error={Boolean(errors.uf)}>
                          {errors.uf}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={1} mb={2}>
                      <FormControlLabel
                        control={
                          <Field
                            component={Switch}
                            checked={values.status || false}
                            onChange={handleChange}
                            color="primary"
                            id="status"
                            name="status"
                            value={values.status || false}
                            inputProps={{ "aria-label": "status" }}
                          />
                        }
                        label="Ativo?"
                      />
                    </Grid>

                    <Grid item xs={6} mb={4}>
                      <Field
                        component={TextField}
                        multiline
                        rows={6}
                        label="Observações"
                        id="comments"
                        name="comments"
                        onChange={handleChange}
                        value={values.comments || ""}
                        error={Boolean(errors.comments)}
                        helperText={errors.comments}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                      />
                    </Grid>

                    <Grid item xs={6} mb={4}>
                      <FormControl
                        variant="outlined"
                        size="small"
                        fullWidth
                        style={{ marginBottom: "8px" }}
                      >
                        <InputLabel htmlFor="constructionExecutedType">
                          Tipo de obra executada
                        </InputLabel>
                        <Field
                          component={Select}
                          native
                          value={
                            values.constructionExecutedType !== undefined &&
                            values.constructionExecutedType >= 0
                              ? values.constructionExecutedType
                              : ""
                          }
                          onChange={handleChange}
                          error={Boolean(errors.constructionExecutedType)}
                          label="Tipo de obra executada"
                          inputProps={{
                            name: "constructionExecutedType",
                            id: "constructionExecutedType",
                          }}
                        >
                          <option
                            aria-label="Escolha um tipo de obra"
                            value=""
                          ></option>
                          {constructionExecutedTypes.map(
                            (constructionExecutedType, index) => {
                              return (
                                <option key={index} value={index}>
                                  {constructionExecutedType}
                                </option>
                              );
                            }
                          )}
                        </Field>
                        <FormHelperText
                          error={Boolean(errors.constructionExecutedType)}
                        >
                          {errors.constructionExecutedType}
                        </FormHelperText>
                      </FormControl>

                      <FormControl
                        variant="outlined"
                        size="small"
                        fullWidth
                        style={{ marginBottom: "7px" }}
                      >
                        <InputLabel htmlFor="hireType">
                          Tipo de contratação
                        </InputLabel>
                        <Field
                          component={Select}
                          native
                          value={
                            values.hireType !== undefined &&
                            values.hireType >= 0
                              ? values.hireType
                              : ""
                          }
                          onChange={handleChange}
                          error={Boolean(errors.hireType)}
                          label="Tipo de contratação"
                          inputProps={{
                            name: "hireType",
                            id: "hireType",
                          }}
                        >
                          <option
                            aria-label="Escolha um tipo de contratação"
                            value=""
                          ></option>
                          {hireTypes.map((hireType, index) => {
                            return (
                              <option key={index} value={index}>
                                {hireType}
                              </option>
                            );
                          })}
                        </Field>
                        <FormHelperText error={Boolean(errors.hireType)}>
                          {errors.hireType}
                        </FormHelperText>
                      </FormControl>

                      <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel htmlFor="employeeQuantity">
                          Número de funcionários
                        </InputLabel>
                        <Field
                          component={Select}
                          native
                          value={
                            values.employeeQuantity !== undefined &&
                            values.employeeQuantity >= 0
                              ? values.employeeQuantity
                              : ""
                          }
                          onChange={handleChange}
                          error={Boolean(errors.employeeQuantity)}
                          label="Número de funcionários"
                          inputProps={{
                            name: "employeeQuantity",
                            id: "employeeQuantity",
                          }}
                        >
                          <option
                            aria-label="Escolha um tipo de obra"
                            value=""
                          ></option>
                          {employeeQuantities.map((employeeQuantity, index) => {
                            return (
                              <option key={index} value={index}>
                                {employeeQuantity}
                              </option>
                            );
                          })}
                        </Field>
                        <FormHelperText
                          error={Boolean(errors.employeeQuantity)}
                        >
                          {errors.employeeQuantity}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    endIcon={<DoneAllIcon />}
                  >
                    Salvar
                  </Button>
                </form>
              )}
            </Formik>
          )}
        </CardContent>
      </Card>
      <Alert
        onClose={() => closeError()}
        isOpened={alert.isOpened}
        title={alert.title}
        message={alert.message}
        buttonLabel="Fechar"
      />
    </>
  );
};

export default ClientsForm;
