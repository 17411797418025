import { Modal } from "@mui/material";
import {
  ModalSucessFilesContent,
  ModalSuccesFilesText,
} from "../../Form/PurchasingAndContractingPlanForm.styles";

import ButtonComponent, {
  ButtonType,
} from "../../../../components/v2/Button/ButtonComponent";

import CloseIcon from "@mui/icons-material/Close";
import thumbUpImg from "../../../../assets/images/thumb-up.png";

interface PurchasingAndContractingListUploadSuccessModalProps {
  isModalFilesOpen: boolean;
  closeFilesModal: () => void;
  text: string;
}

const PurchasingAndContractingListUploadSuccessModal: React.FC<
  PurchasingAndContractingListUploadSuccessModalProps
> = ({ isModalFilesOpen, closeFilesModal, text }) => {
  return (
    <Modal open={isModalFilesOpen} onClose={closeFilesModal} keepMounted>
      <ModalSucessFilesContent>
        <img
          src={thumbUpImg}
          alt="thumb-up"
          style={{
            width: "182px",
            height: "182px",
            objectFit: "contain",
          }}
        />
        <ModalSuccesFilesText>{text}</ModalSuccesFilesText>

        <ButtonComponent
          text="Fechar"
          type={ButtonType.PRIMARY}
          onClick={() => {
            closeFilesModal();
          }}
          startIcon={<CloseIcon />}
          padding={{
            left: 24,
            right: 24,
            top: 10,
            bottom: 10,
          }}
        />
      </ModalSucessFilesContent>
    </Modal>
  );
};

export default PurchasingAndContractingListUploadSuccessModal;
