import styled from "styled-components";
import { Colors } from "../../../../theme/v2/variants";
import { MOBILE_BREAKPOINT } from "../../../../utils/mobileDimen";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";

const ContentContainer = styled.div<{ isMobile?: boolean }>`
  display: grid;
  width: 100%;
  /* grid-template-columns: 1fr 136px 136px 136px 136px 136px 1fr 136px 78px; */
  /* grid-template-columns: repeat(8, minmax(136px, 1fr)) 78px;  */
  grid-template-columns: minmax(150px, 1fr) repeat(7, minmax(136px, 150px)) 78px;
  height: 52px;

  /* @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 169px 143px 140px 140px 140px 140px 169px 78px;

    height: 52px;
  } */
`;

const CellContainer = styled.div<{ isVisible?: boolean }>`
  display: grid;
  align-items: center;
  justify-content: start;
  border-bottom: 1px solid ${Colors.neutralColorLight};
  background-color: ${Colors.neutralColorWhite};
  height: 52px;
  /* visibility: ${(props) => (props.isVisible ? "visible" : "hidden")}; */
  padding-left: 10px;

  // set ellipsis if the text is too long
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 52px;
  }

  &:first-child {
    padding-left: 16px;
  }
`;

const CellText = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: ${Colors.black};

  // set ellipsis if the text is too long
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TagPhase = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: ${Colors.textColorLight};
  background-color: ${Colors.textColorGray};
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
`;

const TagStatusBase = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
`;

const TagInNegotiation = styled(TagStatusBase)`
  color: ${Colors.textColorLight};
  background-color: ${Colors.feedbackSuccess};
`;

const TagFinished = styled(TagStatusBase)`
  color: ${Colors.neutralColorWhite};
  background-color: ${Colors.primaryColor};
`;

const TagNotStarted = styled(TagStatusBase)`
  color: ${Colors.textColorGray};
  background-color: ${Colors.attentionColor};
`;

const TagOverdue = styled(TagStatusBase)`
  color: ${Colors.textColorLight};
  background-color: ${Colors.feedbackError};
`;

const TagNotCompleted = styled(TagStatusBase)`
  color: ${Colors.textColorLight};
  background-color: ${Colors.textColorGray};
`;

const MouseHoverContainer = styled.div`
  position: relative;
  left: 100px;
  top: -50px;
  z-index: 999999;
`;

const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    background-color: ${Colors.textColorBlack};
    color: ${Colors.neutralColor};
    border-radius: 8px;
  }
  & .MuiList-root {
    padding: 0;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  padding-left: 12px;
  padding-right: 22px;
  padding-top: 10px;
  padding-bottom: 10px;

  &:first-child {
    margin-top: 10px;
  }

  &:last-child {
    margin-bottom: 10px;
  }

  &:hover {
    color: #6183f2;
  }
`;

const Link = styled.a`
  cursor: pointer;
  :hover {
    text-decoration: underline;
    text-decoration-color: ${Colors.textColorLight};
  }
`;

export {
  ContentContainer,
  CellContainer,
  CellText,
  TagPhase,
  TagInNegotiation,
  TagNotStarted,
  TagOverdue,
  TagNotCompleted,
  MouseHoverContainer,
  TagFinished,
  StyledMenu,
  StyledMenuItem,
  Link,
};
