import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import {
  IconButton,
  Grid,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import EditIcon from "@mui/icons-material/Edit";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { userRoles } from "../../../config/app-info";
import { api } from "../../../services/api";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const WrapperDataGrid = styled.div`
  width: 100%;
`;

const ContentDataGrid = styled.div`
  height: 48vh;
  overflow-y: auto;
`;

type User = {
  id: number;
  name: string;
  email: string;
  login: string;
  role: number;
  construction: string;
  status: number;
  createdAt: Date;
};

type Props = {
  section: string;
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbar />
    </GridToolbarContainer>
  );
}
const UsersList: React.FC<Props> = ({ section }) => {
  const [users, setUsers] = useState<User[]>([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get("api/users");

        if (response.status === 200) {
          setUsers(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUsers();
  }, []);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
      type: "string",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      type: "string",
    },
    {
      field: "email",
      headerName: "E-mail",
      flex: 1,
      type: "string",
    },
    {
      field: "login",
      headerName: "Login",
      flex: 0.6,
      type: "string",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "role",
      headerName: "Perfil",
      flex: 0.7,
      type: "string",
      headerAlign: "left",
      align: "left",
      valueFormatter: (params) => userRoles[Number(params.value)],
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.6,
      type: "string",
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => (params.value ? "Ativo" : "Inativo"),
    },
    {
      field: "createdAt",
      headerName: "Criado em",
      flex: 1,
      type: "string",
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => {
        const date = new Date(String(params.value));
        return `${date.toLocaleDateString("pt-br")} ${date.toLocaleTimeString(
          "pt-br"
        )}`;
      },
    },
    {
      field: "actions",
      headerName: "Editar",
      width: 50,
      flex: 0.6,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <IconButton
          edge="end"
          aria-label="editar"
          onClick={() => navigate(`/usuarios/editar/${params.row.id}/`)}
          size="large"
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>{section}</title>
      </Helmet>
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            {section}
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Card>
        <CardContent>
          <WrapperDataGrid>
            <ContentDataGrid>
              <DataGrid
                rows={users}
                columns={columns}
                components={{ Toolbar: CustomToolbar }}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      id: !isMobile,
                      email: !isMobile,
                      name: !isMobile,
                      role: !isMobile,
                      createdAt: !isMobile,
                    },
                  },
                }}
              />
            </ContentDataGrid>
          </WrapperDataGrid>
        </CardContent>
      </Card>
    </>
  );
};

export default UsersList;
