import React, { useEffect } from "react";
import { useAuth } from "../../contexts/auth";
import { Navigate } from "react-router-dom";

const Logout: React.FC = () => {
  const { signOut } = useAuth();

  useEffect(() => {
    localStorage.setItem("selectedClient", "");
    localStorage.setItem("selectedClientId", "");
    localStorage.setItem("selectedClientName", "");
    localStorage.setItem("selectedConstruction", "");
    localStorage.setItem("selectedConstructionId", "");
    localStorage.setItem("selectedConstructionName", "");
    signOut();
  }, [signOut]);

  return <Navigate to="/" />;
};

export default Logout;
