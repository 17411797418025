import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  DataGridPremium,
  GridColDef,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
  ptBR,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-premium";
import { LicenseInfo } from "@mui/x-license-pro";
import {
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
} from "@mui/material";
import {
  Document,
  Page,
  Text,
  View,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import { PictureAsPdf } from "@mui/icons-material";
import styled from "styled-components/macro";
import { Box, spacing } from "@mui/system";
import Alert from "../../../components/Alert";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import { useNavigate, useParams } from "react-router-dom";
import { ActionItem } from "../../../config/app-info";
import { Check as CheckIcon, HighlightOff } from "@mui/icons-material";
import SearchBar from "../../../components/SearchBar";
import ActionsPlanPdf from "./pdf";
import {
  ButtonContainer,
  ManagerContainer,
  ManagerProfile,
  PageTitle,
  stylesPdf,
} from "./style";
import { Colors } from "../../../theme/v2/variants";
import { useDashboard } from "../../../contexts/dashboard";
import typography from "../../../theme/v2/typography";
import ButtonComponent, {
  ButtonState,
  ButtonType,
} from "../../../components/Button/ButtonComponent";
import profilePlaceholder from "./../../../assets/images/profile.png";
import ClearIcon from "@mui/icons-material/Clear";

if (process.env.REACT_APP_MUI_LICENSE_KEY) {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);
}

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const WrapperDataGrid = styled.div`
  width: 100%;
`;

const ContentDataGrid = styled.div`
  height: 50vh;
  overflow-y: auto;
`;

type Props = {
  section: string;
};

const checkboxColDef: GridColDef = {
  ...GRID_CHECKBOX_SELECTION_COL_DEF,
  renderHeader: () => "Conclusão",
  width: 100,
  type: "string",
};

const ActionsPlanEdit: React.FC<Props> = ({ section }) => {
  const [constructionName, setConstructionName] = useState("");
  const [managerName, setManagerName] = useState("");
  const [actions, setActions] = useState<ActionItem[]>([]);
  const [actionsByCriteria, setActionsByCriteria] = useState<ActionItem[][]>(
    []
  );
  const [alert, setAlert] = useState({
    title: "",
    message: "",
    isOpened: false,
  });
  const [submitting, setSubmitting] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [labelDtBegin, setLabelDtBegin] = useState("");
  const [labelDtEnd, setLabelDtEnd] = useState("");
  const apiRef = useGridApiRef();
  const { user } = useAuth();
  const [constructionImage, setConstructionImage] = useState("");
  let { id: constructionEditId } = useParams();
  const navigate = useNavigate();
  const { constructionId } = useDashboard();

  useEffect(() => {
    if (constructionId) {
      navigate(`/planos-acoes/editar/${+constructionId}`);
    }
  }, [constructionId]);

  useEffect(() => {
    if (user.id) {
      const findConstructionById = async () => {
        try {
          const result = await api.get(
            `/api/constructions/image/${constructionEditId}`
          );
          setConstructionImage(result.data);
        } catch (error) {
          console.error(error);
        }
      };
      findConstructionById();

      const fetchConstructionName = async () => {
        try {
          const response = await api.get(
            `api/constructions/info/${constructionEditId}`
          );
          setConstructionName(response.data.name);
          setManagerName(response.data.managerUserId.name);
        } catch (error) {
          console.error(error);
        }
      };
      fetchConstructionName();

      const fetchActionsItems = async () => {
        try {
          const response = await api.post(`api/actions/items`, {
            constructionId: constructionEditId,
            dtBegin: "",
            dtEnd: "",
            dateType: "0",
            criteria: [],
            responsibleUser: [],
            status: [],
          });

          if (response.status === 201) {
            setActions(response.data);

            const rowIds = [];
            const arrayActionsByCriteria = [];
            let tempActions = [];
            let criteriaName =
              response.data.length > 0 ? response.data[0].criteria : "";

            if (response.data.length > 0) {
              for (const row of response.data) {
                if (row.status) {
                  rowIds.push(row.id);
                }

                if (criteriaName !== row.criteria) {
                  arrayActionsByCriteria.push(tempActions);
                  criteriaName = row.criteria;
                  tempActions = [];
                }

                tempActions.push(row);
              }
              arrayActionsByCriteria.push(tempActions);
            }

            setActionsByCriteria(arrayActionsByCriteria);

            if (rowIds.length) {
              apiRef.current.selectRows(rowIds);
            }
          }
        } catch (error) {
          console.error(error);
        }
      };
      fetchActionsItems();
    }
  }, [apiRef, constructionEditId, user.id]);

  const columns: GridColDef[] = [
    {
      field: "criteria",
      headerName: "Critério",
      width: 200,
      type: "string",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "what",
      headerName: "Ação",
      flex: 2,
      type: "string",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "whenBegin",
      headerName: "Início previsto",
      flex: 1,
      type: "string",
      headerAlign: "left",
      align: "center",
      valueFormatter: (params) => {
        if (params.value) {
          const date = new Date(String(params.value));
          return `${date.toLocaleDateString("pt-br")}`;
        } else {
          return "";
        }
      },
    },
    {
      field: "whenEnd",
      headerName: "Fim previsto",
      flex: 1,
      type: "string",
      headerAlign: "left",
      align: "center",
      valueFormatter: (params) => {
        if (params.value) {
          const date = new Date(String(params.value));
          return `${date.toLocaleDateString("pt-br")}`;
        } else {
          return "";
        }
      },
    },
    // {
    //   field: "whenRealBegin",
    //   headerName: "Início real",
    //   width: 110,
    //   type: "date",
    //   headerAlign: "left",
    //   align: "center",
    //   valueFormatter: (params) => {
    //     if (params.value) {
    //       const date = new Date(String(params.value));
    //       return `${date.toLocaleDateString("pt-br")}`;
    //     } else {
    //       return "";
    //     }
    //   },
    // },
    {
      field: "statusAsString",
      headerName: "Status",
      flex: 1,
      type: "string",
      headerAlign: "left",
      align: "center",
    },
    {
      field: "responsibleUsers",
      headerName: "Responsáveis",
      flex: 1,
      type: "string",
      headerAlign: "left",
      align: "left",
    },
  ];

  const handleSubmit = async () => {
    const mapSelected = apiRef.current.getSelectedRows();
    const arrayIds = [];
    for (const value of mapSelected.values()) {
      if (value.id) {
        arrayIds.push(Number(value.id));
      }
    }

    try {
      const response = await api.put(`api/actions/finish`, {
        ids: arrayIds,
        userId: user.id,
        constructionId: constructionEditId,
      });
      if (response.status === 200) {
        setAlert({
          title: "Plano de ações concluído!",
          message: "Planos de ações salvos com sucesso",
          isOpened: true,
        });
      }
    } catch (error) {
      console.error(error);
      setAlert({
        title: "Erro ao salvar o plano de ações",
        message: "Algo deu errado. Tente novamente mais tarde.",
        isOpened: true,
      });
    }
  };

  const closeError = () => {
    setAlert({
      title: "",
      message: "",
      isOpened: false,
    });
  };

  const handleCancelSelection = () => {
    if (apiRef.current) {
      apiRef.current.setSelectionModel([]);
    }
  };

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ["criteria"],
      },
    },
  });

  const fetchRecords = async (
    dtBegin: string,
    dtEnd: string,
    filterDateType: string,
    filterCriteria: string[],
    filterResponsibleUser: string[],
    filterStatus: string[]
  ) => {
    if (!submitting) {
      if (dtBegin) {
        setLabelDtBegin(
          new Date(dtBegin + " 08:00:00").toLocaleDateString("pt-BR")
        );
      } else {
        setLabelDtBegin("");
      }

      if (dtEnd) {
        setLabelDtEnd(
          new Date(dtEnd + " 08:00:00").toLocaleDateString("pt-br")
        );
      } else {
        setLabelDtEnd("");
      }
      setSubmitting(true);
      try {
        const response = await api.post(`api/actions/items`, {
          constructionId: constructionEditId,
          dtBegin,
          dtEnd,
          dateType: undefined,
          criteria: filterCriteria,
          responsibleUser: filterResponsibleUser,
          status: filterStatus,
        });

        if (response.status === 201) {
          setActions(response.data);
          const rowIds = [];
          const arrayActionsByCriteria = [];
          let tempActions = [];
          let criteriaName =
            response.data.length > 0 ? response.data[0].criteria : "";

          if (response.data.length > 0) {
            for (const row of response.data) {
              if (row.status) {
                rowIds.push(row.id);
              }

              if (criteriaName !== row.criteria) {
                arrayActionsByCriteria.push(tempActions);
                criteriaName = row.criteria;
                tempActions = [];
              }

              tempActions.push(row);
            }
            arrayActionsByCriteria.push(tempActions);
          }

          setActionsByCriteria(arrayActionsByCriteria);

          if (rowIds.length) {
            apiRef.current.selectRows(rowIds);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  const toggleModal = () => {
    setShowPdf(!showPdf);
  };

  return (
    <>
      <Helmet>
        <title>{section}</title>
      </Helmet>
      <PageTitle>
        <Typography
          variant="h3"
          color={Colors.primaryColorDark}
          fontWeight={typography.fontWeightBold}
        >
          {section}
        </Typography>
        <ManagerContainer>
          <ManagerProfile src={profilePlaceholder} />
          <Box>
            <Typography gutterBottom color={Colors.greenLight}>
              Gerente
            </Typography>
            <Typography variant="h5">
              {managerName.length > 0 ? managerName : "Não cadastrado!"}
            </Typography>
          </Box>
        </ManagerContainer>
      </PageTitle>

      <Card
        sx={{
          borderRadius: "16px",
          padding: "10px",
          border: `1px solid ${Colors.neutralColorWarm}`,
        }}
      >
        <CardContent>
          <SearchBar
            hasFilterCriteria
            hasFilterResponsibleUser
            hasFilterStatus
            callReport={fetchRecords}
            actionsByCriteria={actionsByCriteria}
            setShowPdf={setShowPdf}
            showPdf={showPdf}
            showUtilityText
          />

          <WrapperDataGrid>
            {/* <Grid container mb={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" gutterBottom>
                  Por favor, selecione as ações que deseja concluir na tabela
                  abaixo:
                </Typography>
              </Grid>

              {actionsByCriteria.length > 0 && (
                <Grid item xs={12} sm={6} textAlign={"right"}>
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    endIcon={<PictureAsPdf />}
                    onClick={() => setShowPdf(!showPdf)}
                  >
                    {showPdf ? "Fechar" : "Abrir"}
                  </Button>
                </Grid>
              )}
            </Grid> */}
            <ContentDataGrid>
              <DataGridPremium
                rows={actions}
                pagination
                getRowHeight={() => 55}
                columns={[...columns, checkboxColDef]}
                checkboxSelection
                disableSelectionOnClick
                apiRef={apiRef}
                rowGroupingColumnMode="single"
                groupingColDef={{
                  hideDescendantCount: true,
                }}
                initialState={initialState}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                getRowId={(row) => row.id}
                //isRowSelectable={(params: GridRowParams) => params.row.id}
                checkboxSelectionVisibleOnly
                sx={{
                  borderRadius: "10px",
                  border: `1px solid ${Colors.neutralColorWarm}`,
                  backgroundColor: Colors.neutralColorSurface,
                  "& .MuiDataGrid-columnHeaders": {
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    backgroundColor: `${Colors.textColorLight}`,
                  },
                  "& .MuiDataGrid-footerContainer": {
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    backgroundColor: "white",
                  },
                  "& .MuiDataGrid-row": {
                    backgroundColor: `${Colors.textColorLight}`,
                    "&:hover": {
                      backgroundColor: "#E5EAF8",
                    },
                  },
                }}
              />
            </ContentDataGrid>
          </WrapperDataGrid>
        </CardContent>
      </Card>

      <ButtonContainer>
        {/* <Button
          variant="contained"
          color="info"
          endIcon={<CheckIcon />}
          onClick={handleSubmit}
        >
          Concluir ações selecionadas
        </Button> */}
        <ButtonComponent
          text="Cancelar"
          state={ButtonState.DEFAULT_ENABLED}
          type={ButtonType.TRANSPARENT}
          startIcon={<HighlightOff />}
          onClick={handleCancelSelection}
        />
        <ButtonComponent
          text="Concluir ações selecionadas"
          state={ButtonState.DEFAULT_ENABLED}
          type={ButtonType.SECONDARY}
          startIcon={<CheckIcon />}
          onClick={handleSubmit}
        />
      </ButtonContainer>

      <Dialog
        fullWidth
        maxWidth="xl"
        PaperProps={{
          style: {
            borderRadius: "15px",
            padding: "25px",
            paddingTop: "40px",
          },
        }}
        onClose={toggleModal}
        open={showPdf}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="20px"
        >
          <Typography
            fontSize={typography.h2?.fontSize}
            fontWeight={typography.fontWeightBold}
          >
            Plano de Ações Global
          </Typography>

          <IconButton onClick={toggleModal}>
            <ClearIcon fontSize="large" />
          </IconButton>
        </Box>
        <PDFViewer style={stylesPdf.viewer}>
          <Document title={`${section.toUpperCase()} - Global`}>
            <Page size="A4" style={stylesPdf.page} orientation="landscape" wrap>
              <View style={stylesPdf.section}>
                {constructionImage && (
                  <Image
                    src={constructionImage}
                    style={stylesPdf.logoCliente}
                  />
                )}
                <Image src="/logo.png" style={stylesPdf.logo} />
                <Text
                  style={stylesPdf.title}
                >{`${section.toUpperCase()} - GLOBAL`}</Text>
                <Text style={stylesPdf.construction}>
                  {constructionName.toUpperCase()}
                </Text>
              </View>

              <Text style={stylesPdf.updateText}>
                {labelDtBegin
                  ? `ATUALIZAÇÃO: ${labelDtBegin} à ${labelDtEnd}`
                  : ""}
              </Text>

              {actionsByCriteria.length > 0 &&
                actionsByCriteria.map((itemActions, index) => {
                  return (
                    <ActionsPlanPdf
                      key={index}
                      criteriaName={itemActions[0].criteria!}
                      actions={itemActions}
                      labelDtEnd={labelDtEnd}
                    />
                  );
                })}

              {actionsByCriteria.length > 0 && (
                <Text
                  style={stylesPdf.pagination}
                  render={({ pageNumber, totalPages }) =>
                    `${pageNumber} / ${totalPages}`
                  }
                  fixed
                />
              )}
            </Page>
          </Document>
        </PDFViewer>
      </Dialog>

      <Alert
        onClose={() => closeError()}
        isOpened={alert.isOpened}
        title={alert.title}
        message={alert.message}
        buttonLabel="Fechar"
      />
    </>
  );
};

export default ActionsPlanEdit;
