import React, { useEffect, useState } from "react";
import { LicenseInfo } from "@mui/x-license-pro";
import { Typography as MuiTypography, Dialog, IconButton } from "@mui/material";
import {
  Document,
  Page,
  Text,
  View,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import styled from "styled-components/macro";
import { Box, spacing } from "@mui/system";
import { Client } from "../../../../config/app-info";
import { stylesPdf } from "./PDFstyles.styles";
import typography from "../../../../theme/v2/typography";
import ClearIcon from "@mui/icons-material/Clear";
import PurchasingModel from "../../../PurchasingContractingPlanPage/models/PurchasingModel";
import ContractingModel from "../../../PurchasingContractingPlanPage/models/ContractingMode";

if (process.env.REACT_APP_MUI_LICENSE_KEY) {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);
}

const Typography = styled(MuiTypography)(spacing);

type OrderDetailPDFProps = {
  purchasingSelectedPlan?: PurchasingModel;
  contractingSelectedPlan?: ContractingModel;
  constructionImage: string;
  constructionName: string;
  orderNumber: string | null;
  open: boolean;
  toggleModal: () => void;
  client?: Client;
  planType: string;
};

const OrderDetailPDF: React.FC<OrderDetailPDFProps> = ({
  open,
  toggleModal,
  purchasingSelectedPlan,
  contractingSelectedPlan,
  orderNumber,
  constructionImage,
  constructionName,
  client,
  planType,
}) => {
  const [imageBlob, setImageBlob] = useState<string | null>(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(constructionImage);
        const blob = await response.blob();
        setImageBlob(URL.createObjectURL(blob));
      } catch (error) {
        console.error("Error loading image:", error);
      }
    };

    if (constructionImage) {
      fetchImage();
    }
  }, [constructionImage]);

  const formatDate = (date: Date | string | undefined): string => {
    if (!date) return "";

    let parsedDate: Date;

    if (typeof date === "string") {
      if (date.endsWith("Z")) {
        date = date.slice(0, -1);
      }
      parsedDate = new Date(date);
    } else {
      parsedDate = date;
    }

    if (!(parsedDate instanceof Date) || isNaN(parsedDate.getTime())) {
      console.error("Invalid date:", date);
      return "";
    }

    const day = String(parsedDate.getDate()).padStart(2, "0");
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const year = parsedDate.getFullYear();

    return `${day}/${month}/${year}`;
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      PaperProps={{
        style: {
          borderRadius: "15px",
          padding: "25px",
          paddingTop: "40px",
        },
      }}
      onClose={toggleModal}
      open={open}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
      >
        <Typography
          fontSize={typography.h2?.fontSize}
          fontWeight={typography.fontWeightBold}
        >
          {purchasingSelectedPlan
            ? "Solicitação de Compra"
            : "Solicitação de Contratação"}
        </Typography>

        <IconButton onClick={toggleModal}>
          <ClearIcon fontSize="large" />
        </IconButton>
      </Box>
      <PDFViewer style={stylesPdf.viewer}>
        <Document
          title={`${
            purchasingSelectedPlan
              ? "Solicitação de Compra"
              : "Solicitação de Contratação"
          } - ${orderNumber}`}
        >
          <Page size="A4" style={stylesPdf.page} orientation="portrait" wrap>
            <View style={stylesPdf.header}>
              <View style={stylesPdf.clientLogoView}>
                {imageBlob && (
                  <Image src={imageBlob} style={stylesPdf.logoCliente} />
                )}
              </View>
              <View style={stylesPdf.headerCenterRow}>
                <View style={stylesPdf.titleContainer}>
                  <Text
                    style={stylesPdf.title}
                  >{`Solicitação de Compra de Materiais e/ou Serviços`}</Text>
                </View>
                <View style={stylesPdf.divider}></View>
                <Text
                  style={stylesPdf.clientDescription}
                >{`${client?.address}, Nº ${client?.number}, ${client?.complement} - ${client?.neighborhood}, ${client?.city}/${client?.uf}
CEP: ${client?.cep} / Fone: ${client?.phone} / E-mail: ${client?.email}
C.N.P.J.: ${client?.cnpj}`}</Text>
              </View>
              <View style={stylesPdf.headerRightRow}>
                <View style={stylesPdf.orderNumberDescriptionRow}>
                  <Text
                    style={stylesPdf.orderNumberSymbol}
                  >{`N°: ${orderNumber}`}</Text>
                </View>

                <View style={stylesPdf.divider}></View>
                <View style={stylesPdf.constructionNameDescriptionRow}>
                  <Text
                    style={stylesPdf.constructionNameSymbol}
                  >{`Obra: ${constructionName}`}</Text>
                </View>
              </View>
            </View>
            <View style={stylesPdf.typeAndDateRow}>
              <View style={stylesPdf.typeSelection}>
                <View style={stylesPdf.types}>
                  <View style={stylesPdf.checkbox}>
                    {planType === "PURCHASING" ? <Text>X</Text> : null}{" "}
                  </View>
                  <Text style={stylesPdf.checkboxText}>Compra</Text>
                </View>
                <View style={stylesPdf.types}>
                  <View style={stylesPdf.checkbox}>
                    {planType === "CONTRACTING" ? <Text>X</Text> : null}{" "}
                  </View>
                  <Text style={stylesPdf.checkboxText}>Contratação</Text>
                </View>
              </View>
              <View style={stylesPdf.dateSection}>
                <Text style={stylesPdf.dateText}>{`Data: ${
                  formatDate(purchasingSelectedPlan?.supplierQuotationDate) ||
                  formatDate(contractingSelectedPlan?.supplierQuotationDate)
                }`}</Text>
              </View>
            </View>
            <View style={stylesPdf.requesterSection}>
              <Text style={stylesPdf.requesterText}>{`Requisitante: ${
                purchasingSelectedPlan?.supplierQuotatioResponsable?.name ||
                contractingSelectedPlan?.supplierQuotatioResponsable?.name
              }`}</Text>
            </View>

            <View style={stylesPdf.tableHeader}>
              <View style={stylesPdf.tableHeaderItemRow}>
                <Text style={stylesPdf.tableHeaderItemText}>Item</Text>
              </View>
              <View style={stylesPdf.tableHeaderDescriptionRow}>
                <Text style={stylesPdf.tableHeaderDescriptionText}>
                  Descrição do Material ou Serviço
                </Text>
              </View>
              <View style={stylesPdf.tableHeaderSmallRow}>
                <Text style={stylesPdf.tableHeaderSmallText}>Und.</Text>
              </View>
              <View style={stylesPdf.tableHeaderSmallRow}>
                <Text style={stylesPdf.tableHeaderSmallText}>Qtd.</Text>
              </View>
              <View style={stylesPdf.tableHeaderEspecificationRow}>
                <Text style={stylesPdf.tableHeaderEspecificationText}>
                  Especificações
                </Text>
              </View>
            </View>

            {(purchasingSelectedPlan?.purchasingItems?.length ?? 0) > 0
              ? purchasingSelectedPlan?.purchasingItems?.map((item, index) => (
                  <View key={index} style={stylesPdf.tableRow}>
                    <View style={stylesPdf.tableItemRow}>
                      <Text style={stylesPdf.tableItemText}>{index + 1}</Text>
                    </View>
                    <View style={stylesPdf.tableDescriptionRow}>
                      <Text style={stylesPdf.tableDescriptionText}>
                        {item.name}
                      </Text>
                    </View>
                    <View style={stylesPdf.tableSmallRow}>
                      <Text style={stylesPdf.tableSmallText}>{item.unit}</Text>
                    </View>
                    <View style={stylesPdf.tableSmallRow}>
                      <Text style={stylesPdf.tableSmallText}>
                        {item.quantity}
                      </Text>
                    </View>
                    <View style={stylesPdf.tableEspecificationRow}>
                      <Text style={stylesPdf.tableEspecificationText}>
                        {item.specifications}
                      </Text>
                    </View>
                  </View>
                ))
              : contractingSelectedPlan?.contractingItems?.map(
                  (item, index) => (
                    <View key={index} style={stylesPdf.tableRow}>
                      <View style={stylesPdf.tableItemRow}>
                        <Text style={stylesPdf.tableItemText}>{index + 1}</Text>
                      </View>
                      <View style={stylesPdf.tableDescriptionRow}>
                        <Text style={stylesPdf.tableDescriptionText}>
                          {item.name}
                        </Text>
                      </View>
                      <View style={stylesPdf.tableSmallRow}>
                        <Text style={stylesPdf.tableSmallText}>{""}</Text>
                      </View>
                      <View style={stylesPdf.tableSmallRow}>
                        <Text style={stylesPdf.tableSmallText}>{""}</Text>
                      </View>
                      <View style={stylesPdf.tableEspecificationRow}>
                        <Text style={stylesPdf.tableEspecificationText}>
                          {item.specifications}
                        </Text>
                      </View>
                    </View>
                  )
                )}
            <View style={stylesPdf.footer}>
              <View style={stylesPdf.footerLine} />
              <Text style={stylesPdf.footerText}>Aprovado por</Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </Dialog>
  );
};

export default OrderDetailPDF;
