// create a component called ProfileAvatar
import { IconButton, Button } from "@mui/material";
import React from "react";
import styled from "styled-components";
import NotificationIcon from "../../assets/notification-Icon.svg";
import { Notifications } from "@mui/icons-material";
import { Colors } from "../../theme/v2/variants";

interface ProfileProps {
  userName: string;
  bgColor?: string;
}

const ProfileAvatar: React.FC<ProfileProps> = ({ userName, bgColor }) => {
  return (
    <Container>
      <Icon bgColor={bgColor}>
        <IconText>{userName !== undefined ? userName[0] + "" : ""}</IconText>
      </Icon>
    </Container>
  );
};

const Container = styled.div`
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StateLayer = styled.div`
  height: 24px;
  display: grid;
  grid-template-columns: 24px auto;
  grid-template-areas: "icon label";
  align-items: center;
  column-gap: 16px;
`;

const Icon = styled.div<{ bgColor?: string }>`
  grid-area: icon;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#d78a17")};
  border-radius: 24px;
`;

const IconText = styled.div`
  text-align: center;
  color: #f8f9fc;
  font-size: 16px;
  font-family: "Noto Sans";
  font-weight: 400;
  word-wrap: break-word;
`;

const Label = styled.div`
  grid-area: label;
  text-align: center;
  width: auto;
  max-width: 140px;
  height: 16px;
  width: auto;
  color: ${Colors.primaryColor};
  font-size: 14px;
  font-family: "Noto Sans";
  font-weight: 400;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default ProfileAvatar;
