import { SupplierModel } from "../../pages/PurchasingContractingPlanPage/models/SuppliersModel";
import { api } from "../api";

export default class ManageSuppliersService {
  baseDomainEndPoint = "api/suppliers";

  async getAllClientSuppliers(clientId: number) {
    try {
      const response = await api.get(this.baseDomainEndPoint + `/${clientId}`);
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async createNewSupplier(supplier: SupplierModel) {
    try {
      const response = await api.post(this.baseDomainEndPoint, supplier);
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async sendSelectedSuppliers(suppliers: SupplierModel[]) {
    try {
      const response = await api.post(
        this.baseDomainEndPoint + `/selected-suppliers`,
        suppliers
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async deleteSupplier(id: number) {
    try {
      const response = await api.delete(this.baseDomainEndPoint + `/${id}`);
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async getSuppliersByPlanId(
    planId: number,
    planType: "PURCHASING" | "CONTRACTING"
  ) {
    try {
      const response = await api.get(
        this.baseDomainEndPoint + `/plan/${planId}/${planType}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async findById(id: number) {
    try {
      const response = await api.get(this.baseDomainEndPoint + `/find/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
