export default class VerticalServiceModel {
  public id?: number;
  public description?: string;
  public status?: string;
  public quantity?: number = 1;
  public unit?: string;
  public materialCost?: number;
  public materialCostWithMask?: string;
  public workforceCost?: number;
  public workforceCostWithMask?: string;
  public location?: any;
  public responsibleUserId?: number;
  public responsibleUserName?: string;
  public beginDate?: Date = new Date();
  public endDate?: Date = new Date();
  public constructionid?: number;
  public verticalServiceTypeId?: number;
  public verticalServiceGroupId?: number;
  public weekdaysOnly?: boolean;

  static isValid(data: VerticalServiceModel): boolean {
    try {
      if (
        !data.description ||
        !data.quantity ||
        !data.unit ||
        !data.location ||
        !data.responsibleUserId ||
        !data.beginDate ||
        !data.endDate ||
        !data.verticalServiceGroupId
      )
        return false;

      if (data.quantity < 0) return false;
    } catch (error) {
      return false;
    }

    return true;
  }
}
