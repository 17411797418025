import styled from "styled-components";
import { BaseState } from "../../../pages/Actions/ViewActions/utils/BaseState";
import DashboardCard from "../../Cards/DashboardCard/DashboardCard";
import { Doughnut } from "react-chartjs-2";
import Subtitle from "../Subtitle/Subtitle";
import { Colors } from "../../../theme/variants";
import ActionsService from "../../../services/action/ActionsService";
import { useEffect, useState } from "react";
import { set } from "date-fns";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";

interface ActionsEvolutionProps {
  constructionId?: string;
  year?: string;
}

interface Criteria {
  name: string;
  value: number;
}

const PriorityCriteriaChart: React.FC<ActionsEvolutionProps> = ({
  constructionId,
  year,
}) => {
  const actionService = new ActionsService();
  const [state, setState] = useState<BaseState>(BaseState.loading);
  const [firstCriteria, setFirstCriteria] = useState<Criteria>();
  const [secoundCriteria, setSecoundriteria] = useState<Criteria>();
  const [thirdCriteria, setThirdCriteria] = useState<Criteria>();
  const [othersCriteria, setOthersCriteria] = useState<Criteria>();
  const [month, setMonth] = useState<Date>(new Date());

  useEffect(() => {
    findConstructionPriorityCriteria(constructionId, month);
  }, [constructionId, year]);

  const findConstructionPriorityCriteria = async (
    constructionId?: string,
    date?: Date
  ) => {
    if (!constructionId || !date) {
      return;
    }

    setState(BaseState.loading);
    month.setFullYear(Number(year));

    // remove 2000 start date mock
    const firstDayOfMonth = new Date(2000, date.getMonth(), 1);
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    actionService
      .constructionPriorityCriteria(
        constructionId,
        firstDayOfMonth,
        lastDayOfMonth
      )
      .then((response) => {
        setState(BaseState.success);
        handleSuccessResponse(response);
      })
      .catch((error) => {
        setState(BaseState.error);
      });
  };

  const handleSuccessResponse = async (response: any) => {
    const labels = response.labels;
    const totalActions = response.totalActions;
    let totalActionsValue = 0;
    response.totalActions.forEach((value: number) => {
      totalActionsValue += value;
    });

    const criteria = labels.map((label: string, index: number) => {
      return {
        name: label,
        value: percentOf(totalActions[index], totalActionsValue),
      } as Criteria;
    });
    setFirstCriteria(criteria[0]);
    setSecoundriteria(criteria[1]);
    setThirdCriteria(criteria[2]);
    setOthersCriteria(criteria[3]);
  };

  const percentOf = (value: number, total: number): number => {
    return Number(((value / total) * 100).toFixed());
  };

  const onMonthChanged = (month: string, monthAsNumber: number) => {
    const date = new Date();
    date.setMonth(monthAsNumber);
    date.setFullYear(Number(year));
    setMonth(date);
    findConstructionPriorityCriteria(constructionId, date);
  };

  return (
    <DashboardCard
      title="Critérios prioritários"
      state={state}
      selectorLabel="Mês"
      onMonthChanged={onMonthChanged}
      isDropDownVisible={false}
    >
      <RootContainer>
        <ChartContainer>
          {othersCriteria ? (
            <OtherCriteriaDoughnut>
              <Doughnut
                data={{
                  labels: [othersCriteria.name, ""],
                  datasets: [
                    {
                      data: [othersCriteria.value, othersCriteria.value - 100],
                      backgroundColor: [Colors.primaryColorNeutralBlue, "#ddd"],
                      datalabels: {
                        display: false,
                      },
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      enabled: false,
                    },
                  },
                  maintainAspectRatio: false,
                  cutout: 25,
                  rotation: 180,
                }}
              />
            </OtherCriteriaDoughnut>
          ) : null}
          {thirdCriteria ? (
            <ThirdCriteriaDoughnut>
              <Doughnut
                data={{
                  labels: [thirdCriteria.name, ""],
                  datasets: [
                    {
                      data: [thirdCriteria.value, thirdCriteria.value - 100],
                      backgroundColor: [Colors.primaryColorLight, "#ddd"],
                      datalabels: {
                        display: false,
                      },
                    },
                  ],
                }}
                options={{
                  layout: {
                    padding: {
                      top: 10,
                    },
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      enabled: false,
                    },
                  },
                  maintainAspectRatio: false,
                  cutout: 44,
                  rotation: 180,
                }}
              />
            </ThirdCriteriaDoughnut>
          ) : null}

          {secoundCriteria ? (
            <SecondCriteriaDoughnut>
              <Doughnut
                data={{
                  labels: [secoundCriteria.name, ""],
                  datasets: [
                    {
                      data: [
                        secoundCriteria.value,
                        secoundCriteria.value - 100,
                      ],
                      backgroundColor: [Colors.blue, "#ddd"],
                      datalabels: {
                        display: false,
                      },
                    },
                  ],
                }}
                options={{
                  layout: {
                    padding: {
                      top: 10,
                    },
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      enabled: false,
                    },
                  },
                  maintainAspectRatio: false,
                  cutout: 68,
                  rotation: 180,
                }}
              />
            </SecondCriteriaDoughnut>
          ) : null}
          {firstCriteria ? (
            <FirstCriteriaDoughnut>
              <Doughnut
                data={{
                  labels: [firstCriteria.name, ""],
                  datasets: [
                    {
                      data: [firstCriteria.value, firstCriteria.value - 100],
                      backgroundColor: [Colors.primaryColorDark, "#ddd"],
                      datalabels: {
                        display: false,
                      },
                    },
                  ],
                }}
                options={{
                  layout: {
                    padding: {
                      top: 10,
                    },
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      enabled: false,
                    },
                  },
                  maintainAspectRatio: false,
                  cutout: 92,
                  rotation: 180,
                }}
              />
            </FirstCriteriaDoughnut>
          ) : null}
        </ChartContainer>
        <SubtitleGroup>
          {firstCriteria ? (
            <Subtitle
              color={Colors.primaryColorDark}
              text={firstCriteria.name}
              percentage={firstCriteria.value.toString() + "%"}
            />
          ) : null}
          {secoundCriteria ? (
            <Subtitle
              color={Colors.blue}
              text={secoundCriteria.name}
              percentage={secoundCriteria.value.toString() + "%"}
            />
          ) : null}

          {thirdCriteria ? (
            <Subtitle
              color={Colors.primaryColorLight}
              text={thirdCriteria.name}
              percentage={thirdCriteria.value.toString() + "%"}
            />
          ) : null}
          {othersCriteria ? (
            <Subtitle
              color={Colors.primaryColorNeutralBlue}
              text={othersCriteria.name}
              percentage={othersCriteria.value.toString() + "%"}
            />
          ) : null}
        </SubtitleGroup>
      </RootContainer>
    </DashboardCard>
  );
};

const ChartContainer = styled.div`
  height: 240px;
  width: 230px;
`;

const RootContainer = styled.div`
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    display: grid;
    width: 100%;
    height: 290px;
    justify-content: center;
    align-content: center;
    grid-template-columns: min-content min-content;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: grid;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-content: center;
    grid-template-rows: min-content min-content;
    padding-top: 8px;
  }
`;

const OtherCriteriaDoughnut = styled.div`
  height: 75px;
  width: 75px;
  position: absolute;
  margin-left: 78px;
  margin-top: 82px;
`;

const ThirdCriteriaDoughnut = styled.div`
  height: 130px;
  width: 130px;
  position: absolute;
  margin-left: 50px;
  margin-top: 50px;
`;

const SecondCriteriaDoughnut = styled.div`
  height: 180px;
  width: 180px;
  position: absolute;
  margin-left: 24px;
  margin-top: 24px;
`;

const FirstCriteriaDoughnut = styled.div`
  height: 230px;
  width: 230px;
  position: absolute;
`;

const SubtitleGroup = styled.div`
  display: grid;
  height: fit-content;
  grid-template-rows: min-content min-content min-content min-content;
  margin-top: 0px;
  width: fit-content;
  justify-self: center;
  margin-bottom: 16px;
  margin-left: 24px;
  row-gap: 4px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 0px;
  }
`;

export default PriorityCriteriaChart;
