import styled from "styled-components";
import { Colors } from "../../theme/v2/variants";
import { MOBILE_BREAKPOINT } from "../../utils/mobileDimen";

const PageContainer = styled.div`
  background-color: ${Colors.alto};
  display: grid;
  height: calc(100vh);
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 17px 153px;
  min-width: 60%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    min-width: 0;
    padding: 17px 30px;
  }
`;

const FilesInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px dashed ${Colors.mercury};
  width: 100%;
  padding-top: 47px;
  padding-bottom: 47px;
  margin-top: 12px;
`;

const FilesInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  justify-content: center;
  border-radius: 16px;
  align-items: center;
  background-color: white;
  padding: 15px 0;
`;

const LogoContainer = styled.div`
  margin-top: 50px;
`;

const UserImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${Colors.gallery};
  padding: 10px;
  width: 36px;
  height: 36px;
  margin-top: 23px;
`;

const WelcomeText = styled.h1`
  margin-top: 20px;
  font-family: "Noto Sans";
  font-size: 24px;
  color: ${Colors.black};
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`;

const Highlight = styled.span`
  font-weight: 700;
`;

const SupplierName = styled.div`
  font-family: "Noto Sans";
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: ${Colors.black};
  line-height: 12px;
`;

const SupplierCNPJ = styled.div`
  margin-top: 9px;
  color: ${Colors.silverChalice};
  font-family: "Noto Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  margin-bottom: 32px;
`;

const FileItem = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  border: 1px solid ${Colors.athensGray};
  padding: 8px 12px;
  color: ${Colors.neutralGrayDark};
  border-radius: 8px;
  background-color: ${Colors.mercury};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: max-content max-content max-content;
    justify-content: space-between;
  }
`;

const FileItemText = styled.div`
  color: ${Colors.black};
  font-size: 12px;
  font-weight: 400;
  font-family: "Noto Sans";
  font-style: normal;
  margin-left: 16px;
`;

const FilesContainer = styled.div`
  display: grid;
  width: 100%;
  gap: 10px;
  margin-top: 12px;
`;

const SendContainer = styled.div`
  margin-bottom: 32px;
  margin-top: 32px;
`;

const SuccessTitle = styled.div`
  color: ${Colors.black};
  font-family: "Noto Sans";
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 7px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    text-align: center;
  }
`;

export const SuccessImg = styled.img`
  width: 400px;
  height: 400px;
  object-fit: contain;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 200px;
    height: 200px;
  }
`;

const SuccessText = styled.div`
  font-family: "Noto Sans";
  color: ${Colors.silverChalice};
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 29px;
`;

const CloseButtonContainer = styled.div`
  margin-bottom: 63px;
`;

export {
  CloseButtonContainer,
  SuccessText,
  SuccessTitle,
  PageContainer,
  ContentContainer,
  FilesInputContainer,
  FilesInput,
  LogoContainer,
  UserImgContainer,
  WelcomeText,
  Highlight,
  SupplierName,
  SupplierCNPJ,
  FileItem,
  FileItemText,
  FilesContainer,
  SendContainer,
};
