import { StyleSheet } from "@react-pdf/renderer";

const stylesPdf = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    color: "#000",
    padding: "10px",
    paddingBottom: "20px",
  },
  header: {
    width: "100%",
    height: "85px",
    border: "1px solid black",
    display: "flex",
    flexDirection: "row",
  },
  clientLogoView: {
    width: "20%",
    borderRight: "1px solid black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerCenterRow: {
    width: "55%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  headerRightRow: {
    width: "25%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    borderLeft: "1px solid black",
  },
  orderNumberDescriptionRow: {
    width: "100%",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingLeft: "5px",
  },
  constructionNameDescriptionRow: {
    padding: "5px",
    display: "flex",
    flexDirection: "row",
  },
  divider: {
    width: "100%",
    borderTop: "1px solid black",
  },
  viewer: {
    width: "100%",
    height: "70vh",
  },
  title: {
    padding: "5px",
    fontSize: "12px",
    fontWeight: "bold",
  },
  titleContainer: {
    width: "100%",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  orderNumberSymbol: {
    fontSize: "10px",
    fontWeight: "light",
  },
  constructionNameSymbol: {
    fontSize: "10px",
    fontWeight: "light",
  },
  clientDescription: {
    fontSize: "8px",
    padding: "5px",
    fontWeight: "thin",
    textAlign: "center",
  },
  logoCliente: {
    width: "auto",
    height: "auto",
  },
  typeAndDateRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
  },
  typeSelection: {
    width: "431px",
    paddingVertical: "5px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    borderRight: "1px solid black",
  },
  types: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  checkbox: {
    width: 15,
    height: 15,
    borderWidth: 1,
    borderColor: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 5,
    fontSize: 11,
  },
  checkboxText: {
    fontSize: 10,
    fontWeight: 400,
  },
  dateSection: {
    padding: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dateText: {
    fontSize: "10px",
    fontWeight: "light",
  },
  requesterSection: {
    padding: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
  },
  requesterText: {
    fontSize: "10px",
    fontWeight: "light",
  },
  tableHeader: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
  },
  tableHeaderItemRow: {
    width: "6%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRight: "1px solid black",
    padding: "5px",
  },
  tableHeaderItemText: {
    fontSize: "10px",
    fontWeight: "light",
    textAlign: "center",
  },
  tableHeaderDescriptionRow: {
    width: "51%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRight: "1px solid black",
    padding: "5px",
  },
  tableHeaderDescriptionText: {
    fontSize: "10px",
    fontWeight: "light",
    textAlign: "center",
  },
  tableHeaderSmallRow: {
    width: "9%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRight: "1px solid black",
    padding: "5px",
  },
  tableHeaderSmallText: {
    fontSize: "10px",
    fontWeight: "light",
    textAlign: "center",
  },
  tableHeaderEspecificationRow: {
    width: "25%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
  },
  tableHeaderEspecificationText: {
    fontSize: "10px",
    fontWeight: "light",
    textAlign: "center",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
  },
  tableItemRow: {
    width: "6%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRight: "1px solid black",
    padding: "5px",
  },
  tableItemText: {
    fontSize: "10px",
    fontWeight: "light",
    textAlign: "center",
  },
  tableDescriptionRow: {
    width: "51%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRight: "1px solid black",
    padding: "5px",
  },
  tableDescriptionText: {
    fontSize: "10px",
    fontWeight: "light",
    textAlign: "center",
  },
  tableSmallRow: {
    width: "9%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRight: "1px solid black",
    padding: "5px",
  },
  tableSmallText: {
    fontSize: "10px",
    fontWeight: "light",
    textAlign: "center",
  },
  tableEspecificationRow: {
    width: "25%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
  },
  tableEspecificationText: {
    fontSize: "10px",
    fontWeight: "light",
    textAlign: "center",
  },
  footer: {
    width: "100%",
    height: "90px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
  },
  footerLine: {
    width: "30%",
    borderBottom: "1px dashed black",
    marginBottom: "5px",
  },
  footerText: {
    fontSize: "10px",
    fontWeight: "light",
    textAlign: "center",
  },
});

export { stylesPdf };
