import styled from "styled-components";
import { Colors } from "../../../theme/variants";
import ActionCard, {
  ActionCardProps,
  ActionDetails,
} from "../../../components/ActionCard/ActionCard";
import React, { useEffect, useState } from "react";
import {
  DropResult,
  OnDragEndResponder,
  ResponderProvided,
} from "@hello-pangea/dnd";
import ReactPageBaseComponent from "../../../components/BaseComponents/ReactPageBaseComponent";
import KanbanComponent from "../../Kanban/KanbanComponent";
import DropDownComponent from "../../../components/InputValues/DropDownComponent";
import {
  ViewActionsByENUM,
  ViewActionsByENUMLabel,
  viewTypeActionValues,
  viewTypeActionByValue,
} from "./utils/ViewActionsByENUM";

import ActionsService from "../../../services/action/ActionsService";
import LoadingComponent from "../../../components/StateComponents/LoadingComponent";
import { BaseState } from "./utils/BaseState";
import ErrorComponent from "../../../components/StateComponents/ErrorComponent";
import Snackbar from "@mui/material/Snackbar";

import { useAuth } from "../../../contexts/auth";
import { useDashboard } from "../../../contexts/dashboard";
import MoveActionToDoPopUp from "./MoveActionToDoPopUp";
import { KanbanColumnStatus } from "../../Kanban/KanbanColumn";
import EmptyClientAndConstructionState from "../../../components/StateComponents/EmptyClientAndConstruction";
import Title from "../../../components/v2/Typography/Tittle";
import ConfirmDeleteItemPopUp from "../../../components/Dialogs/ConfirmDeleteItemPopUp";
import DateFilter, { DateFilterType } from "./DateFilter";
import ButtonComponent, {
  ButtonType,
} from "../../../components/Button/ButtonComponent";
import { set } from "date-fns";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";
import { bgcolor, width } from "@mui/system";
import {
  Dialog,
  IconButton,
  Container as MUIContainer,
  SwipeableDrawer,
  Typography,
  Box,
} from "@mui/material";
import { useNavbarVisibility } from "../../../contexts/navbar";
import AddRestrictionAndActionPlanPopUp from "../../VerticalService/components/AddRestrictionAndActionPlanPopUp";
import { ActionModel, IActionModel } from "../models/ActionModel";
import { IRestrictionMappingModel } from "../../Restrictions/models/RestrictionModel";
import { UserResponsible } from "../../../services/UserService";
import { RestrictionFormEntity } from "../../Restrictions/Form";
import RestrictionsService from "../../../services/restrictions/RestrictionsService";
import { ActionItem, MappingItem } from "../../../config/app-info";
import RestrictionMappingService from "../../../services/restrictionMapping/RestrictionMappingService";
import VerticalServiceModel from "../../../services/interfaces/VerticalServiceModel";
import SearchBar from "../../../components/SearchBar";
import { api } from "../../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ActionsFilter from "./ActionsFilter";
import {
  ButtonContainer,
  ManagerContainer,
  ManagerProfile,
  PageTitle,
  stylesPdf,
} from "./style";
import {
  PDFViewer,
  View,
  Document,
  Page,
  Image,
  Text,
} from "@react-pdf/renderer";
import ActionsPlanPdf from "../../ActionsPlan/Edit/pdf";
import ClearIcon from "@mui/icons-material/Clear";
import typography from "../../../theme/v2/typography";
import { ReactComponent as ShareOutlined } from "./../../../assets/share-outline.svg";
import { ReactComponent as FilterOutlined } from "./../../../assets/filter-outline.svg";

interface Props {}

interface LastSource {
  lastSource: KanbanColumnStatus | null;
  lastSourcePosition: number | null;
  destination: string | null;
}

const ViewActionsPage: React.FC<Props> = ({}) => {
  const actionService = new ActionsService();
  const restrictionService = new RestrictionsService();
  const restrictionMappingService = new RestrictionMappingService();
  const [state, setState] = useState<BaseState>(BaseState.loading);
  const [actions, setActions] = useState<ActionCardProps[][]>([[], [], [], []]);
  const [openDeleteActionDialog, setOpenDeleteActionDialog] =
    useState<boolean>(false);
  const [openMoveActionToDoDialog, setOpenMoveActionToDoDialog] =
    useState<boolean>(false);
  const [openDateFilterDialog, setOpenDateFilterDialog] =
    useState<boolean>(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState<boolean>(false);
  const [selectedAction, setSelectedAction] =
    useState<ActionCardProps | null>();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const { user } = useAuth();
  const [viewBy, setViewBy] = useState<ViewActionsByENUM>(
    ViewActionsByENUM.byMonth
  );
  const { constructionId, clientId, dtBegin, dtEnd } = useDashboard();
  const [lastSource, setLastSource] = useState<LastSource>();
  const [selectedDates, setSelectedDates] = useState<DateFilterType>();
  const [actionTitle, setActionTitle] = useState<string>("");
  const [responsibleUsers, setResponsibleUsers] = useState<UserResponsible[]>(
    []
  );
  const [selectedVerticalService, setSelectedVerticalService] =
    useState<VerticalServiceModel>();
  const [
    isAddRestrictionAndActionPlanDialogOpen,
    setIsAddRestrictionAndActionPlanDialogOpen,
  ] = useState<boolean>(false);
  const [showPdf, setShowPdf] = useState(false);
  const [actionsByCriteria, setActionsByCriteria] = useState<ActionItem[][]>(
    []
  );
  const [labelDtBegin, setLabelDtBegin] = useState("");
  const [labelDtEnd, setLabelDtEnd] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [constructionImage, setConstructionImage] = useState("");
  const closeAddRestrictionAndActionPlanDialog = () => {
    // setIsDayServicesDialogOpen(false);
    setSelectedVerticalService(undefined);
    setIsAddRestrictionAndActionPlanDialogOpen(false);
  };

  const { screenWidth } = useNavbarVisibility();
  let { id: constructionEditId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    findActionsByConstructionSortByStatus(viewBy);
  }, [constructionId]);

  useEffect(() => {
    if (constructionId) {
      navigate(`/acompanhar-acoes/${+constructionId}`);
    }
  }, [constructionId]);

  const findActionsByConstructionSortByStatus = async (
    viewBy: string,
    beginDate: Date | undefined = selectedDates?.startDate,
    endDate: Date | undefined = selectedDates?.deadlineDate
  ) => {
    if (isConstructionNotSelected()) {
      return;
    }

    setState(BaseState.loading);

    actionService
      .findActionsByConstructionSortByStatus(
        constructionId.toString(),
        viewBy,
        undefined,
        undefined,
        beginDate,
        endDate
      )
      .then((response) => {
        const findConstructionById = async () => {
          try {
            const result = await api.get(
              `/api/constructions/image/${constructionId}`
            );
            setConstructionImage(result.data);
          } catch (error) {
            console.error(error);
          }
        };
        findConstructionById();

        /* PREPARAR LISTA PARA PDF */
        const allActions = [
          ...response.pending,
          ...response.inProgress,
          ...response.concluded,
          ...response.late,
        ];

        const groupedActions: Record<string, ActionItem[]> = {};

        for (const action of allActions) {
          if (!groupedActions[action.criteria]) {
            groupedActions[action.criteria] = [];
          }
          groupedActions[action.criteria].push(action);
        }

        const actionsArray = Object.values(groupedActions);

        setActionsByCriteria(actionsArray);

        /* PREPARAR LISTA PARA CARDS */
        const pedingActions = response.pending;
        const inProgressActions = response.inProgress;
        const doneActions = response.concluded;
        const lateAction = response.late;
        const arrayActions: ActionCardProps[][] = [
          parseDataToActionCardProps(pedingActions),
          parseDataToActionCardProps(inProgressActions),
          parseDataToActionCardProps(doneActions),
          parseDataToActionCardProps(lateAction),
        ];
        setState(BaseState.success);
        setActions(arrayActions);
      })
      .catch((error) => {
        setState(BaseState.error);
      });
  };

  const handleViewByChange = async (viewBy: string) => {
    setSelectedDates(undefined);
    const viewByAsEnum = viewTypeActionByValue(viewBy);
    setViewBy(viewByAsEnum);
    const viewByEnum = viewTypeActionByValue(viewBy);
    findActionsByConstructionSortByStatus(viewByEnum);
  };

  const parseDataToActionCardProps = (data: any[]): ActionCardProps[] => {
    return data.map((action: any) => {
      const responsibleUsersNames = action.responsibleUsers.split(",");

      const details: ActionDetails = {
        responsibleList: [],
        criteria: action.restrictionName,
        priority: action.priorityAsString,
      };

      responsibleUsersNames.forEach((name: string) => {
        details.responsibleList.push(name);
      });

      const actionCardProps: ActionCardProps = {
        id: action.id,
        title: action.what,
        details: details,
        startDate: new Date(action.whenBegin),
        deadlineDate: new Date(action.whenEnd),
        commentsCount: 0,
      };

      return actionCardProps;
    });
  };

  const onDragEnd: OnDragEndResponder = async (
    result: DropResult,
    provided: ResponderProvided
  ) => {
    const { source, destination } = result;
    const newStatus = destination?.droppableId;
    const actionId = result.draggableId;

    if (!newStatus) {
      return;
    }

    if (
      (destination.droppableId === KanbanColumnStatus.PENDING ||
        destination.droppableId === KanbanColumnStatus.IN_PROGRESS) &&
      (source.droppableId === KanbanColumnStatus.DONE ||
        source.droppableId === KanbanColumnStatus.DELAYED ||
        source.droppableId === KanbanColumnStatus.IN_PROGRESS)
    ) {
      let selectedActions: ActionCardProps | undefined = undefined;

      if (destination.droppableId === KanbanColumnStatus.PENDING) {
        setActionTitle(`Mover para "A fazer"`);
        selectedActions = actions[0].find((action) => action.id === actionId);
      }

      if (destination.droppableId === KanbanColumnStatus.IN_PROGRESS) {
        setActionTitle(`Mover para "Em andamento"`);
        selectedActions = actions[1].find((action) => action.id === actionId);
      }

      setOpenMoveActionToDoDialog(true);
      setSelectedAction(selectedActions);
      setLastSource({
        lastSource: source.droppableId,
        lastSourcePosition: source.index,
        destination: newStatus,
      });
      return;
    }
    updateActionStatus(actionId, newStatus);
  };

  const dialogButtonCancelOnClick = () => {
    setOpenDeleteActionDialog(false);
    setSelectedAction(null);
  };

  const dialogButtonConfirmOnClick = () => {
    if (!selectedAction) {
      return;
    }
    setIsLoadingDialog(true);

    actionService
      .deleteAction(selectedAction.id)
      .then((response) => {
        setOpenDeleteActionDialog(false);
        setIsLoadingDialog(false);
        setSnackbarMessage("Ação excluída com sucesso");
        setOpenSnackbar(true);
        removeActionFromState(selectedAction);
        // remove loading state in dialog button
        setSelectedAction(null);
      })
      .catch((error) => {
        setOpenDeleteActionDialog(false);
        setIsLoadingDialog(false);
        setSnackbarMessage("Erro ao excluir ação");
        setOpenSnackbar(true);
        setSelectedAction(null);
      });
  };

  const handleTrashClick = (action: ActionCardProps) => {
    setOpenDeleteActionDialog(true);
    setSelectedAction(action);
  };

  const removeActionFromState = (action: ActionCardProps) => {
    const newActions = actions.map((column) => {
      const newColumn = column.filter((actionCardProps) => {
        return actionCardProps.id !== action.id;
      });
      return newColumn;
    });
    setActions(newActions);
  };

  const handleEditPermission = (): boolean => {
    const editPermission = [0, 1, 2, 4, 6];
    return !editPermission.includes(user.role);
  };

  function isConstructionNotSelected() {
    return (
      constructionId === null ||
      constructionId === undefined ||
      constructionId === 0
    );
  }

  const onCancelDialogCallback = (action: ActionCardProps) => {
    if (lastSource?.lastSourcePosition === null) {
      return;
    }

    let newActions = [...actions];
    newActions = newActions.map((column) => {
      const newColumn = column.filter((actionCardProps) => {
        return actionCardProps.id !== action.id;
      });
      return newColumn;
    });

    if (lastSource?.lastSource === KanbanColumnStatus.IN_PROGRESS) {
      newActions[1].splice(lastSource?.lastSourcePosition, 0, action);
      setActions(newActions);
    }

    if (lastSource?.lastSource === KanbanColumnStatus.DONE) {
      newActions[2].splice(lastSource?.lastSourcePosition, 0, action);
      setActions(newActions);
    }

    if (lastSource?.lastSource === KanbanColumnStatus.DELAYED) {
      newActions[3].splice(lastSource?.lastSourcePosition, 0, action);
      setActions(newActions);
    }

    setOpenMoveActionToDoDialog(false);
  };

  const onConfirmDialogCallback = (action: ActionCardProps) => {
    if (
      lastSource?.lastSource === null ||
      lastSource?.lastSource === undefined ||
      lastSource?.destination === null
    ) {
      return;
    }

    updateActionStatus(action.id, lastSource?.destination);
    actionService
      .updateAction(action.id, {
        beginDate: action.startDate,
        endDate: action.deadlineDate,
      })
      .then(() => {})
      .catch(() => {});
    setOpenMoveActionToDoDialog(false);
  };

  function updateActionStatus(actionId: string, newStatus: string) {
    actionService
      .updateActionStatus(actionId, newStatus)
      .then((response) => {})
      .catch((error) => {
        setOpenSnackbar(true);
        setSnackbarMessage("Erro ao atualizar status da ação");
      });
  }

  const onSelectDateFilter = (dates: DateFilterType) => {
    setSelectedDates(dates);
    setOpenDateFilterDialog(false);
    findActionsByConstructionSortByStatus(
      viewBy,
      dates.startDate,
      dates.deadlineDate
    );
  };

  const createNewRestriction = async (
    newRestrictionName: string,
    newRestrictionMappingObject: IRestrictionMappingModel
  ) => {
    const restriction = {
      name: newRestrictionName || "",
      status: true,
      code: "",
      criteriaId: Number(newRestrictionMappingObject.criteriaId),
      userId: user.id,
    } as RestrictionFormEntity;
    return await restrictionService.createNewRestriction(restriction);
  };

  const newRestrictionMapping = async (
    restrictionId: string,
    newRestrictionMappingObject: IRestrictionMappingModel
  ): Promise<MappingItem> => {
    const { category, priority, impact } = newRestrictionMappingObject;

    if (priority === undefined) {
      setSnackbarMessage("Preencha todos os campos obrigatórios");
      setOpenSnackbar(true);
      throw new Error("Preencha todos os campos obrigatórios");
    }

    const restrictionMapping: MappingItem = {
      index: 0,
      criteriaId: Number(newRestrictionMappingObject.criteriaId),
      restrictionId: Number(restrictionId),
      constructionId: constructionId,
      userId: user.id,
      status: true,
      category: null,
      priority: priority,
      impact: null,
      comments: newRestrictionMappingObject.comment || "",
      addedByPlanning: false,
    };

    const restrictionMappingResult =
      await restrictionMappingService.createRestrictionsMapping([
        restrictionMapping,
      ]);
    return restrictionMappingResult[0];
  };

  const addOrUpdateActions = async (
    restrictionMappingId: number,
    restrictionMapping: MappingItem,
    isUpdate: boolean = false,
    actions: IActionModel[],
    newRestrictionMappingObject: IRestrictionMappingModel
  ) => {
    const validActions =
      isUpdate === false
        ? actions
            .filter((action) => ActionModel.isValid(action))
            .filter((action) => action.generatedByEmptyObject === true)
        : actions.filter((action) => ActionModel.isValid(action));

    const actionsAsActionItems: ActionItem[] = validActions?.length
      ? validActions.map((action, index) => {
          const { priority } = action;
          const { category, impact } = newRestrictionMappingObject;

          if (priority === undefined) {
            setSnackbarMessage("Preencha todos os campos obrigatórios");
            setOpenSnackbar(true);
            throw new Error("Preencha todos os campos obrigatórios");
          }

          const actionItem: ActionItem = {
            index: index,
            what: action.actionDescription,
            how: action.how || "",
            whenBegin:
              action.beginDate instanceof Date
                ? action.beginDate.toISOString()
                : new Date(action.beginDate).toISOString(),
            whenEnd:
              action.endDate instanceof Date
                ? action.endDate.toISOString()
                : new Date(action.endDate).toISOString(),
            category: category || null,
            priority: priority,
            impact: impact || null,
            status: false,
            userId: user.id,
            restrictionMappingId: restrictionMappingId,
            responsibleUsersId: action?.responsibles?.length
              ? action?.responsibles?.map((responsible) =>
                  Number(responsible.id)
                )
              : [],
            addedByPlanning: action.generatedByEmptyObject,
            verticalService: selectedVerticalService?.id,
            id:
              !action.generatedByEmptyObject && action.id
                ? Number(action.id)
                : undefined,
          };
          return actionItem;
        })
      : [];

    restrictionMappingService
      .createActions(actionsAsActionItems, [restrictionMapping])
      .then((result) => {
        setSnackbarMessage("Ações salvas com sucesso!");
        findActionsByConstructionSortByStatus(viewBy);
        setOpenSnackbar(true);
      })
      .catch((error) => {
        setSnackbarMessage("Erro ao salvar ações");
        setOpenSnackbar(true);
      });
  };

  const createNewRestrictionMappingByNewRestriction = async (
    newRestrictionName: string,
    newRestrictionMappingObject: IRestrictionMappingModel,
    actions: IActionModel[]
  ) => {
    try {
      const newRestriction = await createNewRestriction(
        newRestrictionName,
        newRestrictionMappingObject
      );
      const restrictionId = newRestriction.id.toString();
      const restrictionMapping = await newRestrictionMapping(
        restrictionId,
        newRestrictionMappingObject
      );
      const newActions = await addOrUpdateActions(
        restrictionMapping.id!!,
        restrictionMapping,
        false,
        actions,
        newRestrictionMappingObject
      );
    } catch (error) {
      console.error(error);
      setSnackbarMessage(
        "Ocorreu um erro ao criar um novo mapeamento de restrição com nova restrição"
      );
      setOpenSnackbar(true);
    }
  };

  const getRestrictionMappingId = async (
    constructionId: number,
    restrictionId: number
  ): Promise<MappingItem[]> => {
    return await restrictionMappingService.getRestrictionsMappingByConstructionIdAndRestrictionid(
      constructionId,
      restrictionId
    );
  };

  const existsRestrictionMapping = async (
    restrictionId: string,
    constructionId: number
  ): Promise<MappingItem | undefined> => {
    try {
      const restricrionMapping = await getRestrictionMappingId(
        constructionId,
        Number(restrictionId)
      );
      return restricrionMapping[0];
    } catch (error) {
      return undefined;
    }
  };

  const createNewRestrictionMappingAndNewActions = async (
    restrictionId: string,
    newRestrictionMappingObject: IRestrictionMappingModel,
    actions: IActionModel[]
  ) => {
    const restrictipnMapping = await newRestrictionMapping(
      restrictionId,
      newRestrictionMappingObject
    );
    const newActions = await addOrUpdateActions(
      restrictipnMapping.id!!,
      restrictipnMapping,
      false,
      actions,
      newRestrictionMappingObject
    );
  };

  const onSaveActions = async (
    selectedRestrictionId: string | null | undefined,
    newRestrictionName: string | undefined,
    actions: IActionModel[],
    newRestrictionMappingObject: IRestrictionMappingModel
  ) => {
    const validActions = actions.filter((action) =>
      ActionModel.isValid(action)
    );

    if (validActions.length === 0) {
      setSnackbarMessage(
        "Nenhuma ação está válida para salvar. Verifque se todas as ações estão preenchidas corretamente"
      );
      setOpenSnackbar(true);
      return;
    }

    if (selectedRestrictionId === null && newRestrictionName === undefined) {
      setSnackbarMessage("Nenhuma restrição selecionada");
      setOpenSnackbar(true);
      return;
    }

    if (newRestrictionName !== undefined) {
      createNewRestrictionMappingByNewRestriction(
        newRestrictionName,
        newRestrictionMappingObject,
        actions
      );
      return;
    }

    if (selectedRestrictionId) {
      const restricrionMapping = await existsRestrictionMapping(
        selectedRestrictionId,
        constructionId
      );
      if (restricrionMapping && restricrionMapping.id) {
        await addOrUpdateActions(
          restricrionMapping.id,
          restricrionMapping,
          false,
          actions,
          newRestrictionMappingObject
        );
      } else {
        await createNewRestrictionMappingAndNewActions(
          selectedRestrictionId,
          newRestrictionMappingObject,
          actions
        );
      }
    }
  };

  const onSubmmit = async (
    selectedRestrictionId: string | null | undefined,
    newRestrictionName: string | undefined,
    actions: IActionModel[],
    newRestrictionMappingObject: IRestrictionMappingModel
  ) => {
    if (state === BaseState.loading) {
      return;
    }
    setState(BaseState.loading);
    try {
      await onSaveActions(
        selectedRestrictionId,
        newRestrictionName,
        actions,
        newRestrictionMappingObject
      );
      setState(BaseState.success);
    } catch (error) {
      setState(BaseState.success);
      setSnackbarMessage("Erro ao salvar ações");
      setOpenSnackbar(true);
    }
    setIsAddRestrictionAndActionPlanDialogOpen(false);
    return;
  };

  const fetchRecords = async (
    dtBegin: string,
    dtEnd: string,
    filterDateType: string,
    filterCriteria: string[],
    filterResponsibleUser: string[],
    filterStatus: string[]
  ) => {
    if (!submitting) {
      if (dtBegin) {
        setLabelDtBegin(
          new Date(dtBegin + " 08:00:00").toLocaleDateString("pt-BR")
        );
      } else {
        setLabelDtBegin("");
      }

      if (dtEnd) {
        setLabelDtEnd(
          new Date(dtEnd + " 08:00:00").toLocaleDateString("pt-br")
        );
      } else {
        setLabelDtEnd("");
      }
      setSubmitting(true);
      try {
        const response = await actionService.filterActionsByStatus(
          constructionId.toString(),
          dtBegin,
          dtEnd,
          filterCriteria,
          filterResponsibleUser,
          filterStatus
        );

        const allActions = [
          ...response.pending,
          ...response.inProgress,
          ...response.concluded,
          ...response.late,
        ];

        const groupedActions: Record<string, ActionItem[]> = {};

        for (const action of allActions) {
          if (!groupedActions[action.criteria]) {
            groupedActions[action.criteria] = [];
          }
          groupedActions[action.criteria].push(action);
        }

        const actionsArray = Object.values(groupedActions);

        setActionsByCriteria(actionsArray);

        const pedingActions = response.pending;
        const inProgressActions = response.inProgress;
        const doneActions = response.concluded;
        const lateAction = response.late;
        const arrayActions: ActionCardProps[][] = [
          parseDataToActionCardProps(pedingActions),
          parseDataToActionCardProps(inProgressActions),
          parseDataToActionCardProps(doneActions),
          parseDataToActionCardProps(lateAction),
        ];

        setActions(arrayActions);
        setState(BaseState.success);
      } catch (error) {
        console.error(error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  const onCanceledDateFilter = () => {
    setOpenDateFilterDialog(false);
  };

  const toggleModal = () => {
    setShowPdf(!showPdf);
  };

  const clearFilter = () => {
    findActionsByConstructionSortByStatus(viewBy);
  };

  if (isConstructionNotSelected()) {
    return (
      <MUIContainer
        sx={{ display: "flex", justifyContent: "center", height: "100%" }}
      >
        <EmptyClientAndConstructionState pageDescription="o quadro de Acompanhamento de ações" />
      </MUIContainer>
    );
  }

  return (
    <ReactPageBaseComponent>
      <PageContainer>
        {state === BaseState.loading && !isConstructionNotSelected() ? (
          <LoadingComponent />
        ) : null}
        {state === BaseState.success ? (
          <ContentContainer>
            <AboutContainer>
              <Title>Acompanhamento de Ações</Title>
              <ViewByContainer>
                {/* <DropDownComponent
                  defaultValue={ViewActionsByENUMLabel[viewBy]}
                  key="Visualizar por"
                  componentKey="viewBy"
                  onSelectedValueChanged={(key, value) => {
                    handleViewByChange(value);
                  }}
                  values={viewTypeActionValues()}
                  sx={{
                    width:
                      screenWidth > MOBILE_BREAKPOINT
                        ? "225px"
                        : "calc(100vw - 40px)",
                  }}
                  bakcgrounColor="white"
                  variant="filled"
                /> */}
                <ButtonComponent
                  text={"Exportar PDF"}
                  type={ButtonType.OUTLINE}
                  startIcon={<ShareOutlined />}
                  fullWidth
                  onClick={() => setShowPdf(true)}
                />
                <ButtonComponent
                  text={"Filtrar"}
                  type={ButtonType.OUTLINE}
                  startIcon={<FilterOutlined />}
                  fullWidth
                  onClick={() => setOpenDateFilterDialog(true)}
                />
                <ButtonComponent
                  text="Nova"
                  startIcon={<AddRoundedIcon />}
                  type={ButtonType.PRIMARY}
                  fullWidth
                  onClick={() =>
                    setIsAddRestrictionAndActionPlanDialogOpen(true)
                  }
                />
              </ViewByContainer>
            </AboutContainer>
            <SwipeableDrawer
              anchor={"right"}
              open={openDateFilterDialog}
              onClose={onCanceledDateFilter}
              onOpen={() => {}}
            >
              <SearchBar
                hasFilterCriteria
                hasFilterResponsibleUser
                hasFilterStatus
                isSidebarFilter
                onCloseModal={onCanceledDateFilter}
                callReport={fetchRecords}
                actionsByCriteria={actionsByCriteria}
                setShowPdf={setShowPdf}
                onClearFilter={clearFilter}
                showPdf={showPdf}
              />
            </SwipeableDrawer>
            <SliderContainer>
              <KanbanContainer>
                <KanbanComponent
                  actionsByProps={actions}
                  onDragEndCallback={onDragEnd}
                  actionCardTrashOnClick={handleTrashClick}
                  isDragDisabled={handleEditPermission()}
                />
              </KanbanContainer>
            </SliderContainer>
          </ContentContainer>
        ) : null}

        {state === BaseState.error ? <ErrorComponent /> : null}
      </PageContainer>
      <ConfirmDeleteItemPopUp
        title="Excluir ação"
        description="Deseja realmente excluir a ação?"
        openDeleteActionDialog={openDeleteActionDialog}
        isLoadingDialog={isLoadingDialog}
        dialogButtonCancelOnClick={dialogButtonCancelOnClick}
        dialogButtonConfirmOnClick={dialogButtonConfirmOnClick}
      />
      <AddRestrictionAndActionPlanPopUp
        isOpen={isAddRestrictionAndActionPlanDialogOpen}
        onClose={closeAddRestrictionAndActionPlanDialog}
        onConfirm={onSubmmit}
        userResponsibles={responsibleUsers}
        // isLoadingDialog={addServiceDialogLoading}
        // model={selectedVerticalService}
        // serviceType={selectedDayServicesItems}
      />
      <MoveActionToDoPopUp
        openDialog={openMoveActionToDoDialog}
        action={selectedAction}
        onCancelDialogCallback={onCancelDialogCallback}
        onConfirmDialogCallback={onConfirmDialogCallback}
        title={actionTitle}
      />
      <Snackbar
        message={snackbarMessage}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => {
          setOpenSnackbar(false);
        }}
      />

      <Dialog
        fullWidth
        maxWidth="xl"
        PaperProps={{
          style: {
            borderRadius: "15px",
            padding: "25px",
            paddingTop: "40px",
          },
        }}
        onClose={toggleModal}
        open={showPdf}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="20px"
        >
          <Typography
            fontSize={typography.h2?.fontSize}
            fontWeight={typography.fontWeightBold}
          >
            Plano de Ações Global
          </Typography>

          <IconButton onClick={toggleModal}>
            <ClearIcon fontSize="large" />
          </IconButton>
        </Box>
        <PDFViewer style={stylesPdf.viewer}>
          <Document title={`Plano de Ações - Acompanhamento`}>
            <Page size="A4" style={stylesPdf.page} orientation="landscape" wrap>
              <View style={stylesPdf.section}>
                {constructionImage && (
                  <Image
                    src={constructionImage}
                    style={stylesPdf.logoCliente}
                  />
                )}
                <Image src="/logo.png" style={stylesPdf.logo} />
                <Text
                  style={stylesPdf.title}
                >{`Plano de Ações - Acompanhamento`}</Text>
                <Text style={stylesPdf.construction}>
                  {/* {constructionName.toUpperCase()} */}
                </Text>
              </View>

              <Typography style={stylesPdf.updateText}>
                {labelDtBegin
                  ? `ATUALIZAÇÃO: ${labelDtBegin} à ${labelDtEnd}`
                  : ""}
              </Typography>

              {actionsByCriteria.length > 0 &&
                actionsByCriteria.map((itemActions, index) => {
                  return (
                    <ActionsPlanPdf
                      key={index}
                      criteriaName={itemActions[0].criteria!}
                      actions={itemActions}
                      labelDtEnd={labelDtEnd}
                    />
                  );
                })}

              {actionsByCriteria.length > 0 && (
                <Text
                  style={stylesPdf.pagination}
                  render={({ pageNumber, totalPages }) =>
                    `${pageNumber} / ${totalPages}`
                  }
                  fixed
                />
              )}
            </Page>
          </Document>
        </PDFViewer>
      </Dialog>
    </ReactPageBaseComponent>
  );
};

export const PageContainer = styled.div`
  display: grid;
  height: 100%;
  max-width: 100%;
  min-height: 100%;
  grid-template-columns: 1fr;
  background-color: ${Colors.neutralColorSurface};
  overflow-x: hidden;
  align-content: space-between;
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-template-columns: 1fr;
  max-width: 100%;
  height: 100%;
  flex-grow: 1;
`;

const AboutContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const FilterContainer = styled.div`
  background-color: ${Colors.neutralColorWhite};
  padding: 20px;
  border-radius: 20px;
  margin-top: 24px;
  width: 100%;
`;

const ViewByContainer = styled.div`
  display: grid;
  min-width: 156px;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: flex-start;
  place-items: center;
  gap: 24px;

  @media (max-width: 900px) {
    grid-template-columns: 200px 1fr;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    grid-template-columns: 1fr;
    margin-top: 20px;
    gap: 16px;
  }
`;

const KanbanContainer = styled.div`
  height: 100%;
  width: 100vw;

  @media (max-width: 1300px) {
    width: 110vw;
  }

  @media (max-width: 1100px) {
    width: 140vw;
  }

  @media (max-width: 900px) {
    width: 160vw;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    height: max-content;
  }
`;

const SliderContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-top: 24px;
  padding-bottom: 30px;
  min-height: 80vh;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 10px;
  }
`;

export default ViewActionsPage;
