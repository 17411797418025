import React from "react";
import styled from "styled-components";
import { Colors } from "../../../../theme/v2/variants";
import Divider from "../../../../components/v2/Divider/Divider";

import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { useNavigate } from "react-router-dom";
import { PURCHASING_CONTRACTING_PLAN_PATH } from "../../../../config/app-info";

interface PlanTooltipProps {
  requester: string;
  name: string;
  // quantity: number;
  // unit: string;
  // specifications: string;
  onClickItem: () => void;
}

const PlanTooltip: React.FC<PlanTooltipProps> = ({
  requester,
  name,
  // quantity,
  // unit,
  // specifications,
  onClickItem,
}) => {
  const navigation = useNavigate();
  return (
    <Container>
      <Link onClick={onClickItem}>
        <Title>
          <CreateOutlinedIcon
            fontSize="small"
            style={{
              marginRight: "10px",
              color: `${Colors.neutralColorLight}`,
            }}
          />
          {name}
        </Title>
      </Link>
      {requester && (
        <DescriptionContainer>
          <DescriptionText>
            Requisitante:
            <SpanText> {requester}</SpanText>
          </DescriptionText>
        </DescriptionContainer>
      )}
      <Divider color={Colors.textColorGray} />
      {/*<DescriptionText style={{ marginTop: "10px" }}>*/}
      {/*  Qtd.:*/}
      {/*  <SpanText> {quantity + " " + unit}</SpanText>*/}
      {/*</DescriptionText>*/}
      {/*<DescriptionText style={{ marginTop: "-10px" }}>*/}
      {/*  Espec.:*/}
      {/*  <SpanText> {specifications}</SpanText>*/}
      {/*</DescriptionText>*/}
    </Container>
  );
};

const Container = styled.div`
  width: 200px;
  background-color: ${Colors.black};
  display: grid;
  grid-template-rows: max-content max-content max-content;
  margin-bottom: 48px;
  position: absolute;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 100;
`;

const DescriptionContainer = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
`;

const DescriptionText = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  padding-left: 22px;
  padding-right: 22px;
  padding-bottom: 8px;
  align-items: center;
  color: ${Colors.neutralColor};

  &:last-child {
    padding-bottom: 10px;
  }
`;

const Title = styled.div`
  display: flex;
  font-family: "Noto Sans";
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  padding: 10px;
  color: ${Colors.neutralColor};
  text-align: left;
  align-items: center;
  justify-content: flex-start;
`;

const SpanText = styled.span`
  color: ${Colors.textColorLight};
`;

const Link = styled.a`
  cursor: pointer;
  :hover {
    text-decoration: underline;
    text-decoration-color: ${Colors.textColorLight};
  }
`;

export default PlanTooltip;
