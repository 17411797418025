import {
  OnDragEndResponder,
  DropResult,
  ResponderProvided,
  DragDropContext,
} from "@hello-pangea/dnd";
import styled from "styled-components";
import { ActionCardProps } from "../../components/ActionCard/ActionCard";
import KanbanColumn, { KanbanColumnStatus } from "./KanbanColumn";
import { useEffect, useState } from "react";
import { MOBILE_BREAKPOINT } from "../../utils/mobileDimen";

interface KanbanComponentProps {
  actionsByProps: ActionCardProps[][];
  onDragEndCallback?: OnDragEndResponder;
  actionCardTrashOnClick: (card: ActionCardProps) => void;
  isDragDisabled?: boolean;
}

const KanbanComponent: React.FC<KanbanComponentProps> = ({
  actionsByProps,
  onDragEndCallback,
  actionCardTrashOnClick,
  isDragDisabled,
}) => {
  const [actions, setActions] = useState<ActionCardProps[][]>(actionsByProps);

  useEffect(() => {
    setActions(actionsByProps);
  }, [actionsByProps]);

  const onDragEnd: OnDragEndResponder = async (
    result: DropResult,
    provided: ResponderProvided
  ) => {
    const { source, destination } = result;
    let removedAction: ActionCardProps;
    const newActions = [...actions];

    if (!destination) {
      return;
    }

    switch (source?.droppableId) {
      case KanbanColumnStatus.PENDING:
        removedAction = removeItemById(newActions, 0, source.index);
        break;
      case KanbanColumnStatus.IN_PROGRESS:
        removedAction = removeItemById(newActions, 1, source.index);
        break;
      case KanbanColumnStatus.DONE:
        removedAction = removeItemById(newActions, 2, source.index);
        break;
      case KanbanColumnStatus.DELAYED:
        removedAction = removeItemById(newActions, 3, source.index);
        break;
      default:
        return;
    }

    switch (destination?.droppableId) {
      case KanbanColumnStatus.PENDING:
        addItemById(newActions, 0, destination.index, removedAction);
        break;
      case KanbanColumnStatus.IN_PROGRESS:
        addItemById(newActions, 1, destination.index, removedAction);
        break;
      case KanbanColumnStatus.DONE:
        addItemById(newActions, 2, destination.index, removedAction);
        break;
      case KanbanColumnStatus.DELAYED:
        addItemById(newActions, 3, destination.index, removedAction);
        break;
      default:
        return;
    }

    setActions(newActions);
    onDragEndCallback?.(result, provided);
  };

  const removeItemById = (
    actions: ActionCardProps[][],
    collumId: number,
    index: number
  ): ActionCardProps => {
    const [removedAction] = actions[collumId].splice(index, 1);
    return removedAction;
  };

  const addItemById = (
    actions: ActionCardProps[][],
    collumId: number,
    index: number,
    item: ActionCardProps
  ): void => {
    actions[collumId].splice(index, 0, item);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <KanbanContainer>
        <KanbanColumn
          actions={actions[0]}
          status={KanbanColumnStatus.PENDING}
          onTrashClick={(card) => actionCardTrashOnClick(card)}
          isDragDisabled={isDragDisabled}
        />
        <KanbanColumn
          actions={actions[1]}
          status={KanbanColumnStatus.IN_PROGRESS}
          onTrashClick={(card) => actionCardTrashOnClick(card)}
          isDragDisabled={isDragDisabled}
        />
        <KanbanColumn
          actions={actions[2]}
          status={KanbanColumnStatus.DONE}
          onTrashClick={(card) => actionCardTrashOnClick(card)}
          isDragDisabled={isDragDisabled}
        />
        <KanbanColumn
          actions={actions[3]}
          status={KanbanColumnStatus.DELAYED}
          onTrashClick={(card) => actionCardTrashOnClick(card)}
          isDragDisabled={isDragDisabled}
        />
      </KanbanContainer>
    </DragDropContext>
  );
};

const KanbanContainer = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  min-height: max-content;
  max-width: 100%;
  grid-template-columns: repeat(4, 1fr);
  justify-content: start;
  gap: 16px;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default KanbanComponent;
