import React from "react";
import styled from "styled-components";
import { Colors } from "../../../../theme/v2/variants";
import LinearProgress from "@mui/material/LinearProgress";

interface LoadingProgressProps {
  progress: number;
}

const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 78px;
  padding-left: 47px;
  padding-right: 47px;
  padding-bottom: 21px;
`;

const ProgressText = styled.div`
  font-family: "Noto Sans";
  color: ${Colors.black};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 29px;
`;

const ProgressWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledLinearProgress = styled(LinearProgress)`
  height: 10px;
  border-radius: 6px;
  background-color: ${Colors.gallery};
  margin-bottom: 90px;

  .MuiLinearProgress-bar {
    background-color: ${Colors.tundora};
  }
`;

const ProgressPercentage = styled.div<{ progress: number }>`
  font-family: "Noto Sans";
  position: absolute;
  top: 10px;
  left: ${(props) => `calc(${props.progress}% - 92px)`};
  font-size: 48px;
  color: ${Colors.lightSilverChalice};
  font-weight: 300;
  text-align: center;
`;

const LoadingProgress: React.FC<LoadingProgressProps> = ({ progress }) => {
  return (
    <ProgressBarContainer>
      <ProgressText>
        Aguarde um instante, estamos enviando seu orçamento em instantes...
      </ProgressText>
      <ProgressWrapper>
        <StyledLinearProgress variant="determinate" value={progress} />
        <ProgressPercentage progress={progress}>{progress}%</ProgressPercentage>
      </ProgressWrapper>
    </ProgressBarContainer>
  );
};

export default LoadingProgress;
