import { ptBR } from "date-fns/locale";
import { TABS } from "../../../../constants/tabs";
import { format } from "date-fns";
import { capitalizeFirstLetter } from "../../../../utils/string";

export const getPeriodBySelectedTab = (
  date: Date,
  endDate: Date,
  selectedTab: string
) => {
  let dateFormated = "";

  switch (selectedTab) {
    case TABS.semanal:
      dateFormated = date
        .toLocaleDateString("pt-BR", {
          weekday: "short",
          day: "2-digit",
          month: "2-digit",
        })
        .replace(".,", "");
      break;
    case TABS.mensal:
      const startDateMensal = date.toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
      });
      const endDateMensal = endDate?.toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
      });
      dateFormated = `${startDateMensal} à ${endDateMensal}`;
      break;
    case TABS.trimestral:
      const startDateTrimester = date.toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
      });
      const endDateTrimester = endDate?.toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
      });
      dateFormated = `${startDateTrimester} à ${endDateTrimester}`;
      break;
    case TABS.semestral:
      dateFormated = date.toLocaleDateString("pt-BR", {
        month: "long",
      });
      break;
    case TABS.anual:
      dateFormated = date.toLocaleDateString("pt-BR", {
        month: "short",
      });
      dateFormated +=
        "/" +
        date.toLocaleDateString("pt-BR", {
          year: "numeric",
        });
      dateFormated = dateFormated.replace(".", "");
      break;
    case TABS.custom:
      const startDateCustom = date.toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
      });
      const endDateCustom = endDate?.toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
      });
      dateFormated = `${startDateCustom} à ${endDateCustom}`;
      break;
    default:
      break;
  }

  return dateFormated;
};

export const getPeriodBySelectedTabForPrintedState = (
  startDateAsString: string,
  endDateAsString: string,
  selectedTab: string
): string => {
  if (!startDateAsString || !endDateAsString) return "";

  const startDate = new Date(startDateAsString);
  const endDate = new Date(endDateAsString);

  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) return "";

  switch (selectedTab) {
    case TABS.semanal:
      return `${format(startDate, "dd", { locale: ptBR })} à ${format(
        endDate,
        "dd/MM",
        { locale: ptBR }
      )}`;
    case TABS.mensal:
      return capitalizeFirstLetter(format(startDate, "MMMM", { locale: ptBR }));
    case TABS.trimestral:
      return `Trimestral - ${format(startDate, "MMM", {
        locale: ptBR,
      })} à ${format(endDate, "MMM", { locale: ptBR })}`;
    case TABS.semestral:
      return `Semestral - ${format(startDate, "MMM", {
        locale: ptBR,
      })} à ${format(endDate, "MMM", { locale: ptBR })}`;
    case TABS.anual:
      return `Anual ${format(startDate, "yyyy", { locale: ptBR })}`;
    case TABS.custom:
      return `${format(startDate, "dd/MM/yyyy", { locale: ptBR })} à ${format(
        endDate,
        "dd/MM/yyyy",
        { locale: ptBR }
      )}`;
    default:
      return "";
  }
};
