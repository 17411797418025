import { Colors } from "../../theme/v2/variants";

export const modalTextFieldStyle = {
  "& .MuiFilledInput-root": { backgroundColor: `${Colors.wildSand}` },
  "& .MuiFilledInput-root.Mui-focused": {
    backgroundColor: `${Colors.wildSand}`,
  },
  "& .MuiInputBase-input": {
    fontSize: "16px",
    color: `${Colors.neutralGrayDark}`,
  },
  "& .MuiInputLabel-root": {
    fontSize: "32px",
    color: "red",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: `${Colors.greenLight}`,
  },
  "& .MuiInputLabel-filled": {
    fontSize: "16px",
    color: `${Colors.neutralGrayDark}`,
  },
  "& .MuiInputLabel-shrink": {
    fontSize: "14px",
    color: `${Colors.neutralGrayDark}`,
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: `2px solid ${Colors.greenLight}`,
  },
};
