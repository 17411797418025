import React from "react";
import styled from "styled-components";
import { Colors } from "../../../../theme/v2/variants";

import Avatar from "@mui/material/Avatar";

interface UserInfoProps {
  Date?: string;
  UserName?: string;
  startAvatar?: any;
  endAvatar?: any;
  action?: string;
}

const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0;
`;

const UserText = styled.div<{ endAvatar?: boolean }>`
  color: ${Colors.comet};
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  text-align: ${(props) => (props.endAvatar ? "right" : "left")};
  white-space: nowrap;
  ${(props) => (props.endAvatar ? "padding-right: 9px" : "padding-left: 9px;")};
`;

const BoldText = styled.span`
  font-weight: 700;
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const getInitials = (name: string) => {
  const initials = name
    .split(" ")
    .map((n) => n[0])
    .join("")
    .toUpperCase();
  return initials.slice(0, 1);
};

const UserInfo: React.FC<UserInfoProps> = ({
  action,
  Date,
  UserName,
  startAvatar,
  endAvatar,
}) => {
  return (
    <UserInfoContainer>
      <UserWrapper>
        {startAvatar ? (
          <Avatar
            src={startAvatar}
            alt={UserName}
            sx={{ width: 26, height: 26 }}
          />
        ) : (
          <Avatar
            sx={{
              width: 26,
              height: 26,
              backgroundColor: Colors.primaryColor,
              fontWeight: 700,
              fontSize: "initial",
            }}
          >
            {getInitials(UserName || "")}
          </Avatar>
        )}
        <UserText endAvatar={!!endAvatar}>
          {action} {`${Date ? "em " + Date : ""}`} <br></br> por{" "}
          <BoldText>{UserName}</BoldText>
        </UserText>
      </UserWrapper>{" "}
    </UserInfoContainer>
  );
};

export default UserInfo;
