import styled from "styled-components";
import ReactBaseComponent from "../../../components/BaseComponents/ReactBaseComponent";
import Dialog from "@mui/material/Dialog";
import VerticalServiceModel from "../../../services/interfaces/VerticalServiceModel";
import { Colors } from "../../../theme/v2/variants";
import PopUpBaseComponent from "../../../components/PopUps/PopUpBaseComponent";
import AdvancementIndexComponent, {
  AdvancementIndexComponentRef,
  AdvancementIndexType,
} from "./AdvancementIndexComponent";
import DropDownComponent from "../../../components/InputValues/DropDownComponent";
import { UnitValues } from "../utils";
import InputValueComponent from "../../../components/InputValues/InputValueComponent";
import { UpdateServiceProgressModel } from "../models/UpdateServiceProgressModel";
import React, { useEffect } from "react";
import { moneyMask } from "../../../utils/mask";
import {
  ProgressUpdate,
  ProgressUpdateType,
  VerticalServiceProgressModel,
} from "../../../services/interfaces/VerticalServiceProgressModel";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";
import VerticalService from "../../../services/VeritcalService";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";

interface UpdateServiceProgressPopUpProps {
  isOpen: boolean;
  isLoading: boolean;
  currentProgress?: VerticalServiceProgressModel;
  verticalServiceModel?: VerticalServiceModel;
  cancelOnClick: () => void;
  confirmOnClick: (service: UpdateServiceProgressModel) => void;
  confirmUpdatePastProgress: (
    serviceId: number,
    progressUpdateId: number,
    newValue: number,
    updateType: ProgressUpdateType
  ) => void;
  serviceType?: string;
}

export enum ComponentState {
  UPDATE_PROGRESS,
  EDIT_PAST_PROGRESS,
}

const UpdateServiceProgressPopUp: React.FC<UpdateServiceProgressPopUpProps> = ({
  isOpen,
  cancelOnClick,
  confirmOnClick,
  isLoading,
  currentProgress,
  verticalServiceModel,
  confirmUpdatePastProgress,
  serviceType,
}) => {
  const verticalService = new VerticalService();

  const [data, setData] = React.useState<UpdateServiceProgressModel>(
    new UpdateServiceProgressModel()
  );

  const [currentProgressData, setCurrentProgressData] =
    React.useState<VerticalServiceProgressModel>();

  const [isPhysivalProgressValid, setIsPhysivalProgressValid] =
    React.useState(true);

  const [phisicalValue, setPhisicalValue] = React.useState<number>(0);
  const [phisicalValueAsPercent, setPhisicalValueAsPercent] =
    React.useState<number>(0);
  const [state, setState] = React.useState(ComponentState.UPDATE_PROGRESS);
  const [pastProgressShowing, setPastProgressShowing] = React.useState<
    "PHYSICAL" | "FINANCE" | undefined
  >();
  const [selectedProgressUpdade, setSelectedProgressUpdade] =
    React.useState<ProgressUpdate>();
  const advancementPhysicalIndexRef =
    React.useRef<AdvancementIndexComponentRef>(null);
  const advancementFinancialIndexRef =
    React.useRef<AdvancementIndexComponentRef>(null);

  const [itemsToDelete, setItemsToDelete] = React.useState<ProgressUpdate[]>(
    []
  );

  useEffect(() => {
    setCurrentProgressData(currentProgress);
  }, [currentProgress]);

  const handleWorkforceCostChange = (value: string) => {
    const priceWithMask = moneyMask(value);
    data.workforceCost = Number.parseInt(value.replace(/\D/g, ""));
    updateTempFinancialProgress();
    setData({
      ...data,
      workforceCost: data.workforceCost,
      workforceCostWithMask: priceWithMask,
    });
  };

  const handleMaterialCostChange = (value: string) => {
    const priceWithMask = moneyMask(value);
    data.materialCost = Number.parseInt(value.replace(/\D/g, ""));
    updateTempFinancialProgress();
    setData({
      ...data,
      materialCost: data.materialCost,
      materialCostWithMask: priceWithMask,
    });
  };

  const handlePhisicalProgressChange = (value: string) => {
    let newValue = Number.parseFloat(value);
    if (isNaN(newValue)) {
      newValue = 0;
    }
    data.progressByValue = newValue;
    const physicalTotalValue = currentProgress?.totalPhysicalValue || 0;
    const valueAsPercent = (newValue * 100) / physicalTotalValue;

    updateTempPhysicalProgress();
    setData({
      ...data,
      progressByValue: newValue,
      progressByValueAsPercent: valueAsPercent,
    });
  };

  const handlePhisicalProgressAsPercentChange = (value: string) => {
    let newValue = Number.parseFloat(value);
    if (isNaN(newValue)) {
      newValue = 0;
    }
    const physicalTotalValue = currentProgress?.totalPhysicalValue || 0;

    const newProgressValue = Number(
      (physicalTotalValue * (newValue / 100)).toFixed(2)
    );
    data.progressByValueAsPercent = newValue;
    data.progressByValue = newProgressValue;
    updateTempPhysicalProgress();
    setData({
      ...data,
      progressByValueAsPercent: newValue,
      progressByValue: newProgressValue,
    });
  };

  const updateTempFinancialProgress = () => {
    const materialCostInputValue = data.materialCost || 0;
    const workforceCostInputValue = data.workforceCost || 0;
    const currentFinancialProgresByInputValue =
      (materialCostInputValue + workforceCostInputValue) / 100;
    const currentFinanceProgress =
      currentProgress?.financialProgressAsCurrency || 0;
    const newTempFinanceProgress =
      currentFinancialProgresByInputValue + currentFinanceProgress;
    const totalFinancialProgress = currentProgress?.totalFinancialValue || 0;

    const newFinancialProgressAsPercent =
      (newTempFinanceProgress / totalFinancialProgress) * 100;

    if (currentProgressData) {
      setCurrentProgressData({
        ...currentProgressData,
        financialProgress: Number.parseFloat(
          newFinancialProgressAsPercent.toFixed(2)
        ),
      });
    }
  };

  const updateTempPhysicalProgress = () => {
    const newProgressValue = data.progressByValue || 0;

    const remainingValue = currentProgress?.remainPhyscalValue || 0;
    const currentProgressAsValue =
      currentProgress?.physicalProgressAsTotalValue || 0;
    const sumNewAndCurrent = newProgressValue + currentProgressAsValue;
    const totalPossibleValue = currentProgress?.totalPhysicalValue || 0;

    const newTempPercentage = (sumNewAndCurrent * 100) / totalPossibleValue;

    if (currentProgressData) {
      if (newProgressValue > remainingValue) {
        setIsPhysivalProgressValid(false);
      } else {
        setIsPhysivalProgressValid(true);
      }

      setCurrentProgressData({
        ...currentProgressData,
        physicalProgress: Number.parseFloat(newTempPercentage.toFixed(2)),
      });
    }
  };

  const handleOnCancel = () => {
    if (state === ComponentState.EDIT_PAST_PROGRESS && selectedProgressUpdade) {
      // TODO - preciso adicionar novamente
      setItemsToDelete([]);
      setSelectedProgressUpdade(undefined);
      setState(ComponentState.UPDATE_PROGRESS);
      advancementPhysicalIndexRef.current?.removeUpdateStateAndSelectedUpdate();
      advancementFinancialIndexRef.current?.removeUpdateStateAndSelectedUpdate();
      return;
    }

    cancelOnClick();
    cleanData();
  };

  const handleOnConfirm = async () => {
    if (
      state === ComponentState.EDIT_PAST_PROGRESS &&
      selectedProgressUpdade &&
      selectedProgressUpdade.progressType === ProgressUpdateType.PHYSICAL &&
      data.progressByValue
    ) {
      confirmUpdatePastProgress(
        verticalServiceModel?.id || 0,
        selectedProgressUpdade.id,
        data.progressByValue,
        selectedProgressUpdade.progressType
      );
      handlePhisicalProgressChange("0");
      advancementPhysicalIndexRef.current?.removeUpdateStateAndSelectedUpdate();
      setState(ComponentState.UPDATE_PROGRESS);
      return;
    }

    if (
      state === ComponentState.EDIT_PAST_PROGRESS &&
      selectedProgressUpdade &&
      selectedProgressUpdade.progressType ===
        ProgressUpdateType.FINANCIAL_MATERIAL &&
      data.materialCost
    ) {
      confirmUpdatePastProgress(
        verticalServiceModel?.id || 0,
        selectedProgressUpdade.id,
        data.materialCost,
        selectedProgressUpdade.progressType
      );
      handleMaterialCostChange("0");
      advancementPhysicalIndexRef.current?.removeUpdateStateAndSelectedUpdate();
      setState(ComponentState.UPDATE_PROGRESS);
      return;
    }
    // Adiciona a lógica para deletar todos os itens na lista
    for (const item of itemsToDelete) {
      await verticalService.deleteUpdateProgress(item.id);
    }

    confirmOnClick(data);
    cleanData();
  };

  const cleanData = () => {
    setData(new UpdateServiceProgressModel());
    setItemsToDelete([]);
    setCurrentProgressData(undefined);
  };

  const onStateChange = (state: ComponentState) => {
    setState(state);
  };

  const onSelectPhysicalUpdate = (update: ProgressUpdate) => {
    setState(ComponentState.EDIT_PAST_PROGRESS);

    // eu preciso remover o valor de physicalProgress pela porcentagem relativa de update.value
    const totalPhysicalValue = currentProgress?.totalPhysicalValue || 0;
    const percentValue = (update.value * 100) / totalPhysicalValue;
    const totalProgress =
      currentProgressData?.physicalProgressAsTotalValue || 0;
    const remain = currentProgress?.remainPhyscalValue || 0;

    if (currentProgressData) {
      setSelectedProgressUpdade(update);

      currentProgressData.physicalProgress =
        currentProgressData?.physicalProgress - percentValue;
      currentProgressData.physicalProgressAsTotalValue =
        totalProgress - update.value;
      currentProgressData.remainPhyscalValue = remain + update.value;

      handlePhisicalProgressChange(update.value.toString());
    }
  };

  const onDeletePhysicalUpdate = (update: ProgressUpdate) => {
    if (itemsToDelete.some((item) => item.id === update.id)) {
      setItemsToDelete((prevList) =>
        prevList.filter((item) => item.id !== update.id)
      );
    } else {
      setItemsToDelete((prevList) => [...prevList, update]);
    }
  };

  const onSelectFinanceUpdate = (update: ProgressUpdate) => {
    setState(ComponentState.EDIT_PAST_PROGRESS);
    setSelectedProgressUpdade(update);

    const totalFinancialValue =
      currentProgress?.financialProgressAsCurrency || 0;
    const percentValue = (update.value * 100) / totalFinancialValue;

    if (currentProgressData) {
      currentProgressData.financialProgress =
        currentProgressData.financialProgress - percentValue;
      currentProgressData.financialProgressAsCurrency =
        totalFinancialValue - update.value;
    }

    if (
      update.progressType === ProgressUpdateType.FINANCIAL_MATERIAL &&
      currentProgressData
    ) {
      handleMaterialCostChange((update.value * 100).toString());
      return;
    }

    if (
      update.progressType === ProgressUpdateType.FINANCIAL_WORKFORCE &&
      currentProgressData
    ) {
      handleWorkforceCostChange((update.value * 100).toString());
      return;
    }
  };

  const financeViewPastProgress = () => {
    if (pastProgressShowing === "FINANCE") {
      setPastProgressShowing(undefined);
    }

    setPastProgressShowing("FINANCE");
  };

  const physicalViewPastProgress = () => {
    if (pastProgressShowing === "PHYSICAL") {
      setPastProgressShowing(undefined);
    }
    setPastProgressShowing("PHYSICAL");
  };

  const removeValueFromPastProgress = (
    oldSelectedValue: number | undefined,
    newSelectedValue: number
  ) => {
    if (currentProgressData !== undefined) {
      setCurrentProgressData({
        ...currentProgressData,
        physicalProgress:
          currentProgressData.physicalProgress +
          (oldSelectedValue || 0) -
          newSelectedValue,
      });
    }
  };

  return (
    <ReactBaseComponent>
      <PopUpBaseComponent
        isOpen={isOpen}
        isLoading={isLoading}
        cancelOnClick={handleOnCancel}
        confirmOnClick={() => handleOnConfirm()}
        closeOnClick={() => {
          cancelOnClick();
          cleanData();
        }}
        titte={`Avançar serviços - ${serviceType}`}
        isButtonContainerEnabled={true}
        enableConfirmButton={
          (UpdateServiceProgressModel.isValid(data) &&
            isPhysivalProgressValid) ||
          itemsToDelete.length !== 0
        }
      >
        <ContentContainer>
          <FormContainer>
            <UnityAndProgressContainer>
              <InputValueComponent
                variant="filled"
                onChange={(value) => handlePhisicalProgressChange(value)}
                value={data?.progressByValue?.toString() || "0"}
                label="Unidade"
                mask=""
                placeholder="0"
                isError={!isPhysivalProgressValid}
                errorMessage={
                  "Avanço físico restante " +
                  currentProgress?.remainPhyscalValue?.toString()
                }
                type="number"
                isDisabled={
                  selectedProgressUpdade !== undefined &&
                  selectedProgressUpdade.progressType !==
                    ProgressUpdateType.PHYSICAL
                }
                sufixView={
                  isPhysivalProgressValid ? (
                    <Prefix>{verticalServiceModel?.unit}</Prefix>
                  ) : (
                    <ErrorOutlinedIcon
                      style={{ color: Colors.roofTerracotta }}
                    />
                  )
                }
                isGray={true}
              />
              <TextOr>ou</TextOr>
              <InputValueComponent
                variant="filled"
                onChange={(value) =>
                  handlePhisicalProgressAsPercentChange(value)
                }
                value={data?.progressByValueAsPercent?.toString() || "0"}
                label="Percentual"
                mask=""
                placeholder="0"
                isError={!isPhysivalProgressValid}
                errorMessage={
                  "Avanço físico restante " +
                  currentProgress?.remainPhyscalValue?.toString()
                }
                type="number"
                isDisabled={
                  selectedProgressUpdade !== undefined &&
                  selectedProgressUpdade.progressType !==
                    ProgressUpdateType.PHYSICAL
                }
                sufixView={
                  isPhysivalProgressValid ? (
                    <Prefix>%</Prefix>
                  ) : (
                    <ErrorOutlinedIcon
                      style={{ color: Colors.roofTerracotta }}
                    />
                  )
                }
                isGray={true}
              />
            </UnityAndProgressContainer>
          </FormContainer>
          <IndiceContainer>
            <AdvancementIndexComponent
              title="Índice de avanços físicos"
              key={"physical"}
              ref={advancementPhysicalIndexRef}
              value={currentProgressData?.physicalProgress || 0}
              allUpdates={currentProgressData?.allPhysicalUpdates}
              totalProgress={currentProgressData?.physicalProgressAsTotalValue}
              totalValue={currentProgressData?.totalPhysicalValue}
              type={AdvancementIndexType.PHYSICAL}
              onChangeComponentState={onStateChange}
              onSelectedUpdate={(update) => onSelectPhysicalUpdate(update)}
              onDeletedUpdate={(update) => onDeletePhysicalUpdate(update)}
              showBudgetDescription={false}
              showMaterialUserDescription={true}
              onPastProgressListChangeState={(state) =>
                physicalViewPastProgress()
              }
              isPastUpdatesListEnabled={pastProgressShowing === "PHYSICAL"}
              unity={verticalServiceModel?.unit}
              itemsToDelete={itemsToDelete}
            />
          </IndiceContainer>
        </ContentContainer>
      </PopUpBaseComponent>
    </ReactBaseComponent>
  );
};

const ContentContainer = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
  grid-gap: 32px;
`;

const FormContainer = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
  align-items: start;
`;
const IndiceContainer = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
  row-gap: 16px;
`;

const UnityAndProgressContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  column-gap: 16px;
  align-items: center;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
    align-items: center;
    gap: 5px;
  }
`;

const MaterialCostContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  align-items: start;
  margin-top: 24px;
`;

const TextOr = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  color: ${Colors.textColorBlack};
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`;

const Prefix = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  color: ${Colors.neutralSilverChalice};
  font-weight: 700;
  line-height: 20px;
`;

export default UpdateServiceProgressPopUp;
