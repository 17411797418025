import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ButtonComponent, { ButtonType, ButtonState } from "../../../components/v2/Button/ButtonComponent";
import { Construction, isUserClientRole, Client } from "../../../config/app-info";
import styled from "styled-components";
import { Colors } from "../../../theme/v2/variants";
import DropDownComponent from "../../../components/InputValues/DropDownComponent";
import CheckIcon from "@mui/icons-material/Check";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { useAuth } from "../../../contexts/auth";
import { api } from "../../../services/api";

const DialogTitleStyled = styled(DialogTitle)`
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${Colors.primaryColor};
  padding: 32px 32px 0px 32px;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const DialogContentStyled = styled(DialogContent)`
  padding: 32px;
`;

const WarningText = styled.p`
  color: ${Colors.balticSea};
  margin-bottom: 24px;
  font-family: "Noto Sans";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;

const SelectLabel = styled.p`
  color: ${Colors.balticSea};
  margin-bottom: 8px;
`;

const ButtonsContainer = styled(DialogActions)`
  padding: 16px;
  gap: 8px;
`;

const SelectsContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

const SelectWrapper = styled.div<{ fullWidth?: boolean }>`
  width: ${props => props.fullWidth ? '100%' : '50%'};
`;

interface Props {
  isOpen: boolean;
  constructions: Construction[];
  clients: Client[];
  onClose: () => void;
  onConfirm: (selectedConstructionId: number) => void;
  currentConstructionId: number;
}

const DuplicateServicesPopUp: React.FC<Props> = ({
  isOpen,
  constructions: initialConstructions,
  clients,
  onClose,
  onConfirm,
  currentConstructionId,
}) => {
  const [selectedConstructionId, setSelectedConstructionId] = React.useState<number>();
  const [selectedClientId, setSelectedClientId] = React.useState<number>();
  const [constructions, setConstructions] = React.useState<Construction[]>(initialConstructions);
  const { user } = useAuth();

  const fetchConstructionsByClient = React.useCallback(async (clientId: number) => {
    try {
      const response = await api.get(`/api/constructions/client/${clientId}`);
      setConstructions(response.data);
    } catch (error) {
      console.error('Erro ao buscar construções:', error);
    }
  }, []);

  React.useEffect(() => {
    const mapping = new Map();
    constructions.forEach(construction => {
      if (!mapping.has(construction.clientId)) {
        const client = clients.find(c => c.id === construction.clientId);
        mapping.set(construction.clientId, {
          clientName: client?.name || 'Cliente não encontrado',
          constructions: []
        });
      }
      mapping.get(construction.clientId).constructions.push(construction.name);
    });
  }, [constructions, clients]);

  const handleConfirm = () => {
    if (selectedConstructionId) {
      onConfirm(selectedConstructionId);
    }
  };

  const availableClients = React.useMemo(() => {
    if (isUserClientRole(user.role) && user.responsibleClientId) {
      return clients.filter(client => client.id === user.responsibleClientId);
    }
    return clients;
  }, [clients, user.role, user.responsibleClientId]);

  const filteredConstructions = React.useMemo(() => {
    if (isUserClientRole(user.role) && user.responsibleClientId) {
      const filtered = constructions.filter(
        construction => 
          construction.id !== currentConstructionId && 
          construction.clientId === user.responsibleClientId
      );
      return filtered;
    }
    
    if (selectedClientId) {
      const filtered = constructions.filter(construction => {
        const match = Number(construction.clientId) === Number(selectedClientId);

        return construction.id !== currentConstructionId && match;
      });
      return filtered;
    }
    
    const filtered = constructions.filter(
      construction => construction.id !== currentConstructionId
    );
    return filtered;
  }, [constructions, currentConstructionId, user.role, user.responsibleClientId, selectedClientId]);

  const handleClientChange = (key: string, value: string) => {
    const client = availableClients.find(c => c.name === value);
    if (client) {
      const clientId = Number(client.id);
      setSelectedClientId(clientId);
      setSelectedConstructionId(undefined);
      fetchConstructionsByClient(clientId);
    }
  };

  const handleConstructionChange = (key: string, value: string) => {
    const construction = filteredConstructions.find(c => c.name === value);
    if (construction) {
      setSelectedConstructionId(construction.id);
    }
  };

  const constructionNames = filteredConstructions.map(construction => construction.name);
  const clientNames = availableClients.map(client => client.name);
  const isRestrictedUser = Boolean(isUserClientRole(user.role) && user.responsibleClientId);

  if (isUserClientRole(user.role) && constructionNames.length === 0) {
    onClose();
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitleStyled>Importar serviços de outra obra</DialogTitleStyled>
      <DialogContentStyled>
        <WarningText>
          Atenção! Essa ação irá remover todos os serviços previamente cadastrados para essa obra.
        </WarningText>
        <SelectLabel>
          Selecione a obra que você quer duplicar os serviços:
        </SelectLabel>
        
        <SelectsContainer>
          {!isRestrictedUser && (
            <SelectWrapper>
              <DropDownComponent
                variant="filled"
                defaultValue="Selecione um cliente"
                key="client"
                componentKey="client"
                label="Cliente"
                onSelectedValueChanged={handleClientChange}
                values={clientNames}
                height="56px"
                bakcgrounColor={Colors.concrete}
              />
            </SelectWrapper>
          )}
          
          <SelectWrapper fullWidth={Boolean(isRestrictedUser)}>
            <DropDownComponent
              variant="filled"
              defaultValue="Selecione uma obra"
              key="construction"
              componentKey="construction"
              label="Obra"
              onSelectedValueChanged={handleConstructionChange}
              values={constructionNames}
              height="56px"
              bakcgrounColor={Colors.concrete}
              disabled={!isRestrictedUser && !selectedClientId}
            />
          </SelectWrapper>
        </SelectsContainer>
      </DialogContentStyled>
      
      <ButtonsContainer>
        <ButtonComponent
          type={ButtonType.TRANSPARENT_DARK}
          text="Cancelar"
          onClick={onClose}
          startIcon={<HighlightOffOutlinedIcon />}
        />
        <ButtonComponent
          type={ButtonType.PRIMARY}
          text="Salvar"
          onClick={handleConfirm}
          startIcon={<CheckIcon />}
          state={selectedConstructionId ? ButtonState.DEFAULT_ENABLED : ButtonState.DISABLED}
        />
      </ButtonsContainer>
    </Dialog>
  );
};

export default DuplicateServicesPopUp; 