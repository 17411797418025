import { useEffect, useState } from "react";
import DashboardCard from "../../Cards/DashboardCard/DashboardCard";
import ActionsService from "../../../services/action/ActionsService";
import { BaseState } from "../../../pages/Actions/ViewActions/utils/BaseState";
import styled from "styled-components";
import Subtitle from "../Subtitle/Subtitle";
import { Colors } from "../../../theme/variants";
import { Pie } from "react-chartjs-2";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";

interface RestrictionsRemoveChartProps {
  constructionId?: string;
}

const colorList = [
  Colors.primaryColor,
  Colors.primaryColorLight,
  Colors.primaryColorNeutralBlue,
  Colors.secoundaryColorDark,
  Colors.secondaryColorLight,
];

const DelayedActionsChart: React.FC<RestrictionsRemoveChartProps> = ({
  constructionId,
}) => {
  const actionService = new ActionsService();
  const [state, setState] = useState<BaseState>(BaseState.success);
  const [totalActions, setTotalActions] = useState<number[]>([]);
  const [chartLabel, setChartLabel] = useState<string[]>();

  useEffect(() => {
    listDelayedActions(constructionId);
  }, [constructionId]);

  const listDelayedActions = async (constructionId?: string) => {
    if (!constructionId) {
      return;
    }

    setState(BaseState.loading);

    await actionService
      .delayedActionsByCriteria(constructionId)
      .then((response) => {
        handleDelayedActionsResponse(response);
      })
      .catch((error) => {
        setState(BaseState.error);
      });
  };

  function handleDelayedActionsResponse(response: any) {
    setChartLabel(response.labels);
    setTotalActions(response.totalActions);
    setState(BaseState.success);
  }

  return (
    <DashboardCard
      state={state}
      title="Ações atrasadas"
      selectorLabel="Critério"
      onMonthChanged={(criteria) => {}}
      dropDownData={[]}
      isDropDownVisible={false}
    >
      <RootContainer>
        <ChartContainer>
          <Pie
            data={{
              labels: chartLabel,
              datasets: [
                {
                  data: totalActions,
                  backgroundColor: colorList,
                  datalabels: {
                    display: false,
                  },
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
              },
              maintainAspectRatio: false,
              borderColor: "transparent",
              rotation: 180,
            }}
          />
        </ChartContainer>
        <SubtitleGroup>
          {/* <Subtitle color={Colors.primaryColorLight} text="Total" /> */}
          {chartLabel?.map((label: string, index: number) => (
            <Subtitle
              minWidth={80}
              key={label}
              color={colorList[index]}
              text={label}
            />
          ))}
        </SubtitleGroup>
      </RootContainer>
    </DashboardCard>
  );
};

const RootContainer = styled.div`
  padding-left: 44px;
  padding-right: 44px;

  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: center;
  align-items: center;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 32px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -24px;
    width: 100%;
    overflow-x: hidden;
    margin-top: 32px;
  }
`;

const ChartContainer = styled.div`
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: grid;
    width: fit-content;
    overflow-x: hidden;
  }
`;

const SubtitleGroup = styled.div`
  display: grid;
  grid-template-rows: min-content min-content;
  column-gap: 20px;
  width: fit-content;
  justify-self: center;
  margin-top: 8px;
  margin-bottom: 16px;
  padding-top: 30px;
  padding-bottom: 30px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: -60px;
    overflow: hidden;
  }
`;

const FloatText = styled.div`
  width: 96.1px;
  color: #061134;
  font-size: 32px;
  font-family: "Noto Sans";
  font-weight: 700;
  word-wrap: break-word;
  position: absolute;
  text-align: center;
  margin-left: 16px;
`;

export default DelayedActionsChart;
