import React, { useEffect, useRef, useState } from "react";
import {
  AboutContainer,
  ConstructionImage,
  ConstructionImagePlaceholder,
  ConstructionNameTitle,
  HeaderContainer,
  PageTitle,
  TitleContainer,
  InputFile,
} from "./styles";
import { api } from "../../services/api";
import { useDashboard } from "../../contexts/dashboard";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import ConstructionService from "../../services/ConstructionService";

import ImageOptionsMenu from "./components/ImageOptionsMenu";
import Snackbar from "@mui/material/Snackbar";

const SubHeader = () => {
  const ConstructionServices = new ConstructionService();
  const { constructionId, constructions, setConstructionId } = useDashboard();
  const [constructionImage, setConstructionImage] = useState<string>("");
  const [pageTitle, setPageTitle] = useState("");
  const navigate = useNavigate();
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedConstruction, setSelectedConstruction] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [imageOptionsOpen, setImageOptionsOpen] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const imageOptionsRef = useRef<HTMLDivElement>(null);

  const toggleImageOptions = () => {
    setImageOptionsOpen(!imageOptionsOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      imageOptionsRef.current &&
      !imageOptionsRef.current.contains(event.target as Node)
    ) {
      setImageOptionsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFileSelect = (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    ConstructionServices.updateImage(constructionId?.toString(), formData)
      .then((response) => {
        if (!response) return;
        setImageOptionsOpen(false);
        setConstructionImage(response);
        setSelectedFile(null);
        setOpenSnackbar(true);
        setSnackbarMessage("Imagem atualizada com sucesso!");
      })
      .catch((error) => {
        setOpenSnackbar(true);
        setSnackbarMessage("Erro ao atualizar a imagem");
      });
  };

  const handleDeleteImage = async () => {
    try {
      const deletionSuccessful =
        await ConstructionServices.deleteConstructionImage(constructionId);
      if (deletionSuccessful) {
        await getConstructionImage(constructionId);
        setOpenSnackbar(true);
        setSnackbarMessage("Imagem excluída com sucesso");
      } else {
        setOpenSnackbar(true);
        setSnackbarMessage("Erro ao excluir a imagem");
      }
    } catch (error) {
      setOpenSnackbar(true);
      setSnackbarMessage("Erro ao excluir a imagem");
    } finally {
      setImageOptionsOpen(false);
    }
  };

  useEffect(() => {
    getConstructionImage(constructionId);
  }, [constructionId]);

  useEffect(() => {
    if (constructionId) {
      const constructionName = constructions.find(
        (construction) => construction.id === constructionId
      );
      setSelectedConstruction(constructionName?.name || "");
    }
  }, [constructionId]);

  const getConstructionImage = async (constructionId: number) => {
    try {
      const result = await api.get(
        `/api/constructions/image/${constructionId}`
      );
      setConstructionImage(result.data);
    } catch (error) {
      console.error(error);
      setConstructionImage("");
    }
  };

  useEffect(() => {
    const path = window.location.pathname;
    if (path.includes("/planos-acoes/editar")) {
      setPageTitle("Plano de Ação");
    } else if (path.includes("/mapeamento-restricoes")) {
      setPageTitle("Mapear Restrições");
    } else if (path.includes("/acompanhar-acoes")) {
      setPageTitle("Acompanhar Ações");
    } else if (path.includes("/plano-compras-contratacoes")) {
      setPageTitle("Compras e Contratações");
    } else if (path.includes("/listar")) {
      setPageTitle("Listagem");
    } else if (path.includes("/cadastrar")) {
      setPageTitle("Cadastro");
    } else if (path.includes("/vertical-servicos-relatorio")) {
      setPageTitle("Relatório de Serviços");
    } else {
      setPageTitle("Página padrão");
    }
  });

  function getConstructionInitialLetters(construction: string): string {
    const array = construction.split(" ");
    let firstLetter = array[0]?.charAt(0);
    let lastLetter = array.length > 1 ? array[array.length - 1]?.charAt(0) : "";

    return `${firstLetter}${lastLetter}`;
  }

  if (!selectedConstruction) {
    return null;
  }

  return (
    <HeaderContainer>
      <AboutContainer>
        {constructionImage ? (
          <ConstructionImage
            onClick={toggleImageOptions}
            src={constructionImage}
          />
        ) : (
          <ConstructionImagePlaceholder onClick={toggleImageOptions}>
            {getConstructionInitialLetters(selectedConstruction)}
          </ConstructionImagePlaceholder>
        )}
        <TitleContainer>
          <PageTitle>{pageTitle}</PageTitle>
          {/* <PageTitle>
            {window.location.pathname}
          </PageTitle> */}
          <ConstructionNameTitle>{selectedConstruction}</ConstructionNameTitle>
        </TitleContainer>

        {imageOptionsOpen && (
          <div ref={imageOptionsRef}>
            <ImageOptionsMenu
              onDeleteService={handleDeleteImage}
              onFileSelect={handleFileSelect}
            />
          </div>
        )}
      </AboutContainer>
      <Snackbar
        message={snackbarMessage}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => {
          setOpenSnackbar(false);
        }}
      />
    </HeaderContainer>
  );
};

export default SubHeader;
