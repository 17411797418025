import { Colors } from "../../theme/v2/variants";

export const textFieldDarkStyle = {
  "& .MuiFilledInput-root": { backgroundColor: "#F2F2F2" },
  "& .MuiFilledInput-root.Mui-focused": {
    backgroundColor: "#F2F2F2",
  },
  "& .MuiInputBase-input": {
    fontSize: "16px",
    color: "#3B425A",
  },
  "& .MuiInputLabel-root": {
    fontSize: "32px",
    color: "red",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#2DB6A3",
  },
  "& .MuiInputLabel-filled": {
    fontSize: "16px",
    color: "#3B425A",
  },
  "& .MuiInputLabel-shrink": {
    fontSize: "14px",
    color: "#3B425A",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "2px solid #2DB6A3",
  },
  // Estilos para o estado de erro
  "& .MuiFormHelperText-root.Mui-error": {
    color: Colors.roofTerracotta,
  },
  "& .MuiInputLabel-root.Mui-error": {
    color: Colors.roofTerracotta,
  },
  "& .MuiFilledInput-underline.Mui-error:after": {
    borderBottomColor: Colors.roofTerracotta,
  },
};
