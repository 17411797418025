import React from "react";
import styled from "styled-components";
import TodayIcon from "@mui/icons-material/Today";
import { Colors } from "../../../theme/v2/variants";
import { formatDateLong, formatDateToDayMonth } from "../../../utils/date";

interface DateCardProps {
  title: string;
  date?: Date | string;
  color?: string;
  fontColor?: string;
  shortFormat?: boolean;
  fontSize?: string;
}

const CardContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div<{ color?: string }>`
  margin-right: 8px;
  color: ${(props) => props.color || Colors.greenLight};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

const Title = styled.div<{ color?: string }>`
  font-family: "Noto Sans";
  font-size: 12px;
  color: ${(props) => props.color || Colors.greenLight};
  font-weight: 500;
  align-self: flex-start;
`;

const DateText = styled.div<{ fontColor?: string; fontSize?: string }>`
  color: ${(props) => props.fontColor || Colors.primaryColorDark};
  font-family: "Noto Sans";
  font-size: ${(props) => props.fontSize || "16px"};
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-self: flex-start;
`;

const DateCard: React.FC<DateCardProps> = ({
  title,
  date,
  color,
  fontColor,
  shortFormat = false,
  fontSize,
}) => (
  <CardContainer>
    <IconWrapper color={color}>
      <TodayIcon />
    </IconWrapper>
    <TextContainer>
      <Title color={color}>{title}</Title>
      {date && (
        <DateText fontColor={fontColor} fontSize={fontSize}>
          {shortFormat ? formatDateToDayMonth(date) : formatDateLong(date)}
        </DateText>
      )}
    </TextContainer>
  </CardContainer>
);

export default DateCard;
