import React from "react";
import {
  SuccessText,
  SuccessTitle,
  SuccessImg,
} from "../../SuppliersPage.styles";

import successImg from "../../../../assets/images/digital-ads.png";

interface SuccessfulSendProps {
  handleClose: () => void;
}
const SuccessfulSend: React.FC<SuccessfulSendProps> = ({ handleClose }) => {
  return (
    <>
      <SuccessImg src={successImg} alt="thumb-up" />
      <SuccessTitle>Orçamento enviado com Sucesso!</SuccessTitle>
      <SuccessText>
        Agora nosso time de compras vai analisar sua proposta <br></br>e
        retornamos em breve.
      </SuccessText>
    </>
  );
};

export default SuccessfulSend;
