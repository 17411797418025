import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Outlet, useLocation } from "react-router-dom";

import { CssBaseline, Drawer, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, spacing } from "@mui/system";
import { useAuth } from "../contexts/auth";

import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import Sidebar from "../components/sidebar/Sidebar";
import Footer from "../components/Footer";

import { SidebarItemsType } from "../types/sidebar";
import { Users, UserPlus, LogOut, BarChart2 } from "react-feather";
import {
  ViewTimeline as ViewTimelineIcon,
  AccountBox as AccountBoxIcon,
  AddBusiness as AddBusinessIcon,
  DomainAdd as DomainAddIcon,
  Store as StoreIcon,
  Domain as DomainIcon,
  AssignmentLate as AssignmentLateIcon,
  NoteAdd as NoteAddIcon,
  Plagiarism as PlagiarismIcon,
  Description as DescriptionIcon,
  NoteAlt as NoteAltIcon,
  PendingActions as PendingActionsIcon,
  ManageHistory as ManageHistoryIcon,
  Task as TaskIcon,
  Rule as RuleIcon,
  AddCircleOutline,
  DashboardRounded,
  EventAvailableRounded,
  ArticleRounded,
  Business,
  PersonAdd,
  ContentPasteSearchOutlined,
  ShoppingCartOutlined,
  ListAltRounded,
  AccountTree,
} from "@mui/icons-material";
import {
  ORDER_OF_STEPS_PATH,
  PURCHASING_CONTRACTING_PLAN_PATH,
  authorizedRoutes,
} from "../config/app-info";
import { DashboardProvider, useDashboard } from "../contexts/dashboard";
import { NavbarVisibilityProvider } from "../contexts/navbar";
import { useNavbarVisibility } from "../contexts/navbar";
import { Colors } from "../theme/v2/variants";
import SubHeader from "../components/SubHeader/SubHeader";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

// ${(props) => props.theme.breakpoints.up("md")} {
// const Drawer = styled.div`
//   ${(props) => props.theme.breakpoints.up("xlg")} {
//     width: ${drawerWidth}px;
//     flex-shrink: 0;
//   }
// `;

const AppContent = styled.div`
  display: grid;
  grid-template-rows: 12vh 88vh;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }
`;

const Content = styled.div`
  display: flex;
  height: 100%;
  min-height: 88vh;
`;

const Paper = styled(MuiPaper)(spacing);

const ContentContainer = styled(MuiPaper)`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`;

const MainContent = styled(MuiPaper)<{
  isVerticalServicos?: boolean;
  isPccForm?: boolean;
}>`
  flex: 1;
  background: ${Colors.neutralColorSurface};
  padding: ${(props) =>
    props.isVerticalServicos || props.isPccForm ? "0px" : "40px"};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }

  @media (max-width: 900px) {
    padding: ${(props) =>
      props.isVerticalServicos || props.isPccForm ? "0px" : "20px"};
    padding-bottom: 100px;
  }
`;

type DashboardItems = {
  title: string;
  pages: SidebarItemsType[];
};

type Props = {
  children?: React.ReactNode;
};

const Default: React.FC<Props> = ({ children }) => {
  const pathname = useLocation().pathname;

  const isViewActions =
    pathname === "/acompanhar-acoes" ||
    pathname === "/dashboard/" ||
    pathname === "/vertical-servicos" ||
    pathname === "/vertical-servicos-relatorio" ||
    pathname.includes("/mapeamento-restricoes/listar") ||
    pathname.includes("mapeamento-restricoes/cadastrar") ||
    pathname === "/restricoes/cadastrar/" ||
    pathname.indexOf("/mapeamento-restricoes/editar/") !== -1 ||
    pathname.includes(ORDER_OF_STEPS_PATH) ||
    pathname.includes(PURCHASING_CONTRACTING_PLAN_PATH);
  // pathname.includes("/restricoes/editar/");

  const [mobileOpen, setMobileOpen] = useState(false);
  const [dashboardItems, setDashboardItems] = useState<DashboardItems[]>([]);
  // const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const theme = useTheme();
  const { user } = useAuth();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const { screenWidth } = useNavbarVisibility();
  const { constructionId } = useDashboard();

  useEffect(() => {
    if (user.role !== undefined) {
      const pathsAuthorized = authorizedRoutes[user.role];
      const navItems = [];
      let contentSection: SidebarItemsType[] = [];
      let clientSection: SidebarItemsType[] = [];
      let listsSection: SidebarItemsType[] = [];
      let verticalServiceSection: SidebarItemsType[] = [];
      let restrictionMappingSection: SidebarItemsType[] = [];
      let planningActionSection: SidebarItemsType[] = [];
      let executionActionSection: SidebarItemsType[] = [];

      // Dashboard
      let dashboardSection: SidebarItemsType[] = [];
      if (pathsAuthorized.includes("/dashboard/")) {
        dashboardSection.push({
          href: "/dashboard/",
          icon: DashboardRounded,
          // title: "Painel de Indicadores",
          title: "Dashboard",
        } as unknown as SidebarItemsType);
      }

      // Client's section
      if ([0, 1, 2].includes(user.role)) {
        contentSection.push({
          href: "",
          icon: AddCircleOutline,
          title: "Cadastro",
          children: clientSection,
        });

        contentSection.push({
          href: "",
          icon: ListAltRounded,
          title: "Listas",
          children: listsSection,
        });

        if (pathsAuthorized.includes("/empresas/cadastrar/")) {
          clientSection.push({
            href: "/empresas/cadastrar/",
            icon: Business,
            title: "Empresa",
          } as unknown as SidebarItemsType);
        }
        if (pathsAuthorized.includes("/usuarios/cadastrar/")) {
          clientSection.push({
            href: "/usuarios/cadastrar/",
            icon: PersonAdd,
            title: "Usuário",
          } as unknown as SidebarItemsType);
        }
        if (pathsAuthorized.includes("/obras/cadastrar/")) {
          clientSection.push({
            href: "/obras/cadastrar/",
            icon: DomainAddIcon,
            title: "Obra",
          } as unknown as SidebarItemsType);
        }
        if (pathsAuthorized.includes("/empresas/listar/")) {
          listsSection.push({
            href: "/empresas/listar/",
            icon: StoreIcon,
            title: "Lista de empresas",
          } as unknown as SidebarItemsType);
        }
        if (pathsAuthorized.includes("/usuarios/listar/")) {
          listsSection.push({
            href: "/usuarios/listar/",
            icon: Users,
            title: "Lista de usuários",
          } as SidebarItemsType);
        }
        if (pathsAuthorized.includes("/obras/listar/")) {
          listsSection.push({
            href: "/obras/listar/",
            icon: DomainIcon,
            title: "Lista de obras",
          } as unknown as SidebarItemsType);
        }
      }

      // Restrictions Mappings' Section
      if (pathsAuthorized.includes("/restricoes/listar/")) {
        restrictionMappingSection.push({
          href: "/restricoes/listar/",
          icon: DescriptionIcon,
          title: "Lista de restrições",
        } as unknown as SidebarItemsType);
      }
      // if (pathsAuthorized.includes("/mapeamento-restricoes/listar/")) {
      //   restrictionMappingSection.push({
      //     href: "/mapeamento-restricoes/listar/",
      //     icon: NoteAltIcon,
      //     title: "Mapear Restrições",
      //   } as unknown as SidebarItemsType);
      // }
      if (pathsAuthorized.includes("/mapeamento-restricoes/cadastrar/")) {
        restrictionMappingSection.push({
          href: "/mapeamento-restricoes/cadastrar/",
          icon: NoteAddIcon,
          title: "Inserir novo mapeamentos",
        } as unknown as SidebarItemsType);
      }
      // contentSection.push({
      //   href: "",
      //   icon: ArticleRounded,
      //   title: "Mapear Restrições",
      //   children: restrictionMappingSection,
      // });

      contentSection.push({
        href: "/mapeamento-restricoes/listar/",
        icon: NoteAltIcon,
        title: "Mapear Restrições",
      } as unknown as SidebarItemsType);

      if (pathsAuthorized.includes("/planos-acoes/listar/")) {
        executionActionSection.push({
          href: "/planos-acoes/listar/",
          icon: EventAvailableRounded,
          title: "Acompanhar os planos de ações",
        } as unknown as SidebarItemsType);
      }
      if (pathsAuthorized.includes("/acompanhar-acoes")) {
        executionActionSection.push({
          href: "/acompanhar-acoes",
          icon: ContentPasteSearchOutlined,
          title: "Acompanhar Ações",
        } as unknown as SidebarItemsType);
      }
      // if (pathsAuthorized.includes("/planos-acoes/gantt/")) {
      //   executionActionSection.push({
      //     href: "/planos-acoes/gantt/",
      //     icon: ViewTimelineIcon,
      //     title: "Gantt",
      //   } as unknown as SidebarItemsType);
      // }

      // contentSection.push({
      //   href: "",
      //   icon: EventAvailableRounded,
      //   title: "Plano de Ação",
      //   children: executionActionSection,
      // });
      contentSection.push({
        href: constructionId
          ? `/planos-acoes/editar/${constructionId}`
          : "/planos-acoes/listar/",
        icon: EventAvailableRounded,
        title: "Plano de Ação",
      } as unknown as SidebarItemsType);

      contentSection.push({
        // href: "/acompanhar-acoes",
        href: `/acompanhar-acoes/${constructionId}`,
        icon: ContentPasteSearchOutlined,
        title: "Acompanhar Ações",
      } as unknown as SidebarItemsType);

      // if (pathsAuthorized.includes(ORDER_OF_STEPS_PATH)) {
      //   verticalServiceSection.push({
      //     href: ORDER_OF_STEPS_PATH,
      //     icon: RuleIcon,
      //     title: "Ordem de etapas",
      //   } as unknown as SidebarItemsType);
      // }

      // if (pathsAuthorized.includes("/vertical-servicos")) {
      verticalServiceSection.push({
        href: "/vertical-servicos",
        icon: AccountTree,
        title: "Vertical de serviços",
      } as unknown as SidebarItemsType);
      // }

      // if (pathsAuthorized.includes("/vertical-servicos-relatorio")) {
      //   verticalServiceSection.push({
      //     href: "/vertical-servicos-relatorio",
      //     icon: RuleIcon,
      //     title: "Relatório de serviços",
      //   } as unknown as SidebarItemsType);
      // }

      // if (pathsAuthorized.includes(PURCHASING_CONTRACTING_PLAN_PATH)) {
      verticalServiceSection.push({
        href: PURCHASING_CONTRACTING_PLAN_PATH,
        icon: ShoppingCartOutlined,
        title: "Compras e Contratações",
      } as unknown as SidebarItemsType);
      // }

      let systemSection: SidebarItemsType[] = [];
      // systemSection.push({
      //   href: "/sair/",
      //   icon: LogOut,
      //   title: "Sair",
      // } as SidebarItemsType);

      navItems.push({
        title: "Dashboard",
        pages: dashboardSection,
      });

      navItems.push({
        title: "Planejamento Estratégico",
        pages: contentSection,
      });

      navItems.push({
        title: "Vertical de serviços",
        pages: verticalServiceSection,
      });

      navItems.push({
        title: "Sistema",
        pages: systemSection,
      });

      setDashboardItems(navItems);
    } else {
      setDashboardItems([]);
    }
  }, [user]);

  const routesToHideSubHeader = [
    "empresas",
    "usuarios",
    "obras",
    "dashboard",
    "vertical-servicos",
  ];
  const specificRoutesToHideSubHeader = new Set(["plano-compras-contratacoes"]);
  const startOfRoute = window.location.pathname.split("/")[1];

  return (
    <DashboardProvider>
      <Root>
        <CssBaseline />
        <GlobalStyle />
        <AppContent>
          <Navbar
            isMobile={screenWidth < 901}
            onDrawerToggle={handleDrawerToggle}
          />
          <Content>
            {screenWidth < 901 ? (
              <Drawer onClose={handleDrawerToggle} open={mobileOpen}>
                <Sidebar
                  PaperProps={{ style: { width: drawerWidth } }}
                  // open={mobileOpen}
                  onClose={handleDrawerToggle}
                  items={dashboardItems}
                  onDrawerToggle={handleDrawerToggle}
                />
              </Drawer>
            ) : (
              <Sidebar
                PaperProps={{ style: { width: drawerWidth } }}
                variant="persistent"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                items={dashboardItems}
                onDrawerToggle={handleDrawerToggle}
              />
            )}
            <ContentContainer>
              {/* SUB - HEADER */}
              {!routesToHideSubHeader.includes(startOfRoute) &&
                pathname !== "/plano-compras-contratacoes" && <SubHeader />}
              <MainContent
                isVerticalServicos={pathname.includes("/vertical-servicos")}
                isPccForm={
                  pathname.includes("/plano-compras-contratacoes/form") ||
                  pathname.includes("/plano-compras-contratacoes")
                }
              >
                {children}
                <Outlet />
              </MainContent>
            </ContentContainer>
          </Content>
        </AppContent>
      </Root>
    </DashboardProvider>
  );
};

export default Default;
