import { DeleteOutlineOutlined } from "@mui/icons-material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CheckIcon from "@mui/icons-material/Check";
import { SwipeableDrawer } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Tabs from "@mui/material/Tabs";
import html2canvas from "html2canvas";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as AddIcon } from "../../assets/add-icon.svg";
import { ReactComponent as AlertTriangle } from "../../assets/alert-triangle.svg";
import { ReactComponent as Article } from "../../assets/article.svg";
import { ReactComponent as DownloadIcon } from "../../assets/download.svg";
import { ReactComponent as FilterOutlined } from "../../assets/filter-outline.svg";
import { ReactComponent as ShareOutlined } from "../../assets/share-outline.svg";
import { ReactComponent as ThreeDots } from "../../assets/three_dots.svg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ReactPageBaseComponent from "../../components/BaseComponents/ReactPageBaseComponent";
import ConfirmDeleteItemPopUp from "../../components/Dialogs/ConfirmDeleteItemPopUp";
import DropDownComponent from "../../components/InputValues/DropDownComponent";
import { ServiceLocation } from "../../components/InputValues/ServiceGroupChecklistDownComponent";
import EmptyClientAndConstructionState from "../../components/StateComponents/EmptyClientAndConstruction";
import ErrorComponent from "../../components/StateComponents/ErrorComponent";
import LoadingComponent from "../../components/StateComponents/LoadingComponent";
import ImageOptionsMenu from "../../components/SubHeader/components/ImageOptionsMenu";
import ButtonComponent, {
  ButtonState,
  ButtonType,
} from "../../components/v2/Button/ButtonComponent";
import IconButtonComponent from "../../components/v2/Button/IconButtonComponent";
import Footer from "../../components/v2/Footer";
import {
  ActionItem,
  Client,
  Construction,
  MappingItem,
  PURCHASING_CONTRACTING_PLAN_FORM_PATH,
  isUserClientRole
} from "../../config/app-info";
import { TABS } from "../../constants/index";
import { useAuth } from "../../contexts/auth";
import { useDashboard } from "../../contexts/dashboard";
import { useNavbarVisibility } from "../../contexts/navbar";
import ConstructionService from "../../services/ConstructionService";
import UserService, { UserResponsible } from "../../services/UserService";
import VerticalService from "../../services/VeritcalService";
import { api } from "../../services/api";
import VerticalServiceGroup from "../../services/interfaces/VerticalServiceGroup";
import VerticalServiceImage from "../../services/interfaces/VerticalServiceImages";
import VerticalServiceModel from "../../services/interfaces/VerticalServiceModel";
import {
  ProgressUpdateType,
  VerticalServiceProgressModel,
} from "../../services/interfaces/VerticalServiceProgressModel";
import { VerticalServiceReschedulingDto } from "../../services/interfaces/VerticalServiceReschedulingDto";
import PurchasingContractingPlanService from "../../services/purchasingContractingPlan/PurchasingContractingPlanService";
import { ContractingPlanEntity } from "../../services/purchasingContractingPlan/entity/ContractingPlanEntity";
import { PurchasingPlanEntity } from "../../services/purchasingContractingPlan/entity/PurchasingPlanEntity";
import RestrictionMappingService from "../../services/restrictionMapping/RestrictionMappingService";
import RestrictionsService from "../../services/restrictions/RestrictionsService";
import { Colors } from "../../theme/v2/variants";
import { parseAsUTCWithOffset } from "../../utils/date";
import { MOBILE_BREAKPOINT } from "../../utils/mobileDimen";
import { BaseState } from "../Actions/ViewActions/utils/BaseState";
import { ActionModel, IActionModel } from "../Actions/models/ActionModel";
import ContractingModel from "../PurchasingContractingPlanPage/models/ContractingMode";
import PurchasingModel from "../PurchasingContractingPlanPage/models/PurchasingModel";
import { RestrictionFormEntity } from "../Restrictions/Form";
import { IRestrictionMappingModel } from "../Restrictions/models/RestrictionModel";
import * as Styles from "./VerticalServicePage.styles";
import AddImagesPopUp from "./components/AddImagesPopUp";
import AddRestrictionAndActionPlanPopUp from "./components/AddRestrictionAndActionPlanPopUp";
import AddServicePopUp, {
  AddServicePopUpRef,
  NewServiceType,
} from "./components/AddServicePopUp";
import { AdvancementIndexType } from "./components/AdvancementIndexComponent";
import AdvancementIndexVerticalComponent from "./components/AdvancementIndexVerticalComponent";
import { DateHeaderView } from "./components/DateHeaderView";
import DayServicesListPopUp from "./components/DayServicesListPopUp";
import FilterSideBar, { ServiceStatus } from "./components/FilterSideBar";
import { Header } from "./components/Header";
import ImportExcelFilePopUp from "./components/ImportExcelFilePopUp";
import TableItem from "./components/TableItem";
import UpdateServiceProgressPopUp from "./components/UpdateServiceProgressPopUp";
import VerticalServicePageToPdf from "./components/VerticalServicePageToPdf";
import { UpdateServiceProgressModel } from "./models/UpdateServiceProgressModel";
import OrderOfStepsEditServicePopUp from "./pages/OrderOfSteps/components/OrderOfStepsEditServicePopUp/OrderOfStepsEditServicePopUp";
import DuplicateServicesPopUp from "./components/DuplicateServicesPopUp";

enum ThreeDotsIconEnum {
  IMPORT_XLSX = "import-xlsx",
  PDF = "pdf",
  RESCHEDULING_PDF = "rescheduling-pdf",
  DUPLICATE = "duplicate",
}

const VerticalServicePage = () => {
  const navigate = useNavigate();
  const verticalService = new VerticalService();
  const purchasingContractinService = new PurchasingContractingPlanService();
  const constructionService = new ConstructionService();
  const restrictionService = new RestrictionsService();
  const restrictionMappingService = new RestrictionMappingService();
  const { user } = useAuth();
  let { id: restrictionId } = useParams();

  const userService = new UserService();
  const { constructionId, constructions, clientId, clients } = useDashboard();
  const [state, setState] = useState<BaseState>(BaseState.success);
  const [tableContent, setTableContent] = useState<VerticalServiceGroup[]>([]);
  const [servicesTypes, setServicesTypes] = useState<VerticalServiceGroup[]>(
    []
  );
  const [
    servicesTypesByRegisteredServices,
    setServicesTypesByRegisteredServices,
  ] = useState<VerticalServiceGroup[]>([]);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [hoveredItem, setHoveredItem] = useState<any>(null);
  const [clickedItem, setClickedItem] = useState<any>(null);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [responsibleUsers, setResponsibleUsers] = useState<UserResponsible[]>(
    []
  );
  const [timePeriodListState, setTimePeriodListState] = useState<any[]>([]);
  const [addServiceDialogLoading, setAddServiceDialogLoading] =
    useState<boolean>(false);
  const [dates, setDates] = useState<{ startDate: Date; endDate: Date }>();
  const [datesPdf, setDatesPdf] = useState<{
    startDate: Date;
    endDate: Date;
  }>();
  const [constructionDates, setConstructionDates] = useState<{
    isCustomRange?: boolean;
    constructionBeginningDate?: Date;
    constructionEndDate?: Date;
    beginningDate: Date;
    endDate: Date;
    constructionDays: number;
    constructionWeekDays: number;
  }>();
  const [isDayServicesDialogOpen, setIsDayServicesDialogOpen] =
    useState<boolean>(false);
  const [dayServicesItems, setDayServicesItems] = useState<{
    serviceGroupColor: string;
    serviceType: string;
    data: any[];
  }>({
    serviceGroupColor: "#000",
    data: [],
    serviceType: "",
  });
  const [selectedDayServicesItems, setSelectedDayServicesItems] =
    useState<string>("");
  const [openDeleteActionDialog, setOpenDeleteActionDialog] =
    useState<boolean>(false);
  const [isDeleteActionLoadingDialog, seDeleteActiontLoadingDialog] =
    useState<boolean>(false);
  const [deleteActionItem, setDeleteActionItem] = useState<any>(null);
  const [editActionItem, setEditActionItem] = useState<VerticalServiceModel>();
  const addServiceDialogRef = React.useRef<AddServicePopUpRef>();
  const [selectedTab, setSelectedTab] = React.useState(() => {
    const savedFilters = localStorage.getItem("verticalServicesFilterValues");
    const parsedFilters = savedFilters ? JSON.parse(savedFilters) : {};
    return parsedFilters.byTime || "semanal";
  });
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = React.useState(false);
  const [locations, setLocations] = React.useState<string[]>([]);
  const [services, setServices] = React.useState<string[]>([]);

  //UploadImagePopUp
  const [isUploadImageDialogOpen, setIsUploadImageDialogOpen] =
    useState<boolean>(false);
  const [selectedVerticalServiceImages, setSelectedVerticalServiceImages] =
    useState<VerticalServiceImage[]>([]);
  const [isUploadImageDialogLoading, setIsUploadImageDialogLoading] =
    useState<boolean>(false);

  //AddRestrictionAndActionPlanPopUp
  const [
    isAddRestrictionAndActionPlanDialogOpen,
    setIsAddRestrictionAndActionPlanDialogOpen,
  ] = useState<boolean>(false);

  //UpdateServiceProgressPopUp
  const [
    isUpdateServiceProgressDialogOpen,
    setIsUpdateServiceProgressDialogOpen,
  ] = useState<boolean>(false);
  const [
    isUpdateServiceProgressDialogOpenLoading,
    setIsUpdateServiceProgressDialogOpenLoading,
  ] = useState<boolean>(false);

  const [selectedVerticalService, setSelectedVerticalService] =
    useState<VerticalServiceModel>();
  const [progressByConstruction, setProgressByConstruction] =
    useState<VerticalServiceProgressModel>();
  const [progressByService, setProgressByService] =
    useState<VerticalServiceProgressModel>();
  const pdfRef = useRef(null);
  const [isMobile, setIsMobile] = useState<boolean>();
  const [isPrintState, setPrintState] = useState<boolean>(false);
  const { setNavbarVisibility } = useNavbarVisibility();
  const [selectedClient, setSelectedClient] = useState<Client>();
  const [selectedConstruction, setSelectedConstruction] =
    useState<Construction>();
  const [isPrintStateFooterVisible, setPrintStateFooter] =
    useState<boolean>(true);
  const [lastUpdate, setLastUpdate] = useState<string>("");
  const [lastUpdatedBy, setLastUpdatedBy] = useState<string>("");
  const [showItemsSameDataByGroup, setShowItemsSameDataByGroup] =
    useState<boolean>(true);
  const [showImportXLSXDialog, setShowImportXLSXDikalog] =
    useState<boolean>(false);
  const [isLoadingShowImportXLSXDialog, setIsLoadingShowImportXLSXDialog] =
    useState<boolean>(false);

  // Three Dots Icon Button menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const exportImportMenuIsOpen = Boolean(anchorEl);

  const [deletedImage, setDeletedImage] = useState<VerticalServiceImage>();
  const [selectedFilter, setSelectedFilter] = useState<any>();

  // OrderOfSteps PopUp
  const [isOrderOfStepsPopUpOpen, setIsOrderOfStepsPopUpOpen] = useState(false);
  const [orderOfStepsPopUpLoading, setOrderOfStepsPopUpLoading] =
    useState(false);
  const [orderOfStepsData, setOrderOfStepsData] = useState<{
    changedServiceId: number;
    newStartDate: Date | undefined;
    oldStartDate: Date | undefined;
    newEndDate: Date | undefined;
    oldEndDate: Date | undefined;
  }>();

  const [constructionImage, setConstructionImage] = useState<string>("");
  const [selectedConstructionName, setSelectedConstructionName] = useState("");
  const [selectedClientName, setSelectedClientName] = useState("");
  const [imageOptionsOpen, setImageOptionsOpen] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const imageOptionsRef = useRef<HTMLDivElement>(null);
  const [cleanFilter, setCleanFilter] = useState<boolean>(false);

  const [reschedulingData, setReschedulingData] = useState<any[]>([]);

  const [tableData, setTableData] = useState<{
    byTime: string;
    content: any[];
  }>({ byTime: "", content: [] });

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [shouldFetchData, setShouldFetchData] = useState(false);

  // Adicione este estado
  const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = useState(false);

  useEffect(() => {
    if (isMobile === undefined) handleResize();
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    const loadInitialData = async () => {
      await findResponsibleUsers(constructionId);
      await findLocations(constructionId);
      await findServiceTypes(constructionId);
      getServicesProgressByConstruction(constructionId);
      await getReschedulingByConstructionId(constructionId);
      setSelectedConstruction(
        constructions.find((c) => c.id === constructionId)
      );
      setSelectedClient(clients.find((c) => c.id === clientId));
      getConstructionImage(constructionId);
      loadPageData(constructionId);

      const savedFilters = localStorage.getItem("verticalServicesFilterValues");
      let filters = {
        responsibleList: [],
        locationList: [],
        serviceList: [],
        statusList: [],
        beginDate: undefined,
        endDate: undefined,
        byTime: "semanal",
      };

      if (savedFilters) {
        const parsedFilters = JSON.parse(savedFilters);
        filters = {
          ...parsedFilters,
          beginDate: parsedFilters.beginDate
            ? new Date(parsedFilters.beginDate)
            : undefined,
          endDate: parsedFilters.endDate
            ? new Date(parsedFilters.endDate)
            : undefined,
          byTime: parsedFilters.byTime || "semanal",
        };
      }

      const responsibleUsersId = filters?.responsibleList?.length
        ? filters?.responsibleList
            .map((responsible: { id: string }) => responsible.id)
            .filter((id) => id !== null)
            .join(",")
        : "";

      const statusIds = filters?.statusList?.length
        ? filters.statusList
            .map((status: { name: string }) => {
              const statusObj = Object.values(ServiceStatus).find(
                (s) => s.value === status.name
              );
              return statusObj ? statusObj.key : null;
            })
            .filter((id) => id !== null)
            .join(",")
        : "";

      setFindTableContentParams({
        ...findTableContentParams,
        startDate: filters?.beginDate,
        endDate: filters?.endDate,
        byTime: selectedTab,
        responsibleUsers: responsibleUsersId,
        locations: responsibleUsersId,
        services: filters.serviceList?.length
          ? filters.serviceList.join(",")
          : "",
        filtered: true,
        statuses: statusIds,
      });
    };

    if (constructionId && isInitialLoad) {
      loadInitialData();
      setIsInitialLoad(false);
    }
  }, [clientId, clients, constructionId, constructions, isInitialLoad]);

  useEffect(() => {
    if (dates?.startDate && dates?.endDate && !isInitialLoad) {
      setFindTableContentParams({
        ...findTableContentParams,
        byTime: selectedTab,
        ...dates,
      });

      setConstructionDates({
        ...constructionDates,
        beginningDate: dates.startDate,
        endDate: dates.endDate,
        constructionDays: constructionDates?.constructionDays ?? 0,
        constructionWeekDays: constructionDates?.constructionWeekDays ?? 0,
      });
    }
  }, [dates, isInitialLoad]);

  useEffect(() => {
    if (shouldFetchData) setShouldFetchData(false);
  }, [shouldFetchData]);

  const [findTableContentParams, setFindTableContentParams] = useState<{
    startDate?: Date;
    endDate?: Date;
    byTime: string;
    responsibleUsers?: string;
    locations?: string;
    services?: string;
    filtered: boolean;
    statuses?: string;
    showItemsSamePeriod: boolean;
  }>({
    startDate: dates?.startDate,
    endDate: dates?.endDate,
    byTime: selectedTab,
    responsibleUsers: "",
    locations: "",
    services: "",
    filtered: false,
    statuses: "",
    showItemsSamePeriod: showItemsSameDataByGroup,
  });

  useEffect(() => {
    if (findTableContentParams)
      findTableContent(
        findTableContentParams.startDate,
        findTableContentParams.endDate,
        findTableContentParams.byTime,
        findTableContentParams.responsibleUsers,
        findTableContentParams.locations,
        findTableContentParams.services,
        findTableContentParams.filtered,
        findTableContentParams.statuses,
        findTableContentParams.showItemsSamePeriod
      );
  }, [findTableContentParams, constructionId]);

  const handleResize = () => {
    if (window.innerWidth < MOBILE_BREAKPOINT) setIsMobile(true);
    else setIsMobile(false);
  };

  const getReschedulingByConstructionId = async (constructionId: number) => {
    try {
      const response = await verticalService.getReschedulingByConstructionId(
        constructionId
      );
      setReschedulingData(response);
    } catch (error) {
      console.error("Error finding rescheduling by construction Id:", error);
    }
  };

  const loadPageData = async (constructionId: number) => {
    const constructionInfo = await constructionService.findById(constructionId);
    setSelectedClientName(constructionInfo?.clientId?.name);
  };

  // Update the findTableContent function to handle dates consistently
  const findTableContent = useCallback(
    async (
      startDate?: Date,
      endDate?: Date,
      byTime: string = selectedTab,
      responsibleUsers?: string,
      locations?: string,
      services?: string,
      filtered: boolean = false,
      statuses?: string,
      showItemsSamePeriod: boolean = showItemsSameDataByGroup
    ) => {
      if (!constructionId) return;

      setState(BaseState.loading);
      try {
        const adjustedStartDate = startDate
          ? setStartOfDay(startDate.toISOString())
          : setStartOfDay(new Date().toISOString());

        const adjustedEndDate = endDate
          ? setEndOfDay(endDate.toISOString())
          : setEndOfDay(new Date().toISOString());

        const response = await verticalService.getTableContent(
          constructionId.toString(),
          adjustedStartDate,
          adjustedEndDate,
          byTime.toLowerCase(),
          responsibleUsers,
          locations,
          services,
          showItemsSamePeriod,
          statuses
        );

        if (response?.content && !!response?.content?.length)
          setTableContent(response.content);
        else setTableContent([]);

        setTimePeriodListState(response?.timeList);
        setLastUpdatedBy(response?.lastUpdatedBy);
        setLastUpdate(
          new Date(response?.lastUpdate).toLocaleDateString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
        );

        if (!!response?.byTime) handleSelectedTab(response?.byTime);
        setState(BaseState.success);

        setDatesPdf({
          startDate: response?.firstDay
            ? parseAsUTCWithOffset(response.firstDay)
            : new Date(),
          endDate: response?.lastDay
            ? parseAsUTCWithOffset(response.lastDay)
            : new Date(),
        });
        if (
          (!dates?.startDate || !dates?.endDate) &&
          response?.firstDay &&
          response?.lastDay
        ) {
          const now = new Date();

          const startDate = response?.firstDay
            ? parseAsUTCWithOffset(response.firstDay)
            : now;
          const endDate = response?.lastDay
            ? parseAsUTCWithOffset(response.lastDay)
            : now;

          setDates({
            startDate,
            endDate,
          });

          setConstructionDates({
            isCustomRange: response?.isCustomRange ?? false,
            beginningDate: startDate,
            endDate: endDate,
            constructionBeginningDate: response?.constructionBeginningDate
              ? new Date(response.constructionBeginningDate)
              : undefined,
            constructionEndDate: response?.constructionEndDate
              ? new Date(response.constructionEndDate)
              : undefined,
            constructionDays: response?.constructionTotalDays ?? 0,
            constructionWeekDays: response?.constructionBusinessDays ?? 0,
          });
        }
      } catch (error) {
        console.error("Error fetching table content:", error);
        setState(BaseState.error);
      }
    },
    [
      constructionId,
      dates?.endDate,
      dates?.startDate,
      selectedTab,
      showItemsSameDataByGroup,
      verticalService,
    ]
  );

  const findServiceTypes = async (constructionId?: number) => {
    try {
      const response = await verticalService.getServiceTypes(constructionId);
      const responseFilterByRegisteredServices =
        await verticalService.getServiceTypes(constructionId, false, true);
      setServicesTypes(response);
      setServicesTypesByRegisteredServices(responseFilterByRegisteredServices);
      const serviceTypes = response?.length
        ? response
            .map((service) => service.services.map((service) => service.name))
            .flat()
        : [];
      serviceTypes.sort();
      setServices(serviceTypes);
    } catch (error) {}
  };

  useEffect(() => {
    if (constructionId) {
      findServiceTypes(constructionId);
      findResponsibleUsers(constructionId);
    }
  }, [constructionId]);

  const findLocations = async (constructionId: number) => {
    try {
      const response = await verticalService.getLocationsByConstructionId(
        constructionId
      );
      setLocations(response);
    } catch (error) {
      console.error(error);
    }
  };

  const findResponsibleUsers = useCallback(
    async (constructionId: number) => {
      userService
        .fetchResponsibleUsers(constructionId.toString())
        .then((response) => {
          setResponsibleUsers(response);
        });
    }, [userService]
  );

  const getServicesProgressByConstruction = async (
    constructionid: number = constructionId
  ) => {
    if (isConstructionNotSelected()) {
      return;
    }

    verticalService
      .getServiceProgressByConstructionId(constructionid)
      .then((response) => {
        setProgressByConstruction(response);
      })
      .catch(() => {});
  };

  const getServiceProgressByServiceId = async (
    constructionid: number = constructionId,
    serviceId: number
  ) => {
    if (isConstructionNotSelected()) return;

    verticalService
      .getServiceProgressByServiceId(constructionid, serviceId)
      .then((response) => {
        setProgressByService(response);
      })
      .catch((error) => {
        console.error("error : ", error);
      });
  };

  const isConstructionNotSelected = () =>
    constructionId === null ||
    constructionId === undefined ||
    constructionId === 0;

  const onMouseOver = useCallback(
    (item: any) => {
      if (clickedItem != null && clickedItem.appUniqueId === item.appUniqueId)
        return;

      if (item != null && hoveredItem?.id !== item.id) {
        setHoveredItem(item);
        return;
      }
    },
    [clickedItem, hoveredItem]
  );

  const onMouseLeave = useCallback(
    (item: any) => {
      if (clickedItem) setClickedItem(null);
      if (hoveredItem && hoveredItem?.id === item?.id) setHoveredItem(null);
    },
    [clickedItem, hoveredItem]
  );

  const serviceOnClick = useCallback((item: any) => {
    setHoveredItem(null);
    setClickedItem(item);
  }, []);

  const onAddServiceClick = () => setIsDialogOpen(true);

  const handleExportImportMenuClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const handleExportImportMenuClose = (option: ThreeDotsIconEnum) => {
    if (option === ThreeDotsIconEnum.IMPORT_XLSX)
      setShowImportXLSXDikalog(true);
    if (option === ThreeDotsIconEnum.PDF) generatePDFByBackEnd();
    if (option === ThreeDotsIconEnum.RESCHEDULING_PDF)
      generateReschedulingPDFByBackEnd();
    if (option === ThreeDotsIconEnum.DUPLICATE) handleDuplicateClick();
    setAnchorEl(null);
  };

  const generateReschedulingPDFByBackEnd = async () => {
    if (
      constructionId === null ||
      constructionId === undefined ||
      constructionId === 0
    )
      return;

    if (
      dates === undefined ||
      dates.startDate === undefined ||
      dates.endDate === undefined
    )
      return;

    setState(BaseState.loading);
    if (selectedFilter === undefined) {
      const response = await verticalService.exportReschedulingPDF(
        constructionId.toString(),
        dates.startDate,
        dates.endDate,
        selectedTab.toLowerCase()
      );
      sendReschedulingPDFExportToUser(response);
    } else {
      const selectedValues = selectedFilter;
      const startDate = findTableContentParams.startDate
        ? setStartOfDay(findTableContentParams.startDate.toISOString())
        : setStartOfDay(new Date().toISOString());
      const endDate = findTableContentParams.endDate
        ? setStartOfDay(findTableContentParams.endDate.toISOString())
        : setStartOfDay(new Date().toISOString());
      const responsibleUsersId: string[] = [];
      const serviceTypesIds: string[] = [];

      responsibleUsers.map((user) => {
        if (
          selectedValues.responsibleList.some(
            (responsible: { name: string }) => responsible.name === user.name
          )
        )
          responsibleUsersId.push(user.id.toString());
      });
      selectedValues.serviceList.map((service: any) => {
        servicesTypes.map((serviceType) => {
          serviceType.services.map((serviceType) => {
            if (serviceType.name === service)
              serviceTypesIds.push(serviceType.id.toString());
          });
        });
      });

      const response = await verticalService.exportReschedulingPDF(
        constructionId.toString(),
        startDate,
        endDate,
        findTableContentParams.byTime.toLowerCase(),
        findTableContentParams.responsibleUsers,
        findTableContentParams.locations,
        findTableContentParams.services,
        findTableContentParams.statuses,
        findTableContentParams.showItemsSamePeriod
      );
      sendReschedulingPDFExportToUser(response);
    }

    setState(BaseState.success);
  };

  const sendReschedulingPDFExportToUser = async (pdf: string) => {
    const linkSource = `data:application/vnd.ms-excel;base64,${pdf}`;
    const downloadLink = document.createElement("a");

    const period = dates
      ? getPeriodByTime(selectedTab, dates.startDate)
      : "periodo-desconhecido";

    const fileName = `${
      "Serviços Reprogramados - " +
      (selectedClientName ?? "cliente-desconhecido") +
      "_Programação_" +
      period +
      "_" +
      (selectedConstructionName ?? "construcao-desconhecida")
    }.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const generatePDFByBackEnd = async () => {
    if (
      constructionId === null ||
      constructionId === undefined ||
      constructionId === 0
    )
      return;

    if (
      dates === undefined ||
      dates.startDate === undefined ||
      dates.endDate === undefined
    )
      return;

    setState(BaseState.loading);
    if (selectedFilter === undefined) {
      const response = await verticalService.exportPDF(
        constructionId.toString(),
        dates.startDate,
        dates.endDate,
        selectedTab.toLowerCase()
      );
      sendPDFExportToUser(response);
    } else {
      const selectedValues = selectedFilter;
      const startDate = findTableContentParams.startDate
        ? setStartOfDay(findTableContentParams.startDate.toISOString())
        : setStartOfDay(new Date().toISOString());
      const endDate = findTableContentParams.endDate
        ? setStartOfDay(findTableContentParams.endDate.toISOString())
        : setStartOfDay(new Date().toISOString());
      const responsibleUsersId: string[] = [];
      const serviceTypesIds: string[] = [];

      responsibleUsers.map((user) => {
        if (
          selectedValues.responsibleList.some(
            (responsible: { name: string }) => responsible.name === user.name
          )
        )
          responsibleUsersId.push(user.id.toString());
      });
      selectedValues.serviceList.map((service: any) => {
        servicesTypes.map((serviceType) => {
          serviceType.services.map((serviceType) => {
            if (serviceType.name === service)
              serviceTypesIds.push(serviceType.id.toString());
          });
        });
      });

      const response = await verticalService.exportPDF(
        constructionId.toString(),
        startDate,
        endDate,
        findTableContentParams.byTime.toLowerCase(),
        findTableContentParams.responsibleUsers,
        findTableContentParams.locations,
        findTableContentParams.services,
        findTableContentParams.statuses,
        findTableContentParams.showItemsSamePeriod
      );
      sendPDFExportToUser(response);
    }

    setState(BaseState.success);
  };

  const sendPDFExportToUser = async (pdf: string) => {
    const linkSource = `data:application/vnd.ms-excel;base64,${pdf}`;
    const downloadLink = document.createElement("a");

    const period = dates
      ? getPeriodByTime(selectedTab, dates.startDate)
      : "periodo-desconhecido";

    const fileName = `${
      "Programação de Serviços - " +
      (selectedClientName ?? "cliente-desconhecido") +
      "_Programação_" +
      period +
      "_" +
      (selectedConstructionName ?? "construcao-desconhecida")
    }.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const onCloseDialog = () => {
    if (editActionItem && dayServicesItems.data.length > 0)
      setIsDayServicesDialogOpen(true);

    setIsDialogOpen(false);
    setEditActionItem(undefined);
    (addServiceDialogRef.current as any).cleanData();
  };

  const onConfirmDialog = async (
    model: VerticalServiceModel,
    newServiceType?: NewServiceType,
    purchasingModel?: PurchasingModel,
    contractingModel?: ContractingModel,
    reschedulingReason?: string
  ) => {
    if (newServiceType) {
      const serviceType = await verticalService.createCustomServiceType(
        constructionId,
        newServiceType.name,
        newServiceType.groupId
      );
      model.verticalServiceTypeId = serviceType.id;
      model.verticalServiceGroupId = serviceType.verticalServiceGroupId;
      await findServiceTypes(constructionId);
      await findResponsibleUsers(constructionId);
    }

    if (editActionItem) editService(model, reschedulingReason);
    else addNewService(model, purchasingModel, contractingModel);
  };

  const addNewService = (
    model: VerticalServiceModel,
    purchasingModel?: PurchasingModel,
    contractingModel?: ContractingModel
  ) => {
    if (!dates?.startDate || !dates?.endDate) return;

    setAddServiceDialogLoading(true);
    model.constructionid = constructionId;
    verticalService
      .addService(model)
      .then(async (response: any) => {
        if (purchasingModel) {
          purchasingContractinService
            .savePurchasingPlan(
              constructionId,
              {
                purchasingItems: [
                  {
                    name: purchasingModel.name,
                    unit: purchasingModel.unit,
                    quantity: purchasingModel.quantity,
                    specifications: purchasingModel.specifications,
                  },
                ],
                serviceIds: [response.id],
              } as PurchasingPlanEntity,
              "email"
            )
            .then(() => {})
            .catch(() => {
              console.error("error on save contractingModel");
            });
        }

        if (contractingModel) {
          purchasingContractinService
            .saveContractingPlan(
              constructionId,
              {
                contractingItems: [
                  {
                    name: contractingModel.name,
                    specifications: contractingModel.specifications,
                  },
                ],
                serviceIds: [response.id],
              } as ContractingPlanEntity,
              "email"
            )
            .then(() => {})
            .catch(() => {
              console.error("error on save contractingModel");
            });
        }

        setAddServiceDialogLoading(false);
        setOpenSnackbar(true);
        setSnackbarMessage("Serviço adicionado com sucesso");
        setIsDialogOpen(false);
        setFindTableContentParams({ ...findTableContentParams, ...dates });
        findServiceTypes(constructionId);
        findResponsibleUsers(constructionId);
        getServicesProgressByConstruction(constructionId);

        addServiceDialogRef.current?.cleanData();
      })
      .catch(() => {
        setAddServiceDialogLoading(false);
        setOpenSnackbar(true);
        setSnackbarMessage("Erro ao adicionar serviço");
      });
  };

  const editService = (
    model: VerticalServiceModel,
    reschedulingReason?: string
  ) => {
    if (!dates?.startDate || !dates?.endDate) return;

    setAddServiceDialogLoading(true);
    model.constructionid = constructionId;

    if (typeof model.responsibleUserId === "object" && model.responsibleUserId)
      model.responsibleUserId = (model.responsibleUserId as any).id;

    if (!model.id) {
      setSnackbarMessage("Erro ao atualizar serviço");
      return;
    }

    let isBeginDateChanged = false;
    let isEndDateChanged = false;

    try {
      const begimDateAsDate = new Date(model?.beginDate!);
      const endDateAsDate = new Date(model?.endDate!);
      const editItemBeginDate = new Date(editActionItem?.beginDate!);
      const editItemEndDate = new Date(editActionItem?.endDate!);

      isBeginDateChanged =
        begimDateAsDate.getTime() !== editItemBeginDate.getTime();
      isEndDateChanged = endDateAsDate.getTime() !== editItemEndDate.getTime();

      if (isBeginDateChanged || isEndDateChanged) {
        if (reschedulingReason) {
          const reschedulingData: VerticalServiceReschedulingDto = {
            verticalServiceId: model.id,
            constructionId: Number(model.constructionid),
            oldStartDate: editItemBeginDate,
            newStartDate: begimDateAsDate,
            oldEndDate: editItemEndDate,
            newEndDate: endDateAsDate,
            reschedulingReason: reschedulingReason,
            createdByUser: Number(user.id),
          };
          try {
            verticalService.createRescheduling(reschedulingData);
          } catch (error) {
            console.error("Erro de resposta do servidor:", error);
          }
        }
        openOrderOfStepsPopUp(
          model.id,
          editActionItem?.beginDate,
          model.beginDate,
          editActionItem?.endDate,
          model.endDate
        );
      }
    } catch (error) {
      console.error("error : ", error);
    }

    verticalService
      .updateService(
        {
          ...model,
          status: (model.status as any).value
            ? (model.status as any).value
            : model.status,
        },
        model.id.toString()
      )
      .then(async () => {
        setAddServiceDialogLoading(false);
        setOpenSnackbar(true);
        setSnackbarMessage("Serviço atualizado com sucesso");
        setIsDialogOpen(false);

        if (!isBeginDateChanged && !isEndDateChanged)
          setFindTableContentParams({ ...findTableContentParams, ...dates });

        setEditActionItem(undefined);
        addServiceDialogRef.current?.cleanData();
        await getReschedulingByConstructionId(constructionId);
        setIsDayServicesDialogOpen(true);
        // update dayServicesItems.data changing old model to new model
        const index = dayServicesItems.data.findIndex(
          (item) => item.id === model.id
        );
        dayServicesItems.data[index] = model;
        setIsDayServicesDialogOpen(true);
      })
      .catch(() => {
        setAddServiceDialogLoading(false);
        setOpenSnackbar(true);
        setSnackbarMessage("Erro ao atualizar serviço");
      });
  };

  const openOrderOfStepsPopUp = (
    changedServiceId: number,
    oldStartDate: Date | undefined,
    newStartDate: Date | undefined,
    oldEndDate: Date | undefined,
    newEndDate: Date | undefined
  ) => {
    setOrderOfStepsData({
      changedServiceId,
      newStartDate: newStartDate,
      oldStartDate: oldStartDate,
      oldEndDate: oldEndDate,
      newEndDate: newEndDate,
    });
    setIsOrderOfStepsPopUpOpen(true);
  };

  const handleTableControlNextClick = () => {
    switch (selectedTab) {
      case TABS.semanal:
        nextWeek();
        break;
      case TABS.mensal:
        nextMonth();
        break;
      case TABS.trimestral:
        nextTrimester();
        break;
      case TABS.semestral:
        nextSemester();
        break;
      case TABS.anual:
        nextYear();
        break;
      default:
        break;
    }
  };

  const handleTableControlPreviousClick = () => {
    switch (selectedTab) {
      case TABS.semanal:
        previousWeek();
        break;
      case TABS.mensal:
        previousMonth();
        break;
      case TABS.trimestral:
        previousTrimester();
        break;
      case TABS.semestral:
        previousSemester();
        break;
      case TABS.anual:
        previousYear();
        break;
      default:
        break;
    }
  };

  const nextWeek = () => {
    if (!dates?.startDate || !dates?.endDate) return;

    const newStartDate = new Date(dates.startDate);
    const newEndDate = new Date(dates.endDate);

    newStartDate.setDate(newStartDate.getDate() + 7);
    newEndDate.setDate(newEndDate.getDate() + 7);

    setDates({
      startDate: newStartDate,
      endDate: newEndDate,
    });
  };

  const previousWeek = () => {
    if (!dates?.startDate || !dates?.endDate) return;

    const newStartDate = new Date(dates.startDate);
    const newEndDate = new Date(dates.endDate);

    newStartDate.setDate(newStartDate.getDate() - 7);
    newEndDate.setDate(newEndDate.getDate() - 7);

    setDates({
      startDate: newStartDate,
      endDate: newEndDate,
    });
  };

  const nextMonth = () => {
    if (!dates?.startDate || !dates?.endDate) return;

    const newStartDate = new Date(dates.startDate);
    const newEndDate = new Date(dates.endDate);

    newStartDate.setMonth(newStartDate.getMonth() + 1);
    newEndDate.setMonth(newEndDate.getMonth() + 1);

    setDates({
      startDate: newStartDate,
      endDate: newEndDate,
    });
  };

  const previousMonth = () => {
    if (!dates?.startDate || !dates?.endDate) return;

    const newStartDate = new Date(dates.startDate);
    const newEndDate = new Date(dates.endDate);

    newStartDate.setMonth(newStartDate.getMonth() - 1);
    newEndDate.setMonth(newEndDate.getMonth() - 1);

    setDates({
      startDate: newStartDate,
      endDate: newEndDate,
    });
  };

  const nextTrimester = () => {
    if (!dates?.startDate || !dates?.endDate) return;

    const newStartDate = new Date(dates.startDate);
    const newEndDate = new Date(dates.endDate);

    newStartDate.setMonth(newStartDate.getMonth() + 3);
    newEndDate.setMonth(newEndDate.getMonth() + 3);

    setDates({
      startDate: newStartDate,
      endDate: newEndDate,
    });
  };

  const previousTrimester = () => {
    if (!dates?.startDate || !dates?.endDate) return;

    const newStartDate = new Date(dates.startDate);
    const newEndDate = new Date(dates.endDate);

    newStartDate.setMonth(newStartDate.getMonth() - 3);
    newEndDate.setMonth(newEndDate.getMonth() - 3);

    setDates({
      startDate: newStartDate,
      endDate: newEndDate,
    });
  };

  const nextSemester = () => {
    if (!dates?.startDate || !dates?.endDate) return;

    const newStartDate = new Date(dates.startDate);
    const newEndDate = new Date(dates.endDate);

    newStartDate.setMonth(newStartDate.getMonth() + 6);
    newEndDate.setMonth(newEndDate.getMonth() + 6);

    setDates({
      startDate: newStartDate,
      endDate: newEndDate,
    });
  };

  const previousSemester = () => {
    if (!dates?.startDate || !dates?.endDate) return;

    const newStartDate = new Date(dates.startDate);
    const newEndDate = new Date(dates.endDate);

    newStartDate.setMonth(newStartDate.getMonth() - 6);
    newEndDate.setMonth(newEndDate.getMonth() - 6);

    setDates({
      startDate: newStartDate,
      endDate: newEndDate,
    });
  };

  const nextYear = () => {
    if (!dates?.startDate || !dates?.endDate) return;

    const newStartDate = new Date(dates.startDate);
    const newEndDate = new Date(dates.endDate);

    newStartDate.setFullYear(newStartDate.getFullYear() + 1);
    newEndDate.setFullYear(newEndDate.getFullYear() + 1);

    setDates({
      startDate: newStartDate,
      endDate: newEndDate,
    });
  };

  const previousYear = () => {
    if (!dates?.startDate || !dates?.endDate) return;

    const newStartDate = new Date(dates.startDate);
    const newEndDate = new Date(dates.endDate);

    newStartDate.setFullYear(newStartDate.getFullYear() - 1);
    newEndDate.setFullYear(newEndDate.getFullYear() - 1);

    setDates({
      startDate: newStartDate,
      endDate: newEndDate,
    });
  };

  const moreItemsOnClick = (item: any[], groupColor: string, type: string) => {
    setDayServicesItems({
      serviceGroupColor: groupColor,
      data: item,
      serviceType: type,
    });
    setIsDayServicesDialogOpen(true);
  };

  const getServiceTypeOnClick = (type: string) =>
    setSelectedDayServicesItems(type);

  const editServiceOnClick = (item: any) => {
    setIsDayServicesDialogOpen(false);
    setEditActionItem(item);
    setAddServiceDialogLoading(false);
    // Important! Open dialog after 100ms to avoid flickering
    setTimeout(() => {
      setIsDialogOpen(true);
    }, 100);
  };

  const deleteServiceOnClick = (item: any) => {
    setDeleteActionItem(item);
    setIsDayServicesDialogOpen(false);
    setOpenDeleteActionDialog(true);
  };

  const closeListActionsPopUp = () => {
    setIsDayServicesDialogOpen(false);
    setDayServicesItems({
      serviceGroupColor: "#000",
      data: [],
      serviceType: "",
    });
  };

  const deleteActionialogButtonCancelOnClick = () => {
    setOpenDeleteActionDialog(false);
    setEditActionItem(undefined);
  };

  const deleteActionialogButtonConfirmOnClick = () => {
    if (!dates?.startDate || !dates?.endDate) return;

    seDeleteActiontLoadingDialog(true);
    verticalService
      .deleteService(deleteActionItem.id)
      .then(() => {
        seDeleteActiontLoadingDialog(false);
        setOpenDeleteActionDialog(false);
        setOpenSnackbar(true);
        setSnackbarMessage("Serviço excluído com sucesso");
        setIsDayServicesDialogOpen(true);
        dayServicesItems.data.splice(
          dayServicesItems.data.indexOf(deleteActionItem),
          1
        );
        setFindTableContentParams({ ...findTableContentParams, ...dates });
        getServicesProgressByConstruction();
      })
      .catch(() => {
        seDeleteActiontLoadingDialog(false);
        setOpenDeleteActionDialog(false);
        setOpenSnackbar(true);
        setIsDayServicesDialogOpen(true);
        setSnackbarMessage("Erro ao excluir serviço");
      });
  };

  const serviceClickEditActionCallback = (item: any) => {
    item.responsibleUserName = item?.responsibleUserId?.name;
    editServiceOnClick(item);
  };

  const serviceClickDeleteActionCallback = (item: any) => {
    deleteServiceOnClick(item);
  };

  const handleOnTabChange = (newValue: string) => {
    if (isDateFilterActive) return;
    handleSelectedTab(newValue);

    // Apenas atualizar o byTime mantendo as datas atuais
    if (dates?.startDate && dates?.endDate) {
      setFindTableContentParams({
        ...findTableContentParams,
        startDate: dates.startDate,
        endDate: dates.endDate,
        byTime: newValue,
      });
    }
  };

  const capitalizeFirstLetter = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  const handleSelectedTab = useCallback((newValue: string) => {
    const savedFilters = localStorage.getItem("verticalServicesFilterValues");

    const parsedFilters = savedFilters ? JSON.parse(savedFilters) : {};

    parsedFilters.byTime = newValue;
    setSelectedTab(newValue);
    localStorage.setItem(
      "verticalServicesFilterValues",
      JSON.stringify(parsedFilters)
    );
  }, []);

  const getPeriodBySelectedTab = (date: Date, endDate?: Date) => {
    let dateFormated = "";
    switch (selectedTab) {
      case TABS.semanal:
        dateFormated = capitalizeFirstLetter(
          date
            .toLocaleDateString("pt-BR", {
              weekday: "short",
              day: "2-digit",
              month: "2-digit",
            })
            .replace(".,", "")
        );
        break;
      case TABS.mensal:
        const startDate = capitalizeFirstLetter(
          date.toLocaleDateString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
          })
        );
        const endDateFormatted = capitalizeFirstLetter(
          endDate
            ? endDate.toLocaleDateString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
              })
            : ""
        );
        dateFormated = `${startDate} à ${endDateFormatted}`;
        break;
      case "custom":
      case TABS.trimestral:
        const startDateTrimester = capitalizeFirstLetter(
          date.toLocaleDateString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
          })
        );
        const endDateTrimester = capitalizeFirstLetter(
          endDate
            ? endDate.toLocaleDateString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
              })
            : ""
        );
        dateFormated = `${startDateTrimester} à ${endDateTrimester}`;
        break;
      case TABS.semestral:
        dateFormated = capitalizeFirstLetter(
          date.toLocaleDateString("pt-BR", {
            month: "long",
          })
        );
        break;

      case TABS.anual:
        dateFormated = capitalizeFirstLetter(
          date.toLocaleDateString("pt-BR", {
            month: "short"
          })
        );
        dateFormated +=
          "/" +
          date.toLocaleDateString("pt-BR", {
            year: "numeric"
          });
        dateFormated = dateFormated.replace(".", "");
        break;
      default:
        break;
    }

    return dateFormated;
  };

  const getPeriodByTime = (
    selectedTimePeriod: string,
    date: Date,
    endDate?: Date
  ) => {
    let dateFormated = "";

    const capitalizeFirstLetter = (str: string) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    switch (selectedTimePeriod) {
      case TABS.semanal:
        const currentDate = new Date(date);
        const weekStart = new Date(currentDate);
        const weekEnd = new Date(currentDate);

        weekStart.setDate(
          currentDate.getDate() - ((currentDate.getDay() + 6) % 7)
        );

        weekEnd.setDate(weekStart.getDate() + 6);

        dateFormated = `${weekStart.toLocaleDateString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })} à ${weekEnd.toLocaleDateString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })}`;
        break;

      case TABS.mensal:
        dateFormated = capitalizeFirstLetter(
          date.toLocaleDateString("pt-BR", { month: "long" })
        );
        break;

      case TABS.trimestral:
        const month = date.getMonth();
        const quarterStart = setStartOfDay(
          new Date(date.getFullYear(), month, 1).toISOString()
        );
        const quarterEnd = setEndOfDay(
          new Date(date.getFullYear(), month + 2, 1).toISOString()
        );

        dateFormated = `${capitalizeFirstLetter(
          quarterStart.toLocaleDateString("pt-BR", { month: "long" })
        )} à ${capitalizeFirstLetter(
          quarterEnd.toLocaleDateString("pt-BR", { month: "long" })
        )}`;
        break;

      case TABS.semestral:
        const currentMonth = date.getMonth();
        const semesterStart = currentMonth < 6 ? "Janeiro" : "Julho";
        const semesterEnd = currentMonth < 6 ? "Junho" : "Dezembro";

        dateFormated = `${semesterStart} à ${semesterEnd}`;
        break;

      case TABS.anual:
        dateFormated = date.toLocaleDateString("pt-BR", { year: "numeric" });
        break;

      default:
        break;
    }

    return dateFormated;
  };

  const filterOnClick = () => setIsFilterDrawerOpen(true);

  const onCloseFilterDrawer = () => setIsFilterDrawerOpen(false);

  // Add a new function to handle filter updates
  const handleFilterUpdate = (values: any, shouldCloseDrawer = true) => {
    const startDate = values.beginDate ? new Date(values.beginDate) : undefined;
    const endDate = values.endDate ? new Date(values.endDate) : undefined;

    setSelectedFilter(values);
    handleSelectedTab(values.byTime);

    if (startDate && endDate)
      setDates({
        startDate,
        endDate,
      });

    setFindTableContentParams({
      startDate,
      endDate,
      byTime:
        values.byTime !== "clear" && startDate && endDate
          ? "custom"
          : "semanal",
      responsibleUsers: processResponsibleUsers(values.responsibleList),
      locations: processLocations(values.locationList),
      services: processServices(values.serviceList),
      filtered: true,
      statuses: processStatuses(values.statusList),
      showItemsSamePeriod: showItemsSameDataByGroup,
    });

    if (shouldCloseDrawer) setIsFilterDrawerOpen(false);
  };

  // Add helper functions for processing filter values
  const processResponsibleUsers = (responsibleList: any[]) => {
    const ids = responsibleUsers?.length
      ? responsibleUsers
          .filter(({ name }) =>
            responsibleList.some((item) => item?.name === name)
          )
          .map(({ id }) => id.toString())
      : [];
    return ids?.length ? ids.join(",") : undefined;
  };

  const processLocations = (locationList: any[]) =>
    locationList?.length
      ? locationList
          .map((location: { name: string }) => location.name)
          .join(",")
      : undefined;

  const processServices = (serviceList: string[]) => {
    const ids = serviceList?.length
      ? serviceList
          .map((serviceName) => {
            let serviceId;
            servicesTypes.forEach((group) => {
              group.services.forEach((service) => {
                if (service.name === serviceName) {
                  serviceId = service.id;
                }
              });
            });
            return serviceId;
          })
          .filter(Boolean)
      : [];
    return ids?.length ? ids.join(",") : undefined;
  };

  const processStatuses = (statusList: any[]) => {
    const ids = statusList?.length
      ? statusList
          .map((status) => {
            const statusKey = Object.entries(ServiceStatus).find(
              ([_, value]) => value.value === status.name
            )?.[1]?.key;
            return statusKey;
          })
          .filter(Boolean)
      : [];
    return ids?.length ? ids.join(",") : undefined;
  };

  // Update the onApplyFilter function to use the new handleFilterUpdate
  const onApplyFilter = (selectedValues: any) => {
    const isClearing =
      selectedValues.responsibleList.length === 0 &&
      selectedValues.locationList.length === 0 &&
      selectedValues.serviceList.length === 0 &&
      selectedValues.statusList.length === 0;

    if (selectedValues.byTime === "clear") {
      handleSelectedTab("semanal");
      selectedValues.byTime = "clear";
    } else {
      handleSelectedTab("custom");
      selectedValues.byTime = "custom";
    }
    handleFilterUpdate(selectedValues);
    if (isClearing)
      setDates({
        startDate: selectedValues.beginDate,
        endDate: selectedValues.endDate,
      });
  };

  const handleClearFilter = () => {
    setCleanFilter(true);
    const now = new Date();
    const clearedValues = {
      responsibleList: [],
      locationList: [],
      serviceList: [],
      statusList: [],
      beginDate: now,
      endDate: now,
      byTime: "clear",
    };

    onApplyFilter(clearedValues);

    localStorage.removeItem("verticalServicesFilterValues");
  };

  const openUploadImageDialog = (
    verticalServiceModel?: VerticalServiceModel
  ) => {
    setDeleteActionItem(verticalServiceModel);
    setSelectedVerticalService(verticalServiceModel);
    setIsDayServicesDialogOpen(false);
    setIsUploadImageDialogOpen(true);
    getImagesByVerticalServiceId(verticalServiceModel);
  };

  const closeUploadImageDialog = () => setIsUploadImageDialogOpen(false);

  const openUpdateServiceProgressDialog = (model?: VerticalServiceModel) => {
    if (!model?.id) return;

    getServiceProgressByServiceId(constructionId, model.id);
    setSelectedVerticalService(model);
    setIsUpdateServiceProgressDialogOpen(true);
    setIsDayServicesDialogOpen(false);
  };

  const openAddRestrictionAndActionPlanDialog = (
    verticalServiceModel?: VerticalServiceModel
  ) => {
    if (!verticalServiceModel?.id) return;

    setSelectedVerticalService(verticalServiceModel);
    setIsDayServicesDialogOpen(false);
    setIsAddRestrictionAndActionPlanDialogOpen(true);
  };

  const closeAddRestrictionAndActionPlanDialog = () => {
    setSelectedVerticalService(undefined);
    setIsDayServicesDialogOpen(false);
    setIsAddRestrictionAndActionPlanDialogOpen(false);
  };

  const addPurchasingContractingPlanOnClick = (
    model?: VerticalServiceModel
  ) => {
    if (!model?.id) return;

    navigate(
      PURCHASING_CONTRACTING_PLAN_FORM_PATH + `?verticalServiceId=${model.id}`
    );
  };

  const closeUpdateServiceProgressDialog = () => {
    setIsUpdateServiceProgressDialogOpen(false);
    setSelectedVerticalService(undefined);
  };

  const updateServiceProgressConfirmOnClick = (
    model: UpdateServiceProgressModel
  ) => {
    if (selectedVerticalService?.id === undefined) return;

    if (!dates?.startDate || !dates?.endDate) return;

    model.constructionId = constructionId;
    setAddServiceDialogLoading(true);

    verticalService
      .createNewerviceProgress(model, selectedVerticalService?.id)
      .then((response) => {
        setOpenSnackbar(true);
        setSnackbarMessage("Serviço atualizado com sucesso");
        setIsUpdateServiceProgressDialogOpen(false);
        setSelectedVerticalService(undefined);
        setFindTableContentParams({ ...findTableContentParams, ...dates });
        getServicesProgressByConstruction(constructionId);
        setAddServiceDialogLoading(false);
      })
      .catch(() => {
        setOpenSnackbar(true);
        setSelectedVerticalService(undefined);
        setAddServiceDialogLoading(false);
        setSnackbarMessage("Erro ao atualizar serviço");
      });
  };

  const updatePastServiceProgressConfirmOnClick = (
    serviceId: number,
    pastProgressId: number,
    newValue: number,
    updateType: ProgressUpdateType
  ) => {
    verticalService
      .updateServiceProgress(serviceId, pastProgressId, newValue, updateType)
      .then(() => {
        setOpenSnackbar(true);
        setSnackbarMessage("Progresso atualizado com sucesso");
        getServiceProgressByServiceId(constructionId, serviceId);
      })
      .catch(() => {
        setOpenSnackbar(true);
        setSnackbarMessage("Erro ao atualizar serviço");
      });
  };

  const uploadImagesConfirmOnClick = (
    images: any[],
    serviceModel?: VerticalServiceModel
  ) => {
    if (serviceModel?.id) {
      setIsUploadImageDialogLoading(true);
      verticalService
        .uploadImages(images, serviceModel.id)
        .then(() => {
          setIsUploadImageDialogLoading(false);
          setSnackbarMessage("Imagens adicionadas com sucesso");
          setOpenSnackbar(true);
          getImagesByVerticalServiceId(serviceModel);
        })
        .catch(() => {
          setIsUploadImageDialogLoading(false);
          setSnackbarMessage("Erro ao fazer upload de imagens");
          setOpenSnackbar(true);
        });
    } else {
      setSnackbarMessage("Erro ao fazer upload de imagens");
      setOpenSnackbar(true);
    }
  };

  const getImagesByVerticalServiceId = async (
    verticalServiceModel?: VerticalServiceModel
  ) => {
    if (verticalServiceModel?.id)
      verticalService.getImages(verticalServiceModel.id).then((images) => {
        setSelectedVerticalServiceImages(images);
      });
  };

  const shareOnClick = async () => {
    setPrintState(true);
    setNavbarVisibility(false);
  };

  const dowloadExcelOnClick = async () => {
    const response = await verticalService.getExcelReport(constructionId);
    downloadExcelXLS(response);
  };

  const downloadExcelXLS = (attachment: string) => {
    const linkSource = `data:application/vnd.ms-excel;base64,${attachment}`;
    const downloadLink = document.createElement("a");
    const fileName = `${"report - " + new Date()}.xlsx`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const printScreen = () => {
    const input = pdfRef.current;
    if (!input) return;

    setPrintStateFooter(false);

    setTimeout(() => {
      const divProperties = input as any;
      const totalHeight = divProperties.scrollHeight;
      html2canvas(input, {
        allowTaint: true,
        useCORS: true,
        logging: true,
        height: totalHeight,
        windowHeight: totalHeight,
      }).then((canvas) => {
        var image = canvas.toDataURL("image/png", 1.0);
        var a = document.createElement("a");
        a.href = image;
        a.download = "report.png";
        document.body.appendChild(a);
        a.click();
        setPrintStateFooter(true);
      });
    }, 1000);
  };

  const goToReport = () => navigate(`/vertical-servicos-relatorio`);

  const onDeleteCustomServiceType = (serviceTypeId: string) =>
    verticalService.deleteCustomServiceType(constructionId, serviceTypeId);

  const setDefaultStateOnClick = () => {
    setPrintState(false);
    setNavbarVisibility(true);
  };

  const onChangeGroupByTime = (value: boolean) => {
    setShowItemsSameDataByGroup(value);

    if (!dates?.startDate || !dates?.endDate) return;

    setFindTableContentParams({
      ...findTableContentParams,
      ...dates,
      byTime: isDateFilterActive ? "custom" : selectedTab,
      filtered: false,
      showItemsSamePeriod: value,
    });
  };

  const importXLSXConfirmOnClick = (file: File) => {
    if (!dates?.startDate || !dates?.endDate) return;

    setIsLoadingShowImportXLSXDialog(true);
    verticalService
      .uploadExcelFile(constructionId, file)
      .then(() => {
        setIsLoadingShowImportXLSXDialog(false);
        setShowImportXLSXDikalog(false);
        setOpenSnackbar(true);
        setSnackbarMessage("Planilha importada com sucesso");
        setFindTableContentParams({ ...findTableContentParams, ...dates });
      })
      .catch(() => {
        setIsLoadingShowImportXLSXDialog(false);
        setShowImportXLSXDikalog(false);
        setOpenSnackbar(true);
        setSnackbarMessage("Erro ao importar planilha");
      });
  };

  const deleteImageOnClick = (image: VerticalServiceImage) => {
    setIsUploadImageDialogOpen(false);
    setDeletedImage(image);
  };

  const confirgDeleteImageOnClick = () => {
    const serviceId = (deletedImage as any)?.verticalService;
    const imageId = deletedImage?.id;
    if (serviceId && imageId) {
      setIsUploadImageDialogLoading(true);
      verticalService
        .deleteImage(serviceId, imageId)
        .then(() => {
          setIsUploadImageDialogLoading(true);
          getImagesByVerticalServiceId(selectedVerticalService);
          setDeletedImage(undefined);
          setIsUploadImageDialogOpen(true);
          setSnackbarMessage("Imagem excluída com sucesso");
          setOpenSnackbar(true);
          setIsUploadImageDialogLoading(false);
        })
        .catch(() => {
          setSnackbarMessage("Erro ao excluir imagem");
          setOpenSnackbar(true);
          setIsUploadImageDialogLoading(false);
        });
    } else {
      setSnackbarMessage("Erro ao excluir imagem");
      setOpenSnackbar(true);
    }
  };

  const cancelDeleteImageOnClick = () => {
    setDeletedImage(undefined);
    setIsUploadImageDialogLoading(false);
    setIsUploadImageDialogOpen(true);
  };

  const onOrderOfStepsPopUpClose = useCallback(() => {
    setIsOrderOfStepsPopUpOpen(false);
    setFindTableContentParams({ ...findTableContentParams, ...dates });
  }, [dates, findTableContentParams]);

  const onOrderOfStepsPopUpConfirm = (selectedTypes: ServiceLocation[]) => {
    if (
      orderOfStepsData?.newStartDate === undefined ||
      orderOfStepsData?.oldStartDate === undefined ||
      orderOfStepsData?.oldEndDate === undefined ||
      orderOfStepsData?.newEndDate === undefined ||
      orderOfStepsData?.changedServiceId === undefined
    )
      return;

    verticalService
      .updateServiceDatesByServiceTypes(
        constructionId,
        orderOfStepsData.changedServiceId,
        selectedTypes?.length ? selectedTypes.map(({ id }) => id) : [],
        orderOfStepsData.oldStartDate,
        orderOfStepsData.newStartDate,
        orderOfStepsData.oldEndDate,
        orderOfStepsData.newEndDate
      )
      .then(() => {
        setOpenSnackbar(true);
        setSnackbarMessage("Datas dos serviços atualizadas com sucesso");
        setIsOrderOfStepsPopUpOpen(false);
        setFindTableContentParams({ ...findTableContentParams, ...dates });
      })
      .catch(() => {
        setOpenSnackbar(true);
        setSnackbarMessage("Erro ao atualizar datas dos serviços");
      });
  };

  const getConstructionInitialLetters = (construction: string): string => {
    const array = construction.split(" ");
    let firstLetter = array[0]?.charAt(0);
    let lastLetter = array.length > 1 ? array[array.length - 1]?.charAt(0) : "";

    return `${firstLetter}${lastLetter}`;
  };

  const toggleImageOptions = () => setImageOptionsOpen(!imageOptionsOpen);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      imageOptionsRef.current &&
      !imageOptionsRef.current.contains(event.target as Node)
    )
      setImageOptionsOpen(false);
  };

  const handleFileSelect = (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    constructionService
      .updateImage(constructionId?.toString(), formData)
      .then((response) => {
        if (!response) return;
        setImageOptionsOpen(false);
        setConstructionImage(response);
        setSelectedFile(null);
        setOpenSnackbar(true);
        setSnackbarMessage("Imagem atualizada com sucesso!");
      })
      .catch((error) => {
        setOpenSnackbar(true);
        setSnackbarMessage("Erro ao atualizar a imagem");
      });
  };

  const handleDeleteImage = async () => {
    try {
      const deletionSuccessful =
        await constructionService.deleteConstructionImage(constructionId);
      if (deletionSuccessful) {
        await getConstructionImage(constructionId);
        setOpenSnackbar(true);
        setSnackbarMessage("Imagem excluída com sucesso");
      } else {
        setOpenSnackbar(true);
        setSnackbarMessage("Erro ao excluir a imagem");
      }
    } catch (error) {
      setOpenSnackbar(true);
      setSnackbarMessage("Erro ao excluir a imagem");
    } finally {
      setImageOptionsOpen(false);
    }
  };

  const getConstructionImage = useCallback(async (constructionId: number) => {
    try {
      const result = await api.get(
        `/api/constructions/image/${constructionId}`
      );
      setConstructionImage(result.data);
    } catch (error) {
      console.error(error);
      setConstructionImage("");
    }
  }, []);

  useEffect(() => {
    getConstructionImage(constructionId);
  }, [constructionId, getConstructionImage]);

  useEffect(() => {
    if (constructionId) {
      const constructionName = constructions.find(
        (construction) => construction.id === constructionId
      );
      setSelectedConstructionName(constructionName?.name || "");
    }
  }, [constructionId, constructions]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const getRestrictionMappingId = async (
    constructionId: number,
    restrictionId: number
  ): Promise<MappingItem[]> => {
    return await restrictionMappingService.getRestrictionsMappingByConstructionIdAndRestrictionid(
      constructionId,
      restrictionId
    );
  };

  const existsRestrictionMapping = async (
    restrictionId: string,
    constructionId: number
  ): Promise<MappingItem | undefined> => {
    try {
      const restricrionMapping = await getRestrictionMappingId(
        constructionId,
        Number(restrictionId)
      );
      return restricrionMapping[0];
    } catch (error) {
      return undefined;
    }
  };

  const createNewRestriction = async (
    newRestrictionName: string,
    newRestrictionMappingObject: IRestrictionMappingModel
  ) => {
    const restriction = {
      name: newRestrictionName || "",
      status: true,
      code: "",
      criteriaId: Number(newRestrictionMappingObject.criteriaId),
      userId: user.id,
    } as RestrictionFormEntity;
    return await restrictionService.createNewRestriction(restriction);
  };

  const newRestrictionMapping = async (
    restrictionId: string,
    newRestrictionMappingObject: IRestrictionMappingModel
  ): Promise<MappingItem> => {
    const { category, priority, impact } = newRestrictionMappingObject;

    if (priority === undefined) {
      setSnackbarMessage("Preencha todos os campos obrigatórios");
      setOpenSnackbar(true);
      throw new Error("Preencha todos os campos obrigatórios");
    }

    const restrictionMapping: MappingItem = {
      index: 0,
      criteriaId: Number(newRestrictionMappingObject.criteriaId),
      restrictionId: Number(restrictionId),
      constructionId: constructionId,
      userId: user.id,
      status: true,
      category: null,
      priority: priority,
      impact: null,
      comments: newRestrictionMappingObject.comment || "",
      addedByPlanning: false,
    };

    const restrictionMappingResult =
      await restrictionMappingService.createRestrictionsMapping([
        restrictionMapping,
      ]);
    return restrictionMappingResult[0];
  };

  const addOrUpdateActions = async (
    restrictionMappingId: number,
    restrictionMapping: MappingItem,
    isUpdate: boolean = false,
    actions: IActionModel[],
    newRestrictionMappingObject: IRestrictionMappingModel
  ) => {
    const validActions =
      isUpdate === false
        ? actions
            .filter((action) => ActionModel.isValid(action))
            .filter((action) => action.generatedByEmptyObject === true)
        : actions.filter((action) => ActionModel.isValid(action));

    const actionsAsActionItems: ActionItem[] = validActions?.length
      ? validActions.map((action, index) => {
          const { priority } = action;
          const { category, impact } = newRestrictionMappingObject;

          if (priority === undefined) {
            setSnackbarMessage("Preencha todos os campos obrigatórios");
            setOpenSnackbar(true);
            throw new Error("Preencha todos os campos obrigatórios");
          }

          const actionItem: ActionItem = {
            index: index,
            what: action.actionDescription,
            how: action.how || "",
            whenBegin: action.beginDate instanceof Date
              ? action.beginDate.toISOString()
              : new Date(action.beginDate).toISOString(),
            whenEnd: action.endDate instanceof Date
              ? action.endDate.toISOString()
              : new Date(action.endDate).toISOString(),
            category: category || null,
            priority: priority,
            impact: impact || null,
            status: false,
            userId: user.id,
            restrictionMappingId: restrictionMappingId,
            responsibleUsersId: action?.responsibles?.length
              ? action.responsibles.map(responsible => Number(responsible.id))
              : [],
            addedByPlanning: action.generatedByEmptyObject,
            verticalService: selectedVerticalService?.id,
            id: !action.generatedByEmptyObject && action.id
              ? Number(action.id)
              : undefined,
          };
          return actionItem;
        })
      : [];

    restrictionMappingService
      .createActions(actionsAsActionItems, [restrictionMapping])
      .then((result) => {
        setSnackbarMessage("Ações salvas com sucesso!");
        setOpenSnackbar(true);
      })
      .catch((error) => {
        setSnackbarMessage("Erro ao salvar ações");
        setOpenSnackbar(true);
      });
  };

  const createNewRestrictionMappingByNewRestriction = async (
    newRestrictionName: string,
    newRestrictionMappingObject: IRestrictionMappingModel,
    actions: IActionModel[]
  ) => {
    try {
      const newRestriction = await createNewRestriction(
        newRestrictionName,
        newRestrictionMappingObject
      );
      restrictionId = newRestriction.id.toString();
      const restrictionMapping = await newRestrictionMapping(
        restrictionId,
        newRestrictionMappingObject
      );
      const newActions = await addOrUpdateActions(
        restrictionMapping.id!!,
        restrictionMapping,
        false,
        actions,
        newRestrictionMappingObject
      );
    } catch (error) {
      console.error(error);
      setSnackbarMessage(
        "Ocorreu um erro ao criar um novo mapeamento de restrição com nova restrição"
      );
      setOpenSnackbar(true);
    }
  };

  const onSaveActions = async (
    selectedRestrictionId: string | null | undefined,
    newRestrictionName: string | undefined,
    actions: IActionModel[],
    newRestrictionMappingObject: IRestrictionMappingModel
  ) => {
    const validActions = actions.filter((action) => 
      ActionModel.isValid(action)
    );

    if (validActions.length === 0) {
      setSnackbarMessage(
        "Nenhuma ação está válida para salvar. Verifque se todas as ações estão preenchidas corretamente"
      );
      setOpenSnackbar(true);
      return;
    }

    if (selectedRestrictionId === null && newRestrictionName === undefined) {
      setSnackbarMessage("Nenhuma restrição selecionada");
      setOpenSnackbar(true);
      return;
    }

    if (newRestrictionName !== undefined) {
      createNewRestrictionMappingByNewRestriction(
        newRestrictionName,
        newRestrictionMappingObject,
        actions
      );
      return;
    }

    if (selectedRestrictionId) {
      const restricrionMapping = await existsRestrictionMapping(
        selectedRestrictionId,
        constructionId
      );
      if (restricrionMapping && restricrionMapping.id) {
        await addOrUpdateActions(
          restricrionMapping.id,
          restricrionMapping,
          false,
          actions,
          newRestrictionMappingObject
        );
      } else {
        await createNewRestrictionMappingAndNewActions(
          selectedRestrictionId,
          newRestrictionMappingObject,
          actions
        );
      }
    }
  };

  const createNewRestrictionMappingAndNewActions = async (
    restrictionId: string,
    newRestrictionMappingObject: IRestrictionMappingModel,
    actions: IActionModel[]
  ) => {
    const restrictipnMapping = await newRestrictionMapping(
      restrictionId,
      newRestrictionMappingObject
    );
    const newActions = await addOrUpdateActions(
      restrictipnMapping.id!!,
      restrictipnMapping,
      false,
      actions,
      newRestrictionMappingObject
    );
  };

  const onSubmmit = async (
    selectedRestrictionId: string | null | undefined,
    newRestrictionName: string | undefined,
    actions: IActionModel[],
    newRestrictionMappingObject: IRestrictionMappingModel
  ) => {
    if (state === BaseState.loading) {
      return;
    }
    setState(BaseState.loading);
    try {
      await onSaveActions(
        selectedRestrictionId,
        newRestrictionName,
        actions,
        newRestrictionMappingObject
      );
      setState(BaseState.success);
    } catch (error) {
      setState(BaseState.success);
      setSnackbarMessage("Erro ao salvar ações");
      setOpenSnackbar(true);
    }
    setIsAddRestrictionAndActionPlanDialogOpen(false);
    return;
  };

  const isTodayInRange = (startDate: Date, endDate: Date, byTime: string) => {
    const today = setStartOfDay(new Date().toISOString());

    if (byTime === "semanal") {
      const endOfDay = setEndOfDay(startDate.toISOString());
      return today >= startDate && today <= endOfDay;
    } else return today >= startDate && today < endDate;
  };

  const setStartOfDay = (date: string): Date => {
    const newDate = new Date(date);
    newDate.setHours(newDate.getHours() + 3);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };

  const setEndOfDay = (date: string): Date => {
    const newDate = new Date(date);
    newDate.setHours(newDate.getHours() + 3);
    newDate.setHours(23, 59, 59, 999);
    return newDate;
  };

  const isDateFilterActive = (() => {
    const savedFilters = localStorage.getItem("verticalServicesFilterValues");
    if (savedFilters) {
      const parsedFilters = JSON.parse(savedFilters);
      return !!(parsedFilters.beginDate && parsedFilters.endDate);
    }
    return false;
  })();

  const getLastRescheduling = (verticalServiceId: number) => {
    const filteredRescheduling = reschedulingData.filter((rescheduling) => {
      if (
        rescheduling.verticalService &&
        rescheduling.verticalService.id !== undefined
      )
        return rescheduling.verticalService.id === verticalServiceId;

      return false;
    });

    return filteredRescheduling.length > 0
      ? filteredRescheduling[filteredRescheduling.length - 1]
      : null;
  };

  const handleDuplicateClick = () => {
    setIsDuplicateDialogOpen(true);
  };

  const handleDuplicateConfirm = async (selectedConstructionId: number) => {
    try {
      setState(BaseState.loading);
      const result = await verticalService.duplicateService(constructionId, selectedConstructionId);
      if (result) {
        setOpenSnackbar(true);
        setSnackbarMessage("Serviços importados com sucesso");
        setFindTableContentParams({ ...findTableContentParams });
      }
    } catch (error) {
      setOpenSnackbar(true);
      setSnackbarMessage("Erro ao importar serviços");
    } finally {
      setState(BaseState.success);
      setIsDuplicateDialogOpen(false);
    }
  };

  return (
    <ReactPageBaseComponent>
      <Styles.PageContainer isNavBarHide={isPrintState}>
        {isConstructionNotSelected() ? (
          <Styles.CenterContainer>
            <EmptyClientAndConstructionState pageDescription="a vertical de serviços" />{" "}
          </Styles.CenterContainer>
        ) : null}

        {state === BaseState.loading && !isConstructionNotSelected() ? (
          <Styles.CenterContainer>
            <LoadingComponent />
          </Styles.CenterContainer>
        ) : null}

        {state === BaseState.error ? (
          <Styles.CenterContainer>
            <ErrorComponent />
          </Styles.CenterContainer>
        ) : null}

        {state === BaseState.success && !isConstructionNotSelected() ? (
          <Styles.ContentContainer ref={pdfRef}>
            <Styles.HeaderContainer>
              <Styles.AboutContainer>
                {constructionImage ? (
                  <Styles.ConstructionImage
                    onClick={toggleImageOptions}
                    src={constructionImage}
                  />
                ) : (
                  <Styles.ConstructionImagePlaceholder
                    onClick={toggleImageOptions}
                  >
                    {getConstructionInitialLetters(selectedConstructionName)}
                  </Styles.ConstructionImagePlaceholder>
                )}
                <Styles.TitleContainer>
                  <Styles.PageTitle>Vertical de Serviços</Styles.PageTitle>
                  <Styles.ConstructionNameTitle>
                    {selectedConstructionName}
                  </Styles.ConstructionNameTitle>
                </Styles.TitleContainer>

                {imageOptionsOpen && (
                  <div ref={imageOptionsRef}>
                    <ImageOptionsMenu
                      onDeleteService={handleDeleteImage}
                      onFileSelect={handleFileSelect}
                    />
                  </div>
                )}
              </Styles.AboutContainer>
              {!isPrintState && (
                <Styles.TimeContainer>
                  <Styles.GroupByTimeContainer>
                    <Styles.GroupByTimeText>
                      Agrupar serviços que se repetem no mesmo período
                    </Styles.GroupByTimeText>
                    <Styles.SwitchTime
                      checked={showItemsSameDataByGroup}
                      onChange={(event, checked) =>
                        onChangeGroupByTime(checked)
                      }
                    />
                  </Styles.GroupByTimeContainer>
                  <Styles.LastUpdateContainer>
                    <CheckIcon style={{ color: Colors.neutralColorWhite }} />
                    <Styles.LastUpdateText>
                      {lastUpdatedBy
                        ? `Última atualização em ${lastUpdate} por ${lastUpdatedBy}`
                        : `Última atualização em ${lastUpdate}`}
                    </Styles.LastUpdateText>
                  </Styles.LastUpdateContainer>
                </Styles.TimeContainer>
              )}
              <Header.ColRigth
                isPrintState={isPrintState}
                selectedClientName={selectedClientName}
                selectedConstructionName={selectedConstructionName}
                selectedTab={isDateFilterActive ? "custom" : selectedTab}
                timePeriodListState={timePeriodListState}
                dates={dates}
              />
            </Styles.HeaderContainer>

            {!isMobile && (
              <Styles.WhiteContainer>
                <Styles.DescriptionContainer>
                  <Styles.PageDescription>
                    Insira novo serviço ou clique na tabela para atualizar um
                    serviço vinculado
                  </Styles.PageDescription>
                  {!tableContent?.length ? (
                    <Styles.TableControllerComponent>
                      {isMobile && (
                        <Styles.TimePeriodFilterDropDownContainer>
                          <DropDownComponent
                            onSelectedValueChanged={(key, value) =>
                              handleOnTabChange(value)
                            }
                            key="mobileTabsEnum"
                            componentKey="mobileTabsEnum"
                            values={Object.values(TABS)}
                            defaultValue={selectedTab.toLowerCase()}
                            selectedValue={selectedTab.toLowerCase()}
                            label={"Visualizar por"}
                          />
                        </Styles.TimePeriodFilterDropDownContainer>
                      )}
                      <IconButtonComponent
                        onClick={handleTableControlPreviousClick}
                      >
                        <ArrowLeftIcon style={{ color: Colors.balticSea }} />
                      </IconButtonComponent>
                      <IconButtonComponent
                        onClick={handleTableControlNextClick}
                      >
                        <ArrowRightIcon style={{ color: Colors.balticSea }} />
                      </IconButtonComponent>
                      <Styles.IconButtonActionMenu
                        onClick={(e) => handleExportImportMenuClick(e)}
                        aria-controls={
                          exportImportMenuIsOpen ? "basic-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={
                          exportImportMenuIsOpen ? "true" : undefined
                        }
                      >
                        <ThreeDots style={{ color: Colors.balticSea }} />
                      </Styles.IconButtonActionMenu>
                      <Styles.StyledMenu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={exportImportMenuIsOpen}
                        onClose={handleExportImportMenuClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {!isMobile && (
                          <>
                            <Styles.StyledMenuItem
                              onClick={() =>
                                handleExportImportMenuClose(
                                  ThreeDotsIconEnum.PDF
                                )
                              }
                            >
                              <AddIcon /> Gerar programação
                            </Styles.StyledMenuItem>
                            <Styles.StyledMenuItem
                              onClick={() =>
                                handleExportImportMenuClose(
                                  ThreeDotsIconEnum.RESCHEDULING_PDF
                                )
                              }
                            >
                              <AddIcon /> Serviços reprogramados
                            </Styles.StyledMenuItem>
                          </>
                        )}
                        <Styles.StyledMenuItem
                          onClick={() =>
                            handleExportImportMenuClose(
                              ThreeDotsIconEnum.IMPORT_XLSX
                            )
                          }
                        >
                          <DownloadIcon /> Importar planilha
                        </Styles.StyledMenuItem>
                        <Styles.StyledMenuItem
                          onClick={() =>
                            handleExportImportMenuClose(
                              ThreeDotsIconEnum.DUPLICATE
                            )
                          }
                        >
                          <ContentCopyIcon /> Importar serviços
                        </Styles.StyledMenuItem>
                      </Styles.StyledMenu>
                    </Styles.TableControllerComponent>
                  ) : (
                    <Styles.ButtonEndAlignWrapper>
                      <ButtonComponent
                        state={ButtonState.DEFAULT_ENABLED}
                        startIcon={<AddOutlinedIcon />}
                        text={isMobile ? "Novo" : "Vincular novo serviço"}
                        onClick={() => onAddServiceClick()}
                        padding={{ top: 8, bottom: 8, left: 20, right: 20 }}
                      />
                    </Styles.ButtonEndAlignWrapper>
                  )}
                </Styles.DescriptionContainer>

                {tableContent.length > 0 ? (
                  <Styles.PhysicalContainer>
                    <Styles.AdvancementContainer>
                      <AdvancementIndexVerticalComponent
                        title="Índice de avanços físicos"
                        value={progressByConstruction?.physicalProgress || 0}
                        type={AdvancementIndexType.PHYSICAL}
                        budgetUsed={
                          progressByConstruction?.currentFinancialCoast || 0
                        }
                        showMaterialUserDescription={false}
                        showBudgetDescription={false}
                      />
                      <DateHeaderView
                        constructionBeginningDate={
                          constructionDates?.constructionBeginningDate
                        }
                        constructionEndDate={
                          constructionDates?.constructionEndDate
                        }
                        constructionDays={constructionDates?.constructionDays}
                        constructionWeekDays={
                          constructionDates?.constructionWeekDays
                        }
                      />
                    </Styles.AdvancementContainer>
                    <Styles.MarginRightContainer>
                      {!isMobile && (
                        <Styles.TableControllerComponent>
                          {!isDateFilterActive && (
                            <>
                              <IconButtonComponent
                                onClick={handleTableControlPreviousClick}
                              >
                                <ArrowLeftIcon
                                  style={{ color: Colors.balticSea }}
                                />
                              </IconButtonComponent>
                              <IconButtonComponent
                                onClick={handleTableControlNextClick}
                              >
                                <ArrowRightIcon
                                  style={{ color: Colors.balticSea }}
                                />
                              </IconButtonComponent>
                            </>
                          )}
                          <Styles.IconButtonActionMenu
                            onClick={(e) => handleExportImportMenuClick(e)}
                            aria-controls={
                              exportImportMenuIsOpen ? "basic-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={
                              exportImportMenuIsOpen ? "true" : undefined
                            }
                          >
                            <ThreeDots style={{ color: Colors.balticSea }} />
                          </Styles.IconButtonActionMenu>
                          <Styles.StyledMenu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={exportImportMenuIsOpen}
                            onClose={handleExportImportMenuClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            {!isMobile && (
                              <>
                                <Styles.StyledMenuItem
                                  onClick={() =>
                                    handleExportImportMenuClose(
                                      ThreeDotsIconEnum.PDF
                                    )
                                  }
                                >
                                  <AddIcon /> Gerar programação
                                </Styles.StyledMenuItem>
                                <Styles.StyledMenuItem
                                  onClick={() =>
                                    handleExportImportMenuClose(
                                      ThreeDotsIconEnum.RESCHEDULING_PDF
                                    )
                                  }
                                >
                                  <AddIcon /> Serviços reprogramados
                                </Styles.StyledMenuItem>
                              </>
                            )}
                            <Styles.StyledMenuItem
                              onClick={() =>
                                handleExportImportMenuClose(
                                  ThreeDotsIconEnum.IMPORT_XLSX
                                )
                              }
                            >
                              <DownloadIcon /> Importar planilha
                            </Styles.StyledMenuItem>
                            <Styles.StyledMenuItem
                              onClick={() =>
                                handleExportImportMenuClose(
                                  ThreeDotsIconEnum.DUPLICATE
                                )
                              }
                            >
                              <ContentCopyIcon /> Importar serviços
                            </Styles.StyledMenuItem>
                          </Styles.StyledMenu>
                        </Styles.TableControllerComponent>
                      )}
                    </Styles.MarginRightContainer>
                  </Styles.PhysicalContainer>
                ) : null}
              </Styles.WhiteContainer>
            )}
            <Styles.TableActionContainer>
              <Styles.TableHeader>
                {/* Left Table Action Header */}
                <Styles.TabContainer>
                  {isDateFilterActive &&
                  !!dates?.startDate &&
                  !!dates?.endDate ? (
                    <Tabs
                      value={isDateFilterActive ? "custom" : selectedTab}
                      onChange={(event, value) => handleOnTabChange(value)}
                      style={{ width: "100%", minHeight: 0 }}
                      sx={{
                        "& .MuiTabs-indicator": {
                          display: "none",
                        },
                        "& .MuiTab-root": {
                          color: Colors.neutralColorWhite,
                        },
                        "& .Mui-disabled": {
                          color: Colors.neutralColorWhite,
                          backgroundColor: Colors.doveGray,
                        },
                        "& .Mui-selected": {
                          backgroundColor: Colors.lightBlue,
                        },
                      }}
                    >
                      <Styles.CustomTab
                        value="custom"
                        label={`${dates?.startDate?.toLocaleDateString()} - ${dates?.endDate?.toLocaleDateString()}`}
                        sx={{
                          backgroundColor: Colors.lightBlue,
                          "&.Mui-selected": {
                            backgroundColor: Colors.lightBlue,
                          },
                        }}
                      />
                      <ButtonComponent
                        text={isMobile ? "" : "Limpar filtros"}
                        type={ButtonType.TRANSPARENT}
                        color={Colors.balticSea}
                        onClick={handleClearFilter}
                        startIcon={
                          <DeleteOutlineOutlined
                            style={{ color: Colors.balticSea }}
                          />
                        }
                      />
                    </Tabs>
                  ) : (
                    <Tabs
                      value={isDateFilterActive ? "custom" : selectedTab}
                      onChange={(event, value) => handleOnTabChange(value)}
                      style={{ width: "100%", minHeight: 0 }}
                      sx={{
                        "& .MuiTabs-indicator": {
                          display: "none",
                        },
                        "& .MuiTab-root": {
                          color: Colors.neutralColorWhite,
                        },
                        "& .Mui-disabled": {
                          color: Colors.neutralColorWhite,
                          backgroundColor: Colors.doveGray,
                        },
                        "& .Mui-selected": {
                          backgroundColor: Colors.lightBlue,
                        },
                      }}
                    >
                      <Styles.CustomTab
                        value={TABS.semanal}
                        label="Semanal"
                        disabled={!!isDateFilterActive}
                      />
                      <Styles.CustomTab
                        value={TABS.mensal}
                        label="Mensal"
                        disabled={!!isDateFilterActive}
                      />
                      <Styles.CustomTab
                        value={TABS.trimestral}
                        label="Trimestral"
                        disabled={!!isDateFilterActive}
                      />
                      <Styles.CustomTab
                        value={TABS.semestral}
                        label="Semestral"
                        disabled={!!isDateFilterActive}
                      />
                      <Styles.CustomTab
                        value={TABS.anual}
                        label="Anual"
                        disabled={!!isDateFilterActive}
                      />
                    </Tabs>
                  )}
                </Styles.TabContainer>
                {/* Rigth Table Action Header */}
                <Styles.ButtonActionsContainer>
                  {isMobile && (
                    <Styles.PageDescription>
                      Insira novo serviço ou clique na tabela para atualizar um
                      serviço vinculado
                    </Styles.PageDescription>
                  )}

                  <ButtonComponent
                    type={ButtonType.OUTLINE_SELECTED}
                    text={"Vertical de Serviços"}
                    startIcon={<Article />}
                    onClick={() => {}}
                    padding={{ top: 10, bottom: 10, left: 14, right: 20 }}
                  />
                  <ButtonComponent
                    type={ButtonType.OUTLINE}
                    startIcon={<Article />}
                    onClick={() => goToReport()}
                    text={"Relatório"}
                    padding={{ top: 10, bottom: 10, left: 14, right: 20 }}
                  />
                  <ButtonComponent
                    type={ButtonType.OUTLINE}
                    startIcon={<ShareOutlined />}
                    onClick={() => shareOnClick()}
                    text={"Exportar PDF"}
                    padding={{ top: 10, bottom: 10, left: 14, right: 20 }}
                  />
                  <ButtonComponent
                    type={ButtonType.OUTLINE}
                    startIcon={<FilterOutlined />}
                    onClick={() => filterOnClick()}
                    text={"Filtrar"}
                    padding={{ top: 10, bottom: 10, left: 14, right: 20 }}
                  />
                  {isMobile && (
                    <>
                      <Styles.TimePeriodFilterDropDownContainer>
                        <DropDownComponent
                          onSelectedValueChanged={(key, value) =>
                            handleOnTabChange(value)
                          }
                          key="mobileTabsEnum"
                          componentKey="mobileTabsEnum"
                          values={Object.values(TABS)}
                          defaultValue={selectedTab.toLowerCase()}
                          selectedValue={selectedTab.toLowerCase()}
                          variant="filled"
                          size="medium"
                          sx={{ width: "100%" }}
                          label={"Período"}
                        />
                      </Styles.TimePeriodFilterDropDownContainer>
                      <Styles.TableControllerComponent>
                        {!isDateFilterActive && (
                          <>
                            <IconButtonComponent
                              onClick={handleTableControlPreviousClick}
                            >
                              <ArrowLeftIcon
                                style={{ color: Colors.balticSea }}
                              />
                            </IconButtonComponent>
                            <IconButtonComponent
                              onClick={handleTableControlNextClick}
                            >
                              <ArrowRightIcon
                                style={{ color: Colors.balticSea }}
                              />
                            </IconButtonComponent>
                          </>
                        )}
                        <Styles.IconButtonActionMenu
                          onClick={(e) => handleExportImportMenuClick(e)}
                          aria-controls={
                            exportImportMenuIsOpen ? "basic-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={
                            exportImportMenuIsOpen ? "true" : undefined
                          }
                        >
                          <ThreeDots style={{ color: Colors.balticSea }} />
                        </Styles.IconButtonActionMenu>
                        {isMobile && (
                          <ButtonComponent
                            text={"Limpar filtros"}
                            type={ButtonType.OUTLINE}
                            color={Colors.balticSea}
                            onClick={handleClearFilter}
                            startIcon={
                              <DeleteOutlineOutlined
                                style={{ color: Colors.balticSea }}
                              />
                            }
                          />
                        )}

                        <Styles.StyledMenu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={exportImportMenuIsOpen}
                          onClose={handleExportImportMenuClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          {!isMobile && (
                            <>
                              <Styles.StyledMenuItem
                                onClick={() =>
                                  handleExportImportMenuClose(
                                    ThreeDotsIconEnum.PDF
                                  )
                                }
                              >
                                <AddIcon /> Gerar programação
                              </Styles.StyledMenuItem>
                              <Styles.StyledMenuItem
                                onClick={() =>
                                  handleExportImportMenuClose(
                                    ThreeDotsIconEnum.RESCHEDULING_PDF
                                  )
                                }
                              >
                                <AddIcon /> Serviços reprogramados
                              </Styles.StyledMenuItem>
                            </>
                          )}
                          <Styles.StyledMenuItem
                            onClick={() =>
                              handleExportImportMenuClose(
                                ThreeDotsIconEnum.IMPORT_XLSX
                              )
                            }
                          >
                            <DownloadIcon /> Importar planilha
                          </Styles.StyledMenuItem>
                          <Styles.StyledMenuItem
                            onClick={() =>
                              handleExportImportMenuClose(
                                ThreeDotsIconEnum.DUPLICATE
                              )
                            }
                          >
                            <ContentCopyIcon /> Importar serviços
                          </Styles.StyledMenuItem>
                        </Styles.StyledMenu>
                      </Styles.TableControllerComponent>
                    </>
                  )}
                </Styles.ButtonActionsContainer>
              </Styles.TableHeader>
            </Styles.TableActionContainer>

            {!!tableContent?.length ? (
              <Styles.SliderContainer>
                <Styles.TableContainer>
                  <Styles.HorizontalListPrimaryContainer>
                    <Styles.TimeRangeContainer>
                      <Styles.VerticalListRoot
                        columnQuantity={timePeriodListState?.length}
                        tableLength={tableContent?.length}
                      >
                        {!tableContent?.length ? (
                          isMobile ? (
                            <div />
                          ) : (
                            <div style={{ width: "100px" }} />
                          )
                        ) : (
                          <Styles.EmptyGridItem isPrintState={isPrintState} />
                        )}

                        {!!timePeriodListState?.length &&
                          timePeriodListState.map((item) => {
                            const startDate = setStartOfDay(
                              item.startDateAsDate
                            );
                            const endDate = setEndOfDay(item.endDateAsDate);

                            const isTodayHighlighted = isTodayInRange(
                              startDate,
                              endDate,
                              tableData.byTime
                            );

                            return (
                              <Styles.ItemVerticalListHeader
                                key={item?.space}
                                style={{
                                  minWidth: isMobile ? "90px" : "auto",
                                  maxWidth: "100%",
                                  borderTopLeftRadius: "16px",
                                  borderTopRightRadius: "16px",
                                  padding: "8px",
                                  backgroundColor: isTodayHighlighted
                                    ? Colors.alto
                                    : Colors.neutralColorWhite,
                                  fontWeight: isTodayHighlighted ? 700 : 400,
                                  fontSize: "12px",
                                  // timePeriodListState.length > 10
                                  //   ? "12px"
                                  //   : "14px",
                                  borderTop: `1px solid ${Colors.lightMercury}`,
                                  borderLeft: `1px solid ${Colors.lightMercury}`,
                                  borderRight: `1px solid ${Colors.lightMercury}`,
                                  display: "flex",
                                  justifyContent: "center",
                                  textWrap: "wrap",
                                }}
                              >
                                {getPeriodBySelectedTab(
                                  setStartOfDay(item.startDateAsDate),
                                  setEndOfDay(item.endDateAsDate)
                                )}
                              </Styles.ItemVerticalListHeader>
                            );
                          })}
                      </Styles.VerticalListRoot>
                    </Styles.TimeRangeContainer>

                    <Styles.HorizontalListContainer
                      gridTemplate={`7fr`}
                      tableLength={tableContent.length}
                    >
                      {!!tableContent?.length &&
                        tableContent.map((serviceType) => {
                          return (
                            <React.Fragment key={serviceType.id}>
                              <Styles.ItemHeadTypeIndicator>
                                {serviceType.name}
                              </Styles.ItemHeadTypeIndicator>
                              <Styles.FullItemContainer
                                columnQuantity={timePeriodListState.length}
                              >
                                {timePeriodListState?.length &&
                                  timePeriodListState.map((value, index) => {
                                    return (
                                      <Styles.ItemHeaderGridContainerEmpty
                                        key={"timePeriodList - " + index}
                                        isPrintState={isPrintState}
                                      />
                                    );
                                  })}
                              </Styles.FullItemContainer>

                              {!!serviceType?.services?.length &&
                                serviceType?.services?.map((item) => {
                                  let previousGridItem: any = null;

                                  return (
                                    <React.Fragment
                                      key={item.uniqueApplicationId}
                                    >
                                      <Styles.ItemHeadServiceIndicator>
                                        {item.name}
                                      </Styles.ItemHeadServiceIndicator>

                                      <Styles.FullItemEmptyContainerCustomGrid
                                        gridTemplate={
                                          (item as any).gridTemplate
                                        }
                                      >
                                        {(item as any)?.gridItems.map(
                                          (gridItem: any, index: any) => {
                                            const showIcon =
                                              index === 0 ||
                                              (previousGridItem?.isEmpty &&
                                                !gridItem.isEmpty) ||
                                              (previousGridItem &&
                                                gridItem.id !==
                                                  previousGridItem.id);

                                            previousGridItem = gridItem;

                                            const lastRescheduling =
                                              gridItem?.subItems?.length > 0 &&
                                              !gridItem.isEmpty
                                                ? getLastRescheduling(
                                                    gridItem.subItems[0]?.id
                                                  )
                                                : null;

                                            return (
                                              <TableItem
                                                key={gridItem?.id}
                                                gridItem={gridItem}
                                                index={index}
                                                showIcon={showIcon}
                                                lastRescheduling={
                                                  lastRescheduling
                                                }
                                                serviceType={serviceType}
                                                item={item}
                                                onMouseOver={onMouseOver}
                                                onMouseLeave={onMouseLeave}
                                                serviceOnClick={serviceOnClick}
                                                hoveredItem={hoveredItem}
                                                clickedItem={clickedItem}
                                                moreItemsOnClick={
                                                  moreItemsOnClick
                                                }
                                                getServiceTypeOnClick={
                                                  getServiceTypeOnClick
                                                }
                                                showOnlyOneService={
                                                  showItemsSameDataByGroup
                                                }
                                                serviceClickEditActionCallback={
                                                  serviceClickEditActionCallback
                                                }
                                                serviceClickDeleteActionCallback={
                                                  serviceClickDeleteActionCallback
                                                }
                                                openUploadImageDialog={
                                                  openUploadImageDialog
                                                }
                                                openUpdateServiceProgressDialog={
                                                  openUpdateServiceProgressDialog
                                                }
                                                addPurchasingContractingPlanOnClick={
                                                  addPurchasingContractingPlanOnClick
                                                }
                                                openAddRestrictionAndActionPlanDialog={
                                                  openAddRestrictionAndActionPlanDialog
                                                }
                                                gridLength={
                                                  timePeriodListState.length
                                                }
                                              />
                                            );
                                          }
                                        )}
                                      </Styles.FullItemEmptyContainerCustomGrid>
                                    </React.Fragment>
                                  );
                                })}
                            </React.Fragment>
                          );
                        })}
                    </Styles.HorizontalListContainer>
                  </Styles.HorizontalListPrimaryContainer>
                </Styles.TableContainer>
              </Styles.SliderContainer>
            ) : null}
            {tableContent?.length === 0 ? (
              <Styles.QuadroAcompanhamento>
                <AlertTriangle />
                <Styles.Texto>
                  Você ainda não possui serviços cadastrados na data
                  selecionada. <br></br>Para adicionar um novo serviço, clique
                  no botão abaixo.
                </Styles.Texto>
                {!isMobile && (
                  <ButtonComponent
                    state={ButtonState.DEFAULT_ENABLED}
                    text={isMobile ? "Novo" : "Vincular novo serviço"}
                    onClick={() => onAddServiceClick()}
                    padding={{ top: 8, bottom: 8, left: 20, right: 20 }}
                  />
                )}
              </Styles.QuadroAcompanhamento>
            ) : null}

            {!!isPrintState && !!isPrintStateFooterVisible && (
              <Styles.PrintStateButtonContainer>
                <ButtonComponent
                  state={ButtonState.DEFAULT_ENABLED}
                  type={ButtonType.OUTLINE}
                  text="Sair"
                  onClick={() => setDefaultStateOnClick()}
                />
                <VerticalServicePageToPdf
                  tableContent={tableContent}
                  constructionDates={constructionDates}
                  showItemsSameDataByGroup={false}
                  progressByConstruction={progressByConstruction}
                  selectedTab={isDateFilterActive ? "custom" : selectedTab}
                  timePeriodListState={timePeriodListState}
                  dates={datesPdf}
                />
              </Styles.PrintStateButtonContainer>
            )}
          </Styles.ContentContainer>
        ) : null}

        <Footer />
      </Styles.PageContainer>
      <AddServicePopUp
        serviceTypes={servicesTypes}
        isOpen={!!isDialogOpen}
        onClose={onCloseDialog}
        onConfirm={onConfirmDialog}
        userResponsibles={responsibleUsers}
        isLoadingDialog={addServiceDialogLoading}
        model={editActionItem}
        ref={addServiceDialogRef}
        onDeleteCustomService={onDeleteCustomServiceType}
      />
      <AddRestrictionAndActionPlanPopUp
        isOpen={isAddRestrictionAndActionPlanDialogOpen}
        onClose={closeAddRestrictionAndActionPlanDialog}
        onConfirm={onSubmmit}
        userResponsibles={responsibleUsers}
        isLoadingDialog={addServiceDialogLoading}
        model={selectedVerticalService}
        serviceType={selectedDayServicesItems}
      />
      <Snackbar
        message={snackbarMessage}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => {
          setOpenSnackbar(false);
        }}
      />
      <DayServicesListPopUp
        isOpen={isDayServicesDialogOpen && dayServicesItems.data.length > 0}
        groupColor={dayServicesItems.serviceGroupColor}
        data={dayServicesItems.data}
        serviceType={dayServicesItems.serviceType}
        closeOnClick={() => closeListActionsPopUp()}
        editServiceOnClick={(item) => editServiceOnClick?.(item)}
        deleteServiceOnClick={(item) => deleteServiceOnClick?.(item)}
        uploadImageOnClick={(item) => openUploadImageDialog(item)}
        updateServiceProgressOnClick={(item) =>
          openUpdateServiceProgressDialog(item)
        }
      />
      <ConfirmDeleteItemPopUp
        title="Excluir serviço"
        description="Deseja realmente excluir este serviço?"
        openDeleteActionDialog={openDeleteActionDialog}
        isLoadingDialog={isDeleteActionLoadingDialog}
        dialogButtonCancelOnClick={deleteActionialogButtonCancelOnClick}
        dialogButtonConfirmOnClick={deleteActionialogButtonConfirmOnClick}
      />
      <ConfirmDeleteItemPopUp
        title="Excluir imagem"
        description="Deseja realmente excluir esta imagem?"
        openDeleteActionDialog={deletedImage !== undefined}
        isLoadingDialog={isDeleteActionLoadingDialog}
        dialogButtonCancelOnClick={() => cancelDeleteImageOnClick()}
        dialogButtonConfirmOnClick={confirgDeleteImageOnClick}
      />
      {isFilterDrawerOpen && (
        <React.Fragment>
          <SwipeableDrawer
            anchor={"right"}
            open={isFilterDrawerOpen}
            onClose={() => onCloseFilterDrawer()}
            onOpen={() => {}}
          >
            <FilterSideBar
              responsibleList={responsibleUsers}
              locationList={
                locations?.length
                  ? locations.map((location) => ({
                      id: location,
                      name: location,
                    }))
                  : []
              }
              serviceList={services}
              beginDate={
                dates?.startDate || setStartOfDay(new Date().toISOString())
              }
              endDate={dates?.endDate || setEndOfDay(new Date().toISOString())}
              onCloseFilterSideBar={() => onCloseFilterDrawer()}
              onApplyFilter={(filter) => onApplyFilter(filter)}
              cleanFilter={cleanFilter}
              handleClearFilter={() => setCleanFilter(false)}
            />
          </SwipeableDrawer>
        </React.Fragment>
      )}
      <AddImagesPopUp
        isLoading={isUploadImageDialogLoading}
        isOpen={isUploadImageDialogOpen}
        images={selectedVerticalServiceImages}
        verticalServiceModel={selectedVerticalService}
        cancelOnClick={() => closeUploadImageDialog()}
        confirmOnClick={(images) =>
          uploadImagesConfirmOnClick(images, selectedVerticalService)
        }
        deleteImageOnClick={(image) => deleteImageOnClick(image)}
      />
      <UpdateServiceProgressPopUp
        isOpen={isUpdateServiceProgressDialogOpen}
        isLoading={isUpdateServiceProgressDialogOpenLoading}
        verticalServiceModel={selectedVerticalService}
        currentProgress={progressByService}
        cancelOnClick={() => closeUpdateServiceProgressDialog()}
        confirmOnClick={(model) => updateServiceProgressConfirmOnClick(model)}
        confirmUpdatePastProgress={(
          serviceId,
          progressUpdateId,
          newValue,
          updateType
        ) =>
          updatePastServiceProgressConfirmOnClick(
            serviceId,
            progressUpdateId,
            newValue,
            updateType
          )
        }
        serviceType={selectedDayServicesItems}
      />
      <ImportExcelFilePopUp
        isOpen={showImportXLSXDialog}
        isLoading={isLoadingShowImportXLSXDialog}
        cancelOnClick={() => setShowImportXLSXDikalog(false)}
        confirmOnClick={(file) => importXLSXConfirmOnClick(file)}
        dowloadXLSXTemplate={() => dowloadExcelOnClick()}
      />
      <OrderOfStepsEditServicePopUp
        verticalServiceGroups={servicesTypesByRegisteredServices}
        isOpen={isOrderOfStepsPopUpOpen}
        isLoading={orderOfStepsPopUpLoading}
        onClose={() => onOrderOfStepsPopUpClose()}
        onConfirm={(selectedTypes) => onOrderOfStepsPopUpConfirm(selectedTypes)}
        cancelOnClick={() => onOrderOfStepsPopUpClose()}
      />
      <DuplicateServicesPopUp
        isOpen={isDuplicateDialogOpen}
        constructions={constructions.filter(c => c.id !== constructionId)} // Removendo apenas a construção atual
        clients={clients}
        onClose={() => setIsDuplicateDialogOpen(false)}
        onConfirm={handleDuplicateConfirm}
        currentConstructionId={constructionId}
      />
    </ReactPageBaseComponent>
  );
};

export default VerticalServicePage;
