import { StyleSheet } from "@react-pdf/renderer";
import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";

const stylesPdf = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    color: "#000",
    padding: "10px",
  },
  section: {
    margin: 0,
    padding: 0,
    marginBottom: '20px'
  },
  viewer: {
    width: "100%", //the pdf viewer will take up all of the width and height
    height: "70vh",
  },
  title: {
    fontSize: "10px",
    textAlign: "center",
    marginTop: "10px",
  },
  construction: {
    fontSize: "8px",
    textAlign: "center",
    marginBottom: "5px",
  },
  logo: {
    position: "absolute",
    top: "10px",
    right: "3px",
    height: "20px",
    width: "95px",
  },
  logoCliente: {
    position: "absolute",
    top: "-30px",
    left: "0px",
    width: "95px",
  },
  updateText: {
    fontSize: "7px",
    marginBottom: "4px",
  },
  criteriaName: {
    fontSize: "11px",
    marginBottom: "3px",
    marginTop: "7px",
  },
  table: {
    marginTop: "20px",
  },
  tr: {
    padding: "3px",
    fontSize: "8px",
    fontWeight: "bold",
    backgroundColor: "#d3d3d3",
  },
  td: {
    fontSize: "8px",
    padding: "3px",
    alignItems: "center",
    justifyContent: "center",
  },
  tdCenter: {
    fontSize: "8px",
    padding: "3px",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  pagination: {
    marginTop: "20px",
    marginRight: "20px",
    fontSize: "8px",
    textAlign: "right",
  },
});

export { stylesPdf };

export const PageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  @media(max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    align-items: start;
    gap: 18px;
  }
`

export const ManagerContainer = styled.div`
  display: flex;
  gap: 10px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
  margin-top: 24px;
  gap: 16px;
`

export const ManagerProfile = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border-radius: 50%;
`

