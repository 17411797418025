export const UnitValues = [
  "M",
  "M³",
  "M²",
  "H",
  "H prod.",
  "Kg",
  "Litros",
  "Und",
  "Vb",
  "Cx",
  "Loc/Mês",
];
