import styled from "styled-components";
import { Colors } from "../../theme/variants";
import { ErrorOutline } from "@mui/icons-material";

//import Lottie from 'react-lottie';

interface Props {}

const ErrorComponent: React.FC<Props> = ({}) => {
  return (
    <Container>
      <ErrorOutline
        style={{ height: "48px", width: "48px", color: Colors.primaryColor }}
      />
      <LoadingText>
        {"Aconteceu um erro. \n Tente novamente em instantes"}
      </LoadingText>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  width: fit-content;
  height: fit-content;
  grid-template-rows: min-content auto;
  justify-items: center;
  align-self: center;
  justify-self: center;
`;

const LoadingText = styled.div`
  color: ${Colors.primaryColor};
  font-size: 14px;
  font-family: "Noto Sans";
  font-weight: 600;
  word-wrap: break-word;
  justify-self: center;
  align-self: center;
  margin-top: 24px;
  white-space: pre-line;
  text-align: center;
`;

export default ErrorComponent;
