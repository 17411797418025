import { Colors } from "../../../theme/v2/variants";

interface DividerProps {
  color?: string;
  marginBottom?: string;
  marginTop?: string;
  width?: string;
}

const Divider: React.FC<DividerProps> = ({
  color = `${Colors.neutralColorLight}`,
  marginBottom = "0px",
  marginTop = "0px",
  width = "100%",
}) => {
  return (
    <div
      style={{
        width: width,
        height: "1px",
        backgroundColor: color,
        marginBottom: marginBottom,
        marginTop: marginTop,
      }}
    ></div>
  );
};

export default Divider;
