import moment from "moment";

export const formatDateToISODateOnly = (
  dateString?: string
): string | undefined => {
  if (!dateString) return undefined;
  return moment(dateString).format("YYYY-MM-DD");
};

export const formatDateToDDMMYYYY = (
  dateString?: string
): string | undefined => {
  if (!dateString) return undefined;
  return moment(dateString).format("DD/MM/YYYY");
};
