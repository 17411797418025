import styled from "styled-components";
import { TableActionsENUM } from "../models/TableActionsENUM";
import { ReactComponent as EditIcon } from "../icons/Edit.svg";
import { ReactComponent as DeleteIcon } from "../icons/Delete.svg";
import { ReactComponent as SelectItem } from "../icons/chevron-red.svg";
import {
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { Colors } from "../../../theme/variants";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";
import { useEffect, useState } from "react";
import { ReactComponent as ThreeDotsIcon } from "../icons/three_dots.svg";
import React from "react";

interface TableItemProps {
  item: any;
  actionList: TableActionsENUM[] | undefined;
  onClick?: (action: TableActionsENUM) => void;
}

const TableItemAction: React.FC<TableItemProps> = ({
  item,
  actionList,
  onClick,
}) => {
  const [isMobile, setIsMobile] = useState<boolean>();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleResize = () => {
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    if (isMobile === undefined) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);
  });

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (
    event: Event | React.SyntheticEvent,
    item?: TableActionsENUM
  ) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);

    if (item) {
      onClick?.(item);
    }
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  return (
    <TableItemContainer itemNumber={actionList?.length || 0}>
      {!isMobile &&
        actionList?.map((item: TableActionsENUM) =>
          item === TableActionsENUM.DELETE ? (
            <IconButton onClick={() => onClick?.(item)}>
              <DeleteIcon color="#000" />
            </IconButton>
          ) : item === TableActionsENUM.SELECT ? (
            <IconButton onClick={() => onClick?.(item)}>
              <SelectItem style={{ transform: "rotate(180deg)" }} />
            </IconButton>
          ) : (
            <IconButton onClick={() => onClick?.(item)}>
              <EditIcon />
            </IconButton>
          )
        )}
      {isMobile && (
        <IconButton onClick={handleToggle} ref={anchorRef}>
          <ThreeDotsIcon />
        </IconButton>
      )}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                  sx={{
                    "& .MuiMenu-paper": {
                      fontSize: "14px",
                      backgroundColor: Colors.neutralColorWhite,
                    },
                  }}
                >
                  {actionList?.map((item: TableActionsENUM) =>
                    item === TableActionsENUM.DELETE ? (
                      <MenuItem onClick={(event) => handleClose(event, item)}>
                        Excluir
                      </MenuItem>
                    ) : item === TableActionsENUM.SELECT ? (
                      <MenuItem onClick={(event) => handleClose(event, item)}>
                        Selecionar
                      </MenuItem>
                    ) : (
                      <MenuItem onClick={(event) => onClick?.(item)}>
                        Editar
                      </MenuItem>
                    )
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </TableItemContainer>
  );
};
const TableItemContainer = styled.div<{ itemNumber: number }>`
  display: grid;
  background-color: ${Colors.neutralColorWhite};

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(
      ${({ itemNumber = 0 }) => itemNumber},
      max-content
    );
    height: fit-content;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT - 1}px) {
    width: 40px;
    height: 50px;
  }
`;

// const StyledText = styled.div`
//   color: ${colors.text};
//   font-family: "Open Sans";
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 120%; /* 16.8px */
// `;

export default TableItemAction;
