import CheckIcon from "@mui/icons-material/Check";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import {
  Checkbox,
  FormControlLabel,
  TextField as MuiTextField,
  Tab,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { spacing } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";
import { Field, Formik } from "formik";
import React, { useEffect, useImperativeHandle, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import ReactBaseComponent from "../../../components/BaseComponents/ReactBaseComponent";
import CustomDropDownComponent, {
  ItemType,
} from "../../../components/InputValues/CustomDropDownComponent";
import DropDownComponent from "../../../components/InputValues/DropDownComponent";
import InputDateComponent from "../../../components/InputValues/input-date/InputDateComponent";
import InputValueComponent from "../../../components/InputValues/InputValueComponent";
import LoadingComponent from "../../../components/StateComponents/LoadingComponent";
import ButtonComponent, {
  ButtonState,
  ButtonType,
} from "../../../components/v2/Button/ButtonComponent";
import VerticalServiceModel from "../../../services/interfaces/VerticalServiceModel";
import { UserResponsible } from "../../../services/UserService";
import { Colors } from "../../../theme/v2/variants";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";
import ContractingModel from "../../PurchasingContractingPlanPage/models/ContractingMode";
import { PurchasingContractingENUM } from "../../PurchasingContractingPlanPage/models/PurchasingContractingENUM";
import PurchasingModel from "../../PurchasingContractingPlanPage/models/PurchasingModel";
import { UnitValues } from "../utils";
import { parseAsUTCWithOffset } from "../../../utils/date";

export interface NewServiceType {
  name: string;
  groupId: number;
}

export enum AddVerticalServiceENUM {
  PURCHASING = "Compras",
  CONTRACTING = "Contratação",
  VERTICAL = "Vertical",
}

interface AddServicePopUpProps {
  isOpen: boolean;
  serviceTypes: any[];
  onClose?: () => void;
  onConfirm?: (
    model: VerticalServiceModel,
    newServiceType?: NewServiceType,
    purchasingModel?: PurchasingModel,
    contractingModel?: ContractingModel,
    reschedulingReason?: string
  ) => void;
  userResponsibles?: UserResponsible[];
  isLoadingDialog?: boolean;
  model?: VerticalServiceModel;
  ref: any;
  onDeleteCustomService?: (id: string) => void;
}

export interface AddServicePopUpRef {
  cleanData: () => void;
}

const yupSchema = Yup.object().shape({
  product: Yup.string().when("selectedTab", {
    is: PurchasingContractingENUM.PURCHASING,
    then: Yup.string().required("Campo obrigatório"),
  }),
  service: Yup.string().when("selectedTab", {
    is: PurchasingContractingENUM.CONTRACTING,
    then: Yup.string().required("Campo obrigatório"),
  }),
  quantity: Yup.number()
    .required("Campo obrigatório")
    .when("selectedTab", {
      is: PurchasingContractingENUM.PURCHASING,
      then: Yup.number().required("Campo obrigatório"),
    }),
  unit: Yup.string()
    .required("Campo obrigatório")
    .when("selectedTab", {
      is: PurchasingContractingENUM.PURCHASING,
      then: Yup.string().required("Campo obrigatório"),
    }),
  specifications: Yup.string().required("Campo obrigatório"),
});

const AddServicePopUp = React.forwardRef<
  AddServicePopUpRef | undefined,
  AddServicePopUpProps
>(({ ...props }, ref) => {
  const {
    isOpen,
    onClose,
    onConfirm,
    serviceTypes,
    userResponsibles,
    isLoadingDialog,
    model,
    onDeleteCustomService,
  } = props;

  const [data, setData] = React.useState<VerticalServiceModel>(
    model ? model : new VerticalServiceModel()
  );
  const [newServiceType, setNewServiceType] = React.useState<NewServiceType>();
  const [selecedServiceType, setSelectedServiceType] =
    React.useState<ItemType>();

  const [isReschedulingVisible, setIsReschedulingVisible] = useState(false);

  const reschedulingReasons = [
    "Chuva e consequências",
    "Equipamento em manutenção",
    "Equipamento indisponível",
    "Falta de energia",
    "Falta de água",
    "Frente não liberada",
    "Falta de mão de obra",
    "Baixa produtividade",
    "Falta de material",
    "Atividade fora da programação",
    "Condições Inseguras",
    "Paralisação do serviço",
    "Fiscalização órgãos competentes",
    "Paralisação acidente de trabalho",
    "Estrutura não atingiu resistência",
    "Antecipação de serviço",
    "Inconformidade nas documentações",
    "Mudança no plano de ataque",
  ];

  const [reschedulingReasonValue, setReschedulingReasonValue] =
    React.useState<string>("");

  useImperativeHandle(ref, () => ({
    cleanData: () => {
      cleanData();
    },
  }));

  useEffect(() => {
    setData(
      model
        ? {
            ...model,
            beginDate: model?.beginDate
              ? parseAsUTCWithOffset(model.beginDate.toString())
              : undefined,
            endDate: model?.endDate
              ? parseAsUTCWithOffset(model.endDate.toString())
              : undefined,
          }
        : new VerticalServiceModel()
    );

    if (model)
      serviceTypes.find((serviceGroup) => {
        const service = serviceGroup.services.find(
          ({ id }: { id: number }) => id === model.verticalServiceTypeId
        );
        if (service)
          setSelectedServiceType({
            group: serviceGroup?.name,
            color: serviceGroup?.color,
            id: service?.id,
            type: service?.name,
            addByUser: service?.isAddByUser === true,
          });
      });
  }, [model, serviceTypes]);

  const handleUnityChange = (key: string, value: string) => {
    data.unit = value;
    setData({
      ...data,
      unit: data.unit,
    });
  };

  const handleQuantityChange = (value: string) => {
    data.quantity = Number.parseFloat(value);
    setData({
      ...data,
      quantity: data?.quantity,
    });
  };

  const handleLocationChange = (location: string) => {
    setData({
      ...data,
      location,
    });
  };

  const handleResponsibleChange = (key: string, value: string) => {
    const id = userResponsibles?.filter(({ name }) => name === value)[0]?.id;

    if (id !== undefined) {
      data.responsibleUserId = Number.parseInt(id);
      data.responsibleUserName = value;
      setData({
        ...data,
        responsibleUserId: data?.responsibleUserId,
        responsibleUserName: data?.responsibleUserName,
      });
    }
  };

  const handleReschedulingChange = (key: string, value: string) =>
    setReschedulingReasonValue(value);

  const handleStartDateChange = (date: any) => {
    const startDate = new Date(date);
    let modelDate = new Date(model?.beginDate || new Date());

    if (data?.endDate !== undefined && startDate > data?.endDate)
      data.endDate = new Date(startDate);

    setData({
      ...data,
      beginDate: startDate,
      endDate: data?.endDate,
    });

    if (
      model &&
      data?.endDate &&
      startDate.getTime() === modelDate.getTime() &&
      data?.endDate?.getTime() ===
        new Date(model?.endDate || new Date()).getTime()
    )
      setIsReschedulingVisible(false);
    else setIsReschedulingVisible(true);
  };

  const handleEndDateChange = (date: any) => {
    let endDate = new Date(date);
    let modelDate = new Date(model?.endDate || new Date());

    if (data?.beginDate !== undefined && endDate < data?.beginDate)
      endDate = data?.beginDate;

    setData({
      ...data,
      endDate,
    });

    if (
      model &&
      data?.beginDate &&
      endDate.getTime() === modelDate.getTime() &&
      data.beginDate?.getTime() ===
        new Date(model?.beginDate || new Date()).getTime()
    )
      setIsReschedulingVisible(false);
    else setIsReschedulingVisible(true);
  };

  const handleServiceTypeChange = (
    key: string,
    value: string,
    addedByUser: boolean
  ) => {
    const group = serviceTypes.filter(({ name }) => name === key);
    const service = group[0]?.services.filter(
      (service: { name: string }) => service?.name === value
    );
    if (addedByUser) {
      data.verticalServiceGroupId = group[0]?.id;
      data.description = value;
      setNewServiceType({ name: value, groupId: group[0]?.id });
      setData({
        ...data,
        verticalServiceGroupId: data?.verticalServiceGroupId,
        description: data?.description,
      });
      return;
    }

    data.description = service[0]?.name;
    data.verticalServiceGroupId = group[0]?.id;
    data.verticalServiceTypeId = service[0]?.id;
    setData({
      ...data,
      description: data?.description,
      verticalServiceGroupId: data?.verticalServiceGroupId,
      verticalServiceTypeId: data?.verticalServiceTypeId,
    });
  };

  const handleWeekdaysOnlyChange = (value: boolean) => {
    setData({
      ...data,
      weekdaysOnly: value,
    });
  };

  const cleanData = () => {
    setNewServiceType(undefined);
    setReschedulingReasonValue("");
  };

  const handleOnConfirm = (values: any) => {
    if (isLoadingDialog) return;

    let purchasingModel: PurchasingModel | undefined = undefined;
    let contractingModel: ContractingModel | undefined = undefined;

    if (values.product !== "")
      purchasingModel = {
        name: values?.product,
        quantity: values?.quantity,
        unit: values?.unit,
        specifications: values?.purchasing_specifications,
        services: [],
      };

    if (values.service !== "")
      contractingModel = {
        name: values?.service,
        specifications: values?.contracting_specifications,
        services: [],
      };

    onConfirm?.(
      { ...data, weekdaysOnly: values?.weekdaysOnly },
      newServiceType,
      purchasingModel,
      contractingModel,
      reschedulingReasonValue
    );

    setIsReschedulingVisible(false);
    setReschedulingReasonValue("");
  };

  const handleSubmit = (values: any) => {};

  const isModelUpdated = () => {
    if (!model) return false;
    const normalizedModelBeginDate = model?.beginDate
      ? new Date(model?.beginDate).toISOString()
      : null;
    const normalizedModelEndDate = model?.endDate
      ? new Date(model?.endDate).toISOString()
      : null;
    const normalizedDataBeginDate = data?.beginDate
      ? new Date(data?.beginDate).toISOString()
      : null;
    const normalizedDataEndDate = data?.endDate
      ? new Date(data?.endDate).toISOString()
      : null;

    return (
      model?.location !== data?.location ||
      model?.responsibleUserName !== data?.responsibleUserName ||
      model?.quantity !== data?.quantity ||
      model?.unit !== data?.unit ||
      model?.description !== data?.description ||
      normalizedModelBeginDate !== normalizedDataBeginDate ||
      normalizedModelEndDate !== normalizedDataEndDate ||
      model?.weekdaysOnly !== data?.weekdaysOnly
    );
  };

  const isNewDataValid = (values: {
    service: string;
    quantity: string;
    unit: string;
    purchasing_specifications: string;
    contracting_specifications: string;
    product: string;
    selectedTab: AddVerticalServiceENUM;
    weekdaysOnly: boolean;
  }) => VerticalServiceModel.isValid(data);

  const getButtonState = (values: {
    service: string;
    quantity: string;
    unit: string;
    purchasing_specifications: string;
    contracting_specifications: string;
    product: string;
    selectedTab: AddVerticalServiceENUM;
    weekdaysOnly: boolean;
  }) => {
    if (model)
      return isModelUpdated()
        ? ButtonState.DEFAULT_ENABLED
        : ButtonState.DISABLED;

    return isNewDataValid(values)
      ? ButtonState.DEFAULT_ENABLED
      : ButtonState.DISABLED;
  };

  return (
    <ReactBaseComponent>
      <BootstrapDialog open={isOpen} disableEscapeKeyDown={false}>
        {!isLoadingDialog ? (
          <Formik
            initialValues={{
              service: "",
              quantity: "",
              unit: "",
              purchasing_specifications: "",
              contracting_specifications: "",
              product: "",
              selectedTab: AddVerticalServiceENUM.VERTICAL,
              weekdaysOnly:
                data.weekdaysOnly != undefined ? data.weekdaysOnly : true,
            }}
            validationSchema={yupSchema}
            isInitialValid={true}
            enableReinitialize={true}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({
              values,
              handleSubmit,
              setFieldValue,
              handleChange,
              isValid,
              errors,
            }) => (
              <RootContainer>
                <HeaderContainer>
                  <DialogTitle>
                    {!model ? "Vincular serviços" : "Editar serviço"}
                  </DialogTitle>
                </HeaderContainer>
                <ContentContainer>
                  {values?.selectedTab === AddVerticalServiceENUM.VERTICAL && (
                    <>
                      <NameAndStatusContainer>
                        <CustomDropDownComponent
                          variant="filled"
                          values={serviceTypes}
                          key="CustomDropDownComponent"
                          onSelectedValueChanged={handleServiceTypeChange}
                          selectedValue={selecedServiceType}
                          onDeletedCustomType={onDeleteCustomService}
                          isGray={true}
                        />
                      </NameAndStatusContainer>
                      <QuanityUnitAndPriceContainer model={model}>
                        <InputValueComponent
                          variant="filled"
                          label="Quantidade"
                          type="number"
                          mask=""
                          value={
                            data.quantity !== undefined ? data.quantity : 0
                          }
                          onChange={(value) => handleQuantityChange(value)}
                          isGray={true}
                        />
                        <DropDownComponent
                          variant="filled"
                          defaultValue="Unidade"
                          label="Unidade"
                          key="unity"
                          componentKey="unity"
                          onSelectedValueChanged={(key, value) =>
                            handleUnityChange(key, value)
                          }
                          values={UnitValues}
                          selectedValue={data.unit}
                          height="56px"
                          bakcgrounColor={Colors.concrete}
                        />
                      </QuanityUnitAndPriceContainer>
                      {model ? (
                        <>
                          <LocationAndResponsibleContainer>
                            <InputValueComponent
                              variant="filled"
                              onChange={(value) => handleLocationChange(value)}
                              label="Localização"
                              value={data.location}
                              isGray={true}
                            />
                            <DropDownComponent
                              variant="filled"
                              defaultValue="Responsável"
                              key="responsible"
                              componentKey="responsible"
                              label="Responsável"
                              selectedValue={data.responsibleUserName}
                              onSelectedValueChanged={(key, value) =>
                                handleResponsibleChange(key, value)
                              }
                              values={
                                userResponsibles?.map(({ name }) => name) || []
                              }
                              height="56px"
                              bakcgrounColor={Colors.concrete}
                            />
                          </LocationAndResponsibleContainer>
                          <TimeContainer>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale="pt-br"
                            >
                              <InputDateComponent
                                label="Início"
                                variant="filled"
                                value={
                                  data?.beginDate
                                    ? parseAsUTCWithOffset(
                                        data?.beginDate.toISOString()
                                      )
                                    : new Date()
                                }
                                format="DD/MM/YYYY"
                                enable={true}
                                onChange={(date) => handleStartDateChange(date)}
                                isGray={true}
                              />
                              <InputDateComponent
                                label="Término"
                                variant="filled"
                                value={data?.endDate}
                                format="DD/MM/YYYY"
                                enable={true}
                                onChange={(date) => handleEndDateChange(date)}
                                isGray={true}
                              />
                            </LocalizationProvider>
                          </TimeContainer>
                          {isReschedulingVisible && (
                            <DropDownComponent
                              variant="filled"
                              defaultValue="Causa da reprogramação"
                              key="reschedulingReason"
                              componentKey="reschedulingReason"
                              label="Causa da reprogramação"
                              onSelectedValueChanged={(key, value) =>
                                handleReschedulingChange(key, value)
                              }
                              values={reschedulingReasons}
                              height="56px"
                              bakcgrounColor={Colors.concrete}
                            />
                          )}
                        </>
                      ) : (
                        <LocationResponsibleAndTimeContainer>
                          <InputValueComponent
                            variant="filled"
                            onChange={(value) => handleLocationChange(value)}
                            label="Localização"
                            value={data.location}
                            isGray={true}
                          />
                          <DropDownComponent
                            variant="filled"
                            defaultValue="Responsável"
                            key="responsible"
                            componentKey="responsible"
                            label="Responsável"
                            selectedValue={data.responsibleUserName}
                            onSelectedValueChanged={(key, value) =>
                              handleResponsibleChange(key, value)
                            }
                            values={
                              userResponsibles?.map(({ name }) => name) || []
                            }
                            height="56px"
                            bakcgrounColor={Colors.concrete}
                          />
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="pt-br"
                          >
                            <InputDateComponent
                              label="Início"
                              variant="filled"
                              value={data.beginDate || new Date()}
                              format="DD/MM/YYYY"
                              enable={true}
                              onChange={(date) => handleStartDateChange(date)}
                              isGray={true}
                            />
                            <InputDateComponent
                              label="Término"
                              variant="filled"
                              value={
                                data.endDate ||
                                new Date(
                                  new Date().setDate(new Date().getDate() + 7)
                                )
                              }
                              format="DD/MM/YYYY"
                              enable={true}
                              onChange={(date) => handleEndDateChange(date)}
                              isGray={true}
                            />
                          </LocalizationProvider>
                        </LocationResponsibleAndTimeContainer>
                      )}
                    </>
                  )}

                  {!model && (
                    <PurchasingAndContractionContainer>
                      {values.selectedTab ===
                        AddVerticalServiceENUM.CONTRACTING && (
                        <ContractingForm>
                          <Field
                            name="specifications"
                            render={() => {
                              return (
                                <InputValueComponent
                                  variant="filled"
                                  label="Serviço"
                                  value={values.service}
                                  onChange={(value: string) => {
                                    setFieldValue("service", value);
                                  }}
                                  isGray={true}
                                />
                              );
                            }}
                          />
                          <Field
                            name="specifications"
                            render={() => {
                              return (
                                <InputValueComponent
                                  variant="filled"
                                  label="Especificações"
                                  value={values.contracting_specifications}
                                  onChange={(value: string) => {
                                    setFieldValue(
                                      "contracting_specifications",
                                      value
                                    );
                                  }}
                                  isGray={true}
                                />
                              );
                            }}
                          />
                        </ContractingForm>
                      )}

                      {values.selectedTab ===
                        AddVerticalServiceENUM.PURCHASING && (
                        <PurchasingForm>
                          <PurchasingFormRowOne>
                            <Field
                              name="product"
                              render={() => {
                                return (
                                  <InputValueComponent
                                    variant="filled"
                                    label="Produto"
                                    value={values.product}
                                    onChange={(value: string) => {
                                      setFieldValue("product", value);
                                    }}
                                    isGray={true}
                                  />
                                );
                              }}
                            />

                            <Field
                              name="quantity"
                              render={() => {
                                return (
                                  <InputValueComponent
                                    variant="filled"
                                    label="Quantidade"
                                    type="number"
                                    mask=""
                                    value={values.quantity}
                                    onChange={(value: string) => {
                                      let newValue = Number.parseFloat(value);
                                      if (isNaN(newValue)) {
                                        newValue = 0;
                                      }
                                      setFieldValue("quantity", newValue);
                                    }}
                                    isGray={true}
                                  />
                                );
                              }}
                            />

                            <Field
                              name="unit"
                              render={() => {
                                return (
                                  <InputValueComponent
                                    variant="filled"
                                    label="Unidade"
                                    value={values.unit}
                                    onChange={(value: string) =>
                                      setFieldValue("unit", value)
                                    }
                                    isGray={true}
                                  />
                                );
                              }}
                            />
                          </PurchasingFormRowOne>
                          <Field
                            name="specifications"
                            render={() => {
                              return (
                                <InputValueComponent
                                  variant="filled"
                                  label="Especificações"
                                  value={values.purchasing_specifications}
                                  onChange={(value: string) => {
                                    setFieldValue(
                                      "purchasing_specifications",
                                      value
                                    );
                                  }}
                                  isGray={true}
                                />
                              );
                            }}
                          />
                        </PurchasingForm>
                      )}
                    </PurchasingAndContractionContainer>
                  )}

                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: Colors.butterflyBush }}
                        checked={values.weekdaysOnly}
                        onChange={(value) => {
                          handleWeekdaysOnlyChange(value.target.checked);
                        }}
                      />
                    }
                    label={
                      <CheckboxLabel>
                        Considerar apenas dias úteis
                      </CheckboxLabel>
                    }
                  />
                </ContentContainer>

                <ButtonsContainer>
                  <ButtonComponent
                    color={Colors.neutralGrayDark}
                    text="Cancelar"
                    type={ButtonType.TRANSPARENT_DARK}
                    onClick={() => {
                      onClose?.();
                      setIsReschedulingVisible(false);
                      setReschedulingReasonValue("");
                      setSelectedServiceType(undefined);
                    }}
                    startIcon={<HighlightOffOutlinedIcon />}
                  />
                  <ButtonComponent
                    state={getButtonState(values)}
                    text="Salvar"
                    startIcon={<CheckIcon />}
                    onClick={() => handleOnConfirm(values)}
                    padding={{
                      top: 15,
                      bottom: 15,
                      left: 32,
                      right: 32,
                    }}
                  />
                </ButtonsContainer>
              </RootContainer>
            )}
          </Formik>
        ) : null}

        {isLoadingDialog ? (
          <LoadingDialogContainer>
            <LoadingComponent />
          </LoadingDialogContainer>
        ) : null}
      </BootstrapDialog>
    </ReactBaseComponent>
  );
});

const LocationAndResponsibleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 16px;
  }
`;

const TimeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 16px;
  }
`;

const BootstrapDialog = styled(Dialog)`
  .MuiDialog-paper {
    /* padding: 0; */
    border-radius: 16px;
    margin: 0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    max-width: 825px;
    /* overflow: hidden; */
  }
`;

const RootContainer = styled.div`
  /* min-width: 825px; */
  width: 50vw;
  max-width: 825px;
  height: fit-content;
  display: grid;
  grid-template-rows: min-content min-content max-content min-content;
  padding-bottom: 32px;

  @media (max-width: 900px) {
    width: 70vw;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 90vw;
  }
`;

const LoadingDialogContainer = styled.div`
  display: grid;
  width: 825px;
  height: 369px;
  background: white;
  justify-content: center;
  align-content: center;
`;

const HeaderContainer = styled.div`
  /* margin-left: 32px;
  margin-right: 32px; */
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  margin-top: 32px;
  padding: 0px 32px;
`;

const DialogTitle = styled.div`
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${Colors.primaryColor};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 16px;
  }
`;

const ContentContainer = styled.div`
  /* margin-left: 32px;
  margin-right: 32px; */
  display: grid;
  grid-template-rows: min-content min-content min-content;
  row-gap: 20px;
  padding: 0px 32px;
  padding-top: 32px;
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  padding: 0px 32px;
  margin-top: 10px;
  place-self: end;
  column-gap: 26px;
`;

const NameAndStatusContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 16px;
`;

const QuanityUnitAndPriceContainer = styled.div<{
  model?: VerticalServiceModel | undefined;
}>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  align-items: center;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 16px;
  }
`;

const LocationResponsibleAndTimeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 16px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 16px;
  }
`;

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const CheckboxLabel = styled.div`
  display: inline-block;
  font-family: "Noto Sans", Inter, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  padding-top: 2px;
`;

const PurchaseAndContractingContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PurchaseAndContractingTitleContainer = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
`;

const PurchaseAndContractingContentContainer = styled.div``;

const TabContainer = styled.div`
  margin-bottom: 12px;
`;

const ListPurchasingContractionContainer = styled.div`
  margin-top: 8px;
  margin-left: 32px;
  margin-right: 32px;
`;

const CustomTab = styled((props) => <Tab {...props} />)(() => ({
  fontFamily: "Noto Sans",
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: "normal",
  fontWeight: 500,
  paddingLeft: 16,
  paddingRight: 16,
  color: `${Colors.greenLight}`,
  "&.Mui-selected": {
    color: `${Colors.primaryColorDark}`,
    fontWeight: 700,
  },
}));

const PurchasingAndContractionContainer = styled.div``;

const ContractingForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
`;

const PurchasingForm = styled.form`
  display: grid;
  grid-template-rows: max-content max-content;
  gap: 24px;
`;

const PurchasingFormRowOne = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 24px;
`;

export default AddServicePopUp;
