import styled from "styled-components";
import {
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Snackbar,
} from "@mui/material";
import React from "react";
import { selectStyle } from "../../utils/custom-styles/selectCustomStyle";

export enum DropDownComponentStyle {
  OUTLINED = "outlined",
  TRANSPARENT = "transparent",
}

interface DropDownComponentProps {
  key: string;
  onSelectedValueChanged: (key: string, value: string) => void;
  values: string[];
  defaultValue?: string;
  style?: DropDownComponentStyle;
  selectedValue?: string;
  disabled?: boolean;
  label?: string;
  isWhite?: boolean;
  isNavBar?: boolean;
  variant?: "outlined" | "filled" | "standard" | undefined;
  size?: "small" | "medium";
  sx?: any;
  height?: string;
  bakcgrounColor?: string;
  componentKey: string;
}

const DropDownComponent = (props: DropDownComponentProps) => {
  const key = props.componentKey || props.key;
  const values = props.values;
  const deaultValue = props.defaultValue;
  const style = props.style ?? DropDownComponentStyle.OUTLINED;
  const [selectedValue, setSelectedValue] = React.useState(props.defaultValue);

  React.useEffect(() => {
    setSelectedValue(props.selectedValue);
  }, [props.selectedValue]);

  const handleInputChange = (key: string, value: any) => {
    props.onSelectedValueChanged(key, value);
    setSelectedValue(value);
  };

  const getLabel = () => {
    if (selectedValue) {
      return props.label;
    }

    if (deaultValue) {
      return deaultValue;
    }

    return props.label || deaultValue || "Selecione";
  };

  const inputStyles = props.isWhite
    ? {
        color: "white",
        borderRadius: 16,
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: "#fff",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#fff",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#fff",
        },
        ".MuiSvgIcon-root ": {
          fill: "white !important",
        },
      }
    : null;

  const defaultStyles = {
    borderRadius: props.variant ? "" : props.isNavBar ? "50px" : "16px",
    height: props.isNavBar ? "45px" : props.height ? props.height : "auto",
    backgroundColor: props.bakcgrounColor
      ? props.bakcgrounColor
      : "transparent",
    fontSize: "16px",
  };

  return (
    <Root>
      <FormControl
        variant={props.variant}
        size={props.size || "small"}
        fullWidth
      >
        <InputLabel
          disabled={props.disabled === true}
          id="demo-simple-select-label"
          style={
            props.isWhite
              ? { color: "white" }
              : props.variant
              ? { fontSize: "16px", color: "#3B425A" }
              : {}
          }
        >
          {getLabel()}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          displayEmpty={false}
          variant={props.variant}
          style={defaultStyles}
          label={deaultValue}
          value={selectedValue}
          disabled={props.disabled === true}
          onChange={(event) => {
            handleInputChange(key, event.target.value);
          }}
          sx={
            props.sx
              ? { ...inputStyles, ...props.sx }
              : { ...inputStyles, ...(props.variant && selectStyle) }
          }
        >
          {values.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Root>
  );
};

const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

export default DropDownComponent;
