import { api } from "../api";
import { ContractingPlanEntity } from "./entity/ContractingPlanEntity";
import { PurchasingPlanEntity } from "./entity/PurchasingPlanEntity";

export default class PurchasingContractingPlanService {
  baseDomainEndPoint = "/api/purchasing-contracting-plan";

  async getContractingPlanList(
    constructionId: number
  ): Promise<ContractingPlanEntity[]> {
    const response = await api.get(
      this.baseDomainEndPoint + `/${constructionId}/contracting-plans`
    );

    return response.data.data;
  }

  async getContractingPlanById(id: number): Promise<ContractingPlanEntity> {
    const response = await api.get(
      this.baseDomainEndPoint + `/${id}/contracting-plan`
    );

    return response.data;
  }

  async savePurchasingPlan(
    constructionId: number,
    contractingPlan: PurchasingPlanEntity,
    sendOption: "email" | "whatsapp"
  ): Promise<PurchasingPlanEntity> {
    const requestBody = {
      purchasingPlan: contractingPlan,
      sendOption: sendOption,
    };

    const response = await api.post(
      this.baseDomainEndPoint + `/${constructionId}/purchasing-plan`,
      requestBody
    );

    return response.data;
  }

  async saveContractingPlan(
    constructionId: number,
    contractingPlan: ContractingPlanEntity,
    sendOption: "email" | "whatsapp"
  ): Promise<ContractingPlanEntity> {
    const requestBody = {
      contractingPlan: contractingPlan,
      sendOption: sendOption,
    };
    const response = await api.post(
      this.baseDomainEndPoint + `/${constructionId}/contracting-plan`,
      requestBody
    );

    return response.data;
  }

  async updatePurchasePlan(
    purchasingPlanId: number,
    purchasingPlan: PurchasingPlanEntity,
    sendOption: "email" | "whatsapp"
  ) {
    const requestBody = {
      purchasingPlan: purchasingPlan,
      sendOption: sendOption,
    };
    const response = await api.put(
      this.baseDomainEndPoint + `/${purchasingPlanId}/purchasing-plan`,
      requestBody
    );

    return response.data;
  }

  async updateContractingPlan(
    contractingPlanId: number,
    contractingPlan: ContractingPlanEntity,
    sendOption: "email" | "whatsapp"
  ) {
    const requestBody = {
      contractingPlan: contractingPlan,
      sendOption: sendOption,
    };
    const response = await api.put(
      this.baseDomainEndPoint + `/${contractingPlanId}/contracting-plan`,
      requestBody
    );

    return response.data;
  }

  async requestPurchasingPlanQuoting(
    purchasingPlanId: number,
    supplierIds: number[],
    sendOption: "email" | "whatsapp"
  ): Promise<void> {
    const requestBody = {
      supplierIds: supplierIds,
      sendOption: sendOption,
    };
    const response = await api.put(
      this.baseDomainEndPoint + `/${purchasingPlanId}/purchasing-plan-quoting`,
      requestBody
    );

    return response.data;
  }

  async requestContractingPlanQuoting(
    contractingPlanId: number,
    supplierIds: number[],
    sendOption: "email" | "whatsapp"
  ): Promise<void> {
    const requestBody = {
      supplierIds: supplierIds,
      sendOption: sendOption,
    };
    const response = await api.put(
      this.baseDomainEndPoint +
        `/${contractingPlanId}/contracting-plan-quoting`,
      requestBody
    );

    return response.data;
  }

  async getPurchasingPlanList(
    constructionId: number
  ): Promise<PurchasingPlanEntity[]> {
    const response = await api.get(
      this.baseDomainEndPoint + `/${constructionId}/purchasing-plans`
    );

    return response.data.data;
  }

  async getPurchasingPlanById(id: number): Promise<PurchasingPlanEntity> {
    const response = await api.get(
      this.baseDomainEndPoint + `/${id}/purchasing-plan`
    );

    return response.data;
  }

  async returnPurchasingPhase(id: number): Promise<void> {
    try {
      const response = await api.put(
        this.baseDomainEndPoint + `/${id}/status/revert-purchasing-plan-phase`
      );

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async returnContractingPhase(id: number): Promise<void> {
    try {
      const response = await api.put(
        this.baseDomainEndPoint + `/${id}/status/revert-contracting-plan-phase`
      );

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateStatusPurchasing(id: number): Promise<void> {
    try {
      const response = await api.put(
        this.baseDomainEndPoint + `/${id}/status/purchasing-plan`
      );

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateStatusContracting(id: number): Promise<void> {
    try {
      const response = await api.put(
        this.baseDomainEndPoint + `/${id}/status/contracting-plan`
      );

      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async passToNextPhase(id: number): Promise<void> {
    try {
      const response = await api.post(this.baseDomainEndPoint + `/${id}/phase`);

      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }

  async deletePurchasingPlan(id: number): Promise<void> {
    const result = await api.delete(
      this.baseDomainEndPoint + `/${id}/delete-purchasing-plan`
    );
    return result.data;
  }

  async deleteContractingPlan(id: number): Promise<void> {
    const result = await api.delete(
      this.baseDomainEndPoint + `/${id}/delete-contracting-plan`
    );
    return result.data;
  }

  async purchasingExportPdf(id: number): Promise<void> {
    const result = await api.get(
      this.baseDomainEndPoint + `/${id}/purchasing-export-pdf`
    );
    return result.data;
  }

  async contractingExportPdf(id: number): Promise<void> {
    const result = await api.get(
      this.baseDomainEndPoint + `/${id}/contracting-export-pdf`
    );
    return result.data;
  }
}
