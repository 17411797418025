import { api } from "../api";

export default class ManageFilesService {
  baseDomainEndPoint = "api/uploaded-file";

  async getPurcharsingContractingUploadedFiles(id: number, planType: string) {
    try {
      const response = await api.get(
        this.baseDomainEndPoint + `/${planType}/${id}/get-plan-files`
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async deletePurcharsingContractingUploadedFiles(
    id: number,
    planType: string
  ) {
    try {
      const response = await api.delete(
        this.baseDomainEndPoint + `/${planType}/${id}/delete-file`
      );

      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  uploadFiles = async (
    files: File[],
    uploadedFileDto: any,
    pcpDto: any,
    onUploadProgress: (progressEvent: ProgressEvent) => void
  ) => {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("files", file);
    });

    for (const key in uploadedFileDto) {
      formData.append(`uploadedFileDto[${key}]`, uploadedFileDto[key]);
    }

    for (const key in pcpDto) {
      formData.append(`pcpDto[${key}]`, pcpDto[key]);
    }
    try {
      const result = await api.post(
        `/api/uploaded-file/upload-file`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress,
        }
      );

      return result.data;
    } catch (error) {
      console.error(error);
    }
  };
}
