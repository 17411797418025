import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import ReactBaseComponent from "../../../components/BaseComponents/ReactBaseComponent";
import { Colors } from "../../../theme/v2/variants";
import ButtonComponent, {
  ButtonState,
  ButtonType,
} from "../../../components/v2/Button/ButtonComponent";
import VerticalServiceModel from "../../../services/interfaces/VerticalServiceModel";
import React, { useEffect, useState } from "react";
import UserService, { UserResponsible } from "../../../services/UserService";
import LoadingComponent from "../../../components/StateComponents/LoadingComponent";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";
import "dayjs/locale/pt-br";
import CheckIcon from "@mui/icons-material/Check";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import CloseIcon from "@mui/icons-material/Close";
import NewRestrictionComponent from "../../Restrictions/Form/components/NewRestrictionComponent";
import RestrictionMappingModel, {
  IRestrictionMappingModel,
} from "../../Restrictions/models/RestrictionModel";
import ICriteriaModel from "../../Restrictions/models/CriteriaModel";
import { ActionModel, IActionModel } from "../../Actions/models/ActionModel";
import { useNavigate } from "react-router-dom";
import { useDashboard } from "../../../contexts/dashboard";
import ActionsService from "../../../services/action/ActionsService";
import CriteriaService from "../../../services/criteria/CriteriaService";
import RestrictionMappingService from "../../../services/restrictionMapping/RestrictionMappingService";
import RestrictionsService from "../../../services/restrictions/RestrictionsService";
import { BaseState } from "../../Actions/ViewActions/utils/BaseState";
import NewActionComponent from "../../Restrictions/Form/components/NewActionComponent";

interface AddRestrictionAndActionPlanPopUpProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (
    selectedRestrictionId: string | null | undefined,
    newRestrictionName: string | undefined,
    actions: IActionModel[],
    newRestrictionMappingObject: IRestrictionMappingModel
  ) => Promise<void>;
  userResponsibles?: UserResponsible[];
  isLoadingDialog?: boolean;
  model?: VerticalServiceModel;
  serviceType?: string;
}

const AddRestrictionAndActionPlanPopUp: React.FC<
  AddRestrictionAndActionPlanPopUpProps
> = ({ isOpen, onClose, onConfirm, isLoadingDialog, serviceType, model }) => {
  const navigation = useNavigate();
  const restrictionService = new RestrictionsService();
  const criteriaService = new CriteriaService();
  const userService = new UserService();
  const actionsService = new ActionsService();
  const restrictionMappingService = new RestrictionMappingService();
  const { constructionId, constructions, clientId, clients } = useDashboard();

  const [newRestrictionMappingObject, setNewRestriction] =
    useState<IRestrictionMappingModel>(RestrictionMappingModel.emptyObject());
  const [disabledRestriction, setDisabledRestriction] =
    useState<boolean>(false);
  const [criteria, setCriteria] = useState<ICriteriaModel[]>([]);
  const [restrictions, setRestrictions] = useState<IRestrictionMappingModel[]>(
    []
  );
  const [actions, setActions] = useState<IActionModel[]>([
    ActionModel.emptyObject(),
  ]);
  const [selectedRestrictionId, setSelectedRestrictionId] = useState<
    string | null
  >();
  const [newRestrictionName, setNewRestrictionName] = useState<string>();
  const [state, setState] = useState<BaseState>(BaseState.loading);
  const [responsibles, setResponsibles] = useState<UserResponsible[]>([]);
  const [selectedActionId, setSelectedActionId] = useState<string>();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const onRestrictionChange = (restriction: IRestrictionMappingModel) => {
    setNewRestriction(restriction);
  };

  const onCriteriaChange = (value: string, name: string) => {
    setActions([ActionModel.emptyObject()]);
    // const newRestriction = RestrictionMappingModel.emptyObject();
    // newRestriction.criteriaId = value;
    // newRestriction.criteriaName = name;
    // setNewRestriction(newRestriction);
    if (disabledRestriction === true) {
      setDisabledRestriction(false);
    }
  };

  const loadActionsByRestriction = async (restrictionId: string) => {
    try {
      const result = await restrictionService.findByConstructionAndRestriction(
        constructionId,
        restrictionId
      );

      if (result.length === 0) {
        setActions([ActionModel.emptyObject()]);
        return;
      }

      const actions = ActionModel.parseFromEntityArray(
        result[0].actionItems || []
      );

      setActions(actions);
      setNewRestriction(RestrictionMappingModel.parseFromEntity(result[0]));
      setDisabledRestriction(true);
    } catch (error) {
      console.error(error);
    }
  };

  const onSelectedRestrictionChange = (id: string | null) => {
    if (id === null || id === "") {
      return;
    }

    if (
      id === null &&
      actions.length > 0 &&
      actions[0].generatedByEmptyObject === false
    ) {
      setActions([ActionModel.emptyObject()]);
      setSelectedRestrictionId(null);
      return;
    }
    loadActionsByRestriction(id!!);
    setSelectedRestrictionId(id);
    setNewRestrictionName(undefined);
  };

  const onActionChange = (action: IActionModel) => {
    const index = actions.findIndex((a) => a.id === action.id);
    const newActions = [...actions];
    newActions[index] = action;
    setActions(newActions);
  };

  const actionDeleteOnClick = (actionId: string) => {
    setSelectedActionId(actionId);
    const index = actions.findIndex((action) => action.id === actionId);
    // remove item from array if actions[index].generatedByEmptyObject === true
    if (actions[index].generatedByEmptyObject === true) {
      setActions(actions.filter((action) => action.id !== actionId));
      return;
    }
    setIsDialogOpen(true);
  };

  const onNewRestrictionNameChange = (value: string | undefined) => {
    setNewRestrictionName(value);
    setActions([ActionModel.emptyObject()]);
  };

  const fetchRestrictions = async (constructionId: number) => {
    const restrictions =
      await restrictionService.fetchRestrictionByConstructionId(constructionId);
    return RestrictionMappingModel.parseFromEntityArray(restrictions);
  };

  const fetchCriteria = async () => {
    const result = await criteriaService.fetchCriteria();
    return ICriteriaModel.fromEntityArray(result);
  };

  const loadPageData = async (constructionId: number) => {
    try {
      setState(BaseState.loading);

      const restrictions = await fetchRestrictions(constructionId);
      const criteria = await fetchCriteria();
      const responsibles = await userService.fetchResponsibleUsers(
        constructionId.toString()
      );

      setResponsibles(responsibles);
      setRestrictions(restrictions);
      setCriteria(criteria);
      setState(BaseState.success);
    } catch (error) {
      console.error(error);
      setState(BaseState.error);
    }
  };

  useEffect(() => {
    loadPageData(constructionId);
  }, [constructionId]);

  return (
    <ReactBaseComponent>
      <BootstrapDialog open={isOpen} disableEscapeKeyDown={false}>
        {!isLoadingDialog ? (
          <RootContainer>
            <HeaderContainer>
              <DialogTitle>Nova Restrição - {serviceType}</DialogTitle>
              <CloseButton onClick={() => onClose?.()}>X</CloseButton>
            </HeaderContainer>
            <ContentContainer>
              <NewRestrictionComponent
                restriction={newRestrictionMappingObject}
                restrictions={restrictions}
                criteria={criteria}
                onRestrictionChange={onRestrictionChange}
                disabled={disabledRestriction}
                disabledAllCriteriaAndRestriction={false}
                onCriteriaChangeCallback={(value: string, name: string) =>
                  onCriteriaChange(value, name)
                }
                onSelectedRestrictionChange={(id: string | null) =>
                  onSelectedRestrictionChange(id)
                }
                onNewRestrictionNameChange={onNewRestrictionNameChange}
                isOnVerticalServicePopUp={true}
              />
            </ContentContainer>

            <ActionContentContainer>
              <DialogTitle>Plano de Ação</DialogTitle>
              {actions.map((action, index) => (
                <React.Fragment key={action.id}>
                  <NewActionComponent
                    action={action}
                    index={index}
                    responsibles={responsibles}
                    onActionChange={(action: IActionModel) =>
                      onActionChange(action)
                    }
                    isDisabled={false}
                    isAddState={true}
                    onDeletedAction={(action) => actionDeleteOnClick(action.id)}
                    isOnVerticalServicePopUp
                    model={model}
                  />
                  {index !== actions.length - 1 && <ListDivider />}
                </React.Fragment>
              ))}
            </ActionContentContainer>

            <ButtonsContainer>
              <ButtonComponent
                color={Colors.neutralGrayDark}
                text="Cancelar"
                type={ButtonType.TRANSPARENT_DARK}
                onClick={() => onClose?.()}
                startIcon={<HighlightOffOutlinedIcon />}
              />
              <ButtonComponent
                text="Salvar"
                startIcon={<CheckIcon />}
                onClick={() =>
                  onConfirm(
                    selectedRestrictionId,
                    newRestrictionName,
                    actions,
                    newRestrictionMappingObject
                  )
                }
                padding={{
                  top: 15,
                  bottom: 15,
                  left: 32,
                  right: 32,
                }}
              />
            </ButtonsContainer>
          </RootContainer>
        ) : null}

        {isLoadingDialog ? (
          <LoadingDialogContainer>
            <LoadingComponent />
          </LoadingDialogContainer>
        ) : null}
      </BootstrapDialog>
    </ReactBaseComponent>
  );
};

const ListDivider = styled.div`
  display: grid;
  width: 100%;
  margin-top: 16px;
  border: 1px dashed ${Colors.neutralColor};
  margin-bottom: 16px;
`;

const CloseButton = styled(CloseIcon)`
  cursor: pointer;
  font-size: 24px;
`;

const BootstrapDialog = styled(Dialog)`
  .MuiDialog-paper {
    /* padding: 0; */
    border-radius: 16px;
    margin: 0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    max-width: 825px;
    overflow: hidden;
  }
`;

const RootContainer = styled.div`
  /* min-width: 825px; */
  width: 50vw;
  max-width: 825px;
  height: fit-content;
  display: grid;
  grid-template-rows: min-content min-content max-content min-content;
  padding: 0 32px;
  padding-bottom: 32px;
  overflow-y: auto;
  gap: 29px;

  @media (max-width: 900px) {
    width: 70vw;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 90vw;
  }
`;

const LoadingDialogContainer = styled.div`
  display: grid;
  width: 825px;
  height: 369px;
  background: white;
  justify-content: center;
  align-content: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  margin-top: 32px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    /* margin-left: 16px;
    margin-right: 16px; */
  }
`;

const DialogTitle = styled.div`
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${Colors.primaryColor};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 16px;
  }
`;

const ContentContainer = styled.div`
  display: grid;
  row-gap: 32px;
`;

const ActionContentContainer = styled.div`
  display: grid;
  row-gap: 16px;
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  margin-top: 10px;
  place-self: end;
  column-gap: 26px;
`;

export default AddRestrictionAndActionPlanPopUp;
