import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import {
  IconButton,
  Grid,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { api } from "../../../services/api";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const WrapperDataGrid = styled.div`
  width: 100%;
`;

const ContentDataGrid = styled.div`
  height: 48vh;
  overflow-y: auto;
`;

type Restriction = {
  id: number;
  name: string;
  code?: string;
  criteriaId: string;
  status: number;
  createdAt: Date;
};

type Props = {
  section: string;
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbar />
    </GridToolbarContainer>
  );
}
const RestrictionsList: React.FC<Props> = ({ section }) => {
  const [restrictions, setRestrictions] = useState<Restriction[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRestrictions = async () => {
      try {
        const response = await api.get("api/restrictions");

        if (response.status === 200) {
          setRestrictions(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchRestrictions();
  }, []);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
      type: "string",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "Nome",
      flex: 1.8,
      type: "string",
    },
    {
      field: "criteriaId",
      headerName: "Critério",
      flex: 1,
      type: "string",
      headerAlign: "left",
      align: "left",
      valueGetter: ({ row }) => row.criteriaId.name,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.4,
      type: "string",
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => (params.value ? "Ativo" : "Inativo"),
    },
    {
      field: "createdAt",
      headerName: "Criado em",
      flex: 0.8,
      type: "string",
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => {
        const date = new Date(String(params.value));
        return `${date.toLocaleDateString("pt-br")} ${date.toLocaleTimeString(
          "pt-br"
        )}`;
      },
    },
    {
      field: "actions",
      headerName: "Editar",
      width: 50,
      flex: 0.3,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <IconButton
          edge="end"
          aria-label="editar"
          onClick={() => navigate(`/restricoes/editar/${params.row.id}/`)}
          size="large"
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>{section}</title>
      </Helmet>
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            {section}
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Card>
        <CardContent>
          <WrapperDataGrid>
            <ContentDataGrid>
              <DataGrid
                rows={restrictions}
                columns={columns}
                components={{ Toolbar: CustomToolbar }}
              />
            </ContentDataGrid>
          </WrapperDataGrid>
        </CardContent>
      </Card>
    </>
  );
};

export default RestrictionsList;
