import { Delete, DeleteOutline, Edit } from "@mui/icons-material";
import { Button } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as CheckIcon } from "../../../assets/check.svg";
import { ReactComponent as ImageIcon } from "../../../assets/image.svg";
import ReactBaseComponent from "../../../components/BaseComponents/ReactBaseComponent";
import IconButtonComponent from "../../../components/Button/IconButtonComponent";
import { Colors } from "../../../theme/variants";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";

interface ServiceDetailsComponentProps {
  location?: string;
  timePeriod?: { startDate: Date; endDate: Date };
  responsibleName?: string;
  status?: any;
  serviceGroupColor: string;
  isMobile: boolean;
  editServiceOnClick?: () => void;
  deleteServiceOnClick?: () => void;
  updateServiceProgressOnClick?: () => void;
  uploadImageOnClick?: () => void;
}

const ServiceDetailsComponent: React.FC<ServiceDetailsComponentProps> = ({
  location,
  timePeriod,
  responsibleName,
  status,
  serviceGroupColor,
  editServiceOnClick,
  deleteServiceOnClick,
  updateServiceProgressOnClick,
  uploadImageOnClick,
  isMobile,
}) => {
  const colorByStatus = (status?: any) => {
    switch (status?.value || "") {
      case 1:
        return Colors.attentionColor;
      case 2:
        return Colors.feedbackSuccess;
      case 3:
        return Colors.feedbackError;
      case 4:
        return Colors.warningColor;
    }
  };

  const timePeriodString = () => {
    if (timePeriod) {
      const startDateString = timePeriod.startDate.toLocaleDateString("Pt-BR", {
        day: "numeric",
        month: "numeric",
      });
      const endDateString = timePeriod.endDate.toLocaleDateString("Pt-BR", {
        day: "numeric",
        month: "numeric",
      });

      const differenceInMilliseconds =
        timePeriod.endDate.getTime() - timePeriod.startDate.getTime();
      const differenceInDays = Math.floor(
        differenceInMilliseconds / (1000 * 60 * 60 * 24)
      );

      if (differenceInDays === 0) {
        return `${startDateString} | 1 dia`;
      }

      return `${startDateString} > ${endDateString} | ${differenceInDays} dias`;
    } else {
      return "Sem período";
    }
  };

  return (
    <ReactBaseComponent>
      <Container>
        <ServiceTypeAndStatusContainer>
          <ServiceType style={{ color: `${serviceGroupColor}` }}>
            {location}
          </ServiceType>
          <StatusContainer>
            <StatusSymbol style={{ backgroundColor: colorByStatus(status) }} />
            <StatusText>{status.name}</StatusText>
          </StatusContainer>
        </ServiceTypeAndStatusContainer>
        <TimePeriod>{timePeriodString()}</TimePeriod>
        <Responsible>Responsável: {responsibleName}</Responsible>
        {!isMobile && (
          <ButtonActionsContainer>
            <Button
              variant="text"
              style={{ color: "#000" }}
              startIcon={<Edit />}
              onClick={() => editServiceOnClick?.()}
            >
              Editar serviço
            </Button>
            <Button
              variant="text"
              style={{ color: "#000" }}
              onClick={() => updateServiceProgressOnClick?.()}
              startIcon={<CheckIcon style={{ color: "#000" }} />}
            >
              Avançar serviço
            </Button>
            <Button
              variant="text"
              style={{ color: "#000" }}
              onClick={() => uploadImageOnClick?.()}
              startIcon={<ImageIcon style={{ color: "#000" }} />}
            >
              Adicionar imagens
            </Button>
            <Button
              variant="text"
              style={{ color: "#000" }}
              onClick={() => deleteServiceOnClick?.()}
              startIcon={<Delete style={{ color: "#000" }} />}
            >
              Excluir serviço
            </Button>
          </ButtonActionsContainer>
        )}

        {isMobile && (
          <ButtonActionsContainer>
            <IconButtonComponent onClick={() => editServiceOnClick?.()}>
              <Edit style={{ color: "#000" }} />
            </IconButtonComponent>
            <IconButtonComponent
              onClick={() => updateServiceProgressOnClick?.()}
            >
              <CheckIcon style={{ color: "#000" }} />
            </IconButtonComponent>
            <IconButtonComponent onClick={() => uploadImageOnClick?.()}>
              <ImageIcon style={{ color: "#000" }} />
            </IconButtonComponent>
            <IconButtonComponent onClick={() => deleteServiceOnClick?.()}>
              <DeleteOutline style={{ color: "#000" }} />
            </IconButtonComponent>
          </ButtonActionsContainer>
        )}
      </Container>
    </ReactBaseComponent>
  );
};

const Container = styled.div`
  display: grid;
  max-width: 100%;
  grid-template-rows: auto auto auto max-content;
  row-gap: 16px;
  justify-content: start;
  align-content: start;
  margin-left: 32px;
  margin-right: 32px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
    margin-left: 16px;
    margin-right: 16px;
  }
`;

const ServiceTypeAndStatusContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
`;

const ServiceType = styled.div`
  font-size: 16px;
  font-family: "Noto Sans";
  font-weight: 700;
  word-wrap: break-word;
`;

const StatusContainer = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  justify-content: center;
  align-content: center;
  column-gap: 4px;
  margin-left: 24px;
`;

const StatusText = styled.div`
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-top: -3px;
  color: #000;
`;

const StatusSymbol = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 12px;
`;

const TimePeriod = styled.div`
  color: ${Colors.textColorBlack};
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Responsible = styled.div`
  color: ${Colors.textColorBlack};
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const ButtonActionsContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 16px;
  margin-top: 16px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export default ServiceDetailsComponent;
