import { SupplierModel } from "../../pages/PurchasingContractingPlanPage/models/SuppliersModel";
import { api } from "../api";
import {
  OrderStatusEnum,
  ReponseStatusEnum,
} from "../../pages/PurchasingContractingPlanPage/Show";

export enum BudgetStatusENUM {
  APROVADO = "APROVADO",
  REPROVADO = "REPROVADO",
  PENDENTE = "PENDENTE",
}

export default class ManageBudgetsService {
  baseDomainEndPoint = "api/budgets";

  async validateToken(token: string) {
    try {
      return await api.get(
        `${this.baseDomainEndPoint}/validate-token/${token}`
      );
    } catch (error) {
      return { status: 500, data: { success: false } };
    }
  }

  async getBudgetsByPlanId(
    planId: number,
    planType: "PURCHASING" | "CONTRACTING"
  ): Promise<any[]> {
    const response = await api.get(
      `${this.baseDomainEndPoint}/plan/${planId}/${planType}`
    );

    return response.data;
  }

  updateBudgetStatus = async (id: number, status: BudgetStatusENUM) => {
    try {
      const response = await api.put(
        `${this.baseDomainEndPoint}/${id}/status/${status}`
      );
      return response.data;
    } catch (error) {
      console.error("Erro ao atualizar o status do orçamento:", error);
      throw error;
    }
  };

  updateBudgetDecisionObservation = async (id: number, observation: string) => {
    try {
      const response = await api.put(
        `${this.baseDomainEndPoint}/${id}/decision-observation`,
        { observation }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao atualizar a observação de decisão do orçamento:",
        error
      );
      throw error;
    }
  };

  updatePurchaseDecisionObservation = async (
    id: number,
    observation: string,
    status: ReponseStatusEnum
  ) => {
    try {
      const response = await api.put(
        `${this.baseDomainEndPoint}/${id}/purchase-observation`,
        { observation, status }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao atualizar a observação de decisão da compra:",
        error
      );
      throw error;
    }
  };

  updateReceiptDecisionObservation = async (
    id: number,
    observation: string,
    status: ReponseStatusEnum
  ) => {
    try {
      const response = await api.put(
        `${this.baseDomainEndPoint}/${id}/receipt-observation`,
        { observation, status }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao atualizar a observação de decisão do recebimento:",
        error
      );
      throw error;
    }
  };

  updateValue = async (id: number, value: string) => {
    try {
      const response = await api.put(`${this.baseDomainEndPoint}/${id}/value`, {
        value,
      });
      return response.data;
    } catch (error) {
      console.error("Erro ao atualizar a valor do orçamento:", error);
      throw error;
    }
  };

  async uploadFiles({
    files,
    budgetDTO,
    token,
    onUploadProgress,
  }: {
    files: File[];
    budgetDTO: any;
    token: string;
    onUploadProgress: (progressEvent: ProgressEvent) => void;
  }) {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("files", file);
    });

    for (const key in budgetDTO) {
      formData.append(`budgetDTO[${key}]`, budgetDTO[key]);
    }

    formData.append("token", token);

    try {
      const result = await api.post(
        `${this.baseDomainEndPoint}/create-budget`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress,
        }
      );

      return result.data;
    } catch (error) {
      console.error(error);
    }
  }
}
