import styled from "styled-components";
import { Colors } from "../../../../theme/v2/variants";
import { MOBILE_BREAKPOINT } from "../../../../utils/mobileDimen";
import typography from "../../../../theme/v2/typography";
import { Switch } from "@mui/material";

export const HeaderContainer = styled.div`
  display: grid;
  max-width: 100%;
  padding: 41px;
  align-items: center;
  background-color: ${Colors.primaryColorLight};

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr;
    align-content: start;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-rows: 1fr;
    padding: 20px;
  }
`;

export const AboutContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
`;

export const ConstructionImage = styled.img`
  background-color: ${Colors.neutralColorWhite};
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: block;
  object-fit: cover;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 50px;
    height: 50px;
  }
`;

export const ConstructionImagePlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.primaryDarkBlue};
  color: ${Colors.secondaryColorLight};
  min-width: 70px;
  min-height: 70px;
  border-radius: 50%;
  object-fit: cover;
  font-size: ${typography.h1?.fontSize};
  font-weight: ${typography.fontWeightBold};
  text-transform: uppercase;
  user-select: none;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 50px;
    height: 50px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 4px;
  margin-left: 14px;
`;

export const PageTitle = styled.div`
  font-size: 16px;
  font-family: "Noto Sans";
  font-weight: 400;
  word-wrap: break-word;
  line-height: 21.79px;
  color: ${Colors.neutralColorWhite};
`;

export const ConstructionNameTitle = styled.div`
  font-size: 32px;
  font-family: "Noto Sans";
  font-weight: 700;
  word-wrap: break-word;
  line-height: 43.58px;
  color: ${Colors.neutralColorWhite};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 80%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 26px;
    width: 70%;
  }
`;

export const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 20px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    justify-content: center;
    align-items: start;
    gap: 40px;
    margin-top: 20px;
  }
`;

export const GroupByTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

export const GroupByTimeText = styled.span`
  font-size: 14px;
  font-family: "Noto Sans";
  font-weight: 500;
  color: ${Colors.neutralColorWhite};
  word-wrap: break-word;

  text-align: end;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 12px;
  }
`;

export const SwitchTime = styled(Switch).attrs({
  focusVisibleClassName: ".Mui-focusVisible",
  disableRipple: true,
})`
  width: 52px;
  height: 32px;
  padding: 0;

  & .MuiSwitch-switchBase {
    padding: 0;
    margin: 5px;
    transition-duration: 300ms;

    &.Mui-checked {
      transform: translateX(20px);
      color: ${Colors.neutralColorWhite};

      & + .MuiSwitch-track {
        background-color: ${Colors.greenLight};
        opacity: 1;
        border: 0;
      }

      &.Mui-disabled + .MuiSwitch-track {
        opacity: 0.5;
      }
    }

    &.Mui-focusVisible .MuiSwitch-thumb {
      color: #33cf4d;
      border: 6px solid ${Colors.neutralColorWhite};
    }

    &.Mui-disabled .MuiSwitch-thumb {
      color: ${Colors.butterflyBush};
    }

    &.Mui-disabled + .MuiSwitch-track {
      opacity: 0.7;
    }
  }

  & .MuiSwitch-thumb {
    box-sizing: border-box;
    width: 22px;
    height: 22px;
  }

  & .MuiSwitch-track {
    border-radius: 100px;
    background-color: ${Colors.butterflyBush};
    opacity: 1;
    transition: 0.5s;
  }
`;
