import styled from "styled-components";
import { Colors } from "../../theme/v2/variants";
import ActionCard, {
  ActionCardProps,
} from "../../components/ActionCard/ActionCard";
import { Droppable } from "@hello-pangea/dnd";
import { useEffect, useRef, useState } from "react";
import { MOBILE_BREAKPOINT } from "../../utils/mobileDimen";

export enum KanbanColumnStatus {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  DONE = "DONE",
  DELAYED = "DELAYED",
}

interface Props {
  actions: ActionCardProps[];
  status: KanbanColumnStatus;
  onTrashClick: (card: ActionCardProps) => void;
  isDragDisabled?: boolean;
}

interface ColumnConfig {
  color: string;
  title: string;
  status: KanbanColumnStatus;
}

const KanbanColumn: React.FC<Props> = ({
  actions,
  status,
  onTrashClick,
  isDragDisabled,
}) => {
  const columnConfig = statusColor();
  const [kanbanColumnContainerHeight, setKanbanColumnContainerHeight] =
    useState(0);
  const stageCanvasRef = useRef(null);
  const headerRef = useRef(null);

  useEffect(() => {
    if (stageCanvasRef.current && headerRef.current) {
      const containerHeight = (stageCanvasRef.current as any).offsetHeight;
      const headerHeight = (headerRef.current as any).offsetHeight;
      setKanbanColumnContainerHeight(containerHeight - headerHeight - 20);
    }
  }, [stageCanvasRef, headerRef]);

  function statusColor(): ColumnConfig {
    let color = null;
    let title = null;
    switch (status) {
      case KanbanColumnStatus.PENDING:
        color = Colors.primaryColor;
        title = "A fazer";
        break;
      case KanbanColumnStatus.IN_PROGRESS:
        color = Colors.secondaryColor;
        title = "Em andamento";
        break;
      case KanbanColumnStatus.DONE:
        color = Colors.feedbackSuccess;
        title = "Concluído";
        break;
      case KanbanColumnStatus.DELAYED:
        color = Colors.darkRed;
        title = "Em atraso";
        break;
    }

    const columnConfig: ColumnConfig = {
      color,
      title,
      status,
    };

    return columnConfig;
  }

  return (
    <KanbanColumnContainer ref={stageCanvasRef}>
      <KanbarHeader ref={headerRef}>
        <KanbanColumnTitleContainer>
          <KanbanColumnTitle columnColor={columnConfig.color}>
            {columnConfig.title}
          </KanbanColumnTitle>
          <ViewCircleCountItems color={columnConfig.color}>
            {actions.length}
          </ViewCircleCountItems>
        </KanbanColumnTitleContainer>
        {/* <UnderlineView color={columnConfig.color} /> */}
      </KanbarHeader>
      <Droppable droppableId={columnConfig.status}>
        {(droppableProvided, snapshot) => (
          <KanbanDropArea
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
            className={snapshot.isDraggingOver ? "dragging" : ""}
            style={{ height: "100%" }}
          >
            <KanbanColumnCardsContainer>
              {actions.map((action, position) => (
                <ActionCard
                  key={action.id}
                  cardProps={action}
                  position={position}
                  onTrashClick={onTrashClick}
                  isDragDisabled={isDragDisabled}
                />
              ))}
              {droppableProvided.placeholder}
            </KanbanColumnCardsContainer>
          </KanbanDropArea>
        )}
      </Droppable>
    </KanbanColumnContainer>
  );
};

const KanbanColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 0px;
  max-height: 100%;
  gap: 16px;
  flex-shrink: 0;
  background-color: white;
  border: 1px solid ${Colors.neutralColorWarm};
  border-radius: 24px;
  justify-items: center;
  align-content: start;
  margin-top: 20px;
  overflow: hidden;
  padding: 20px 0px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: calc(100vw - 40px);
  }
`;

const KanbanDropArea = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  max-height: 100vh;
  overflow-y: hidden;
  height: max-content;
`;

const KanbanColumnCardsContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
  padding: 0px 16px;

  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const KanbanColumnTitleContainer = styled.div`
  width: 100%;
  height: 30px;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: start;
  align-items: center;
  margin-left: 4px;
  padding: 0px 16px;
`;

const ViewCircle = styled.div`
  ${(props) => `
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${props.color};
    margin: 2px;
    `}
`;

const KanbarHeader = styled.div`
  width: 100%;
  justify-content: center;
  align-content: start;
  grid-template-rows: 30 min-content;
`;

const KanbanColumnTitle = styled.div<{ columnColor: string }>`
  color: ${Colors.neutralColorWhite};
  background-color: ${(props) => props.columnColor};
  height: auto;
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 3px 9px;
  border-radius: 5px;
  margin-right: 10px;
`;

const ViewCircleCountItems = styled.div`
  ${(props) => `
            min-width: 20px;
            min-height: 20px;
            padding-left: 4px;
            padding-right: 4px;
            border-radius: 50%;
            background-color: ${props.color};
            display: flex;
            justify-content: center;
            align-items: center;
            color: ${Colors.textColorLight};
     `}
`;

const UnderlineView = styled.div`
  ${(props) => `
      width: 100%;
        height: 0;
        border: 3px solid ${props.color};
        margin-top: 20px;
 `}
`;

export default KanbanColumn;
