import styled from "styled-components";
import DropDownChecklistComponent, {
  DropDownComponentRef,
} from "../../../components/v2/InputValues/DropDownChecklist";
import ButtonComponent, {
  ButtonState,
  ButtonType,
} from "../../../components/Button/ButtonComponent";
import { LocalizationProvider } from "@mui/x-date-pickers";
import React, { useCallback, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";
import { Colors } from "../../../theme/v2/variants";

import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import InputDateComponent from "../../../components/InputValues/input-date/InputDateComponent";
import type { VerticalServicesFilterProps } from "../../../types/verticalServices.type";

export const ServiceStatus = {
  IN_PROGRESS: {
    key: 1,
    value: "Em andamento",
  },
  DONE: {
    key: 2,
    value: "Concluído",
  },
  OVERDUE: {
    key: 3,
    value: "Em atraso",
  },
  PENDING: {
    key: 4,
    value: "A fazer",
  },
};

interface User {
  id: string;
  name: string;
}

interface Location {
  id: string;
  name: string;
}

interface FilterSideBarProps {
  responsibleList: User[];
  locationList: Location[];
  serviceList: string[];
  beginDate?: Date;
  endDate?: Date;
  onCloseFilterSideBar: () => void;
  cleanFilter?: boolean;
  handleClearFilter?: () => void;
  onApplyFilter: (selectedValues: SelectedValues) => void;
}

export interface SelectedValues {
  responsibleList: { id: string; name: string }[];
  locationList: { id: string; name: string }[];
  serviceList: string[];
  statusList: { id: string; name: string }[];
  beginDate?: Date | null;
  endDate?: Date | null;
  byTime: string;
}

const FilterSideBar: React.FC<FilterSideBarProps> = ({
  responsibleList,
  locationList,
  serviceList,
  cleanFilter = false,
  onCloseFilterSideBar,
  handleClearFilter,
  onApplyFilter,
}) => {
  const refOne = React.useRef<DropDownComponentRef>();
  const refTwo = React.useRef<DropDownComponentRef>();
  const refSFilterStatus = React.useRef<DropDownComponentRef>();

  const saveFiltersToLocalStorage = (filters: SelectedValues) => {
    const filtersToSave = {
      ...filters,
      beginDate: filters.beginDate
        ? filters.beginDate.toISOString()
        : undefined,
      endDate: filters.endDate ? filters.endDate.toISOString() : undefined,
      responsibleList: filters.responsibleList.map((responsible) => ({
        id: responsible.id,
        name: responsible.name,
      })),
      locationList: filters.locationList.map((location) => ({
        id: location.id,
        name: location.name,
      })),
      statusList: filters.statusList.map((status) => ({
        id: status.id,
        name: status.name,
      })),
    };
    localStorage.setItem(
      "verticalServicesFilterValues",
      JSON.stringify(filtersToSave)
    );
  };

  const loadFiltersFromLocalStorage = (): SelectedValues => {
    const savedFilters = localStorage.getItem("verticalServicesFilterValues");
    if (savedFilters) {
      const parsedFilters = JSON.parse(
        savedFilters
      ) as VerticalServicesFilterProps;
      return {
        ...parsedFilters,
        responsibleList: parsedFilters?.responsibleList ?? [],
        locationList: parsedFilters?.locationList ?? [],
        serviceList:
          Array.isArray(parsedFilters?.serviceList) &&
          parsedFilters.serviceList.every((item) => typeof item === "string")
            ? parsedFilters.serviceList
            : [],
        statusList: parsedFilters?.statusList ?? [],
        ...(parsedFilters?.beginDate && {
          beginDate: new Date(parsedFilters.beginDate),
        }),
        ...(parsedFilters?.endDate && {
          endDate: new Date(parsedFilters.endDate),
        }),
        byTime: parsedFilters.byTime ?? "custom",
      };
    }
    return {
      responsibleList: [],
      locationList: [],
      serviceList: [],
      statusList: [],
      beginDate: undefined,
      endDate: undefined,
      byTime: "clear",
    };
  };

  const [selectedValues, setSelectedValues] = React.useState<SelectedValues>(
    loadFiltersFromLocalStorage()
  );

  const cleanFilterSideBar = useCallback(() => {
    const now = new Date();
    const clearedValues = {
      responsibleList: [],
      locationList: [],
      serviceList: [],
      statusList: [],
      beginDate: now,
      endDate: now,
      byTime: "clear",
    };

    setSelectedValues(clearedValues);

    (refOne.current as any)?.cleanData();
    (refTwo.current as any)?.cleanData();
    (refSFilterStatus.current as any)?.cleanData();

    localStorage.removeItem("verticalServicesFilterValues");

    onApplyFilter(clearedValues);
  }, [onApplyFilter]);

  useEffect(() => {
    if (cleanFilter && handleClearFilter) handleClearFilter();
  }, [cleanFilter, handleClearFilter]);

  const aplyFilter = () => {
    const updatedValues = {
      ...selectedValues,
      byTime:
        selectedValues.beginDate && selectedValues.endDate
          ? "custom"
          : selectedValues.byTime,
    };
    setSelectedValues(updatedValues);

    saveFiltersToLocalStorage(updatedValues);

    onApplyFilter(updatedValues);
    onCloseFilterSideBar();
  };

  const handleStartDateChange = (date: any) => {
    if (date && date.isValid()) {
      const nativeDate = date.toDate();
      const updatedValues = { ...selectedValues, beginDate: nativeDate };
      setSelectedValues(updatedValues);
      saveFiltersToLocalStorage(updatedValues);
    } else {
      const updatedValues = { ...selectedValues, beginDate: undefined };
      setSelectedValues(updatedValues);
      saveFiltersToLocalStorage(updatedValues);
    }
  };

  const handleEndDateChange = (date: any) => {
    if (date && date.isValid()) {
      const nativeDate = date.toDate();
      const updatedValues = { ...selectedValues, endDate: nativeDate };
      setSelectedValues(updatedValues);
      saveFiltersToLocalStorage(updatedValues);
    } else {
      const updatedValues = { ...selectedValues, endDate: undefined };
      setSelectedValues(updatedValues);
      saveFiltersToLocalStorage(updatedValues);
    }
  };

  const handleResponsibleChange = (selectedResponsibleValues: string[]) => {
    const updatedValues = {
      ...selectedValues,
      responsibleList: selectedResponsibleValues.map((name) => {
        const user = responsibleList.find((user) => user.name === name);
        return user ? { id: user.id, name: user.name } : { id: "", name };
      }),
    };
    setSelectedValues(updatedValues);
    saveFiltersToLocalStorage(updatedValues);
  };

  const handleLocationChange = (selectedLocationValues: string[]) => {
    const updatedValues = {
      ...selectedValues,
      locationList: selectedLocationValues.map((name) => {
        const location = locationList.find(
          (location) => location.name === name
        );
        return location
          ? { id: location.id, name: location.name }
          : { id: "", name };
      }),
    };
    setSelectedValues(updatedValues);
    saveFiltersToLocalStorage(updatedValues);
  };

  const handleStatusChange = (selectedStatusValues: string[]) => {
    const updatedValues = {
      ...selectedValues,
      statusList: selectedStatusValues.map((name) => {
        const status = Object.values(ServiceStatus).find(
          (status) => status.value === name
        );
        return status
          ? { id: String(status.key), name: status.value }
          : { id: name, name };
      }),
    };
    setSelectedValues(updatedValues);
    saveFiltersToLocalStorage(updatedValues);
  };

  return (
    <Container>
      <HeaderContainer>
        <Title>Filtrar</Title>
        <CloseButton onClick={() => onCloseFilterSideBar()}>X</CloseButton>
      </HeaderContainer>
      <DropDownChecklistComponent
        variant="filled"
        ref={refOne}
        key="responsibleDropdown"
        onSelectedValueChanged={handleResponsibleChange}
        values={responsibleList.map((user) => user.name)}
        placeholder="Responsável"
        backgroundColor={Colors.concrete}
        selectedValues={selectedValues.responsibleList.map(
          (responsible) => responsible.name
        )}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <InputDateComponent
          label="Início"
          variant="filled"
          value={selectedValues?.beginDate ?? undefined}
          format="DD/MM/YYYY"
          enable={true}
          onChange={(date) => handleStartDateChange(date)}
          isGray={true}
        />
        <InputDateComponent
          label="Fim"
          variant="filled"
          value={selectedValues?.endDate ?? undefined}
          format="DD/MM/YYYY"
          enable={true}
          onChange={(date) => handleEndDateChange(date)}
          isGray={true}
        />
      </LocalizationProvider>
      <DropDownChecklistComponent
        variant="filled"
        ref={refTwo}
        key="Localização"
        onSelectedValueChanged={handleLocationChange}
        values={locationList.map((location) => location.name)}
        placeholder="Localização"
        backgroundColor={Colors.concrete}
        selectedValues={selectedValues.locationList.map(
          (location) => location.name
        )}
      />
      <DropDownChecklistComponent
        variant="filled"
        ref={refSFilterStatus}
        key="statusDropdown"
        onSelectedValueChanged={handleStatusChange}
        values={Object.values(ServiceStatus).map((status) => status.value)}
        placeholder="Status"
        backgroundColor={Colors.concrete}
        selectedValues={selectedValues.statusList.map((status) => status.name)}
      />
      <ButtonContainer>
        <ButtonComponent
          text="Limpar filtros"
          type={ButtonType.TRANSPARENT}
          color={Colors.neutralGrayDark}
          onClick={cleanFilterSideBar}
          startIcon={<DeleteOutlinedIcon style={{ color: Colors.balticSea }} />}
        />
        <ButtonComponent
          state={ButtonState.DEFAULT_ENABLED}
          text="Aplicar filtro"
          onClick={aplyFilter}
          startIcon={<CheckIcon />}
        />
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  width: 358px;
  height: 100%;
  grid-template-rows: auto auto auto auto auto auto;
  align-content: start;
  row-gap: 32px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 24px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  margin-top: 29px;
  margin-bottom: 2px;
`;

const Title = styled.div`
  color: ${Colors.primaryColor};
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 12px;
`;

const CloseButton = styled(CloseIcon)`
  cursor: pointer;
  font-size: 24px;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: end;
  gap: 24px;
  margin-top: 32px;
`;

export default FilterSideBar;
