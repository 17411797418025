import { api } from "../api";
import ICriteriaEntity from "./entity/CriteriaEntity";

export default class CriteriaService {
  fetchCriteria = async (): Promise<ICriteriaEntity[]> => {
    try {
      const response = await api.get("api/criteria");
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
