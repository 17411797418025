import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { createGlobalStyle } from "styled-components/macro";
import { GlobalStyleProps } from "../types/styles";

const SupplyLayoutStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
    overflow-x: hidden;
  }

  body {
    background: #d8d8d8;
    margin: 0;
    padding: 0;
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

type Props = {
  children?: React.ReactNode;
};

const SupplyLayout: React.FC<Props> = ({ children }) => {
  return (
    <Root>
      <SupplyLayoutStyle />
      {children}
    </Root>
  );
};

export default SupplyLayout;
