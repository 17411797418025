import {
  CellContainer,
  CellText,
  ContentContainer,
  MouseHoverContainer,
  TagFinished,
  TagInNegotiation,
  TagNotCompleted,
  TagNotStarted,
  TagOverdue,
  TagPhase,
  StyledMenu,
  StyledMenuItem,
  Link,
} from "./PurchasingAndContractingListItem.styles";
import { ReactComponent as ThreeDots } from "../../../../assets/three_dots.svg";
import PurchasingModel from "../../models/PurchasingModel";
import ContractingModel from "../../models/ContractingMode";
import { PurchasingPlanStatusENUM } from "../../models/PurchasingPlanStatusENUM";
import { ContractingPlanStatusENUM } from "../../models/ContractingPlanStatusENUM";
import { PurchasingPhaseENUM } from "../../models/PurchasingPhaseENUM";
import { ContractingPhaseENUM } from "../../models/ContractingPhaseENUM";
import { MouseEventHandler, useState } from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import PlanTooltip from "../PlanTooltip/PlanTooltop";
import { PurchasingContractingENUM } from "../../models/PurchasingContractingENUM";
import { useAuth } from "../../../../contexts/auth";
import { isEapTeamORClientManager } from "../../../../config/app-info";
import { Colors } from "../../../../theme/v2/variants";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PurchasingAndContractingFilesModal from "../PurchasingAndContractingFilesModal/PurchasingAndContractingFilesModal";
import navbar from "../../../../components/navbar/Navbar";

interface PurchasingAndContractingListItemProps {
  purchasingModel?: PurchasingModel;
  contractingModel?: ContractingModel;
  isMobile?: boolean;
  index: number;
  onDelete: (id: number, type: PurchasingContractingENUM) => void;
  onEdit: (id: number) => void;
  onClickItem: (id: number) => void;
  initAndCompleteNegotiationCallback: (
    id: number,
    isPhaseInitiated: boolean,
    type: PurchasingContractingENUM
  ) => void;
  returnPhaseCallback: (id: number, type: PurchasingContractingENUM) => void;
}

const PurchasingAndContractingListItem: React.FC<
  PurchasingAndContractingListItemProps
> = ({
  purchasingModel,
  contractingModel,
  isMobile,
  index,
  onDelete,
  onClickItem,
  onEdit,
  initAndCompleteNegotiationCallback,
  returnPhaseCallback,
}) => {
  const name = purchasingModel?.name || contractingModel?.name || "--";
  const planType = purchasingModel?.name ? "PURCHASING" : "CONTRACTING";
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const exportImportMenuIsOpen = Boolean(anchorEl);

  const planId = purchasingModel?.id || contractingModel?.id;

  const supplierQuotationDate: Date | undefined =
    purchasingModel?.supplierQuotationDate ||
    contractingModel?.supplierQuotationDate;

  const supplierDateLimitOrNegotiationDateLimit: Date | undefined =
    purchasingModel?.purchasingDateLimit ||
    contractingModel?.contractingDateLimit;
  const deliveryDateLimitOrHireDateLimit: Date | undefined =
    purchasingModel?.deliveryDateLimit || contractingModel?.deliveryDateLimit;

  const purchasingStatus = purchasingModel?.status;
  const contractingStatus = contractingModel?.status;
  const phase = purchasingModel?.phase || contractingModel?.phase || "--";
  const responsibleName =
    purchasingModel?.responsisbleName ||
    contractingModel?.responsisbleName ||
    "--";

  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleThreeDotsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenFilesModal = () => {
    setOpenModal(!openModal);
  };

  const handleDelete = () => {
    if (purchasingModel && purchasingModel.id) {
      onDelete(purchasingModel.id, PurchasingContractingENUM.PURCHASING);
    }

    if (contractingModel && contractingModel.id) {
      onDelete(contractingModel.id, PurchasingContractingENUM.CONTRACTING);
    }
  };

  const handleEdit = () => {
    if (purchasingModel && purchasingModel.id) {
      onEdit(purchasingModel.id);
    }

    if (contractingModel && contractingModel.id) {
      onEdit(contractingModel.id);
    }
  };

  const handleOnClick = () => {
    if (purchasingModel && purchasingModel.id) {
      onClickItem(purchasingModel.id);
    }

    if (contractingModel && contractingModel.id) {
      onClickItem(contractingModel.id);
    }
  };

  const handleReturnPhase = () => {
    if (purchasingModel && purchasingModel.id) {
      returnPhaseCallback(
        purchasingModel.id,
        PurchasingContractingENUM.PURCHASING
      );
    }
    if (contractingModel && contractingModel.id) {
      returnPhaseCallback(
        contractingModel.id,
        PurchasingContractingENUM.CONTRACTING
      );
    }
  };

  const handleInitAndCompleteNegotiation = () => {
    if (purchasingModel && purchasingModel.id) {
      initAndCompleteNegotiationCallback(
        purchasingModel.id,
        purchasingModel.isPhaseInitiated === true,
        PurchasingContractingENUM.PURCHASING
      );
    }

    if (contractingModel && contractingModel.id) {
      initAndCompleteNegotiationCallback(
        contractingModel.id,
        contractingModel.isPhaseInitiated === true,
        PurchasingContractingENUM.CONTRACTING
      );
    }
  };

  const onMouseEnter = (event: any) => {
    if (isMouseOver !== undefined) {
      setIsMouseOver(true);
    }
  };

  const onMouseLeave = (event: any) => {
    if (isMouseOver) {
      setIsMouseOver(false);
    }
  };

  return (
    <ContentContainer onMouseLeave={onMouseLeave}>
      <CellContainer isVisible={true} onMouseEnter={onMouseEnter}>
        <CellText
          style={{
            fontWeight: "700",
            color: isMouseOver ? Colors.LinkColor : Colors.textColorBlack,
          }}
        >
          <Link onClick={handleOnClick}>{name}</Link>
        </CellText>
      </CellContainer>
      <CellContainer isVisible={true}>
        <CellText>
          {supplierQuotationDate?.toLocaleDateString("PT-BR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }) || "--"}
        </CellText>
      </CellContainer>
      <CellContainer isVisible={isMobile ? false : true}>
        <CellText>
          {supplierDateLimitOrNegotiationDateLimit?.toLocaleDateString(
            "PT-BR",
            {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }
          ) || "--"}
        </CellText>
      </CellContainer>
      <CellContainer isVisible={isMobile ? false : true}>
        <CellText>
          {deliveryDateLimitOrHireDateLimit?.toLocaleDateString("PT-BR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }) || "--"}
        </CellText>
      </CellContainer>
      <CellContainer isVisible={isMobile ? false : true}>
        <CellText>
          {purchasingModel &&
            (
              purchasingModel.purchasingItems?.reduce(
                (acc, item) => acc + (item.cost || 0),
                0
              ) || (purchasingModel.cost ? Number(purchasingModel.cost) : 0)
            ).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
          {contractingModel &&
            (
              contractingModel.contractingItems?.reduce(
                (acc, item) => acc + (item.cost || 0),
                0
              ) || (contractingModel.cost ? Number(contractingModel.cost) : 0)
            ).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
        </CellText>
      </CellContainer>
      <CellContainer isVisible={isMobile ? false : true}>
        {purchasingStatus && (
          <div>
            {purchasingStatus === PurchasingPlanStatusENUM.IN_PROGRESS && (
              <TagInNegotiation>{"Em progresso"}</TagInNegotiation>
            )}

            {purchasingStatus === PurchasingPlanStatusENUM.NOT_STARTED && (
              <TagNotStarted>{"Não iniciado"}</TagNotStarted>
            )}

            {purchasingStatus === PurchasingPlanStatusENUM.OVERDUE && (
              <TagOverdue>{"Atrasado"}</TagOverdue>
            )}

            {purchasingStatus === PurchasingPlanStatusENUM.INCOMPLETE && (
              <TagNotCompleted>{"Incompleto"}</TagNotCompleted>
            )}

            {purchasingStatus ===
              PurchasingPlanStatusENUM.TOTALLY_COMPLETED && (
              <TagFinished>{"Finalizado"}</TagFinished>
            )}
          </div>
        )}

        {contractingStatus && (
          <div>
            {contractingStatus === ContractingPlanStatusENUM.IN_PROGRESS && (
              <TagInNegotiation>{"Em progresso"}</TagInNegotiation>
            )}

            {contractingStatus === ContractingPlanStatusENUM.NOT_STARTED && (
              <TagNotStarted>{"Não iniciado"}</TagNotStarted>
            )}

            {contractingStatus === ContractingPlanStatusENUM.OVERDUE && (
              <TagOverdue>{"Atrasado"}</TagOverdue>
            )}

            {contractingStatus === ContractingPlanStatusENUM.INCOMPLETE && (
              <TagNotCompleted>{"Incompleto"}</TagNotCompleted>
            )}
            {contractingStatus ===
              ContractingPlanStatusENUM.TOTALLY_COMPLETED && (
              <TagFinished>{"Finalizado"}</TagFinished>
            )}
          </div>
        )}
      </CellContainer>
      <CellContainer isVisible={isMobile ? false : true}>
        {purchasingModel && phase === PurchasingPhaseENUM.ON_APPROVAL && (
          <TagPhase>{"Em aprovação"}</TagPhase>
        )}

        {purchasingModel && phase === PurchasingPhaseENUM.PURCHASING && (
          <TagPhase>{"Comprar"}</TagPhase>
        )}

        {purchasingModel && phase === PurchasingPhaseENUM.QUOTATION && (
          <TagPhase>{"Cotação"}</TagPhase>
        )}

        {purchasingModel &&
          phase === PurchasingPhaseENUM.ON_APPROVAL_RECEIPT && (
            <TagPhase>{"Aprovar entrega"}</TagPhase>
          )}

        {purchasingModel && phase === PurchasingPhaseENUM.TOTALLY_COMPLETED && (
          <div>--</div>
        )}

        {contractingModel && phase === ContractingPhaseENUM.ON_APPROVAL && (
          <TagPhase>{"Em aprovação"}</TagPhase>
        )}

        {contractingModel && phase === ContractingPhaseENUM.CONTRACTING && (
          <TagPhase>{"Contratação"}</TagPhase>
        )}

        {contractingModel && phase === ContractingPhaseENUM.QUOTATION && (
          <TagPhase>{"Cotação"}</TagPhase>
        )}

        {contractingModel &&
          phase === PurchasingPhaseENUM.ON_APPROVAL_RECEIPT && (
            <TagPhase>{"Aprovar serviço"}</TagPhase>
          )}
      </CellContainer>
      <CellContainer isVisible={isMobile ? false : true}>
        <CellText>{responsibleName}</CellText>
      </CellContainer>
      <CellContainer isVisible={isMobile ? false : true}>
        <IconButton onClick={handleThreeDotsClick}>
          <MoreVertIcon
            sx={{
              color: isMouseOver ? Colors.LinkColor : Colors.textColorBlack,
              fontSize: 26,
            }}
          />
        </IconButton>
        <StyledMenu
          id="basic-menu"
          anchorEl={anchorEl}
          open={exportImportMenuIsOpen}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {purchasingModel &&
            purchasingModel?.status !==
              PurchasingPlanStatusENUM.TOTALLY_COMPLETED && (
              <StyledMenuItem
                disabled={
                  (purchasingModel?.responsibleId === undefined ||
                    isEapTeamORClientManager(user.role) === false) &&
                  purchasingModel?.responsibleId !== user.id
                }
                onClick={() => handleInitAndCompleteNegotiation()}
              >
                <ListItemIcon>
                  <ArrowBackIcon fontSize="small" />
                </ListItemIcon>
                {purchasingModel?.isPhaseInitiated === false
                  ? "Iniciar fase"
                  : "Finalizar fase"}
              </StyledMenuItem>
            )}

          {purchasingModel &&
            purchasingModel?.phase !== PurchasingPhaseENUM.QUOTATION && (
              <StyledMenuItem
                disabled={
                  (purchasingModel?.responsibleId === undefined ||
                    !isEapTeamORClientManager(user.role)) &&
                  purchasingModel?.responsibleId !== user.id
                }
                onClick={() => handleReturnPhase()}
              >
                <ListItemIcon>
                  <ArrowBackIcon
                    fontSize="small"
                    sx={{ color: Colors.neutralColorLight }}
                  />
                </ListItemIcon>
                Retornar fase
              </StyledMenuItem>
            )}

          {contractingModel &&
            contractingModel?.phase !== ContractingPhaseENUM.QUOTATION && (
              <StyledMenuItem
                disabled={
                  (contractingModel?.responsibleId === undefined ||
                    !isEapTeamORClientManager(user.role)) &&
                  contractingModel?.responsibleId !== user.id
                }
                onClick={() => handleReturnPhase()}
              >
                <ListItemIcon>
                  <ArrowBackIcon
                    fontSize="small"
                    sx={{ color: Colors.neutralColorLight }}
                  />
                </ListItemIcon>
                Retornar fase
              </StyledMenuItem>
            )}

          {contractingModel &&
            contractingModel?.status !==
              ContractingPlanStatusENUM.TOTALLY_COMPLETED && (
              <StyledMenuItem
                disabled={
                  contractingModel?.responsibleId === undefined ||
                  isEapTeamORClientManager(user.role) === false
                }
                onClick={() => handleInitAndCompleteNegotiation()}
              >
                <ListItemIcon>
                  <ArrowBackIcon
                    fontSize="small"
                    sx={{ color: Colors.neutralColorLight }}
                  />
                </ListItemIcon>
                {contractingModel?.isPhaseInitiated === false
                  ? "Iniciar fase"
                  : "Finalizar fase"}
              </StyledMenuItem>
            )}
          <StyledMenuItem onClick={() => handleEdit()}>
            <ListItemIcon>
              <CreateOutlinedIcon
                fontSize="small"
                sx={{ color: Colors.neutralColorLight }}
              />
            </ListItemIcon>
            Editar plano
          </StyledMenuItem>
          <StyledMenuItem onClick={() => handleOpenFilesModal()}>
            <ListItemIcon>
              <InsertDriveFileOutlinedIcon
                fontSize="small"
                sx={{ color: Colors.neutralColorLight }}
              />
            </ListItemIcon>
            Arquivos
          </StyledMenuItem>
          <StyledMenuItem onClick={() => handleDelete()}>
            <ListItemIcon>
              <DeleteOutlineOutlinedIcon
                fontSize="small"
                sx={{ color: Colors.neutralColorLight }}
              />
            </ListItemIcon>
            Excluir plano
          </StyledMenuItem>
        </StyledMenu>
      </CellContainer>

      {isMouseOver && (
        <MouseHoverContainer
          style={index === 0 || index === 1 ? { marginTop: "5px" } : {}}
        >
          <PlanTooltip
            onClickItem={handleEdit}
            requester={purchasingModel?.requesterResponsable?.name || ""}
            name={purchasingModel?.name || contractingModel?.name || ""}
            // quantity={
            //   purchasingModel?.quantity || contractingModel?.quantity || 0
            // }
            // unit={purchasingModel?.unit || contractingModel?.unit || ""}
            // specifications={
            //   purchasingModel?.specifications ||
            //   contractingModel?.specifications ||
            //   ""
            // }
          />
        </MouseHoverContainer>
      )}
      {openModal && (
        <PurchasingAndContractingFilesModal
          planId={planId}
          planType={planType}
          toggleModal={handleOpenFilesModal}
          open={openModal}
        />
      )}
    </ContentContainer>
  );
};

export default PurchasingAndContractingListItem;
