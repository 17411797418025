import styled from "styled-components";
import { Colors } from "../../../../theme/v2/variants";
import { MOBILE_BREAKPOINT } from "../../../../utils/mobileDimen";

const HeaderCellContainer = styled.div<{ isVisible?: boolean }>`
  display: grid;
  align-items: center;
  justify-content: start;
  background-color: ${Colors.neutralColorLight};
  padding-left: 10px;
  height: 52px;

  /* visibility: ${(props) => (props.isVisible ? "visible" : "hidden")}; */

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 52px;
  }

  &:first-child {
    border-top-left-radius: 8px;
    padding-left: 16px;
  }

  &:last-child {
    border-top-right-radius: 8px;
  }
`;

const HeaderCellText = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  color: ${Colors.textColorBlack};
`;

export { HeaderCellContainer, HeaderCellText };
