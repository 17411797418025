import axios from "axios";

export const api = axios.create({
  baseURL:
    process.env.REACT_APP_HOST_API || "https://api.engenhariaeperformance.com/",
  withCredentials: true,
  timeout: 300000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
    "Access-Control-Allow-Credentials": "true",
  },
});
