import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import styled from "styled-components";
import { BaseState } from "../../../pages/Actions/ViewActions/utils/BaseState";
import DashboardCard from "../../Cards/DashboardCard/DashboardCard";
import React, { useEffect, useState } from "react";
import ActionsService from "../../../services/action/ActionsService";
import Subtitle from "../Subtitle/Subtitle";
import { Colors } from "../../../theme/variants";
import { set } from "date-fns/esm";
Chart.register(CategoryScale);

interface ActionsEvolutionProps {
  constructionId?: string;
  year?: string;
}

const borderColor = "#0000";
const baseConfig = {
  borderColor: borderColor,
  borderWidth: 0,
  borderRadius: Number.MAX_VALUE,
  borderSkipped: false,
  categoryPercentage: 0.3,
  data: [],
};

const baseData = {
  labels: [],
  datasets: [
    {
      label: "Novas",
      backgroundColor: Colors.primaryColorLight,
      ...baseConfig,
      datalabels: {
        display: false,
      },
    },
    {
      label: "Ações programadas",
      backgroundColor: Colors.primaryColorNeutralBlue,
      ...baseConfig,
      datalabels: {
        display: false,
      },
    },
    {
      label: "Concluídas",
      backgroundColor: Colors.secondaryColor,
      ...baseConfig,
      datalabels: {
        display: false,
      },
    },
  ],
};

const ActionsEvolution: React.FC<ActionsEvolutionProps> = ({
  constructionId,
  year,
}) => {
  const actionService = new ActionsService();
  const [state, setState] = useState<BaseState>(BaseState.loading);
  const [chartData, setChartData] = useState<any>({ labels: [], datasets: [] });
  const [month, setMonth] = React.useState<Date>(new Date());
  const [totalActions, setTotalActions] = React.useState<number[]>([]);
  const [newActions, setNewActions] = React.useState<number[]>([]);

  useEffect(() => {
    month.setFullYear(Number(year));
    findActionsByPeriod(constructionId, month);
  }, [constructionId, year]);

  const findActionsByPeriod = async (constructionId?: string, date?: Date) => {
    if (!constructionId || !date) {
      return;
    }
    setState(BaseState.loading);

    await actionService
      .findActionsEvolutionByPeriod(constructionId, date)
      .then((response) => {
        handleResponseData(response);
        setState(BaseState.success);
      })
      .catch((error) => {
        setState(BaseState.error);
      });
  };

  const handleResponseData = ({ periods, data }: any) => {
    baseData.labels = periods;
    if (!data.futureMonth) {
      baseData.datasets[0].data = data.news;
      baseData.datasets[2].data = data.concluded;
    } else {
      baseData.datasets[0].data = [];
      baseData.datasets[2].data = [];
    }

    baseData.datasets[1].data = data.totalScheduled;

    setChartData(baseData);
    setTotalActions(data.total);
    setNewActions(data.newActionsByPeriod);
  };

  const onMonthChanged = (month: string, monthAsNumber: number) => {
    const date = new Date();
    date.setMonth(monthAsNumber);
    date.setFullYear(Number(year));
    setMonth(date);
    findActionsByPeriod(constructionId, date);
  };

  return (
    <DashboardCard
      title="Evolução das Ações"
      state={state}
      onMonthChanged={onMonthChanged}
      selectorLabel="Mês"
    >
      <RootContainer>
        <ChartAndSubtitleContainer>
          <ChartContainer>
            <Bar
              width={800}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                },
                maintainAspectRatio: false,
              }}
              data={chartData}
            />
          </ChartContainer>
          <SubtitleGroup>
            <Subtitle color={Colors.primaryColorLight} text="Novas" />
            <Subtitle
              color={Colors.primaryColorNeutralBlue}
              text="Ações Programadas"
            />
            <Subtitle color={Colors.secondaryColor} text="Concluídas" />
          </SubtitleGroup>
        </ChartAndSubtitleContainer>
        <TotalActionsContainer>
          <TotalActionsTitle>Total de ações cadastradas</TotalActionsTitle>
          <TotalActionsNumber>
            {totalActions[totalActions.length - 1]}
          </TotalActionsNumber>
          {newActions[0] !== undefined ? (
            <TotalActionsSubtitle>Variância no período:</TotalActionsSubtitle>
          ) : null}
          <VariationByDateContainer lenght={chartData.labels}>
            {chartData.labels.map((label: string, index: number) => {
              if (newActions[index] === undefined) {
                return;
              }

              return (
                <VariationByDateItem>
                  <VariationByDateTitle>
                    {label + ":  " + totalActions[index]}
                  </VariationByDateTitle>
                  <VariationByDateNumber
                    style={{
                      backgroundColor: `${
                        baseData.datasets[0].data[index] > 0
                      } ? #${Colors.feedbackSuccess} : #${
                        Colors.primaryColorDark
                      }`,
                    }}
                  >
                    {newActions[index]}
                  </VariationByDateNumber>
                </VariationByDateItem>
              );
            })}
          </VariationByDateContainer>
        </TotalActionsContainer>
      </RootContainer>
    </DashboardCard>
  );
};

const RootContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 238px;
  column-gap: 20px;
`;

const TotalActionsContainer = styled.div`
  display: grid;
  grid-template-rows: min-content min-content min-content min-content;
  column-gap: 20px;
  justify-content: center;
  border-left: 1px solid ${Colors.neutralColorGray};
`;

const TotalActionsTitle = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${Colors.textColorBlack};
  margin-top: 16px;
`;

const TotalActionsNumber = styled.div`
  font-family: "Noto Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${Colors.secondaryColor};
  margin-top: 4px;
  text-align: center;
`;

const TotalActionsSubtitle = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${Colors.textColorBlack};
  margin-top: 4px;
  margin-left: 8px;
`;

const VariationByDateContainer = styled.div<{
  lenght: number;
}>`
  display: grid;
  grid-template-rows: repeat(${(props) => props.lenght}, min-content);
  column-gap: 12px;
  margin-left: 16px;
`;

const VariationByDateItem = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 8;
  margin-top: 12px;
  justify-content: start;
  align-content: center;
`;

const VariationByDateTitle = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${Colors.textColorBlack};
`;

const VariationByDateNumber = styled.div<{
  // lenght: number;
}>`
  width: 32px;
  height: 16px;
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 16px;
  padding-bottom: 4px;
  color: ${Colors.neutralColorWhite};
  background-color: ${Colors.primaryColorDark};
  margin-top: 3px;
  margin-left: 8px;
`;

const ChartAndSubtitleContainer = styled.div`
  padding-left: 44px;
  padding-right: 44px;
  padding-top: 28px;
  display: grid;
  grid-template-rows: max-content max-content;
`;

const ChartContainer = styled.div`
  height: 240px;
`;

const SubtitleGroup = styled.div`
  display: grid;
  grid-template-columns: min-content min-content min-content;
  column-gap: 20px;
  width: fit-content;
  justify-self: center;
  margin-bottom: 16px;
`;

export default ActionsEvolution;
