import React, { MutableRefObject } from "react";
import styled from "styled-components";
import * as Styles from "../VerticalServicePageToPdf.styles";
import { BaseState } from "../../../Actions/ViewActions/utils/BaseState";
import VerticalServiceGroup from "../../../../services/interfaces/VerticalServiceGroup";
import AdvancementIndexVerticalComponent from "../AdvancementIndexVerticalComponent";
import { AdvancementIndexType } from "../AdvancementIndexComponent";
import { VerticalServiceProgressModel } from "../../../../services/interfaces/VerticalServiceProgressModel";
import { ConstructionDatesProps } from ".";
import { Tabs } from "@mui/material";
import moment from "moment";
import { TABS } from "../../../../constants/tabs";
import { Colors } from "../../../../theme/v2/variants";
import TableItem from "../TableItem";
import {
  getPeriodBySelectedTab,
  getPeriodBySelectedTabForPrintedState,
} from "./logic";
import { parseAsUTCWithOffset } from "../../../../utils/date";

type Props = {
  isConstructionNotSelected: boolean;
  pdfRefPageHeader: MutableRefObject<null>;
  pdfRefTableHeader: MutableRefObject<null>;
  pdfRefTableContent: MutableRefObject<null>;
  tableContent: VerticalServiceGroup[];
  progressByConstruction?: VerticalServiceProgressModel;
  constructionDates?: ConstructionDatesProps;
  timePeriodListState: any[];
  clientName: string;
  constructionName: string;
  selectedTab: string;
  showItemsSameDataByGroup: boolean;
  dates?: { startDate: Date; endDate: Date };
};

export const Model = ({
  isConstructionNotSelected,
  pdfRefPageHeader,
  pdfRefTableHeader,
  pdfRefTableContent,
  tableContent,
  progressByConstruction,
  constructionDates,
  timePeriodListState,
  clientName,
  constructionName,
  selectedTab,
  showItemsSameDataByGroup,
  dates,
}: Props) => (
  <Container>
    <Styles.PageContainer>
      {BaseState?.success && !isConstructionNotSelected ? (
        <Styles.ContentContainer>
          <Styles.HeaderContainer ref={pdfRefPageHeader}>
            <Styles.AboutContainer>
              <Styles.Title>Vertical de Serviços</Styles.Title>
              {!!tableContent?.length && (
                <Styles.AdvancementContainer>
                  <AdvancementIndexVerticalComponent
                    title="Índice de avanços físicos"
                    value={progressByConstruction?.physicalProgress || 0}
                    type={AdvancementIndexType.PHYSICAL}
                    budgetUsed={
                      progressByConstruction?.currentFinancialCoast || 0
                    }
                    showMaterialUserDescription={false}
                    showBudgetDescription={false}
                    printState={true}
                  />
                </Styles.AdvancementContainer>
              )}
            </Styles.AboutContainer>
            <Styles.PrintStatePageDescriptionContainer>
              <Styles.PrintStatePageDescriptionText>
                {`Empresa: ${clientName}`}
              </Styles.PrintStatePageDescriptionText>
              <Styles.PrintStatePageDescriptionText>
                {`Obra: ${constructionName}`}
              </Styles.PrintStatePageDescriptionText>

              <Styles.PrintStatePageDescriptionText>
                {`Período: ${
                  dates &&
                  getPeriodBySelectedTabForPrintedState(
                    dates.startDate.toISOString(),
                    dates.endDate.toISOString(),
                    selectedTab
                  )
                }`}
              </Styles.PrintStatePageDescriptionText>
            </Styles.PrintStatePageDescriptionContainer>
          </Styles.HeaderContainer>

          <Styles.TableContainer ref={pdfRefTableHeader}>
            <Styles.TableHeader>
              <span>
                {moment(constructionDates?.beginningDate).isValid() &&
                  moment(constructionDates?.endDate).isValid() && (
                    <>
                      Período da obra:{" "}
                      <Styles.PeriodBold>
                        {moment(
                          constructionDates?.constructionBeginningDate
                        ).format("DD/MM/YYYY")}{" "}
                        -{" "}
                        {moment(constructionDates?.constructionEndDate).format(
                          "DD/MM/YYYY"
                        )}{" "}
                      </Styles.PeriodBold>{" "}
                      ({constructionDates?.constructionDays} total -{" "}
                      {constructionDates?.constructionWeekDays} dias úteis)
                    </>
                  )}
              </span>
              <Styles.TabContainer>
                <Tabs
                  value={selectedTab}
                  onChange={() => null}
                  style={{ width: "100%" }}
                >
                  <Styles.CustomTab value={TABS.semanal} label="Semanal" />
                  <Styles.CustomTab value={TABS.mensal} label="Mensal" />
                  <Styles.CustomTab
                    value={TABS.trimestral}
                    label="Trimestral"
                  />
                  <Styles.CustomTab value={TABS.semestral} label="Semestral" />
                  <Styles.CustomTab value={TABS.anual} label="Anual" />
                  <Styles.CustomTab value={TABS.custom} label="" />
                </Tabs>
              </Styles.TabContainer>
            </Styles.TableHeader>
          </Styles.TableContainer>

          <Styles.TableContainer ref={pdfRefTableContent}>
            <Styles.HorizontalListPrimaryContainer>
              <Styles.VerticalListRoot
                columnQuantity={timePeriodListState?.length}
              >
                {!!tableContent?.length && (
                  <Styles.EmptyGridItem isPrintState={true} />
                )}

                {!!timePeriodListState?.length &&
                  timePeriodListState.map(
                    ({ startDateAsDate, endDateAsDate, space }) => {
                      const startDate = new Date(startDateAsDate);
                      const endDate = new Date(endDateAsDate);
                      const today = new Date();

                      const isTodayInRange =
                        today >= startDate && today <= endDate;

                      return (
                        <Styles.ItemVerticalListHeader
                          key={space}
                          style={{
                            minWidth:
                              tableContent?.length === 0 ? "180px" : "auto",
                            maxWidth: "100%",
                            borderTopLeftRadius: isTodayInRange
                              ? "16px"
                              : "0px",
                            borderTopRightRadius: isTodayInRange
                              ? "16px"
                              : "0px",
                            backgroundColor: isTodayInRange
                              ? Colors.neutralColorSurface
                              : Colors.neutralColorWhite,
                            fontWeight: isTodayInRange ? 700 : 400,
                            borderTop: isTodayInRange
                              ? `1px solid ${Colors.lightMercury}`
                              : "none",
                            borderLeft: isTodayInRange
                              ? `1px solid ${Colors.lightMercury}`
                              : "none",
                            borderRight: isTodayInRange
                              ? `1px solid ${Colors.lightMercury}`
                              : "none",
                          }}
                        >
                          {getPeriodBySelectedTab(
                            parseAsUTCWithOffset(startDateAsDate),
                            parseAsUTCWithOffset(endDateAsDate),
                            selectedTab
                          )}
                        </Styles.ItemVerticalListHeader>
                      );
                    }
                  )}
              </Styles.VerticalListRoot>

              <Styles.HorizontalListContainer>
                {tableContent.map((serviceType) => {
                  return (
                    <React.Fragment key={serviceType?.id}>
                      <Styles.FullItemContainer
                        columnQuantity={timePeriodListState?.length + 1}
                      >
                        <Styles.ItemHeadTypeIndicator>
                          {serviceType?.name}
                        </Styles.ItemHeadTypeIndicator>
                        {timePeriodListState?.length &&
                          timePeriodListState.map((value, index) => {
                            return (
                              <Styles.ItemHeaderGridContainerEmpty
                                key={"timePeriodList - " + index}
                                isPrintState={true}
                              />
                            );
                          })}
                      </Styles.FullItemContainer>

                      {!!serviceType?.services?.length &&
                        serviceType?.services.map((item) => {
                          return (
                            <React.Fragment key={item?.uniqueApplicationId}>
                              {
                                <Styles.FullItemEmptyContainerCustomGrid
                                  gridTemplate={`190px ${
                                    (item as any).gridTemplate
                                  }`}
                                >
                                  <Styles.ItemHeadServiceIndicator>
                                    {item?.name}
                                  </Styles.ItemHeadServiceIndicator>
                                  {(item as any)?.gridItems.map(
                                    (gridItem: any, index: any) => {
                                      return (
                                        <TableItem
                                          gridItem={gridItem}
                                          index={index}
                                          serviceType={serviceType}
                                          item={item}
                                          onMouseOver={() => null}
                                          onMouseLeave={() => null}
                                          serviceOnClick={() => null}
                                          hoveredItem={null}
                                          clickedItem={null}
                                          moreItemsOnClick={() => null}
                                          showOnlyOneService={
                                            showItemsSameDataByGroup
                                          }
                                          serviceClickEditActionCallback={() =>
                                            null
                                          }
                                          serviceClickDeleteActionCallback={() =>
                                            null
                                          }
                                          openUploadImageDialog={() => null}
                                          openUpdateServiceProgressDialog={() =>
                                            null
                                          }
                                          addPurchasingContractingPlanOnClick={() =>
                                            null
                                          }
                                        />
                                      );
                                    }
                                  )}
                                </Styles.FullItemEmptyContainerCustomGrid>
                              }
                            </React.Fragment>
                          );
                        })}
                    </React.Fragment>
                  );
                })}
              </Styles.HorizontalListContainer>
            </Styles.HorizontalListPrimaryContainer>
          </Styles.TableContainer>
        </Styles.ContentContainer>
      ) : null}
    </Styles.PageContainer>
  </Container>
);

const Container = styled.div`
  position: absolute;
  left: -9999px;
  top: -9999px;
  width: 97%;
`;
