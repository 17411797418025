import styled from "styled-components";
import { Colors } from "../../../theme/variants";
import DropDownComponent from "../../InputValues/DropDownComponent";
import { BaseState } from "../../../pages/Actions/ViewActions/utils/BaseState";
import LoadingComponent from "../../StateComponents/LoadingComponent";
import ErrorComponent from "../../StateComponents/ErrorComponent";
import React, { useEffect } from "react";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";

const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

interface DashboardCardProps {
  title: string;
  children?: React.ReactNode;
  onMonthChanged?: (month: string, monthAsNumber: number) => void;
  state?: BaseState;
  dropDownData?: string[] | null;
  isDropDownVisible?: boolean;
  selectorLabel?: string;
}
const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  children,
  onMonthChanged,
  state,
  dropDownData,
  isDropDownVisible = true,
  selectorLabel,
}) => {
  const [month, setMonth] = React.useState<number>(new Date().getMonth());
  const [defaultValue, setDefaultValue] = React.useState<string>();

  DashboardCard.defaultProps = {
    state: BaseState.error,
  };
  const dropDownComponentOnSelectedValueChanged = (
    key: string,
    value: string
  ) => {
    const monthAsInt = dropDownData
      ? dropDownData.indexOf(value)
      : months.indexOf(value);
    if (monthAsInt !== -1) {
      onMonthChanged?.(value, monthAsInt);
      setMonth(monthAsInt);
    }
  };

  useEffect(() => {
    setDefaultValue(dropDownData ? dropDownData[0] : months[month]);
  }, [dropDownData]);

  return (
    <CardContainer>
      <CardTitleContainer>
        <Title>{title}</Title>
        <DropDownContainer>
          {isDropDownVisible ? (
            <DropDownComponent
              componentKey="months"
              key="months"
              values={dropDownData || months}
              label={selectorLabel}
              defaultValue={defaultValue}
              onSelectedValueChanged={dropDownComponentOnSelectedValueChanged}
            />
          ) : null}
        </DropDownContainer>
      </CardTitleContainer>
      <Divider />
      <ChildrenContainer>
        {state === BaseState.success ? children : null}
        {state === BaseState.loading ? (
          <StateContainer>
            <LoadingComponent size={100} />{" "}
          </StateContainer>
        ) : null}
        {state === BaseState.error ? (
          <StateContainer>
            <ErrorComponent />
          </StateContainer>
        ) : null}
      </ChildrenContainer>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  max-width: 100%;
  min-height: 227px;
  position: relative;
  background: ${Colors.textColorLight};
  border-radius: 16px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1px auto;
  align-content: start;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    overflow-x: scroll;
    min-height: 0px;
  }
`;

const CardTitleContainer = styled.div`
  display: grid;
  grid-template-columns: auto 132px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    overflow-x: hidden;
    grid-template-columns: 1fr 132px;
  }
`;

const DropDownContainer = styled.div`
  margin-top: 16px;
  margin-right: 16px;
`;

const Divider = styled.div`
  border: 1px ${Colors.primaryColor} solid;
`;

const Title = styled.div`
  color: #00030c;
  font-size: 16px;
  font-family: "Noto Sans";
  font-weight: 700;
  word-wrap: break-word;
  display: grid;
  margin-top: 24px;
  margin-left: 28px;
  margin-bottom: 16px;
`;

const ChildrenContainer = styled.div`
  display: grid;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    overflow-x: scroll;
  }
`;

const StateContainer = styled.div`
  display: grid;
  margin-top: 60px;
  margin-bottom: 60px;
`;

export default DashboardCard;
