// Layouts
import AuthLayout from "./layouts/Auth";
import DefaultLayout from "./layouts/Default";
import SupplyLayout from "./layouts/SupplyLayout";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import Page404 from "./pages/Auth/Page404";

import Login from "./pages/Login";
import Logout from "./pages/Logout";
import UsersList from "./pages/Users/List";
import UsersForm from "./pages/Users/Form";
import Dashboard from "./pages/Dashboard";
import DashboardV2 from "./pages/Dashboard/Dashboard";
import ConstructionsList from "./pages/Constructions/List";
import ConstructionsForm from "./pages/Constructions/Form";
import RestrictionsList from "./pages/Restrictions/List";
import RestrictionsForm from "./pages/Restrictions/Form";
import ClientsList from "./pages/Clients/List";
import ClientsForm from "./pages/Clients/Form";
import MappingRestrictionsAddForm from "./pages/RestrictionsMapping/AddForm";
import MappingRestrictionsEditForm from "./pages/RestrictionsMapping/EditForm";
import { MappingProvider } from "./contexts/mapping";
import MappingRestrictionsList from "./pages/RestrictionsMapping/List";
import ActionsList from "./pages/Actions/List";
import ActionsEditForm from "./pages/Actions/EditForm";
import ActionsPlanList from "./pages/ActionsPlan/List";
import ActionsPlanEdit from "./pages/ActionsPlan/Edit";
import ActionsPlanningGantt from "./pages/ActionsPlanningGantt";
import { ActionProvider } from "./contexts/action";
import ViewActionsPage from "./pages/Actions/ViewActions/ViewActionsPage";
import VerticalServicePage from "./pages/VerticalService/VerticalServicePage";
import ServiceReportPage from "./pages/VerticalService/pages/ServiceReportPage";
import RestrictionListPage from "./pages/Restrictions/List/RestrictionListPage";
import RestrictionFormPage from "./pages/Restrictions/Form/RestrictionForm";
import OrderOfStepsPage from "./pages/VerticalService/pages/OrderOfSteps/OrderOfStepsPage";
import {
  ORDER_OF_STEPS_PATH,
  PURCHASING_CONTRACTING_PLAN_PATH,
  PURCHASING_CONTRACTING_PLAN_FORM_PATH,
  PURCHASING_CONTRACTING_PLAN_ORDER_DETAIL_PATH,
} from "./config/app-info";
import PurchasingContractingPlanPage from "./pages/PurchasingContractingPlanPage";
import PurchasingAndContractingPlanForm from "./pages/PurchasingContractingPlanPage/Form";
import PurchasingContractingPlanOrderDetail from "./pages/PurchasingContractingPlanPage/Show";
import SuppliersPage from "./pages/Suppliers/SuppliersPage";

const routes = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <Login />,
      },
    ],
  },
  {
    path: "dashboard",
    element: <DefaultLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <DashboardV2 section="Painel de indicadores" />
          </AuthGuard>
        ),
      },
      {
        path: "old",
        element: (
          <AuthGuard>
            <Dashboard section="Painel de indicadores" />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "empresas",
    element: <DefaultLayout />,
    children: [
      {
        path: "listar",
        element: (
          <AuthGuard>
            <ClientsList section="Lista de empresas" />
          </AuthGuard>
        ),
      },
      {
        path: "cadastrar",
        element: (
          <AuthGuard>
            <ClientsForm section="Cadastrar empresa" />
          </AuthGuard>
        ),
      },
      {
        path: "editar/:id",
        element: (
          <AuthGuard>
            <ClientsForm section="Alteração de empresa" />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "obras",
    element: <DefaultLayout />,
    children: [
      {
        path: "listar",
        element: (
          <AuthGuard>
            <ConstructionsList section="Lista de obras" />
          </AuthGuard>
        ),
      },
      {
        path: "cadastrar",
        element: (
          <AuthGuard>
            <ConstructionsForm section="Cadastrar obra" />
          </AuthGuard>
        ),
      },
      {
        path: "editar/:id",
        element: (
          <AuthGuard>
            <ConstructionsForm section="Alteração de obra" />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "restricoes",
    element: <DefaultLayout />,
    children: [
      {
        path: "listar",
        element: (
          <AuthGuard>
            <MappingProvider>
              <RestrictionsList section="Lista de restrições" />
            </MappingProvider>
          </AuthGuard>
        ),
      },
      {
        path: "cadastrar",
        element: (
          <AuthGuard>
            <MappingProvider>
              <RestrictionFormPage />
            </MappingProvider>
          </AuthGuard>
        ),
      },
      {
        path: "editar/:id",
        element: (
          <AuthGuard>
            <MappingProvider>
              <RestrictionsForm section="Alteração de restrição" />
            </MappingProvider>
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "usuarios",
    element: <DefaultLayout />,
    children: [
      {
        path: "listar",
        element: (
          <AuthGuard>
            <UsersList section="Lista de usuários" />
          </AuthGuard>
        ),
      },
      {
        path: "cadastrar",
        element: (
          <AuthGuard>
            <UsersForm section="Cadastrar usuário" />
          </AuthGuard>
        ),
      },
      {
        path: "editar/:id",
        element: (
          <AuthGuard>
            <UsersForm section="Alteração de usuário" />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "mapeamento-restricoes",
    element: <DefaultLayout />,
    children: [
      {
        path: "cadastrar",
        element: (
          <AuthGuard>
            <MappingProvider>
              <RestrictionFormPage />
            </MappingProvider>
          </AuthGuard>
        ),
      },
      {
        path: "listar",
        element: (
          <AuthGuard>
            <MappingProvider>
              <RestrictionListPage />
            </MappingProvider>
          </AuthGuard>
        ),
      },
      {
        path: "editar/:id",
        element: (
          <AuthGuard>
            <MappingProvider>
              <RestrictionFormPage />
            </MappingProvider>
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "planejamento-acoes",
    element: <DefaultLayout />,
    children: [
      {
        path: "listar",
        element: (
          <AuthGuard>
            <MappingProvider>
              <ActionsList section="Planejamento das ações" />
            </MappingProvider>
          </AuthGuard>
        ),
      },
      {
        path: "editar/:id",
        element: (
          <AuthGuard>
            <MappingProvider>
              <ActionProvider>
                <RestrictionFormPage />
              </ActionProvider>
            </MappingProvider>
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "planos-acoes",
    element: <DefaultLayout />,
    children: [
      {
        path: "listar",
        element: (
          <AuthGuard>
            <MappingProvider>
              <ActionsPlanList section="Acompanhar planos de ações" />
            </MappingProvider>
          </AuthGuard>
        ),
      },
      {
        path: "editar/:id",
        element: (
          <AuthGuard>
            <MappingProvider>
              <ActionsPlanEdit section="Plano de ações" />
            </MappingProvider>
          </AuthGuard>
        ),
      },
      {
        path: "gantt",
        element: (
          <AuthGuard>
            <MappingProvider>
              <ActionsPlanningGantt section="Gantt" />
            </MappingProvider>
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "sair",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <Logout />,
      },
    ],
  },
  {
    path: "acompanhar-acoes/:id",
    element: (
      <DefaultLayout>
        <AuthGuard>
          <MappingProvider>
            <ViewActionsPage />
          </MappingProvider>
        </AuthGuard>
      </DefaultLayout>
    ),
    children: [],
  },
  {
    path: "vertical-servicos/",
    element: (
      <DefaultLayout>
        <AuthGuard>
          <MappingProvider>
            <VerticalServicePage />
          </MappingProvider>
        </AuthGuard>
      </DefaultLayout>
    ),
    children: [],
  },
  {
    path: ORDER_OF_STEPS_PATH,
    element: (
      <DefaultLayout>
        <AuthGuard>
          <MappingProvider>
            <OrderOfStepsPage />
          </MappingProvider>
        </AuthGuard>
      </DefaultLayout>
    ),
    children: [],
  },
  {
    path: PURCHASING_CONTRACTING_PLAN_PATH,
    element: (
      <DefaultLayout>
        <AuthGuard>
          <MappingProvider>
            <PurchasingContractingPlanPage />
          </MappingProvider>
        </AuthGuard>
      </DefaultLayout>
    ),
  },
  {
    path: PURCHASING_CONTRACTING_PLAN_FORM_PATH,
    element: (
      <DefaultLayout>
        <AuthGuard>
          <MappingProvider>
            <PurchasingAndContractingPlanForm />
          </MappingProvider>
        </AuthGuard>
      </DefaultLayout>
    ),
  },
  {
    path: PURCHASING_CONTRACTING_PLAN_ORDER_DETAIL_PATH,
    element: (
      <DefaultLayout>
        <AuthGuard>
          <MappingProvider>
            <PurchasingContractingPlanOrderDetail />
          </MappingProvider>
        </AuthGuard>
      </DefaultLayout>
    ),
  },
  {
    path: "vertical-servicos-relatorio",
    element: (
      <DefaultLayout>
        <AuthGuard>
          <MappingProvider>
            <ServiceReportPage />
          </MappingProvider>
        </AuthGuard>
      </DefaultLayout>
    ),
    children: [],
  },
  {
    path: "contratacao/envio-orcamento/:planId",
    element: (
      <SupplyLayout>
        <SuppliersPage type="contracting" />
      </SupplyLayout>
    ),
    children: [],
  },
  {
    path: "compra/envio-orcamento/:planId",
    element: (
      <SupplyLayout>
        <SuppliersPage type="purchasing" />
      </SupplyLayout>
    ),
    children: [],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
