import { isValid, parseISO } from "date-fns";
import moment from "moment";

export const parseAsUTCWithOffset = (date: string): Date => {
  const [year, month, day] = date.split("T")[0].split("-");
  return new Date(Date.UTC(Number(year), Number(month) - 1, Number(day), 3));
};

export const formatDate = (date?: Date): string => {
  if (!date || isNaN(date.getTime())) return "";
  return moment(date).format("DD/MM/YYYY");
};

export const setStartOfDay = (date: string | Date): Date => {
  const newDate = new Date(date);
  newDate.setUTCHours(0, 0, 0, 0);
  return newDate;
};

export const setEndOfDay = (date: string): Date => {
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + 3);
  newDate.setHours(23, 59, 59, 999);
  return newDate;
};

export const formatDateToDayMonth = (input?: Date | string): string => {
  if (!input) return "";

  const date = typeof input === "string" ? parseISO(input) : input;

  if (!isValid(date)) return "";

  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");

  return `${day}/${month}`;
};

export const formatDateLong = (dateInput?: Date | string): string => {
  if (!dateInput) return "Data não disponível";

  const date = typeof dateInput === "string" ? parseISO(dateInput) : dateInput;

  if (!isValid(date)) return "Data não disponível";

  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = date
    .toLocaleString("pt-BR", { month: "short" })
    .replace(".", "");
  const year = date.getUTCFullYear();

  return `${day} ${month.charAt(0).toUpperCase() + month.slice(1)} ${year}`;
};
