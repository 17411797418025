import styled from "styled-components";
import { Colors } from "../../../theme/v2/variants";
import { LinearProgress } from "@mui/material";
import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export enum AdvancementIndexType {
  PHYSICAL = "Físico",
  FINANCIAL = "Financeiro",
}

interface AdvancementIndexComponentProps {
  title: string;
  value: number;
  type?: AdvancementIndexType;
  concludedActions?: number;
  totalActions?: number;
  budgetUsed?: number;
  serviceTypes?: {
    typeId: number;
    typeName: string;
    physicalProgress: number;
    financialProgress: number;
    physicalProgressPercent: number;
  }[];
  isTotal?: boolean;
}

const AdvancementIndexReportComponent: React.FC<
  AdvancementIndexComponentProps
> = ({
  title,
  value,
  type,
  concludedActions,
  totalActions,
  budgetUsed,
  serviceTypes,
  isTotal,
}) => {
  const [openTypes, setOpenTypes] = React.useState<boolean>(false);
  return (
    <Container>
      <TitleContainer>
        <TitleWithButtonContainer>
          {!isTotal &&
            (openTypes ? (
              <ArrowDropUpIcon
                style={{ cursor: "pointer" }}
                onClick={() => setOpenTypes(!openTypes)}
              />
            ) : (
              <ArrowDropDownIcon
                style={{ cursor: "pointer" }}
                onClick={() => setOpenTypes(!openTypes)}
              />
            ))}

          <IndiceTitle isTotal={isTotal}>{title}</IndiceTitle>
        </TitleWithButtonContainer>
        <IndiceValue>
          {value ? Number.parseFloat(value.toFixed(2)) : 0}%
        </IndiceValue>
      </TitleContainer>
      <IndiceContainer>
        <LinearProgress
          sx={{
            backgroundColor: "rgba(217, 217, 217, 0.5)",
            "& .MuiLinearProgress-bar": {
              backgroundColor: Colors.sweetPink,
            },
            height: "4px",
            borderRadius: "9px",
          }}
          variant="determinate"
          value={
            value === null
              ? 0
              : value > 100
              ? 100
              : Number.parseFloat(value.toFixed(2))
          }
        />
      </IndiceContainer>
      {!isTotal && openTypes && (
        <ServiceTypesContainer>
          {serviceTypes?.map((serviceType) => (
            <ServiceTypeContainer>
              <TitleContainer>
                <TitleWithButtonContainer>
                  <KeyboardArrowRightIcon
                    style={{ color: Colors.neutralGrayLight }}
                  />
                  <ServiceTypeTitle>{serviceType.typeName}</ServiceTypeTitle>
                </TitleWithButtonContainer>
                <IndiceValue>
                  {serviceType.physicalProgressPercent
                    ? Number.parseFloat(
                        serviceType.physicalProgressPercent.toFixed(2)
                      )
                    : 0}
                  %
                </IndiceValue>
              </TitleContainer>
              <ServiceTypeProgress>
                <LinearProgress
                  sx={{
                    backgroundColor: "rgba(217, 217, 217, 0.5)",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: Colors.dullLavender,
                    },
                    height: "4px",
                    borderRadius: "9px",
                  }}
                  variant="determinate"
                  value={
                    serviceType.physicalProgressPercent === null
                      ? 0
                      : serviceType.physicalProgressPercent > 100
                      ? 100
                      : Number.parseFloat(
                          serviceType.physicalProgressPercent.toFixed(2)
                        )
                  }
                />
              </ServiceTypeProgress>
            </ServiceTypeContainer>
          ))}
        </ServiceTypesContainer>
      )}
    </Container>
  );
};

const TitleWithButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 19px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
  align-content: start;
  height: fit-content;
`;

const IndiceContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  justify-content: center;
`;

const IndiceTitle = styled.div<{ isTotal?: boolean }>`
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: ${({ isTotal }) => (isTotal ? "700" : "500")};
  line-height: normal;
  color: ${Colors.neutralGrayDark};
`;

const IndiceValue = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${Colors.neutralGrayDark};
`;

const ServiceTypesContainer = styled.div`
  display: grid;
  background-color: ${Colors.alabster};
  padding: 16px 24px;
  border-radius: 8px;
  gap: 16px;
  border: 1px solid ${Colors.concrete};
  margin-top: 24px;
`;

const ServiceTypeContainer = styled.div``;

const ServiceTypeTitle = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${Colors.neutralGrayDark};
`;

const ServiceTypeProgress = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  justify-content: center;
`;

export default AdvancementIndexReportComponent;
