import {
  Box,
  Dialog,
  Typography,
  Stack,
  DialogActions,
  DialogContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ButtonComponent, {
  ButtonState,
  ButtonType,
} from "../../../../components/v2/Button/ButtonComponent";
import { ArrowBackSharp } from "@mui/icons-material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Colors } from "../../../../theme/v2/variants";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import {
  ContentContainer,
  ModalActionsContainer,
} from "./PurchasingAndContractingFilesModal.styles";
import ManageFilesService from "../../../../services/files/ManageFilesService";
import PlanFilesViewerModal from "../PlanFilesViewerModal/PlanFilesViewerModal";
import DeleteFileModal from "../DeleteFileModal/DeleteFileModal";
import FileCard from "./FileCard/FileCard";
import NoFilesDialog from "./NoFilesDialog/NoFilesDialog";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Title from "../../../../components/v2/Typography/Tittle";
import styled from "styled-components";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { MOBILE_BREAKPOINT } from "../../../../utils/mobileDimen";
import { useNavbarVisibility } from "../../../../contexts/navbar";

interface PurchasingAndContractingFilesModalProps {
  planId: number | undefined;
  planType: string;
  toggleModal: () => void;
  open: boolean;
}

interface FileProps {
  id: number;
  fileName: string;
  uploadedBy: string;
  uploadedAt: string;
  fileUrl: string;
  category: string;
  fileType: string;
  identifier: string;
  uploadedFilePCC?: any;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function changeTab(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const PurchasingAndContractingFilesModal: React.FC<
  PurchasingAndContractingFilesModalProps
> = ({ toggleModal, open, planId, planType }) => {
  const [filesList, setFilesList] = useState<FileProps[]>([]);
  const [fileId, setFileId] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectedFile, setSelectedFile] = useState<string>("");
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [value, setValue] = React.useState(0);

  const manageFilesService = new ManageFilesService();

  const { screenWidth } = useNavbarVisibility();

  useEffect(() => {
    getUploadedFilesHandler();
  }, [planId]);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const getUploadedFilesHandler = async () => {
    try {
      const data =
        await manageFilesService.getPurcharsingContractingUploadedFiles(
          planId!,
          planType
        );

      if (data && data.length > 0) {
        setFilesList([...data]);
        selectFileHandler(selectedIndex, data[selectedIndex]?.fileUrl);
      } else {
        setFilesList([]);
      }
    } catch (error) {
      console.error("Erro ao buscar arquivos:", error);
      setFilesList([]);
    }
  };

  const deleteUploadedFileHandler = async (id: number, type: string) => {
    setIsLoading(true);
    try {
      const data =
        await manageFilesService.deletePurcharsingContractingUploadedFiles(
          id,
          type
        );

      if (data?.id === id) {
        const updatedFilesList = filesList.filter((file) => file.id !== id);
        setFilesList([...updatedFilesList]);
        setIsLoading(false);
        setOpenDeleteModal(false);
      }
    } catch (error) {
      console.error("Erro ao excluir arquivo:", error);
      setIsLoading(false);
    }
  };

  const selectFileHandler = (idx: number, url: string) => {
    setSelectedIndex(idx);
    setSelectedFile(url);
  };

  const deleteFileHandler = (id: number) => {
    setFileId(id);
    setOpenDeleteModal(!openDeleteModal);
  };

  const selectedFileUrl = filesList[selectedIndex]?.fileUrl || selectedFile;

  if (filesList.length === 0) {
    return <NoFilesDialog open={open} toggleModal={toggleModal} />;
  }

  return (
    <BootstrapDialog open={open} onClose={toggleModal} fullWidth maxWidth="lg">
      <Box sx={{ paddingX: "25px", paddingTop: "25px" }}>
        <Box sx={boxHeaderStyles}>
          <ButtonComponent
            text={"Voltar"}
            type={ButtonType.TRANSPARENT}
            startIcon={<ArrowBackSharp />}
            onClick={toggleModal}
          />
          <CloseRoundedIcon
            fontSize="medium"
            onClick={toggleModal}
            style={closeIconStyles}
          />
        </Box>
        <TitleContainer>
          <Title>Lista de Anexos</Title>
        </TitleContainer>
      </Box>
      <DialogContent>
        {screenWidth <= MOBILE_BREAKPOINT ? (
          <>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: Colors.primaryColorDark,
                    height: "2px",
                  },
                }}
              >
                <Tab label="Arquivos" {...changeTab(0)} />
                <Tab label="Visualização" {...changeTab(1)} />
              </Tabs>
            </Box>
            <ContentContainer>
              <CustomTabPanel value={value} index={0}>
                <Box
                  sx={{
                    display: "flex",
                    flex: "1",
                    flexDirection: "column",
                    gap: "16px",
                    paddingTop: "20px",
                  }}
                >
                  {filesList.map((file: FileProps, idx) => {
                    return (
                      <FileCard
                        idx={idx}
                        file={file}
                        selectedIndex={selectedIndex}
                        selectFileHandler={() => {
                          selectFileHandler(idx, file.fileUrl);
                          handleChange(undefined, 1);
                        }}
                        deleteFileHandler={() => deleteFileHandler(file.id)}
                      />
                    );
                  })}
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Box
                  height="100%"
                  borderRadius="5px"
                  sx={{
                    flex: "2",
                    bgcolor: "#f5f5f5",
                    marginTop: "20px",
                    overflow: "hidden",
                  }}
                >
                  <PlanFilesViewerModal fileUrl={selectedFileUrl} />
                </Box>
              </CustomTabPanel>
            </ContentContainer>
          </>
        ) : (
          <ContentContainer>
            <Box
              sx={{
                display: "flex",
                flex: "1",
                flexDirection: "column",
                gap: "16px",
                paddingTop: "20px",
              }}
            >
              {filesList.map((file: FileProps, idx) => {
                return (
                  <FileCard
                    idx={idx}
                    file={file}
                    selectedIndex={selectedIndex}
                    selectFileHandler={() =>
                      selectFileHandler(idx, file.fileUrl)
                    }
                    deleteFileHandler={() => deleteFileHandler(file.id)}
                  />
                );
              })}
            </Box>
            <Box
              height="100%"
              borderRadius="5px"
              sx={{
                flex: "2",
                bgcolor: "#f5f5f5",
                marginTop: "10px",
                overflow: "hidden",
              }}
            >
              <PlanFilesViewerModal fileUrl={selectedFileUrl} />
            </Box>
          </ContentContainer>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: 0 }}>
        <ModalActionsContainer>
          <a
            href={selectedFile}
            target="_blank"
            rel="noreferrer"
            download="document.pdf"
          >
            <ButtonComponent
              state={
                selectedFile === ""
                  ? ButtonState.DISABLED
                  : ButtonState.DEFAULT_ENABLED
              }
              fullWidth
              color={Colors.black}
              startIcon={<OpenInNewIcon fontSize="medium" />}
              text={"Abrir em Nova Aba"}
            />
          </a>
          <ButtonComponent
            startIcon={<FileUploadOutlinedIcon fontSize="medium" />}
            text={"Subir Novo Documento"}
            type={ButtonType.PRIMARY}
            state={ButtonState.DISABLED}
            onClick={() => {}}
          />
        </ModalActionsContainer>
      </DialogActions>
      {openDeleteModal && (
        <DeleteFileModal
          open={openDeleteModal}
          isLoading={isLoading}
          toggleModal={() => setOpenDeleteModal(!openDeleteModal)}
          deleteFileHandler={() => deleteUploadedFileHandler(fileId!, planType)}
        />
      )}
    </BootstrapDialog>
  );
};

const BootstrapDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 0;
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      margin: 0;
      border-radius: 0;
      width: 100vw;
      max-height: 100vh;
      height: 100vh;
    }
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const dialogPaperStyles = {
  width: "100vw",
  height: "100vh",
  borderRadius: "16px",
};

const boxHeaderStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const closeIconStyles = {
  cursor: "pointer",
};

const dialogTitleStyles = {
  fontSize: "32px",
  fontWeight: "bold",
};

const contentContainerStyles = {
  width: "100%",
  display: "flex",
  gap: "16px",
};

export default PurchasingAndContractingFilesModal;
