import { Box, Dialog, MenuItem, Tab, ToggleButton } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import styled from "styled-components";
import { Colors } from "../../../theme/v2/variants";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";

import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 0;
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    max-width: 825px;
    overflow-y: hidden;
    overflow-x: hidden;
  }
`;

const PageContainer = styled.div`
  /* display: grid;
  width: 100%;
  height: calc(100vh);
  grid-template-columns: 1fr;
  grid-template-rows: auto, auto;
  background-color: ${Colors.neutralColorSurface};
  overflow: visible;
  overflow-y: hidden;
  position: relative;
  align-content: start; */

  display: grid;
  width: 100%;
  height: 100%;
  min-height: 100%;
  grid-template-columns: 1fr;
  background-color: ${Colors.neutralColorSurface};
  overflow-x: hidden;
  align-content: space-between;
`;

const CenterContainer = styled.div`
  display: grid;
  height: 85vh;
  align-content: center;
`;

const RootContainer = styled.div`
  min-width: 320px;
  max-width: 825px;
  height: fit-content;
  display: grid;
  grid-template-rows: min-content min-content min-content;
`;

const LoadingDialogContainer = styled.div`
  display: grid;
  width: 825px;
  height: 369px;
  background: white;
  justify-content: center;
  align-content: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-y: auto;
  padding: 32px 40px;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    /* margin-top: 64px; */
    /* max-height: calc(100vh - 64px); */
    /* padding: 0 5%; */
    padding: 32px 40px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    /* padding-right: 16px;
    padding-left: 16px; */

    // max-height: calc(100vh - 64px);
    /* padding-top: 80px; */
    /* padding-top: 40px; */
    overflow-x: hidden;
    padding-bottom: 16px;
    padding: 16px 20px;
  }
`;

const FormDescriptionText = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: -20px;
  }
`;

const ChooseFormContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: max-content max-content max-content;
  gap: 16px;
  margin-top: -16px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: flex;
    flex-direction: column;
  }
`;

const LocationFormContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 2fr;
  gap: 16px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
  }
`;

const ServiceFormContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 200px 200px;
  gap: 16px;

  @media (max-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    & > :first-child {
      grid-column: span 3;
    }
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;

    & > :first-child {
      grid-column: auto !important;
      /* display: none !important; */
    }
  }
`;

const StartAndEndDateContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;

const HeaderContainer = styled.div<{ isMobile?: boolean }>`
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  margin-top: 18px;
  padding-bottom: 16px;
  column-gap: 16px;

  @media (max-width: 1000px) {
    flex-direction: column;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    margin-left: 0px;
  }
`;

const GroupedHeaderContainer = styled.div<{ isMobile?: boolean }>`
  /* display: grid; */
  display: flex;
  max-width: 100%;
  margin-top: 18px;
  padding-bottom: 16px;
  /* grid-template-columns: max-content max-content; */
  column-gap: 34px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    align-items: center;
    margin-left: 0px;
    margin-right: 16px;
  }
`;

const DialogTitle = styled.div`
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${Colors.primaryColorDark};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 20px;
  }
`;

const PurchasingAndContractionContainer = styled.div`
  margin-top: 24px;
`;

const TabContainer = styled.div`
  margin-top: 8px;
`;

const ListPurchasingContractionContainer = styled.div`
  margin-top: 8px;
  margin-left: 32px;
  margin-right: 32px;
`;

const CustomTab = styled((props) => <Tab {...props} />)(() => ({
  fontFamily: "Noto Sans",
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: "normal",
  fontWeight: 400,
  paddingLeft: 48,
  paddingRight: 48,
  color: `${Colors.greenLight}`,
  "&.Mui-selected": {
    color: `${Colors.primaryColorDark}`,
    fontWeight: 700,
  },
}));

const PurchasingForm = styled.form`
  /* display: grid;
  grid-template-rows: max-content max-content max-content max-content; */
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
`;

const PurchasingFormRowOne = styled.div`
  display: grid;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 3fr 1fr 1fr 2fr;
    gap: 24px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-rows: max-content max-content max-content max-content;
    gap: 16px;
  }
`;
const BuilderAndSupplierContainer = styled.div`
  display: grid;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr 2fr 2fr 1fr 2fr 2fr;
    gap: 16px;
    align-items: center;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-rows: max-content max-content max-content;
    gap: 16px;
  }
`;

const BuilderAndSupplierContainerDescription = styled.div`
  font-family: "Noto Sans";
  font-size: 24px;
  font-weight: 400;
  line-height: 21.79px;
  color: ${Colors.textColorBlack};
`;

const ContractingForm = styled.form`
  /* display: grid;
  grid-template-rows: max-content max-content max-content max-content; */
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
`;

const ContractingFormRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 16px;
`;

const ResponsibleContainer = styled.div`
  display: grid;
  background-color: white;
  gap: 16px;
  margin-top: 48px;
  padding: 25px;
  border-radius: 16px;
`;

const ResponsibleForm = styled.div`
  gap: 24px 16px;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: flex;
    flex-direction: column;
  }
`;

const ResponsibleFormRow = styled.div`
  display: grid;
  width: 100%;
  grid-column: span 3;
  gap: 16px;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
  }
`;

const ResponsibleContainerTitle = styled.div`
  font-family: "Noto Sans";
  font-size: 24px;
  line-height: 21.79px;
  margin-bottom: 24px;
  color: ${Colors.textColorBlack};
`;

const ResponsibleRow = styled.div`
  display: grid;
  width: 100% @media (min-width: ${MOBILE_BREAKPOINT}px) {
    gap: 16px;
    align-items: center;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    gap: 8px;
    margin-top: 4px;
  }
`;

const ResponsibleRowDesctipion = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 21.79px;
  color: ${Colors.textColorBlack};
`;

const AutoCompleteCustomMenuItem = styled(MenuItem)`
  display: flex;
  height: 40px;
  color: #565757;
  padding-left: 16px;
  flex-direction: row;
`;

const CustomMenuItemText = styled.div`
  font-family: "Noto Sans";
  width: 230px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ButtonContainerWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 24px;
  margin-top: 48px;
  /* background-color: blue; */

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const ButtonContainerStart = styled.div`
  display: flex;
  /* background-color: yellow; */
  /* width: 100%; */
  justify-content: flex-start;
  gap: 24px;
  margin-top: 48px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    justify-content: center;
    margin-top: 0px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 1000px) {
    margin-top: 20px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    flex-direction: column;
    margin-top: 20px;
    gap: 15px;
  }
`;

const ButtonContainerEnd = styled.div`
  display: flex;
  /* background-color: green; */
  justify-content: flex-end;
  gap: 24px;
  margin-top: 48px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    justify-content: center;
    margin-top: 15px;
  }
`;

const SelectedTag = styled.div`
  display: flex;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
  margin: 2px;

  border-radius: 4px;
  align-items: center;
  justify-content: center;
`;

const FilesInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
  padding: 10px;
  border-radius: 20px;
  outline: 1px dashed #333;
`;

const FilesInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  justify-content: center;
  border-radius: 16px;
  align-items: center;
  background-color: white;
  padding: 15px 0;
`;

const SelectedTagText = styled.div`
  font-family: "Noto Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #333;
`;

const ModalContent = styled(Box)`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  padding: 0 16px;
  border-top-left-radius: 24px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  margin-top: 29px;
  margin-bottom: 23px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 0px;
    margin-right: 16px;
  }
`;

const ModalTitle = styled.div`
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${Colors.primaryColorDark};
  margin-top: 16px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 24px;
  }
`;

const CloseButton = styled(CloseIcon)`
  cursor: pointer;
  font-size: 24px;
`;

const ModalToggleButton = styled(ToggleButton)`
  border-radius: 100px;
  font-size: 14px;
  padding: 8px 31px;

  &.Mui-selected {
    background-color: ${Colors.lightBlue};
    color: #fff;
  }

  &.Mui-selected:hover {
    background-color: ${Colors.lightBlue};
    & > svg:first-child {
      color: ${Colors.primaryColorDark};
    }
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 8px 20px;
  }
`;

const ModalForm = styled.form`
  width: 100%;
  height: 212px;
  margin-top: 23px;
  margin-bottom: 61px;
`;

const ModalFormRow = styled.div`
  display: grid;
  grid-container: max-content;
  margin-bottom: 12px;
`;

const ModalButtonRow = styled.div`
  display: grid;
  grid-container: max-content;
  margin-bottom: 37px;
`;

const FileItem = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  border: 1px solid #ecedf3;
  padding: 6px 8px;
  margin-bottom: 8px;
  color: ${Colors.neutralGrayDark};
  border-radius: 8px;

  &:hover {
    background-color: ${Colors.greenLight};
    color: #FFF;
    box-shadow: 0px 7px 10px -7px rgba(0, 0, 0, 0.66);
    border: 1px solid ${Colors.jungleGreen};
  },
`;

const FileItemText = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  margin-left: 16px;
  margin-right: 16px;
`;

const ListFilesContainer = styled.div`
  widht: 100%;
  height: 232px;
  margin-top: 23px;
  margin-bottom: 41px;
  overflow-y: auto;
`;

const ModalSucessFilesContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 420px;
  height: 375px;
  background-color: white;
  border-radius: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 68px 49px;
`;

const ModalSuccesFilesText = styled(Box)`
  color: #3b425a;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.1px;
  margin-bottom: 32px;
`;

const ModalLoadingFilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding 57px 47px;
`;

const ModalLoadingFilesText = styled(Box)`
  width: 100%;
  color: #3b425a;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
`;

const BorderLinearProgress = styled(LinearProgress)`
  height: 12px;
  width: 344px;
  border-radius: 40px;
  background-color: #e5eaf8;
  & .MuiLinearProgress-bar {
    background-color: #8ea2e4;
  }
`;

export {
  BorderLinearProgress,
  ModalLoadingFilesText,
  ModalLoadingFilesContainer,
  ModalSuccesFilesText,
  ModalSucessFilesContent,
  ListFilesContainer,
  FileItemText,
  FileItem,
  ModalToggleButton,
  ModalButtonRow,
  ModalFormRow,
  ModalHeader,
  ModalContent,
  ModalTitle,
  ModalForm,
  CloseButton,
  BootstrapDialog,
  PageContainer,
  CenterContainer,
  RootContainer,
  LoadingDialogContainer,
  HeaderContainer,
  GroupedHeaderContainer,
  DialogTitle,
  ContentContainer,
  FormDescriptionText,
  ChooseFormContainer,
  LocationFormContainer,
  ServiceFormContainer,
  PurchasingAndContractionContainer,
  TabContainer,
  ListPurchasingContractionContainer,
  CustomTab,
  PurchasingForm,
  PurchasingFormRowOne,
  BuilderAndSupplierContainer,
  BuilderAndSupplierContainerDescription,
  ContractingForm,
  ContractingFormRow,
  ResponsibleContainerTitle,
  ResponsibleContainer,
  ResponsibleForm,
  ResponsibleFormRow,
  ResponsibleRow,
  ResponsibleRowDesctipion,
  AutoCompleteCustomMenuItem,
  CustomMenuItemText,
  ButtonContainerWrap,
  ButtonContainer,
  ButtonContainerStart,
  ButtonContainerEnd,
  StartAndEndDateContainer,
  SelectedTag,
  SelectedTagText,
  FilesInputContainer,
  FilesInput,
};
