import Dialog from "@mui/material/Dialog";
import styled from "styled-components";
import { Colors } from "../../../theme/variants";
import ButtonComponent, {
  ButtonState,
  ButtonType,
} from "../../../components/Button/ButtonComponent";
import LoadingLottieJson from "../../../assets/loading.json";
import { useLottie } from "lottie-react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { spacing } from "@mui/system";
import { TextField as MuiTextField } from "@mui/material";
import { ActionCardProps } from "../../../components/ActionCard/ActionCard";
import { useEffect, useState } from "react";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";
import { Dayjs } from "dayjs";

interface MoveActionToDoPopUpProps {
  openDialog: boolean;
  isLoadingDialog?: boolean;
  action?: ActionCardProps | null;
  title?: string;
  onCancelDialogCallback: (action: ActionCardProps) => void;
  onConfirmDialogCallback: (action: ActionCardProps) => void;
}

const MoveActionToDoPopUp: React.FC<MoveActionToDoPopUpProps> = ({
  openDialog,
  isLoadingDialog,
  action,
  title,
  onCancelDialogCallback,
  onConfirmDialogCallback,
}) => {
  const options = {
    animationData: LoadingLottieJson,
    loop: true,
    autoplay: true,
    style: { width: 120, height: 120 },
  };
  const { View } = useLottie(options);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const nextWeek = new Date();
  nextWeek.setDate(nextWeek.getDate() + 8);
  const [beginDate, setBeginDate] = useState<Date | undefined>(tomorrow);
  const [endDate, setEndDate] = useState<Date | undefined>(nextWeek);

  useEffect(() => {
    setBeginDate(tomorrow);
    setEndDate(nextWeek);
  }, [action]);

  const beginDateOnChange = (date: any) => {
    setBeginDate(date.toDate());
    const datePlusServenDays = new Date(date.toDate());
    datePlusServenDays.setDate(datePlusServenDays.getDate() + 7);
    setEndDate(datePlusServenDays);
  };

  const endDateOnChange = (date: any) => {
    setEndDate(date.toDate());
  };

  const onConfirmDialog = () => {
    if (action && beginDate && endDate) {
      action.startDate = beginDate;
      action.deadlineDate = endDate;
      onConfirmDialogCallback(action);
    }
  };

  const onCancelDialog = () => {
    if (action) {
      onCancelDialogCallback(action);
    }
  };

  return (
    <BootstrapDialog open={openDialog}>
      {!isLoadingDialog ? (
        <DialogContainer>
          <DialogTitle>{title}</DialogTitle>

          <DialogDescription>
            Ao mover a ação você precisa definir uma nova data para a ação
          </DialogDescription>

          <DatePickerContainer>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="pt-br"
            >
              <DesktopDatePicker
                label="Data início"
                value={beginDate}
                onChange={(date) => beginDateOnChange(date)}
                renderInput={(params) => (
                  <TextField variant="filled" size="small" {...params} />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="pt-br"
            >
              <DesktopDatePicker
                label="Data fim"
                value={endDate}
                onChange={(date) => endDateOnChange(date)}
                renderInput={(params) => (
                  <TextField variant="filled" size="small" {...params} />
                )}
              />
            </LocalizationProvider>
          </DatePickerContainer>

          <ButtonContainer>
            <ButtonComponent
              text="Cancelar"
              type={ButtonType.TRANSPARENT}
              fullWidth
              onClick={onCancelDialog}
            />
            <ButtonComponent
              state={ButtonState.DEFAULT_ENABLED}
              text="Confirmar"
              fullWidth
              onClick={onConfirmDialog}
            />
          </ButtonContainer>
        </DialogContainer>
      ) : null}
      {isLoadingDialog === true ? (
        <LoadingDialogContainer>{View}</LoadingDialogContainer>
      ) : null}
    </BootstrapDialog>
  );
};

const BootstrapDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 0;
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      width: 95vw;
    }
  }
`;

const DialogContainer = styled.div`
  display: grid;
  /* grid-template-columns: 1fr;
  grid-template-rows: auto auto; */

  height: max-content;
  background: white;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 32px;
  padding-bottom: 24px;
  align-content: start;

  /* @media (min-width: ${MOBILE_BREAKPOINT}px) {
    width: 397px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 100%;
    padding-top: 24px;
    padding-right: 16px;
    padding-left: 16px;
  } */
`;

const DialogTitle = styled.div`
  color: ${Colors.primaryColor};
  font-size: 24px;
  font-family: "Noto Sans";
  font-weight: 700;
  word-wrap: break-word;

  /* @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 16px;
  } */
`;

const DialogDescription = styled.div`
  color: ${Colors.primaryColor};
  font-size: 16px;
  font-family: "Noto Sans";
  font-weight: 400;
  word-wrap: break-word;
  margin-top: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  gap: 24px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    gap: 16px;
  }
`;

const LoadingDialogContainer = styled.div`
  display: grid;
  width: 301px;
  height: 216px;
  background: white;
  justify-content: center;
  align-content: center;
`;

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const DatePickerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  margin-top: 20px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

export default MoveActionToDoPopUp;
