import * as React from "react";
import styled from "styled-components/macro";
import { Colors } from "../../theme/v2/variants";

import {
  Grid,
  List,
  ListItemText as MuiListItemText,
  ListItemButtonProps as MuiListItemButtonProps,
  ListItemButton as MuiListItemButton,
} from "@mui/material";

// @ts-ignore
interface ListItemButtonProps extends MuiListItemButtonProps {
  component?: string;
  href?: string;
}

const Wrapper = styled.div`
  padding: 0 ${(props) => props.theme.spacing(4)};
  background: ${Colors.neutralColorSurface};
  margin-top: 60px;
`;

const ListItemButton = styled(MuiListItemButton)<ListItemButtonProps>`
  width: auto;
  padding: 0 ${(props) => props.theme.spacing(2)};

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${Colors.black};
  }
`;

function Footer() {
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Grid container item xs={12} md={12} justifyContent="center">
          <List>
            <ListItemButton>
              <ListItemText
                primary={`GoBuilder©${new Date().getFullYear()} - Todos os direitos reservados`}
              />
            </ListItemButton>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
