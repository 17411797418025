import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { CardContainer } from "../PurchasingAndContractingFilesModal.styles";
import { Colors } from "../../../../../theme/v2/variants";
import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../../../../utils/mobileDimen";
import typography from "../../../../../theme/v2/typography";

interface FileProps {
  id: number;
  fileName: string;
  uploadedBy: string;
  uploadedAt: string;
  fileUrl: string;
  category: string;
  fileType: string;
  identifier: string;
  uploadedFilePCC?: any;
}

interface FileCardProps {
  idx: number;
  selectedIndex: number;
  file: FileProps;
  selectFileHandler: (index: number, fileUrl: string) => void;
  deleteFileHandler: (fileId: number) => void;
}

const FileCard: React.FC<FileCardProps> = ({
  idx,
  selectedIndex,
  file,
  selectFileHandler,
  deleteFileHandler,
}) => {
  const isSelected = selectedIndex === idx;
  const cardBackgroundColor = isSelected
    ? Colors.neutralColorWhite
    : Colors.textColorLight;

  return (
    <CardContainer
      isSelected={isSelected}
      style={{ backgroundColor: cardBackgroundColor }}
      // onClick={() => selectFileHandler(idx, file.fileUrl)}
    >
      <FileIcon>
        <InsertDriveFileOutlinedIcon
          fontSize="large"
          sx={{ color: Colors.textColorGray }}
        />
      </FileIcon>
      <InfoContainer>
        <div>
          <FileNumber>
            <InsertDriveFileOutlinedIcon
              fontSize="small"
              sx={{ color: Colors.textColorGray }}
            />
            <SmallText>Pedido nº {file.identifier}</SmallText>
          </FileNumber>
          <SmallText style={clearTextStyles}>
            {`${file.category} - ${file.fileType}`}
          </SmallText>

          <FileNameContainer>
            <FileName>{file.fileName}</FileName>
          </FileNameContainer>
        </div>
        <div>
          <SmallText>
            Atualizado em {new Date(file.uploadedAt).toLocaleDateString()}
          </SmallText>
          <SmallText>
            por <b>{file.uploadedBy}</b>
          </SmallText>
        </div>
      </InfoContainer>
      <ActionsContainer>
        <IconButton
          disableRipple={false}
          onClick={() => selectFileHandler(idx, file.fileUrl)}
          sx={{
            backgroundColor: isSelected ? "rgba(0, 0, 0, 0.1)" : "none",
          }}
        >
          <VisibilityOutlinedIcon fontSize="large" sx={iconStyles} />
        </IconButton>
        <IconButton
          disableRipple={false}
          onClick={() => deleteFileHandler(file.id)}
        >
          <DeleteOutlineOutlinedIcon fontSize="large" sx={iconStyles} />
        </IconButton>
      </ActionsContainer>
    </CardContainer>
  );
};

const FileIcon = styled.div`
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const FileNumber = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  gap: 2px;

  & > :first-child {
    @media (min-width: ${MOBILE_BREAKPOINT}px) {
      display: none;
    }
  }
`;

// const FileName = styled.p`
//   line-height: 10%;
//   font-size: 18px;
//   font-weight: ${typography.fontWeightBold};

//   @media (max-width: ${MOBILE_BREAKPOINT}px) {
//     font-size: 14px;
//     font-weight: ${typography.fontWeightMedium};
//   }
// `;

// const FileNameContainer = styled.div`
//   overflow: "hidden";
//   text-overflow: "ellipsis";
//   background-color: "red";


//   @media (max-width: ${MOBILE_BREAKPOINT}px) {
//   }
// `;


const FileNameContainer = styled.div`
  width: 250px; /* Ensure a fixed or max width is defined */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 200px; /* Adjust for smaller screens */
  }
`;

const FileName = styled.span`
  font-size: 18px;
  font-weight: ${typography.fontWeightBold};
  text-overflow: ellipsis;
  white-space: nowrap;
  
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 14px;
    font-weight: ${typography.fontWeightMedium};
  }
`;

const SmallText = styled.span`
  font-size: 12px;
  display: block;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 10px;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: "5px";

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column-reverse;
    justify-content: space-between;
  }
`;

const iconStyles = {
  cursor: "pointer",
  color: Colors.textColorGray,
};

const InfoContainer = styled.div`
  flex-grow: 1;
  text-align: start;
  gap: 20px;

  /* @media (max-width: 1000px) {
    display: flex;
  } */
`;

const clearTextStyles = {
  color: Colors.neutralColor,
};

const smallTextStyles = {
  fontSize: "12px",
};

const boldTextStyles = {
  fontSize: "18px",
  fontWeight: "bold",
};

const boxIconContainerStyles = {
  display: "flex",
  gap: "5px",
};

export default FileCard;
