import styled from "styled-components";
import ReactBaseComponent from "../../../components/BaseComponents/ReactBaseComponent";
import { Colors } from "../../../theme/v2/variants";
import PopUpBaseComponent from "../../../components/PopUps/PopUpBaseComponent";
import { useState } from "react";
import React from "react";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";
import { Checkbox, FormControlLabel } from "@mui/material";
import ReactDOM from "react-dom";

interface ExportOptions {
  includeProgress: boolean;
  includeGraph: boolean;
  includeImages: boolean;
  includeRescheduling: boolean;
}

interface OptionsToExportPopUpProps {
  isOpen: boolean;
  cancelOnClick: () => void;
  onExport: (options: ExportOptions) => void;
}

const OptionsToExportPopUp: React.FC<OptionsToExportPopUpProps> = ({
  isOpen,
  cancelOnClick,
  onExport,
}) => {
  const [options, setOptions] = useState({
    includeProgress: true,
    includeGraph: true,
    includeImages: true,
    includeRescheduling: true,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOptions({ ...options, [event.target.name]: event.target.checked });
  };

  const handleExport = () => {
    onExport(options);
    cancelOnClick();
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <ReactBaseComponent>
      <PopUpBaseComponent
        isOpen={isOpen}
        cancelOnClick={() => {
          cancelOnClick();
        }}
        confirmOnClick={() => handleExport()}
        closeOnClick={() => {
          cancelOnClick();
        }}
        titte="Selecione os campos que deseja exportar"
        isButtonContainerEnabled={true}
        width={383}
        enableConfirmButton={true}
        positiveButtonText="Exportar PDF"
      >
        <ContentContainer>
          <FormControlLabel
            control={
              <Checkbox
                checked={options.includeProgress}
                onChange={handleChange}
                name="includeProgress"
                color="primary"
              />
            }
            label={<CheckboxLabel>Avanços Físicos</CheckboxLabel>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={options.includeGraph}
                onChange={handleChange}
                name="includeGraph"
                color="primary"
              />
            }
            label={<CheckboxLabel>Evolução dos serviços</CheckboxLabel>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={options.includeRescheduling}
                onChange={handleChange}
                name="includeRescheduling"
                color="primary"
              />
            }
            label={<CheckboxLabel>Causas de reprogramação</CheckboxLabel>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={options.includeImages}
                onChange={handleChange}
                name="includeImages"
                color="primary"
              />
            }
            label={<CheckboxLabel>Imagens anexas dos serviços</CheckboxLabel>}
          />
        </ContentContainer>
      </PopUpBaseComponent>
    </ReactBaseComponent>,
    document.body
  );
};

const CheckboxLabel = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  color: ${Colors.black};
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
  width: 100%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 100%;
    max-height: 400px;
    width: 90%;
  }
`;

export default OptionsToExportPopUp;
