import styled from "styled-components";
import { TableItemModel } from "../models/TableItemModel";
import { format } from "date-fns";
import { Colors } from "../../../theme/variants";

interface TableItemProps {
  item: any;
  value: any;
}

const TableItem: React.FC<TableItemProps> = ({ item, value }) => {
  let formattedValue: string;

  if (value instanceof Date) {
    formattedValue = format(value, "dd/MM/yyyy");
  } else {
    if (typeof value === "boolean") {
      formattedValue = value ? "Sim" : "Não";
    } else {
      formattedValue = value.toString();
    }
  }

  return (
    <TableItemContainer>
      <StyledText title={formattedValue}>{formattedValue}</StyledText>
    </TableItemContainer>
  );
};
const TableItemContainer = styled.div`
  width: 100%;
  background-color: ${Colors.neutralColorWhite};
  padding: 20px;
  height: 16px;
  overflow: hidden;
`;

const StyledText = styled.div`
  color: ${Colors.textColorGray};
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default TableItem;
