import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
} from "@mui/material";
import React from "react";
import ButtonComponent, {
  ButtonType,
} from "../../../../../components/Button/ButtonComponent";
import typography from "../../../../../theme/v2/typography";

interface NoFilesDialogProps {
  open: boolean;
  toggleModal: () => void;
}

const NoFilesDialog: React.FC<NoFilesDialogProps> = ({ open, toggleModal }) => {
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      PaperProps={{
        style: { borderRadius: "15px", padding: "10px 5px" },
      }}
      onClose={toggleModal}
      open={open}
    >
      <DialogTitle fontSize={typography.h3?.fontSize}>
        Nenhum arquivo encontrado
      </DialogTitle>
      <DialogContent>
        <Typography fontSize={typography.body1?.fontSize}>
          Não encontramos nenhum arquivo no plano selecionado.
        </Typography>
      </DialogContent>
      <DialogActions>
        <ButtonComponent
          text={"Fechar"}
          type={ButtonType.OUTLINE}
          onClick={toggleModal}
        />
      </DialogActions>
    </Dialog>
  );
};

export default NoFilesDialog;
