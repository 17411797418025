import { Typography } from "@mui/material";

import { LoadAndErrorContainer } from "./PlanFilesViewerModal.styles";
import typography from "../../../../theme/newTheme/typography";

interface PlanFilesModalProps {
  fileUrl: string;
}

const PlanFilesViewerModal: React.FC<PlanFilesModalProps> = ({ fileUrl }) => {
  if (fileUrl === "") {
    return (
      <LoadAndErrorContainer>
        <Typography
          fontSize={typography.h4?.fontSize}
          fontWeight={typography.fontWeightBold}
          color="#e53e3e"
        >
          Ocorreu um Erro
        </Typography>
        <Typography fontSize={typography.body1?.fontSize}>
          Não foi possível carregar o documento.
        </Typography>
      </LoadAndErrorContainer>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          padding: "10px",
          overflow: "auto",
        }}
      >
        <iframe
          style={{ border: 0, height: "60vh" }}
          src={fileUrl}
          width="100%"
        ></iframe>
      </div>
    </div>
  );
};

export default PlanFilesViewerModal;
