import { Box, Typography } from "@mui/material";
import { formatDate } from "../../../utils/date";
import { Colors } from "../../../theme/v2/variants";
import moment from "moment";

type Props = {
  constructionBeginningDate?: Date;
  constructionEndDate?: Date;
  constructionDays?: number;
  constructionWeekDays?: number;
};

export const DateHeaderView = ({
  constructionBeginningDate,
  constructionEndDate,
  constructionDays,
  constructionWeekDays,
}: Props) => {
  if (
    moment(constructionBeginningDate).isValid() &&
    moment(constructionEndDate).isValid()
  )
    return (
      <Box display="inline-flex" gap={2} marginTop="16px">
        <Typography fontSize="12px" fontWeight={400} color={Colors.waterloo}>
          Período da obra:
        </Typography>
        <Typography fontSize="12px" fontWeight={700} color={Colors.waterloo}>
          {formatDate(constructionBeginningDate)} -{" "}
          {formatDate(constructionEndDate)}
        </Typography>
        {constructionDays && constructionWeekDays && (
          <Typography fontSize="12px" fontWeight={400} color={Colors.waterloo}>
            ({constructionDays} total - {constructionWeekDays} dias úteis)
          </Typography>
        )}
      </Box>
    );

  return null;
};
