import React from "react";
import styled from "styled-components";
import { Colors } from "../../../../theme/v2/variants";
import { StatusEnum } from "./Timeline";
import UserInfo from "../UserInfo/UserInfo";
import { MOBILE_BREAKPOINT } from "../../../../utils/mobileDimen";

interface Step {
  title: string;
  description?: string;
  // icon: React.ReactNode;
  status?: StatusEnum;
  Date?: string;
  UserName?: string;
  action?: string;
  UserImage?: string;
}

interface BadgeProps {
  steps: Step[];
}

const BadgeContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-top: 18px;
  margin-bottom: 24px;
`;

const BadgeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 50px;
  overflow: visible;
  &:first-child {
    align-items: flex-start;
  }
  &:last-child {
    align-items: flex-end;
  }

  @media (max-width: 1000px) {
    transform: scale(0.8);
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const TextBox = styled.div<{ status?: StatusEnum }>`
  background-color: ${(props) =>
    props.status === StatusEnum.OVERDUE
      ? Colors.attentionColor
      : props.status === StatusEnum.COMPLETED
      ? Colors.greenLight
      : props.status === StatusEnum.REJECTED
      ? Colors.alertRed
      : props.status === StatusEnum.NOT_STARTED
      ? Colors.neutralColorSurface
      : props.status === StatusEnum.IN_PROGRESS
      ? Colors.lightBlue
      : ""};
  color: ${(props) =>
    props.status === StatusEnum.PENDING
      ? Colors.greenLight
      : props.status === StatusEnum.NOT_STARTED
      ? Colors.comet
      : "white"};
  border: 1px solid
    ${(props) =>
      props.status === StatusEnum.PENDING
        ? Colors.greenLight
        : props.status === StatusEnum.IN_PROGRESS
        ? Colors.lightBlue
        : props.status === StatusEnum.COMPLETED
        ? Colors.greenLight
        : props.status === StatusEnum.REJECTED
        ? Colors.alertRed
        : props.status === StatusEnum.NOT_STARTED
        ? Colors.gullGray
        : props.status === StatusEnum.OVERDUE
        ? Colors.attentionColor
        : Colors.lightBlue};
  border-radius: 4px;
  padding: 4px 10px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
`;

const Badge: React.FC<BadgeProps> = ({ steps }) => {
  let isRejected = false;

  return (
    <BadgeContainer>
      {steps.map((step, index) => {
        return (
          <React.Fragment key={index}>
            <BadgeWrapper>
              <TextBox status={isRejected ? StatusEnum.REJECTED : step.status}>
                {step.title}
              </TextBox>
              {!isRejected &&
                step.status !== StatusEnum.NOT_STARTED &&
                step.status !== StatusEnum.PENDING && (
                  <UserInfo
                    Date={step.Date}
                    UserName={step.UserName}
                    startAvatar={step.UserImage}
                    action={step.action}
                  />
                )}
              {step.status === StatusEnum.REJECTED ? (isRejected = true) : ""}
            </BadgeWrapper>
          </React.Fragment>
        );
      })}
    </BadgeContainer>
  );
};

export default Badge;
