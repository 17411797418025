import React, { createContext, useContext, useEffect, useState } from "react";
import { Client, Construction, DashboardContextData } from "../config/app-info";
import { useCallback } from "react";

interface DashboardData {
  clientId: number;
  constructionId: number;
}

const dashboardDefaultValue: DashboardContextData = {
  constructionId: 0,
  selectedConstruction: undefined,
  setConstructionId: () => {},
  clientId: 0,
  setClientId: () => {},
  dtBegin: new Date(),
  setDtBegin: () => {},
  dtEnd: new Date(),
  setDtEnd: () => {},
  isDashboard: false,
  setIsDashboard: () => {},
  constructions: [],
  setConstructions: () => {},
  clients: [],
  setClients: () => {},
};

const DashboardContext = createContext<DashboardContextData>(
  dashboardDefaultValue
);

interface Props {
  children: React.ReactNode;
}

export const DashboardProvider: React.FC<Props> = ({ children }) => {
  const [dashboardData, setDashboardData] = useState<DashboardData>(() => {
    const savedData = localStorage.getItem("dashboardData");
    return savedData
      ? JSON.parse(savedData)
      : { clientId: 0, constructionId: 0 };
  });

  const { clientId, constructionId } = dashboardData;

  const setClientId = useCallback(
    (id: number): void =>
      setDashboardData((prevData: DashboardData) => ({
        ...prevData,
        clientId: id,
      })),
    []
  );

  const setConstructionId = useCallback(
    (id: number): void =>
      setDashboardData((prevData: DashboardData) => ({
        ...prevData,
        constructionId: id,
      })),
    []
  );

  const [selectedConstruction, setSelectedConstruction] =
    useState<Construction>();
  const [dtBegin, setDtBegin] = useState<Date | null>(null);
  const [dtEnd, setDtEnd] = useState<Date | null>(null);
  const [isDashboard, setIsDashboard] = useState(false);
  const [constructions, setConstructions] = useState<Construction[]>([]);
  const [clients, setClients] = useState<Client[]>([]);

  useEffect(() => {
    localStorage.setItem("dashboardData", JSON.stringify(dashboardData));
  }, [dashboardData]);

  return (
    <DashboardContext.Provider
      value={{
        constructionId,
        setConstructionId,
        clientId,
        setClientId,
        dtBegin,
        setDtBegin,
        dtEnd,
        setDtEnd,
        isDashboard,
        setIsDashboard,
        constructions,
        setConstructions,
        clients,
        setClients,
        selectedConstruction,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => useContext(DashboardContext);
