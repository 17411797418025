import styled from "styled-components";
import ReactBaseComponent from "../../../components/BaseComponents/ReactBaseComponent";
import Dialog from "@mui/material/Dialog";
import { Colors } from "../../../theme/variants";
import ServiceDetailsComponent from "./ServiceDetailsComponent";
import React, { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import { Button } from "@mui/material";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";

interface DayServicesListPopUpProps {
  isOpen: boolean;
  groupColor: string;
  data: any[];
  serviceType?: string;
  closeOnClick?: () => void;
  editServiceOnClick?: (item: any) => void;
  deleteServiceOnClick?: (item: any) => void;
  updateServiceProgressOnClick?: (item: any) => void;
  uploadImageOnClick?: (item: any) => void;
}

const DayServicesListPopUp: React.FC<DayServicesListPopUpProps> = ({
  isOpen,
  groupColor,
  data,
  closeOnClick,
  editServiceOnClick,
  deleteServiceOnClick,
  updateServiceProgressOnClick,
  uploadImageOnClick,
  serviceType,
}) => {
  const [isMobile, setIsMobile] = useState<boolean>();

  const handleResize = () => {
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    if (isMobile === undefined) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);
  });

  const getFormattedDate = (dataItem: any) => {
    if (!dataItem) {
      return "";
    }

    const date = new Date(data[0].date);
    const formattedDate = date.toLocaleDateString("pt-BR", {
      weekday: "long",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    return formattedDate;
  };

  return (
    <ReactBaseComponent>
      <BootstrapDialog open={isOpen}>
        <RootContainer>
          <HeaderAndSubheaderContainer>
            <HeaderContainer>
              <DialogTitle>{serviceType}</DialogTitle>
              <Button
                variant="text"
                style={{ color: "#000" }}
                startIcon={<Close />}
                onClick={() => closeOnClick?.()}
              />
            </HeaderContainer>
            <Subheader>{getFormattedDate(data[0])}</Subheader>
          </HeaderAndSubheaderContainer>
          <ContaintContainer itemCount={data.length}>
            <div style={{ height: "16px" }} />
            {data.map((item, index) => {
              return (
                <React.Fragment>
                  <ServiceDetailsComponent
                    serviceGroupColor={groupColor}
                    location={item.location}
                    timePeriod={{
                      startDate: new Date(item.beginDate),
                      endDate: new Date(item.endDate),
                    }}
                    isMobile={isMobile || false}
                    responsibleName={item.responsibleUserId.name}
                    status={item.status}
                    editServiceOnClick={() => editServiceOnClick?.(item)}
                    deleteServiceOnClick={() => deleteServiceOnClick?.(item)}
                    updateServiceProgressOnClick={() =>
                      updateServiceProgressOnClick?.(item)
                    }
                    uploadImageOnClick={() => uploadImageOnClick?.(item)}
                  />
                  {index !== data.length - 1 && <Divider />}
                </React.Fragment>
              );
            })}
          </ContaintContainer>
        </RootContainer>
      </BootstrapDialog>
    </ReactBaseComponent>
  );
};

const BootstrapDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 0;
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    max-width: 825px;
    overflow-y: hidden;
    overflow-x: hidden;
  }
`;

const RootContainer = styled.div`
  max-width: 825px;
  height: 483px;
  display: grid;
  grid-template-rows: auto auto;
  padding-bottom: 24px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    min-width: 320px;
  }
`;

const ContaintContainer = styled.div<{ itemCount: number }>`
  display: grid;
  grid-template-rows: repeat(${(props) => props.itemCount}, auto);
  overflow-y: auto;
  overflow-x: hidden;
`;

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const HeaderAndSubheaderContainer = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
  row-gap: 8px;
  border-bottom: 2px solid ${Colors.primaryColor};
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 16px;
  padding-bottom: 16px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

const DialogTitle = styled.div`
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Divider = styled.div`
  height: 0;
  border: 1px #465586 dashed;
  max-width: 90%;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 32px;
  margin-right: 32px;
`;

const Subheader = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${Colors.textColorGray};
`;

export default DayServicesListPopUp;
