import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";

interface NavbarVisibilityContextType {
  isNavbarVisible: boolean;
  screenWidth: number;
  setNavbarVisibility: (isVisible: boolean) => void;
}

const NavbarVisibilityContext = createContext<
  NavbarVisibilityContextType | undefined
>(undefined);

export const NavbarVisibilityProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const setNavbarVisibility = (isVisible: boolean) => {
    setIsNavbarVisible(isVisible);
  };

  return (
    <NavbarVisibilityContext.Provider
      value={{ isNavbarVisible, setNavbarVisibility, screenWidth }}
    >
      {children}
    </NavbarVisibilityContext.Provider>
  );
};

export const useNavbarVisibility = () => {
  const context = useContext(NavbarVisibilityContext);
  if (context === undefined) {
    throw new Error(
      "useNavbarVisibility must be used within a NavbarVisibilityProvider"
    );
  }
  return context;
};
