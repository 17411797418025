import styled from "styled-components";
import { Colors } from "../../theme/v2/variants";
import { IconButton, Button, Typography, Box } from "@mui/material";
import { ArrowUpward } from "@mui/icons-material";
import { ReactComponent as CalendarCheck } from "../../assets/icon_calendar_check.svg";
import { ReactComponent as CommentIcon } from "../../assets/comments_icon.svg";
import { ReactComponent as TrashIcon } from "../../assets/trash_icon.svg";
import { Draggable } from "@hello-pangea/dnd";
import { MOBILE_BREAKPOINT } from "../../utils/mobileDimen";
import TodayRoundedIcon from "@mui/icons-material/TodayRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import EventAvailableRoundedIcon from "@mui/icons-material/EventAvailableRounded";

export interface ActionDetails {
  responsibleList: string[];
  criteria: string | null;
  priority: string | null;
}

export interface ActionCardProps {
  id: string;
  title: string;
  // tagList: string[];
  details: ActionDetails;
  startDate: Date;
  deadlineDate: Date;
  commentsCount: number;
}

export interface ActionDraggableCardProps {
  cardProps: ActionCardProps;
  position: number;
  onTrashClick: (card: ActionCardProps) => void;
  isDragDisabled?: boolean;
}

const ActionCard: React.FC<ActionDraggableCardProps> = ({
  cardProps: { id, title, details, startDate, deadlineDate, commentsCount },
  position: index,
  onTrashClick,
  isDragDisabled,
}) => {
  const startDateAsString = startDate.toLocaleDateString();
  const deadlineDateAsString = deadlineDate.toLocaleDateString();
  // .substring(0, 5);

  const onTrashClickCallback = () => {
    onTrashClick({
      id,
      title,
      details,
      startDate,
      deadlineDate,
      commentsCount,
    });
  };

  function getConstructionInitialLetters(construction: string): string {
    const array = construction.split(" ");
    let firstLetter = array[0]?.charAt(0);
    let lastLetter = array.length > 1 ? array[array.length - 1]?.charAt(0) : "";

    return `${firstLetter}${lastLetter}`;
  }

  function getPriorityColor(priority: string | null): string {
    let color = "";
    switch (priority) {
      case "Muito-Baixa":
        color = Colors.greenLight;
        break;
      case "Baixa":
        color = Colors.primaryColorNeutralBlue;
        break;
      case "Média":
        color = Colors.secoundaryColorNeutral;
        break;
      case "Alta":
        color = Colors.attentionColor;
        break;
      case "Muito-Alta":
        color = Colors.alertRed;
        break;
      default:
        color = Colors.greenLight;
        break;
    }

    return color;
  }

  return (
    <Draggable isDragDisabled={isDragDisabled} draggableId={id} index={index}>
      {(provided, snapshop) => (
        <ViewActionsContainer
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <TitleContainer>
            <StartDateContainer>
              <StartDateText>
                <TodayRoundedIcon />
                Inicio {startDateAsString}
              </StartDateText>
              <IconContainer>
                <IconButton size="large" onClick={() => onTrashClickCallback()}>
                  <DeleteOutlineRoundedIcon
                    fontSize="large"
                    sx={{ color: Colors.sweetPink }}
                  />
                </IconButton>
              </IconContainer>
            </StartDateContainer>
            <TitleText title={title}>{title}</TitleText>
          </TitleContainer>

          <TagListContainer>
            <Box>
              <TagTitle>Prioridade</TagTitle>
              <TagContainer color={getPriorityColor(details.priority)}>
                <TagText>{details.priority}</TagText>
              </TagContainer>
            </Box>
            <Box>
              <TagTitle>Restrição</TagTitle>
              <TagContainer color={Colors.greenLight}>
                <TagText>{details.criteria}</TagText>
              </TagContainer>
            </Box>
          </TagListContainer>
          <DetailsContainer>
            {details.responsibleList.map((responsible) => (
              <ResponsibleContainer>
                <ResponsibleInitials>
                  {getConstructionInitialLetters(responsible)}
                </ResponsibleInitials>
                <Box display="flex" flexDirection="column">
                  <Typography
                    color={Colors.greenLight}
                    fontWeight={400}
                    fontSize="14px"
                  >
                    Responsável
                  </Typography>
                  <Typography
                    color={Colors.primaryColorDark}
                    fontWeight={700}
                    fontSize="16px"
                  >
                    {responsible}
                  </Typography>
                </Box>
              </ResponsibleContainer>
            ))}
            <TimeTag>
              <EventAvailableRoundedIcon
                sx={{ color: Colors.secondaryColorLight }}
              />{" "}
              <Typography>Término</Typography>{" "}
              <Typography fontWeight={700}>{deadlineDateAsString}</Typography>
            </TimeTag>
          </DetailsContainer>
        </ViewActionsContainer>
      )}
    </Draggable>
  );
};

const ViewActionsContainer = styled.div`
  display: grid;
  /* width: 311px; */
  width: 100%;
  /* height: 252px; */
  /* background: ${Colors.textColorLight}; */
  background: ${Colors.neutralColorWhite};
  border: 1px solid ${Colors.neutralColorWarm};
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 16px;
  /* grid-template-rows: min-content min-content auto min-content; */
  grid-template-columns: 1fr;
  margin-bottom: 16px;
  padding: 16px;
  /* margin-left: 8px;
  margin-right: 8px; */

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-bottom: 16px;
  }
`;

const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  border-bottom: 1px solid ${Colors.neutralColorWarm};
  padding-bottom: 15px;
`;

const TitleText = styled.div`
  overflow: hidden;
  font-family: "Noto Sans";
  height: fit-content;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: ${Colors.primaryColor};
`;

const IconContainer = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: ${Colors.sweetPink}55;
`;

const StartDateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StartDateText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${Colors.neutralColor};
  font-size: 14px;
  font-family: "Noto Sans";
  font-weight: 400;
  word-wrap: break-word;
`;

const TagListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  height: fit-content;
  border-bottom: 1px solid ${Colors.neutralColorWarm};
  padding: 20px 0px;
`;

const TagContainer = styled.div<{ color?: string }>`
  min-width: auto;
  margin-top: 8px;
  padding: 10px 12px;
  background-color: ${(props) =>
    props.color ? props.color : Colors.neutralColor};
  border-radius: 100px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const TagText = styled.div`
  color: #f1f0f0;
  font-size: 14px;
  font-family: "Noto Sans";
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.4px;
  word-wrap: break-word;
  height: fit-content;
  display: -webkit-box;
`;

const TagTitle = styled.div`
  color: ${Colors.gray};
  font-size: 14px;
  font-family: "Noto Sans";
  line-height: 16px;
  letter-spacing: 0.4px;
  word-wrap: break-word;
  height: fit-content;
  display: -webkit-box;
`;

const TimeTag = styled.div`
  display: inline-flex;
  gap: 5px;
  min-width: auto;
  margin-top: 8px;
  font-size: 14px;
  border-radius: 100px;
  align-items: center;
  border: 1px solid ${Colors.secoundaryColorNeutral};
  color: ${Colors.secondaryColorLight};
  padding: 4px 16px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: fit-content;
  margin-top: 20px;
`;

const ResponsibleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
`;

const ResponsibleInitials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.stTropaz};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 800;
  color: ${Colors.neutralColorWhite};
`;

export default ActionCard;
