import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import {
  Button,
  IconButton,
  Grid,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { Construction } from "../../../config/app-info";
import { api } from "../../../services/api";
import Alert from "../../../components/Alert";
import { useAuth } from "../../../contexts/auth";
//import { decrypt } from "../../../utils/crypto";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const WrapperDataGrid = styled.div`
  width: 100%;
`;

const ContentDataGrid = styled.div`
  height: 48vh;
  overflow-y: auto;
`;

type Props = {
  section: string;
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbar />
    </GridToolbarContainer>
  );
}
const ConstructionsList: React.FC<Props> = ({ section }) => {
  const [constructions, setConstructions] = useState<Construction[]>([]);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [currentConstructionId, setCurrentConstructionId] = useState(-1);
  const [alert, setAlert] = useState({
    title: "",
    message: "",
    isOpened: false,
  });
  const navigate = useNavigate();
  const { user } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  //const storageUser = sessionStorage.getItem("@Auth:user");

  useEffect(() => {
    const responsibleClientId = user.responsibleClientId
      ? Number(user.responsibleClientId)
      : 0;

    const fetchConstructions = async () => {
      try {
        const response = await api.get("api/constructions", {
          headers: { clientId: responsibleClientId },
        });
        setConstructions(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchConstructions();
  }, [user]);

  const doDelete = async () => {
    const indexConstruction = constructions.findIndex(
      (constructionItem) =>
        Number(constructionItem.id) === currentConstructionId
    );
    const currentConstruction = constructions[indexConstruction];
    try {
      const response = await api.delete(
        `api/constructions/${currentConstruction.id}`
      );

      if (response.data) {
        const newConstructions = [...constructions];
        newConstructions.splice(indexConstruction, 1);
        setConstructions(newConstructions);
      } else {
        setAlert({
          title: "Não foi possível remover esta obra!",
          message:
            "Por favor, remova todas as restrições e ações vinculadas a obra e tente novamente.",
          isOpened: true,
        });
      }
      setCurrentConstructionId(-1);
      setOpenDialogDelete(false);
    } catch (error) {
      setCurrentConstructionId(-1);
      setOpenDialogDelete(false);
      console.error(error);
    }
  };

  const handleClickOpenDelete = (constructionId: number) => {
    setCurrentConstructionId(constructionId);
    setOpenDialogDelete(true);
  };

  const handleCloseDelete = () => {
    setCurrentConstructionId(-1);
    setOpenDialogDelete(false);
  };

  const closeError = () => {
    setAlert({
      title: "",
      message: "",
      isOpened: false,
    });
  };

  const columns: GridColDef[] = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   flex: 0.5,
    //   type: "string",
    //   headerAlign: "center",
    //   align: "center",
    //   maxWidth: 20,
    // },
    {
      field: "code",
      headerName: "Código",
      flex: 0.5,
      type: "string",
      headerAlign: "center",
      align: "center",
      maxWidth: 80,
    },
    {
      field: "name",
      headerName: "Obra",
      flex: 1,
      type: "string",
    },
    {
      field: "clientId",
      headerName: "Cliente",
      flex: 1,
      type: "string",
      valueGetter: ({ row }) => row.clientId.name,
    },
    {
      field: "managerUserId",
      headerName: "Gestor",
      flex: 1,
      type: "string",
      valueGetter: ({ row }) => row.managerUserId.name,
    },
    {
      field: "executiveAdvisorUserId",
      headerName: "Assessor executivo",
      flex: 1,
      type: "string",
      valueGetter: ({ row }) => row.executiveAdvisorUserId.name,
    },
    {
      field: "finished",
      headerName: "Ativa",
      flex: 0.6,
      type: "string",
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => (params.value ? "Não" : "Sim"),
    },
    {
      field: "dtBegin",
      headerName: "Data início",
      flex: 1,
      type: "string",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (params.value !== null) {
          const date = new Date(String(params.value));
          return date.toLocaleDateString("pt-br");
        } else {
          return "-";
        }
      },
    },
    {
      field: "dtEnd",
      headerName: "Data fim",
      flex: 1,
      type: "string",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (params.value !== null) {
          const date = new Date(String(params.value));
          return date.toLocaleDateString("pt-br");
        } else {
          return "-";
        }
      },
    },
    {
      field: "actions",
      headerName: "Ações",
      width: 50,
      flex: 0.6,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <IconButton
            edge="end"
            aria-label="editar"
            onClick={() => navigate(`/obras/editar/${params.row.id}/`)}
            size="large"
          >
            <EditIcon />
          </IconButton>

          {user.role !== 6 && (
            <IconButton
              edge="end"
              aria-label="remover"
              onClick={() => handleClickOpenDelete(Number(params.id))}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>{section}</title>
      </Helmet>
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            {section}
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Card>
        <CardContent>
          <WrapperDataGrid>
            <ContentDataGrid>
              <DataGrid
                rows={constructions}
                columns={columns}
                components={{ Toolbar: CustomToolbar }}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      clientId: !isMobile,
                      managerUserId: !isMobile,
                      executiveAdvisorUserId: !isMobile,
                      dtBegin: !isMobile,
                      dtEnd: !isMobile,
                    },
                  },
                }}
              />
            </ContentDataGrid>
          </WrapperDataGrid>
        </CardContent>
      </Card>
      <Dialog
        open={openDialogDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Exclusão de obra</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja realmente excluir esta obra?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDelete}
            color="primary"
            variant="contained"
          >
            Não
          </Button>
          <Button
            onClick={doDelete}
            color="secondary"
            autoFocus
            variant="contained"
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>

      <Alert
        onClose={() => closeError()}
        isOpened={alert.isOpened}
        title={alert.title}
        message={alert.message}
        buttonLabel="Fechar"
      />
    </>
  );
};

export default ConstructionsList;
