import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../utils/mobileDimen";
import { Colors } from "../../theme/v2/variants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      marginBottom: 10,
    },
    formControl: {
      width: "100%",
      marginBottom: "10px",
    },
    buttonSave: {},
    input: {
      marginBottom: "10px",
    },
  })
);

export default useStyles;

export const FormContainer = styled.div<{isSideBar?: boolean}>`
  display: grid;
  grid-template-columns: ${(props) => (props.isSideBar ? '1fr' : 'repeat(5, 1fr)')} ;
  gap: 24px;
  margin-bottom: 28px;
  /* padding: ${(props) => props.isSideBar ? '24px 16px' : ''}; */
  /* padding-left: 16px;
  padding-right: 16px;
  padding-top: 24px; */
  

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
  }

  /* @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  } */

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
`;

export const DialogTitle = styled.div`
  color: ${Colors.primaryColor};
  font-size: 24px;
  font-family: "Noto Sans";
  font-weight: 700;
  margin-top: 12px;

  word-wrap: break-word;
`;


export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 100px;
  width: auto;

  @media (max-width: 1000px) {
    gap: 24px;
  }

  @media (max-width: 900px) {
    width: 100%;
    gap: 16px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

export const SearchBarContainer = styled.div<{isSideBar?: boolean}>`
  display: grid;
  width: ${(props)=> props.isSideBar ? '358px' : ''};
  height: 100%;
  /* grid-template-rows: auto auto auto auto auto auto; */
  align-content: start;
  row-gap: 32px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 24px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin-bottom: 20px;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 16px;
  }
`;
