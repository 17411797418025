import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled, { withTheme } from "styled-components/macro";
import { IconButton as MuiIconButton } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import NavbarUserDropdown from "./NavbarUserDropdown";
import { api } from "../../services/api";
import { useDashboard } from "../../contexts/dashboard";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import DropDownComponent from "../InputValues/DropDownComponent";
import { Colors } from "../../theme/variants";
import { MOBILE_BREAKPOINT } from "../../utils/mobileDimen";
import {
  Client,
  ORDER_OF_STEPS_PATH,
  PURCHASING_CONTRACTING_PLAN_PATH,
  isUserClientRole,
} from "../../config/app-info";
import "dayjs/locale/pt-br";
import logo from "./../../assets/logo-icon.svg";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

type NavbarProps = {
  theme: {};
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
  isMobile: boolean;
};

const Navbar: React.FC<NavbarProps> = ({ onDrawerToggle, isMobile }) => {
  const [dtFirstBeginDate, setDtFirstBeginDate] = useState<Date | null>(null);
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedConstruction, setSelectedConstruction] = useState("");

  const {
    clientId,
    setClientId,
    constructionId,
    setConstructionId,
    setDtBegin,
    setDtEnd,
    setIsDashboard,
    setConstructions,
    constructions,
    clients,
    setClients,
  } = useDashboard();

  const location = useLocation();
  const { user } = useAuth();
  const pathname = location.pathname;
  const startOfRoute = window.location.pathname.split("/")[1];

  const isNewScreens = useMemo(
    () =>
      [
        "/acompanhar-acoes",
        "/dashboard/",
        "/vertical-servico",
        "/mapeamento-restricoes/listar/",
        "mapeamento-restricoes/cadastrar/",
        "/mapeamento-restricoes/editar/",
        ORDER_OF_STEPS_PATH,
        PURCHASING_CONTRACTING_PLAN_PATH,
      ].some((route) => pathname.includes(route)),
    [pathname]
  );

  const isDashboardSection = pathname.indexOf("/dashboard/") !== -1;
  const routesToHideDropdown = ["empresas", "usuarios", "obras"];

  const fetchConstructions = useCallback(async () => {
    const response = await api.get(`api/constructions/client/${clientId}`);
    setConstructions(response.data);
  }, [clientId, setConstructions]);

  const fetchFirstDtBegin = useCallback(async () => {
    const response = await api.get(
      `api/actions/first-begin-date/client/${clientId}`
    );
    setDtFirstBeginDate(response.data);
  }, [clientId]);

  const fetchClients = useCallback(async () => {
    const response = await api.get("api/clients", {
      params: { status: true },
    });

    if (isUserClientRole(user.role) && user.responsibleClientId) {
      const client = response.data.find(
        (c: Client) => c.id === user.responsibleClientId
      );
      if (client) {
        setSelectedClient(client.name);
        setClients([client]);
        setClientId(client.id);
        return;
      }
    }

    setClients(response.data);
  }, [setClientId, setClients, user.role, user.responsibleClientId]);

  useEffect(() => {
    if (clientId > 0 && clients?.length) {
      const clientName = clients.find(({ id }) => +id === +clientId);
      setSelectedClient(clientName?.name || "");
      fetchConstructions();
      fetchFirstDtBegin();
    }
  }, [clientId, clients, fetchConstructions, fetchFirstDtBegin]);

  useEffect(() => {
    if (user?.id) {
      setIsDashboard(isDashboardSection);
      if (isDashboardSection || isNewScreens) fetchClients();

      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const lastDayOfMonth = new Date(year, today.getMonth() + 1, 0).getDate();
      setDtEnd(new Date(`${year}-${month}-${lastDayOfMonth} 08:00:00`));
      if (dtFirstBeginDate) setDtBegin(dtFirstBeginDate);
    }
  }, [
    dtFirstBeginDate,
    isDashboardSection,
    isNewScreens,
    pathname,
    setClientId,
    setClients,
    setConstructions,
    setDtBegin,
    setDtEnd,
    setIsDashboard,
    user,
    fetchClients,
  ]);

  useEffect(() => {
    if (!!constructionId) {
      const constructionName = constructions.find(
        (construction) => construction.id === constructionId
      );
      setSelectedConstruction(constructionName?.name || "");
    }
  }, [constructionId, constructions]);

  const clientNameList = useMemo(
    () => clients.map(({ name }) => name),
    [clients]
  );

  const constructionNameList = useMemo(
    () => constructions.map(({ name }) => name),
    [constructions]
  );

  const handleSelectClient = useCallback(
    (key: string, value: string) => {
      const clientId = clients.find(({ name }) => name === value)?.id;
      if (clientId) setClientId(clientId);
    },
    [clients, setClientId]
  );

  const handleSelectConstruction = useCallback(
    (key: string, value: string) => {
      const constructionId = constructions.find(
        ({ name }) => name === value
      )?.id;
      if (constructionId) setConstructionId(constructionId);
    },
    [constructions, setConstructionId]
  );

  return (
    <React.Fragment>
      <NavbarV2>
        <LogoContainer>
          {isMobile && (
            <IconButton onClick={onDrawerToggle}>
              <MenuIcon fontSize="large" />
            </IconButton>
          )}
          <LogoIcon src={logo} />
        </LogoContainer>

        {!routesToHideDropdown.includes(startOfRoute) ? (
          <DropdownWrapper>
            <DropdownContainer>
              <DropDownComponent
                defaultValue="Cliente"
                label="Cliente"
                key="clients"
                componentKey="clients"
                variant="outlined"
                isNavBar
                selectedValue={selectedClient}
                onSelectedValueChanged={handleSelectClient}
                values={clientNameList}
                sx={{ borderRadius: "50px" }}
              />
            </DropdownContainer>
            <DropdownContainer>
              <DropDownComponent
                defaultValue="Obra"
                label="Obra"
                key="obra"
                componentKey="obra"
                variant="outlined"
                isNavBar
                selectedValue={selectedConstruction}
                onSelectedValueChanged={handleSelectConstruction}
                values={constructionNameList}
                sx={{ borderRadius: "50px" }}
              />
            </DropdownContainer>
          </DropdownWrapper>
        ) : (
          <span></span>
        )}
        <ProfileComponentContainer>
          <NavbarUserDropdown />
        </ProfileComponentContainer>
      </NavbarV2>
    </React.Fragment>
  );
};

const NavbarV2 = styled.div`
  width: 100vw;
  /* height: 80px; */
  padding: 5px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  background-color: ${Colors.neutralColorWhite};
  display: grid;
  grid-template-columns: minmax(0px, 255px) 1fr max-content;
  align-items: center;
  justify-content: space-between;
  /* grid-gap: 24px; */
  z-index: 100;

  @media (max-width: 900px) {
    width: 100vw;
    padding: 15px 0px;
    gap: 10px;
    grid-template-rows: 1fr 1fr;
  }
`;

const DropdownWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  padding: 0px 24px;

  @media (max-width: 900px) {
    display: flex;
    justify-content: space-evenly;
    width: 100vw;
    padding: 0px 20px;
    grid-row: 2;
  }
`;

const ProfileComponentContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 8px;
  margin-right: 24px;
`;

const DropdownContainer = styled.div`
  width: 360px;

  @media (max-width: 1200px) {
    width: 280px;
  }

  @media (max-width: 900px) {
    width: 360px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }
`;

const LogoContainer = styled.div`
  width: 135px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    width: min-content;
    padding-left: 24px;
  }
`;

const LogoIcon = styled.img`
  width: 70px;

  @media (max-width: 900px) {
    width: 60px;
  }
`;

export default withTheme(Navbar);
