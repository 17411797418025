import { Modal } from "@mui/material";

import {
  BorderLinearProgress,
  ModalLoadingFilesContainer,
  ModalLoadingFilesText,
} from "../../Form/PurchasingAndContractingPlanForm.styles";
import React from "react";

interface LoadingModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
  progress: number;
}

const LoadingModal: React.FC<LoadingModalProps> = ({
  isModalOpen,
  closeModal,
  progress,
}) => {
  return (
    <Modal open={isModalOpen} onClose={closeModal} keepMounted>
      <ModalLoadingFilesContainer>
        <ModalLoadingFilesText>Carregando Documento...</ModalLoadingFilesText>
        <BorderLinearProgress variant="determinate" value={progress} />
      </ModalLoadingFilesContainer>
    </Modal>
  );
};

export default LoadingModal;
