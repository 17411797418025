import {
  Dialog,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import typography from "../../../../theme/newTheme/typography";
import ButtonComponent, {
  ButtonType,
} from "../../../../components/v2/Button/ButtonComponent";
import CheckIcon from "@mui/icons-material/Check";
import { LinearProgress } from "@mui/material";

interface DeleteModalProps {
  toggleModal: () => void;
  deleteHandler: () => void;
  open: boolean;
  isLoading: boolean;
  title: string;
  text: string;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  open,
  toggleModal,
  deleteHandler,
  isLoading,
  title,
  text,
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      PaperProps={{
        style: { borderRadius: "15px", padding: "10px 5px" },
      }}
      onClose={toggleModal}
      open={open}
    >
      <DialogTitle
        fontSize={typography.h3?.fontSize}
        fontWeight={typography.fontWeightBold}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography fontSize={typography.body1?.fontSize}>{text}</Typography>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <LinearProgress style={{ width: "100%" }} />
        ) : (
          <ButtonComponent
            startIcon={<CheckIcon fontSize="medium" />}
            text={"Confirmar"}
            type={ButtonType.DANGER}
            onClick={deleteHandler}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
