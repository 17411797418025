import React from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Delete from "@mui/icons-material/Delete";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { ImageOptionsContainer, HoverButton, TextButton } from "../styles";
import { Colors } from "../../../theme/v2/variants";

interface ImageOptionsMenuProps {
  onDeleteService: () => void;
  onFileSelect: (file: File) => void;
}

const ImageOptionsMenu: React.FC<ImageOptionsMenuProps> = ({
  onDeleteService,
  onFileSelect,
}) => {
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      onFileSelect(file);
    }
  };

  const handleAddImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <ImageOptionsContainer>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept=".png, .jpg, .jpeg, .svg"
        onChange={handleFileChange}
      />
      <HoverButton
        variant="text"
        onClick={handleAddImageClick}
        endIcon={
          <ArrowForwardIosOutlinedIcon
            style={{ color: Colors.neutralGrayLight, fontSize: 14 }}
          />
        }
      >
        <TextButton>
          <AddOutlinedIcon
            style={{ color: Colors.neutralGrayLight, fontSize: 24 }}
          />
          Atualizar imagem
        </TextButton>
      </HoverButton>

      <HoverButton
        variant="text"
        onClick={onDeleteService}
        endIcon={
          <ArrowForwardIosOutlinedIcon
            style={{ color: Colors.neutralGrayLight, fontSize: 14 }}
          />
        }
      >
        <TextButton>
          <Delete style={{ color: Colors.neutralGrayLight }} />
          Excluir imagem
        </TextButton>
      </HoverButton>
    </ImageOptionsContainer>
  );
};

export default ImageOptionsMenu;
