import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Formik, Field, FormikHelpers } from "formik";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import {
  TextField,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  Switch,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { DoneAll as DoneAllIcon } from "@mui/icons-material";
import Alert from "../../../components/Alert";
import { api } from "../../../services/api";
import { Criteria } from "../../../config/app-info";
import { useAuth } from "../../../contexts/auth";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const validationSchema = yup.object({
  name: yup
    .string()
    .min(3, "O nome deve ter no mínimo 3 letras")
    .required("Nome é obrigatório"),
  criteriaId: yup.string().required("Critério é obrigatório"),
});

export type RestrictionFormEntity = {
  id: number;
  name: string;
  status: boolean;
  code?: string;
  criteriaId: number;
  userId: number;
};

type Props = {
  section: string;
};

const RestrictionsForm: React.FC<Props> = ({ section }) => {
  const [restriction, setRestriction] = useState<RestrictionFormEntity>(
    {} as RestrictionFormEntity
  );
  const [alert, setAlert] = useState({
    title: "",
    message: "",
    isOpened: false,
  });
  const [criteria, setCriteria] = useState<Criteria[]>([]);
  let { id: restrictionId } = useParams();
  const { user } = useAuth();

  useEffect(() => {
    if (restrictionId) {
      const fetchUserData = async () => {
        const response = await api.get(`api/restrictions/${restrictionId}`);
        setRestriction(response.data);
      };
      fetchUserData();
    } else {
      setRestriction({} as RestrictionFormEntity);
    }

    const fetchCriteria = async () => {
      try {
        const response = await api.get("api/criteria");
        setCriteria(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCriteria();
  }, [restrictionId]);

  const onSubmit = async (
    values: RestrictionFormEntity,
    { setSubmitting, resetForm }: FormikHelpers<RestrictionFormEntity>
  ) => {
    setSubmitting(false);

    if (restrictionId) {
      try {
        const response = await api.put(
          `api/restrictions/${restrictionId}/`,
          values
        );

        if (response.status === 200 || response.status === 204) {
          setAlert({
            title: "Alteração concluída!",
            message: "Restrição alterada com sucesso",
            isOpened: true,
          });
        }
      } catch (error) {
        setAlert({
          title: "Erro na alteração",
          message: "Algo deu errado. Tente novamente mais tarde.",
          isOpened: true,
        });
      }
    } else {
      try {
        if (!values.status) values.status = false;
        values.userId = user.id;
        const response = await api.post("api/restrictions/", values);

        if (response.status === 201) {
          setAlert({
            title: "Cadastro concluído!",
            message: "Restrição cadastrada com sucesso",
            isOpened: true,
          });
          resetForm();
        }
      } catch (error) {
        setAlert({
          title: "Erro no cadastro",
          message: "Algo deu errado. Tente novamente mais tarde.",
          isOpened: true,
        });
      }
    }
  };

  const closeError = () => {
    setAlert({
      title: "",
      message: "",
      isOpened: false,
    });
  };

  return (
    <>
      <Helmet>
        <title>{section}</title>
      </Helmet>
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            {section}
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Card mb={6}>
        <CardContent>
          {restriction && (
            <Formik
              initialValues={
                restriction || {
                  name: "",
                  status: false,
                  code: "",
                  criteriaId: 0,
                }
              }
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                handleSubmit,
                values,
                handleChange,
                errors,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Grid container spacing={2}>
                    <Grid item xs={5} mb={4}>
                      <Field
                        component={TextField}
                        label="Nome"
                        id="name"
                        name="name"
                        onChange={handleChange}
                        value={values.name || ""}
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                      />
                    </Grid>

                    <Grid item xs={3} mb={2}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel htmlFor="criteriaId">Critério</InputLabel>
                        <Field
                          component={Select}
                          native
                          value={
                            values.criteriaId >= 0 ? values.criteriaId : -1
                          }
                          onChange={handleChange}
                          error={Boolean(errors.criteriaId)}
                          label="Critério"
                          inputProps={{
                            name: "criteriaId",
                            id: "criteriaId",
                          }}
                        >
                          <option
                            aria-label="Escolha um critério"
                            value=""
                          ></option>
                          {criteria.map((criteria) => {
                            return (
                              <option key={criteria.id} value={criteria.id}>
                                {criteria.name}
                              </option>
                            );
                          })}
                        </Field>
                        <FormHelperText error={Boolean(errors.criteriaId)}>
                          {errors.criteriaId}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={1} mb={2}>
                      <FormControlLabel
                        control={
                          <Field
                            component={Switch}
                            checked={values.status || false}
                            onChange={handleChange}
                            color="primary"
                            id="status"
                            name="status"
                            value={values.status || false}
                            inputProps={{ "aria-label": "status" }}
                          />
                        }
                        label="Ativo?"
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    endIcon={<DoneAllIcon />}
                  >
                    Salvar
                  </Button>
                </form>
              )}
            </Formik>
          )}
        </CardContent>
      </Card>
      <Alert
        onClose={() => closeError()}
        isOpened={alert.isOpened}
        title={alert.title}
        message={alert.message}
        buttonLabel="Fechar"
      />
    </>
  );
};

export default RestrictionsForm;
